import React, {useState, useEffect, useRef } from 'react';

import api from '../../../services/api';
import axios from 'axios';

import { useHistory } from "react-router-dom";
import moment from 'moment';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import Backdrop from '@mui/material/Backdrop';

import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';
import ButtonSecondaryOnlyIcon from '../../../componentes/ButtonSecondaryOnlyIcon';

import { DataMask, CpfMask, CnpjMask, mvalor, CEPMask } from '../../../vendor/mascaras';
import { formataDinheiro, formataDinheiroforBanco } from '../../../vendor/formatar';

import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";


export default function ModalGerirAtpv({vendaId, dadosVenda, dadosCompra, visible, change}) {
  

  let IconPrint = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 30.778 28.606">
        <g id="printing" transform="translate(0 -18.065)">
            <g id="Grupo_31" data-name="Grupo 31" transform="translate(9.917 41.014)">
            <g id="Grupo_30" data-name="Grupo 30">
                <path id="Caminho_68" data-name="Caminho 68" d="M174.894,399.834H166a1.026,1.026,0,1,0,0,2.052h8.891a1.026,1.026,0,0,0,0-2.052Z" transform="translate(-164.977 -399.834)" fill="#fff"/>
            </g>
            </g>
            <g id="Grupo_33" data-name="Grupo 33" transform="translate(9.917 37.778)">
            <g id="Grupo_32" data-name="Grupo 32">
                <path id="Caminho_69" data-name="Caminho 69" d="M174.894,346.006H166a1.026,1.026,0,1,0,0,2.052h8.891a1.026,1.026,0,0,0,0-2.052Z" transform="translate(-164.977 -346.006)" fill="#fff"/>
            </g>
            </g>
            <g id="Grupo_35" data-name="Grupo 35" transform="translate(0 18.065)">
            <g id="Grupo_34" data-name="Grupo 34">
                <path id="Caminho_70" data-name="Caminho 70" d="M28.384,25.029H25.119V19.091a1.026,1.026,0,0,0-1.026-1.026H6.684a1.026,1.026,0,0,0-1.026,1.026v5.938H2.394A2.4,2.4,0,0,0,0,27.422V37.744a2.4,2.4,0,0,0,2.394,2.394H5.658v5.507a1.026,1.026,0,0,0,1.026,1.026H24.093a1.026,1.026,0,0,0,1.026-1.026V40.138h3.264a2.4,2.4,0,0,0,2.394-2.394V27.422A2.4,2.4,0,0,0,28.384,25.029ZM7.71,20.117H23.068v4.912H7.71Zm15.358,24.5H7.71V36.225H23.067C23.067,36.48,23.067,44.426,23.067,44.619Zm1.026-13.566H21.482a1.026,1.026,0,1,1,0-2.052h2.611a1.026,1.026,0,0,1,0,2.052Z" transform="translate(0 -18.065)" fill="#fff"/>
            </g>
            </g>
        </g>
    </svg>

  )

  let IconSalvar = props =>(
    <svg id="diskette" xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 57.091 57.091">
      <g id="Grupo_376" data-name="Grupo 376" transform="translate(16.837 47.056)">
        <g id="Grupo_375" data-name="Grupo 375">
          <path id="Caminho_292" data-name="Caminho 292" d="M172.744,422H152.673a1.673,1.673,0,1,0,0,3.345h20.071a1.673,1.673,0,1,0,0-3.345Z" transform="translate(-151 -422)" fill="#fff"/>
        </g>
      </g>
      <g id="Grupo_378" data-name="Grupo 378" transform="translate(16.837 33.675)">
        <g id="Grupo_377" data-name="Grupo 377">
          <path id="Caminho_293" data-name="Caminho 293" d="M172.744,302H152.673a1.673,1.673,0,0,0,0,3.345h20.071a1.673,1.673,0,0,0,0-3.345Z" transform="translate(-151 -302)" fill="#fff"/>
        </g>
      </g>
      <g id="Grupo_380" data-name="Grupo 380" transform="translate(16.837 40.365)">
        <g id="Grupo_379" data-name="Grupo 379">
          <path id="Caminho_294" data-name="Caminho 294" d="M172.744,362H152.673a1.673,1.673,0,1,0,0,3.345h20.071a1.673,1.673,0,1,0,0-3.345Z" transform="translate(-151 -362)" fill="#fff"/>
        </g>
      </g>
      <g id="Grupo_382" data-name="Grupo 382" transform="translate(13.492)">
        <g id="Grupo_381" data-name="Grupo 381">
          <rect id="Retângulo_64" data-name="Retângulo 64" width="24" height="14" transform="translate(-0.492 0.091)" fill="#fff"/>
        </g>
      </g>
      <g id="Grupo_384" data-name="Grupo 384">
        <g id="Grupo_383" data-name="Grupo 383">
          <path id="Caminho_295" data-name="Caminho 295" d="M56.6,9.41,47.681.49A1.673,1.673,0,0,0,46.5,0H40.254V16.168a1.673,1.673,0,0,1-1.673,1.673H11.82a1.673,1.673,0,0,1-1.673-1.673V0H1.673A1.673,1.673,0,0,0,0,1.673V55.419a1.673,1.673,0,0,0,1.673,1.673H55.419a1.673,1.673,0,0,0,1.673-1.673V10.593A1.673,1.673,0,0,0,56.6,9.41Zm-13,44.336H13.492V30.33H43.6Z" fill="#fff"/>
        </g>
      </g>
    </svg>
  )


  const inputNumero = useRef(null);
  let history = useHistory();

  const [tabPage, settabPage] = useState("1");
  const [loaderPage, setloaderPage] = useState(false);

  const [dadosatpv, setdadosatpv] = useState([]);
  const [tipoPessoaComprador, settipoPessoaComprador] = useState(1);

  const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);

  const [alertError, setalertError] = useState(false);
  const [alertErrorMsg, setalertErrorMsg] = useState('');

  const [alertSucess, setalertSucess] = useState(false);
  const [alertSucessMsg, setalertSucessMsg] = useState('');

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertError(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertSucess(false);
  };





  const detalhesDadosAtpv = async()=>{
      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let response = await api.post('detalhesDadosAtpv', {
          "venda_id": vendaId
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      let data = response.data;
      setdadosatpv(response.data);


      let fornecedor = await api.post('detalhesPessoa', {
          "codigo": dadosCompra?.fornecedorCodigo
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      fornecedor = fornecedor.data;

      let cliente = await api.post('detalhesPessoa', {
          "codigo": dadosVenda?.clienteCodigo
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      cliente = cliente.data;


      let enderecosCliente = await api.post('listagemEnderecosPesssoa', {
        "pessoaId": cliente?.id
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      enderecosCliente = enderecosCliente.data[0];

      formik.setFieldValue('placa', (data.placa ? data.placa : dadosVenda?.placa));
      formik.setFieldValue('renavam', (data.renavam ? data.renavam : dadosVenda?.renavam));
      formik.setFieldValue('chassi', (data.chassi ? data.chassi : dadosVenda?.chassi));
      formik.setFieldValue('km', (data.km ? data.km : dadosCompra?.km));

      formik.setFieldValue('nomeProprietario', (data.nomeProprietario ? data.nomeProprietario : dadosCompra?.fornecedorNome));
      formik.setFieldValue('emailProprietario', (data.emailProprietario ? data.emailProprietario : fornecedor?.email));
      
      if(fornecedor?.tipo === 1){
        formik.setFieldValue('cpfCnpjProprietario', (data.cpfCnpjProprietario ? data.cpfCnpjProprietario : fornecedor?.cpf));
      }else{
        formik.setFieldValue('cpfCnpjProprietario', (data.cpfCnpjProprietario ? data.cpfCnpjProprietario : fornecedor?.cnpj));
      }
      
      formik.setFieldValue('valorVenda', formataDinheiro(data.valorVenda ? data.valorVenda : dadosVenda?.precoVenda));

      formik.setFieldValue('nomeComprador', (data.nomeComprador ? data.nomeComprador : cliente?.nome));


      settipoPessoaComprador(cliente?.tipo);

      if(cliente?.tipo === 1){
        formik.setFieldValue('cpfCnpjComprador', (data.cpfCnpjComprador ? data.cpfCnpjComprador : cliente?.cpf));
        formik.setFieldValue('rgComprador', (data.rgComprador ? data.rgComprador : cliente?.rg));
      }else{
        formik.setFieldValue('cpfCnpjComprador', (data.cpfCnpjComprador ? data.cpfCnpjComprador : cliente?.cnpj));
      }

      formik.setFieldValue('emailComprador', (data.emailComprador ? data.emailComprador : cliente?.email));

      formik.setFieldValue('cepComprador', (data.cepComprador ? data.cepComprador : enderecosCliente?.cep)); 
      formik.setFieldValue('ruaComprador', (data.ruaComprador ? data.ruaComprador : enderecosCliente?.rua));
      formik.setFieldValue('numeroComprador', (data.numeroComprador ? data.numeroComprador : enderecosCliente?.numero));
      formik.setFieldValue('bairroComprador', (data.bairroComprador ? data.bairroComprador : enderecosCliente?.bairro));
      formik.setFieldValue('cidadeComprador', (data.cidadeComprador ? data.cidadeComprador : enderecosCliente?.cidade));
      formik.setFieldValue('estadoComprador', (data.estadoComprador ? data.estadoComprador : enderecosCliente?.estado));
      formik.setFieldValue('complementoComprador', (data.complementoComprador ? data.complementoComprador : enderecosCliente?.complemento));
      
      
  }

  const permissoesUsuario = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post('permissoesUsuario', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    // console.log(permissoes.data.pessoas[3].update);
    setpermissoesdoUsuario(permissoes.data.pessoas);
  }



  const validationSchema = yup.object({
    // tipo: yup
    //   .number('Seleciona o tipo de pessoa')
    //   .required('Tipo Obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      placa: '',
      renavam: '',
      chassi: '',
      km: '',
      nomeProprietario: '',
      emailProprietario: '',
      cpfCnpjProprietario: '',
      cpfCnpjComprador: '',
      rgComprador: '',
      nomeComprador: '',
      emailComprador: '',
      valorVenda: '',
      cepComprador: '',
      ruaComprador: '',
      numeroComprador: '',
      bairroComprador: '',
      cidadeComprador: '',
      estadoComprador: '',
      complementoComprador: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
    
      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.put(`updateDadosATPV`, {
          "venda_id": vendaId,
          "placa": values.placa ? values.placa : null,
          "renavam": values.renavam ? values.renavam : null,
          "chassi": values.chassi ? values.chassi : null,
          "km": values.km ? values.km : null,
          "nomeProprietario": values.nomeProprietario ? values.nomeProprietario : null,
          "emailProprietario": values.emailProprietario ? values.emailProprietario : null,
          "cpfCnpjProprietario": values.cpfCnpjProprietario ? values.cpfCnpjProprietario : null,
          "cpfCnpjComprador": values.cpfCnpjComprador ? values.cpfCnpjComprador : null,
          "rgComprador": values.rgComprador ? values.rgComprador : null,
          "nomeComprador": values.nomeComprador ? values.nomeComprador : null,
          "emailComprador": values.emailComprador ? values.emailComprador : null,
          "valorVenda": values.valorVenda ? formataDinheiroforBanco(values.valorVenda) : null,
          "cepComprador": values.cepComprador ? values.cepComprador : null,
          "ruaComprador": values.ruaComprador ? values.ruaComprador : null,
          "numeroComprador": values.numeroComprador ? values.numeroComprador : null,
          "bairroComprador": values.bairroComprador ? values.bairroComprador : null,
          "cidadeComprador": values.cidadeComprador ? values.cidadeComprador : null,
          "estadoComprador": values.estadoComprador ? values.estadoComprador : null,
          "complementoComprador": values.complementoComprador ? values.complementoComprador : null
      },
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      setalertSucess(true);        
      setalertSucessMsg(`Dados Atualizados com sucesso!`);        
      setTimeout(() => { setalertSucess(false) }, 6000);
      
    }
  });


  const handleBuscaCep = async(props)=>{
    formik.handleChange(props);
    
    let cep = props.target.value;
    
    if(cep.length >= 9){

      setloaderPage(true);

      let response = await axios({
        method: 'get',
        url: `https://viacep.com.br/ws/${cep}/json/`,
        headers: { 
          "Content-type": "application/json"
        }
      });

      let data = response.data;
        
      if(data.cep){
        data?.logradouro && formik.setFieldValue('ruaComprador', data.logradouro);
        data?.bairro && formik.setFieldValue('bairroComprador', data.bairro);
        data?.localidade && formik.setFieldValue('cidadeComprador', data.localidade);
        data?.uf && formik.setFieldValue('estadoComprador', data.uf);
        
        inputNumero.current.focus();
      }

      setloaderPage(false);
    }

  }


  useEffect(async ()=>{ 

    if(visible){
        setloaderPage(true);
        await detalhesDadosAtpv();
        settabPage("1");
        permissoesUsuario();
        setloaderPage(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[visible]);


  const Input = styled('input')({
    display: 'none',
  });



  const handleChangeTabPage = (event, newValue) => {
    settabPage(newValue); 
  };


  const handleGerarDadosATPV = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);


    let dadosAtpv = await api.post(`detalhesDadosAtpv`, {
      "venda_id": vendaId
    },
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    dadosAtpv = dadosAtpv.data;

    NativeFancybox.show(
      // Array containing gallery items
      [
        // Gallery item
        {
          src: `https://sistema.desdobrar.com/apps/gerarATPV.php?data=${window.btoa(JSON.stringify(dadosAtpv))}`,
          type: "iframe",
          preload: false,
        },
      ],
      // Gallery options
      {
        mainClass: "myFancybox",
      }
    );

  }

  return(
    <>

    <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
    <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
        {alertErrorMsg}
    </Alert>
    </Snackbar>

    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
    <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
        {alertSucessMsg}
    </Alert>
    </Snackbar>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Dados para ATPV
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={1} >
            <TabContext value={tabPage}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}> 
                <Tabs value={tabPage} onChange={handleChangeTabPage} aria-label="estoque-tabs" textColor="primary" indicatorColor="primary" variant="scrollable">
                    <Tab label="Veículo" value="1" /> 
                    <Tab label="Vendedor" value="2" />
                    <Tab label="Comprador" value="3" />
                </Tabs>
                </Box>

                <TabPanel value="1" style={{width: '100%'}}>
                    {
                        dadosatpv.length === 1 ?

                        <Grid container item spacing={0} style={{width: '100%'}}>
                            <Grid container spacing={2} >
       
                                <Grid item xs={12} md={4}>
                                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                                </Grid>
                            
                            </Grid>
                        </Grid>

                        :
                        <>
                            <Grid container item spacing={2}>
                                    
                                <Grid item xs={12} md={4} >
                                    <TextField
                                        fullWidth
                                        id="placa"
                                        name="placa"
                                        label="Placa"
                                        margin="none"
                                        type="text"
                                        value={formik.values.placa}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.placa && Boolean(formik.errors.placa)}
                                        helperText={formik.touched.placa && formik.errors.placa}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4} >
                                    <TextField
                                        fullWidth
                                        id="valorVenda"
                                        name="valorVenda"
                                        label="Valor venda"
                                        margin="none"
                                        type="text"
                                        value={formik.values.valorVenda}
                                        onChange={formik.handleChange}
                                        error={formik.touched.valorVenda && Boolean(formik.errors.valorVenda)}
                                        helperText={formik.touched.valorVenda && formik.errors.valorVenda}
                                        onKeyUp={()=>{ formik.setFieldValue('valorVenda', formik.values.valorVenda && mvalor(formik.values.valorVenda)) }}
                                        inputProps={{
                                            maxLength: 13,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4} >
                                    <TextField
                                        fullWidth
                                        id="renavam"
                                        name="renavam"
                                        label="Renavam"
                                        margin="none"
                                        type="text"
                                        value={formik.values.renavam}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.renavam && Boolean(formik.errors.renavam)}
                                        helperText={formik.touched.renavam && formik.errors.renavam}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} >
                                    <TextField
                                        fullWidth
                                        id="chassi"
                                        name="chassi"
                                        label="Chassi"
                                        margin="none"
                                        type="text"
                                        value={formik.values.chassi}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.chassi && Boolean(formik.errors.chassi)}
                                        helperText={formik.touched.chassi && formik.errors.chassi}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} >
                                    <TextField
                                        fullWidth
                                        id="km"
                                        name="km"
                                        label="Km"
                                        margin="none"
                                        type="text"
                                        value={formik.values.km}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.km && Boolean(formik.errors.km)}
                                        helperText={formik.touched.km && formik.errors.km}
                                    />
                                </Grid>
                                
                            </Grid>
                        </>
                    }
                </TabPanel>

                <TabPanel value="2" style={{width: '100%'}}>
                    {
                        dadosatpv.length === 1 ?

                        <Grid container item spacing={0} style={{width: '100%'}}>
                            <Grid container spacing={2} >
       
                                <Grid item xs={12} md={4}>
                                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                                </Grid>
                            
                            </Grid>
                        </Grid>

                        :
                        <>
                            <Grid container item spacing={2}> 

                                <Grid item xs={12} md={12} >
                                    <TextField
                                        fullWidth
                                        id="nomeProprietario"
                                        name="nomeProprietario"
                                        label="Nome Vendedor"
                                        margin="none"
                                        type="text"
                                        value={formik.values.nomeProprietario}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.nomeProprietario && Boolean(formik.errors.nomeProprietario)}
                                        helperText={formik.touched.nomeProprietario && formik.errors.nomeProprietario}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12} >
                                    <TextField
                                        fullWidth
                                        id="emailProprietario"
                                        name="emailProprietario"
                                        label="E-mail Vendedor"
                                        margin="none"
                                        type="text"
                                        value={formik.values.emailProprietario}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.emailProprietario && Boolean(formik.errors.emailProprietario)}
                                        helperText={formik.touched.emailProprietario && formik.errors.emailProprietario}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12} >
                                    <TextField
                                        fullWidth
                                        id="cpfCnpjProprietario"
                                        name="cpfCnpjProprietario"
                                        label="CPNJ/CPF Vendedor"
                                        margin="none"
                                        type="text"
                                        value={formik.values.cpfCnpjProprietario}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.cpfCnpjProprietario && Boolean(formik.errors.cpfCnpjProprietario)}
                                        helperText={formik.touched.cpfCnpjProprietario && formik.errors.cpfCnpjProprietario}
                                    />
                                </Grid>

                                
               
                            </Grid>
                        </>
                    }
                </TabPanel>

                <TabPanel value="3" style={{width: '100%'}}>
                    {
                        dadosatpv.length === 1 ?

                        <Grid container item spacing={0} style={{width: '100%'}}>
                            <Grid container spacing={2} >
       
                                <Grid item xs={12} md={12}>
                                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                                </Grid>
                            
                            </Grid>
                        </Grid>

                        :
                        <>
                            <Grid container item spacing={2}>


                            <Grid item xs={12} md={12} >
                                    <TextField
                                        fullWidth
                                        id="nomeComprador"
                                        name="nomeComprador"
                                        label="Nome Comprador"
                                        margin="none"
                                        type="text"
                                        value={formik.values.nomeComprador}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.nomeComprador && Boolean(formik.errors.nomeComprador)}
                                        helperText={formik.touched.nomeComprador && formik.errors.nomeComprador}
                                    />
                                </Grid>

                                <Grid item xs={12} md={tipoPessoaComprador === 1 ? 5 : 6} >
                                    <TextField
                                        fullWidth
                                        id="emailComprador"
                                        name="emailComprador"
                                        label="E-mail Comprador"
                                        margin="none"
                                        type="text"
                                        value={formik.values.emailComprador}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.emailComprador && Boolean(formik.errors.emailComprador)}
                                        helperText={formik.touched.emailComprador && formik.errors.emailComprador}
                                    />
                                </Grid>

                                <Grid item xs={12} md={tipoPessoaComprador === 1 ? 3.5 : 6} >
                                    <TextField
                                        fullWidth
                                        id="cpfCnpjComprador"
                                        name="cpfCnpjComprador"
                                        label="CPNJ/CPF Comprador"
                                        margin="none"
                                        type="text"
                                        value={formik.values.cpfCnpjComprador}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.cpfCnpjComprador && Boolean(formik.errors.cpfCnpjComprador)}
                                        helperText={formik.touched.cpfCnpjComprador && formik.errors.cpfCnpjComprador}
                                    />
                                </Grid>


                                {
                                  tipoPessoaComprador === 1 &&

                                  <Grid item xs={12} md={3.5} >
                                    <TextField
                                        fullWidth
                                        id="rgComprador"
                                        name="rgComprador"
                                        label="RG Comprador"
                                        margin="none"
                                        type="text"
                                        value={formik.values.rgComprador}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.rgComprador && Boolean(formik.errors.rgComprador)}
                                        helperText={formik.touched.rgComprador && formik.errors.rgComprador}
                                    />
                                  </Grid>
                                }


                                

                                <Grid item xs={12} md={4} >
                                    <TextField
                                        fullWidth
                                        id="cepComprador"
                                        name="cepComprador"
                                        label="CEP Comprador"
                                        margin="none"
                                        type="text"
                                        value={formik.values.cepComprador}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={handleBuscaCep}
                                        error={formik.touched.cepComprador && Boolean(formik.errors.cepComprador)}
                                        helperText={formik.touched.cepComprador && formik.errors.cepComprador}
                                        InputProps={{
                                            inputComponent: CEPMask,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={8} >
                                    <TextField
                                        fullWidth
                                        id="ruaComprador"
                                        name="ruaComprador"
                                        label="Rua Comprador"
                                        margin="none"
                                        type="text"
                                        value={formik.values.ruaComprador}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.ruaComprador && Boolean(formik.errors.ruaComprador)}
                                        helperText={formik.touched.ruaComprador && formik.errors.ruaComprador}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4} >
                                    <TextField
                                        fullWidth
                                        inputRef={inputNumero}
                                        id="numeroComprador"
                                        name="numeroComprador"
                                        label="Número Comprador"
                                        margin="none"
                                        type="text"
                                        value={formik.values.numeroComprador}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.numeroComprador && Boolean(formik.errors.numeroComprador)}
                                        helperText={formik.touched.numeroComprador && formik.errors.numeroComprador}
                                    />
                                </Grid>

                                <Grid item xs={12} md={8} >
                                    <TextField
                                        fullWidth
                                        id="bairroComprador"
                                        name="bairroComprador"
                                        label="Bairro Comprador"
                                        margin="none"
                                        type="text"
                                        value={formik.values.bairroComprador}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.bairroComprador && Boolean(formik.errors.bairroComprador)}
                                        helperText={formik.touched.bairroComprador && formik.errors.bairroComprador}
                                    />
                                </Grid>

                                <Grid item xs={12} md={8} >
                                    <TextField
                                        fullWidth
                                        id="cidadeComprador"
                                        name="cidadeComprador"
                                        label="Cidade Comprador"
                                        margin="none"
                                        type="text"
                                        value={formik.values.cidadeComprador}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.cidadeComprador && Boolean(formik.errors.cidadeComprador)}
                                        helperText={formik.touched.cidadeComprador && formik.errors.cidadeComprador}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4} >
                                    <TextField
                                        fullWidth
                                        id="estadoComprador"
                                        name="estadoComprador"
                                        label="Estado Comprador"
                                        margin="none"
                                        type="text"
                                        value={formik.values.estadoComprador}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.estadoComprador && Boolean(formik.errors.estadoComprador)}
                                        helperText={formik.touched.estadoComprador && formik.errors.estadoComprador}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12} >
                                    <TextField
                                        fullWidth
                                        id="complementoComprador"
                                        name="complementoComprador"
                                        label="Complemento Comprador"
                                        margin="none"
                                        type="text"
                                        value={formik.values.complementoComprador}
                                        disabled={!permissoesdoUsuario[3]?.update}
                                        onChange={formik.handleChange}
                                        error={formik.touched.complementoComprador && Boolean(formik.errors.complementoComprador)}
                                        helperText={formik.touched.complementoComprador && formik.errors.complementoComprador}
                                    />
                                </Grid>
                                    

                                
                                
                            </Grid>
                        </>
                    }
                </TabPanel>

            </TabContext>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>
            

          <Grid container item spacing={1.5} xs={3.7} md={5.8}>
              <Grid item xs={3} md={2} >
                  <ButtonSecondaryOnlyIcon type="button" icon={<IconPrint/>} onClick={()=>{ handleGerarDadosATPV() }} />
              </Grid>
          </Grid>


          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon={<IconSalvar/>} txt="Salvar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}