import React, { useState, useEffect, useRef } from "react";

import api from "../../../../services/api";
import { useHistory } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";

import TextField from "@mui/material/TextField";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import ButtonDefault from "../../../../componentes/ButtonDefault";
import ButtonOutlineSecondary from "../../../../componentes/ButtonOutlineSecondary";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { TelMask, mtel } from '../../../../vendor/mascaras';

export default function ModalEditContato({ idLista, detalhes, visible, change, getContatos }) {
  let history = useHistory();
  const inputNumero = useRef(null);

  const [loaderPage, setloaderPage] = useState(false);

  const [alertError, setalertError] = useState(false);
  const [alertErrorMsg, setalertErrorMsg] = useState("");

  const [alertSucess, setalertSucess] = useState(false);
  const [alertSucessMsg, setalertSucessMsg] = useState("");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseAlertError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setalertError(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setalertSucess(false);
  };

  const validationSchema = yup.object({
    nome: yup.string("Insira o nome do contato").required("Nome Obrigatório"),
    numero: yup.string("Insira o telefone do contato").required("Telefone Obrigatório")
  });

  const formik = useFormik({
    initialValues: {
      nome: "",
      numero: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let response = await api.put(
        "updateContatoListaTransmissao",
        {
          id: detalhes?.id,
          nome: values.nome,
          numero: values.numero,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      change(false);
      getContatos(idLista);

      setalertSucess(true);
      setalertSucessMsg(`Contato Atualizado com sucesso!`);
      setTimeout(() => {
        setalertSucess(false);
      }, 6000);
    },
  });



  useEffect(async () => {
    if(visible){
      formik.setFieldValue('nome', detalhes.nome);
      formik.setFieldValue('numero', mtel(detalhes.numero));
    }

  }, [visible]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: "2000" }}
        open={loaderPage}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={alertError}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseAlertError}
      >
        <Alert
          onClose={handleCloseAlertError}
          severity="error"
          sx={{ width: "100%", marginTop: "-150px" }}
        >
          {alertErrorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={alertSucess}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseAlertSuccess}
      >
        <Alert
          onClose={handleCloseAlertSuccess}
          severity="success"
          sx={{ width: "100%", marginTop: "-150px" }}
        >
          {alertSucessMsg}
        </Alert>
      </Snackbar>

      <Dialog
        open={visible}
        maxWidth="xs"
        fullWidth={true}
        onClose={() => {
          change(false);
        }}
      >
        <form method="post" onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            Editar contato
            <IconButton
              aria-label="close"
              onClick={() => {
                change(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText></DialogContentText>

            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id="nome"
                  name="nome"
                  label="Nome"
                  margin="normal"
                  type="text"
                  value={formik.values.nome}
                  onChange={formik.handleChange}
                  error={formik.touched.nome && Boolean(formik.errors.nome)}
                  helperText={formik.touched.nome && formik.errors.nome}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  inputRef={inputNumero}
                  fullWidth
                  id="numero"
                  name="numero"
                  label="Número"
                  margin="normal"
                  type="text"
                  value={formik.values.numero}
                  onChange={formik.handleChange}
                  error={formik.touched.numero && Boolean(formik.errors.numero)}
                  helperText={formik.touched.numero && formik.errors.numero}
                  onKeyUp={()=>{ formik.setFieldValue('numero', mtel(formik.values.numero)) }}
                  inputProps={{
                    maxLength: 15,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
            >
              <Grid item xs={4} md={4}>
                <ButtonOutlineSecondary
                  type="button"
                  onClick={() => {
                    change(false);
                  }}
                  icon=""
                  txt="Cancelar"
                />
              </Grid>

              <Grid item xs={4} md={4} style={{ marginRight: 15 }}>
                <ButtonDefault type="submit" icon="" txt="Salvar" />
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
