import React, {useContext, useState} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import Footer from '../../componentes/Footer';
import SubMenusFinancas from './submenusFinancas';

import TabContasApagar from './tabContasApagar';
import TabContasAreceber from './tabContasAreceber';
import TabComissoesApagar from './tabComissoesApagar';
import { GlobalContext } from '../../context/GlobalContext';

export default function Contas() {

  const { whatsAppInfo } = useContext(GlobalContext);
  const [tabPage, settabPage] = useState('1');

  const handleChangeTabPage = (event, newValue) => {
    settabPage(newValue);
  };


  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcar - Contas</title>
        </Helmet>
      </HelmetProvider>

      <div>
        <div className='container-page'>

          <SubMenusFinancas activePage="contas" />

          <div className={`${whatsAppInfo?.session ? 'container' : 'container-no-margin' }`}>
            <TabContext value={tabPage}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '10px' }}>
                <TabList onChange={handleChangeTabPage} aria-label="" textColor="primary" indicatorColor="primary">
                  <Tab label="Contas a pagar" value="1" />
                  <Tab label="Contas a receber" value="2" />
                  <Tab label="Comissões a pagar" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <TabContasApagar />
              </TabPanel>

              <TabPanel value="2">
                <TabContasAreceber />
              </TabPanel>

              <TabPanel value="3">
                <TabComissoesApagar />
              </TabPanel>
            </TabContext>
          </div>

        </div>
        <Footer/>
      </div>
    </>
    
  );
}