import React, {useState, useEffect } from 'react';
import api from '../../../services/api';
import moment from 'moment';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';
import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';


import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { mvalor, mnumero } from '../../../vendor/mascaras';
import { formataDinheiroforBanco } from '../../../vendor/formatar';

export default function ModalEditplanoContas({ planoContaId, visible, change, refreshPlanoContas}) {

  const [loaderPage, setloaderPage] = useState(false);
  const [dadosPlanocontas, setdadosPlanocontas] = useState([]);


  const detalhesPlanoContas = async()=>{

    setloaderPage(true);

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    planoContaId && api.post('detalhesPlanoContas', {
        "id": planoContaId
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    }).then( async response => {
        
        let data = response.data;
        data.descricao && formik.setFieldValue('descricao', data.descricao);
        data.operacao && formik.setFieldValue('operacao', data.operacao);
        setdadosPlanocontas(data);

    }).catch((error) => {
        
    });

    setloaderPage(false);

  }


 
  useEffect(()=>{
    detalhesPlanoContas();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[planoContaId]);

  const validationSchema = yup.object({
    descricao: yup
      .string('Informe o nome do caixa')
      .required('Nome Obrigatório'),
    operacao: yup
      .string('Informe a operação')
      .required('Operação Obrigatória'),
  });

  const formik = useFormik({
    initialValues: {
       descricao: '',
       operacao: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.put('updatePlanoContas', {
        "id": planoContaId,
        "descricao": values.descricao,
        "operacao": values.operacao,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

      refreshPlanoContas();
      change(false);

    }
  });

  

  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} scroll='body' onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Editar plano de contas: {dadosPlanocontas?.descricao}
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={2.5} style={{marginTop: '0px'}}>

                <Grid item xs={12} md={6} >
                    <TextField
                        fullWidth
                        id="descricao"
                        name="descricao"
                        label="Descrição"
                        margin="none"
                        type="text"
                        value={formik.values.descricao}
                        onChange={formik.handleChange}
                        error={formik.touched.descricao && Boolean(formik.errors.descricao)}
                        helperText={formik.touched.descricao && formik.errors.descricao}
                        inputProps={{
                            maxLength: 155,
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={6} >
                    <FormControl sx={{ m: 0, width: '100%' }} >
                    <InputLabel id="label-operacao">Operação</InputLabel>
                    <Select
                        labelId="label-operacao"
                        name="operacao"
                        label="Operação"
                        placeholder='Operação'
                        value={formik.values.operacao}
                        onChange={formik.handleChange}
                        error={formik.touched.operacao && Boolean(formik.errors.operacao)}
                    >

                        <MenuItem value='1'>Crédito</MenuItem>
                        <MenuItem value='2'>Débito</MenuItem>
                        {/* <MenuItem value='3'>Neutra</MenuItem> */}

                    </Select>
                    <FormHelperText error>{formik.touched.operacao && formik.errors.operacao}</FormHelperText>
                    </FormControl>
                </Grid>
                
          </Grid>
        </DialogContent>

        <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

            <Grid item xs={4} md={3}>
                <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
            </Grid>
            
            <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon=""  txt="Salvar" />
            </Grid>

        </Grid>
        </DialogActions>
        
        </form>
    </Dialog>
    </>
  );

}