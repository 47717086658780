import React, {useState, useEffect } from 'react';
import api from '../../../services/api';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';

import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { mvalor } from '../../../vendor/mascaras';
import { formataDinheiro, formataDinheiroforBanco } from '../../../vendor/formatar';

export default function ModalEditDespesa({ idDespesa, visible, change, refreshDespesas}) {

  const [loaderPage, setloaderPage] = useState(false);
  const [dadosDespesa, setdadosDespesa] = useState([]);
 
  
  const detalhesDespesa = async()=>{

    setloaderPage(true);

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    idDespesa && api.post('detalhesDespesasVeiculo', {
        "id": idDespesa
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    }).then( async response => {
        
        let data = response.data;
        data.descricao && formik.setFieldValue('descricao', data.descricao);
        data.valor && formik.setFieldValue('valor', formataDinheiro(data.valor));
        setdadosDespesa(data);

    }).catch((error) => {
        
    });

    setloaderPage(false);

  }


  useEffect(()=>{
    visible === true && detalhesDespesa();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[visible]);


  const validationSchema = yup.object({
    descricao: yup
      .string('Informe a descrição')
      .required('Descrição Obrigatório'),
    valor: yup
      .string('Informe o valor')
      .required('Valor Obrigatório')
      .test({
        message: () => "Informe o valor",
        test: async (values) => {

          let valor = formataDinheiroforBanco(values);

          if(valor <= 0){
            return false
          }else{
            return true
          } 
        },
      }),
    
  });

  const formik = useFormik({
    initialValues: {
       descricao: '',
       valor: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.put('updateDespesaVeiculo', {
        "id": idDespesa,
        "descricao": values.descricao,
        "valor": formataDinheiroforBanco(values.valor),
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

      formik.resetForm();
      refreshDespesas();
      change(false);

    }
  });

  

  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} scroll='body' onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Editar despesa
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={2.5} style={{marginTop: '0px'}}>

                <Grid item xs={12} md={8} >
                    <TextField
                        fullWidth
                        id="descricao"
                        name="descricao"
                        label="Descrição"
                        margin="none"
                        type="text"
                        value={formik.values.descricao}
                        onChange={formik.handleChange}
                        error={formik.touched.descricao && Boolean(formik.errors.descricao)}
                        helperText={formik.touched.descricao && formik.errors.descricao}
                        inputProps={{
                            maxLength: 155,
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4} >
                    <TextField
                    fullWidth
                    id="valor"
                    name="valor"
                    label="Valor"
                    margin="none"
                    type="text"
                    value={formik.values.valor}
                    onChange={formik.handleChange}
                    error={formik.touched.valor && Boolean(formik.errors.valor)}
                    helperText={formik.touched.valor && formik.errors.valor}
                    onKeyUp={()=>{ formik.setFieldValue('valor', formik.values.valor && mvalor(formik.values.valor)) }}
                    inputProps={{
                        maxLength: 13,
                    }}
                    />
                </Grid>
                
          </Grid>
        </DialogContent>

        <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

            <Grid item xs={4} md={3}>
                <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
            </Grid>
            
            <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon=""  txt="Salvar" />
            </Grid>

        </Grid>
        </DialogActions>
        
        </form>
    </Dialog>
    </>
  );

}