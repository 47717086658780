import axios from 'axios';
import {io} from "socket.io-client";

axios.defaults.timeout = 60000;


export const api = axios.create({
  //  baseURL: 'http://localhost:3333'
  // baseURL: 'http://192.168.0.143:3333' 
  baseURL: 'https://api.nexcar.digital'   
});

export const socketwpp = io("https://api.whatsapp.nexcar.digital/");
export default api;