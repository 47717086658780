import React from 'react';
import './style.css';
import Button from '@mui/material/Button';

export default function ButtonCirclePrimary({ icon, onClick }) {
    
  return (
    <Button id='button-circle-primary' type='button' onClick={onClick}>
        {icon}
    </Button>
  );
}