import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import grapesjs from "grapesjs";
import pt from "./pt";
import "grapesjs/dist/css/grapes.min.css";
import basicBlocks from "grapesjs-blocks-basic";

const GrapesEditor = () => {
  const editorRef = useRef(null);

  useEffect(() => {
    editorRef.current = grapesjs.init({
      container: "#gjs",
      fromElement: false,
      //   height: "100vh",
      width: "auto",
      i18n: {
        messages: { pt },
      },
      storageManager: { autoload: 0 },
      plugins: [basicBlocks],
      pluginsOpts: {
        [basicBlocks]: {
          blocks: [
            "column1",
            "column2",
            "column3",
            "column3-7",
            "text",
            "link",
            "image",
            "video",
            "map",
          ],
        },
      },
    });

    // editorRef.current.Panels.addPanel('commands',{
    //   id: 'myNewButton',
    //   className: 'fa fa-save',
    //   command: 'someCommand',
    //   attributes: { title: 'Some title'},
    //   active: false,
    //   label: `
    //       <select id="mySelect" style="padding: 4px; border-radius: 4px;">
    //         <option value="option1">Opção 1</option>
    //         <option value="option2">Opção 2</option>
    //         <option value="option3">Opção 3</option>
    //       </select>
    //     `,
    // });

    editorRef.current.Panels.addPanel({
      id: 'pagina-select-panel',
      buttons: [{
        id: 'pagina-select',
        className: 'custom-select-class',
        attributes: { title: 'Pagina' },
        label: `
          <select id="page-select" style="padding: 1px; border-radius: 4px; margin-left: 250px;">
            <option value="Início">Início</option>
            <option value="Veículos">Veículos</option>
            <option value="Detalhes Veícul">Detalhes Veículo</option>
          </select>
        `,
      }]
    });

    const pageSelect = document.getElementById('page-select');
    pageSelect.addEventListener('change', (event) => {
      const value = event.target.value;
      console.log("Página selecionada:", value);
      // Aqui você pode executar qualquer função necessária com base na seleção
    });

    // Adicionar comando personalizado para salvar
    editorRef.current.Commands.add("save-db", {
      run(editor, sender) {
        sender && sender.set("active", false); // Desativa o botão após o clique
        const html = editor.getHtml();
        const css = editor.getCss();

        // Exemplo de salvamento
        console.log("HTML:", html);
        console.log("CSS:", css);

        // Lógica de salvamento aqui (ex.: requisição ao servidor)
      },
    });

    // Adicionar botão de salvar ao painel superior sem alterar o layout
    editorRef.current.Panels.addButton("options", {
      id: "save-db",
      className: "fa fa-save",
      command: "save-db",
      attributes: { title: "Salvar" },
    });

    editorRef.current.BlockManager.add("my-custom-block", {
      label: `
        <i class="fa fa-star" style="margin-right: 5px;"></i>
        <span>Meu Bloco Personalizado</span>
        `,
      category: "Elementos",
      content: `
          <div class="custom-block">
            <h2>Título Personalizado</h2>
            <p>Este é um bloco personalizado que você pode editar!</p>
          </div>
        `,
      style: `
          .custom-block {
            padding: 20px;
            background-color: #f5f5f5;
            border: 1px solid #ddd;
          }
          .custom-block h2 {
            color: #333;
          }
        `,
    });

    const defaultHTML = `<div class="my-block">
        <h1>Bem-vindo ao meu site!</h1>
        <p>Este é um conteúdo padrão para começar.</p>
    </div>`;
    const defaultCSS = `.my-block {
    padding: 20px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    }
    .my-block h1 {
    color: #333;
    }`;

    editorRef.current.setComponents(defaultHTML);
    editorRef.current.setStyle(defaultCSS);

    // editorRef.current.getConfig().showDevices = 0;
    // editorRef.current.Panels.addPanel({ id: "devices-c" }).get("buttons").add([
    //     { id: "set-device-desktop", command: function(e) { return e.setDevice("Desktop") }, className: "fa fa-desktop", active: 1},
    //     { id: "set-device-tablet", command: function(e) { return e.setDevice("Tablet") }, className: "fa fa-tablet" },
    //     { id: "set-device-mobile", command: function(e) { return e.setDevice("Mobile portrait") }, className: "fa fa-mobile" },
    // ]);

    // editorRef.current.render();

    return () => {
      if (editorRef.current) editorRef.current.destroy();
    };
  }, []);

  return <div id="gjs" style={{ flex: 1, height: "100%" }} />;
};

export default GrapesEditor;
