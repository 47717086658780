import React, { useContext, useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams, useHistory } from "react-router-dom";
import './style.css';
import moment from 'moment';


import { useFormik } from 'formik';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';

import Footer from '../../../componentes/Footer';
import Submenu from '../../../componentes/SubMenu';
import ButtonCirclePrimary from '../../../componentes/ButtonCirclePrimary';
import ButtonCircleDanger from '../../../componentes/ButtonCircleDanger';

import ModalNovoEndereco from './modals/modalNovoEndereco';
import ModalEditEndereco from './modals/modalEditEndereco';
import ModalNovoTelefone from './modals/modalNovoTelefone';
import ModalEditTelefone from './modals/modalEditTelefone';

import api from '../../../services/api';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';

import { DataMask, CpfMask, CnpjMask } from '../../../vendor/mascaras';

import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { GlobalContext } from '../../../context/GlobalContext';



export default function PessoasDetalhes() {

    let IconPessoa = props =>(
        <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 22 26">
            <g id="user_account_people_man" data-name="user, account, people, man" transform="translate(-5 -3)">
            <path id="Caminho_44" data-name="Caminho 44" d="M23.737,16.181A1,1,0,1,0,22.331,17.6,8.938,8.938,0,0,1,25,24c0,1.223-3.506,3-9,3s-9-1.778-9-3a8.939,8.939,0,0,1,2.635-6.363,1,1,0,1,0-1.414-1.414A10.927,10.927,0,0,0,5,24c0,3.248,5.667,5,11,5s11-1.752,11-5a10.92,10.92,0,0,0-3.263-7.819Z" fill="#4a4a4a"/>
            <path id="Caminho_45" data-name="Caminho 45" d="M16,17a7,7,0,1,0-7-7A7,7,0,0,0,16,17ZM16,5a5,5,0,1,1-5,5,5,5,0,0,1,5-5Z" fill="#4a4a4a"/>
            </g>
        </svg>
    );
    
    let IconCarro = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 47.701 17.828">
        <g id="sedan" transform="translate(0 -160.323)">
        <path id="Caminho_46" data-name="Caminho 46" d="M91.224,293.383a.7.7,0,0,0,.494-.2l1.056-1.056a.7.7,0,0,0-.988-.988L90.73,292.19a.7.7,0,0,0,.494,1.193Z" transform="translate(-82.091 -118.438)" fill="#4a4a4a"/>
        <path id="Caminho_47" data-name="Caminho 47" d="M404.416,293.177a.7.7,0,1,0,.988-.988l-1.056-1.056a.7.7,0,1,0-.988.988Z" transform="translate(-365.595 -118.437)" fill="#4a4a4a"/>
        <path id="Caminho_48" data-name="Caminho 48" d="M45.594,167.577c-3.947-1.833-9.883-2.008-11.082-2.025l-5.661-3.6a10.391,10.391,0,0,0-5.588-1.627H16.61a11.211,11.211,0,0,0-4.988,1.178l-3.544,1.772A10.477,10.477,0,0,1,4.683,164.3l-2.762.345A2.2,2.2,0,0,0,0,166.821v4.46a2.952,2.952,0,0,0,2.132,2.826l3.3.943a4.431,4.431,0,0,0,8.078.86H34.937a4.437,4.437,0,1,0-.51-1.4H14.021a4.432,4.432,0,1,0-8.789-.973l-2.717-.776A1.548,1.548,0,0,1,1.4,171.281v-2.092h.795a.7.7,0,1,0,0-1.4H1.4v-.97a.8.8,0,0,1,.7-.789l2.762-.345A11.872,11.872,0,0,0,8.7,164.523l.319-.159,1.293,1.287a4.4,4.4,0,0,0,3.135,1.3h1.441a.7.7,0,1,0,0-1.4H13.448a3.074,3.074,0,0,1-.362-.022l.772-3.41a9.794,9.794,0,0,1,2.752-.4h3.554v3.83H17.876a.7.7,0,1,0,0,1.4h16.43c.066,0,6.668.024,10.7,1.9a2.2,2.2,0,0,1,.627.44h-.871a.7.7,0,1,0,0,1.4h1.529c.008.076.013.152.013.229v2.479c0,.692-.3,1.122-.795,1.122a.7.7,0,1,0,0,1.4,2.068,2.068,0,0,0,1.583-.717,2.7,2.7,0,0,0,.609-1.8v-2.479A3.678,3.678,0,0,0,45.594,167.577Zm-6.807,3.105a3.035,3.035,0,1,1-3.035,3.035A3.039,3.039,0,0,1,38.787,170.682Zm-29.127,0a3.035,3.035,0,1,1-3.035,3.035A3.039,3.039,0,0,1,9.661,170.682Zm2.1-5.641a3.067,3.067,0,0,1-.461-.38l-.959-.959,1.9-.952.039-.019Zm9.8-3.321h1.7a9,9,0,0,1,4.837,1.409l3.806,2.422H21.562Z" fill="#4a4a4a"/>
        <path id="Caminho_49" data-name="Caminho 49" d="M169.038,280.564a.7.7,0,1,0,0,1.4h15.683a.7.7,0,0,0,0-1.4Z" transform="translate(-152.655 -109.039)" fill="#4a4a4a"/>
        </g>
    </svg>
    );

    let IconContrato = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 27.107 27.107">
        <g id="contract" transform="translate(0 -0.5)">
        <path id="Caminho_50" data-name="Caminho 50" d="M20.743,27.607H.868A.867.867,0,0,1,0,26.74V1.367A.867.867,0,0,1,.868.5H20.743a.867.867,0,0,1,.867.867v9.059a.867.867,0,1,1-1.735,0V2.234H1.734V25.873H19.875V21.82a.867.867,0,1,1,1.735,0v4.92a.867.867,0,0,1-.867.867Zm0,0" fill="#4a4a4a"/>
        <path id="Caminho_51" data-name="Caminho 51" d="M215.468,127.775h-.153a.868.868,0,0,1-.849-.85c-.009-.443,0-2.706,1.009-3.718L226.4,112.276a.894.894,0,0,1,1.227,0l2.333,2.333a.867.867,0,1,1-1.227,1.227l-1.719-1.72L216.7,124.432a3.154,3.154,0,0,0-.458,1.564,3.141,3.141,0,0,0,1.565-.458l9.231-9.233a.867.867,0,0,1,1.478.541c.425,5.1-2.163,6.576-3.553,7.371a3.948,3.948,0,0,0-.684.443,2.034,2.034,0,0,0-.546.9.861.861,0,0,1-.973.733.877.877,0,0,1-.742-.987,3.532,3.532,0,0,1,1-1.834,4.548,4.548,0,0,1,1.086-.761,4.487,4.487,0,0,0,2.658-3.675l-7.726,7.728c-.927.926-2.9,1.008-3.568,1.008Zm0,0" transform="translate(-203.11 -105.627)" fill="#4a4a4a"/>
        <path id="Caminho_52" data-name="Caminho 52" d="M67.856,302.92a2.018,2.018,0,0,1-1.388-.532,2.906,2.906,0,0,1-1.69.532,3.527,3.527,0,0,1-1.858-.473,4.2,4.2,0,0,1-1.881.473.867.867,0,1,1,0-1.735,2.285,2.285,0,0,0,.738-.131,3.175,3.175,0,0,1-.116-.359,4.6,4.6,0,0,1,1.682-4.7,1.835,1.835,0,0,1,2.318.379,2.594,2.594,0,0,1,.205,2.9,6.557,6.557,0,0,1-1.27,1.906c.057,0,.118.006.182.006.533,0,1.018-.373,1.018-.782a.867.867,0,1,1,1.735,0,1.328,1.328,0,0,0,.113.687l1-.86a.868.868,0,1,1,1.133,1.314l-1.353,1.165A.87.87,0,0,1,67.856,302.92Zm-3.584-5.444c-.055,0-.294.135-.544.556a3.326,3.326,0,0,0-.435,2,4.839,4.839,0,0,0,.975-1.439c.263-.619.2-.961.147-1.015-.146-.151-.142-.107-.143-.107Zm0,0" transform="translate(-56.986 -279.609)" fill="#4a4a4a"/>
        <path id="Caminho_53" data-name="Caminho 53" d="M84.287,80.145H71.938a.867.867,0,1,1,0-1.735H84.287a.867.867,0,1,1,0,1.735Zm0,0" transform="translate(-67.307 -73.785)" fill="#4a4a4a"/>
        <path id="Caminho_54" data-name="Caminho 54" d="M84.287,151.852H71.938a.867.867,0,1,1,0-1.735H84.287a.867.867,0,1,1,0,1.735Zm0,0" transform="translate(-67.307 -141.696)" fill="#4a4a4a"/>
        <path id="Caminho_55" data-name="Caminho 55" d="M78.516,223.543H71.938a.867.867,0,1,1,0-1.735h6.579a.867.867,0,1,1,0,1.735Zm0,0" transform="translate(-67.307 -209.591)" fill="#4a4a4a"/>
        <path id="Caminho_56" data-name="Caminho 56" d="M248.739,302.848a.866.866,0,0,1-.614-.254l-.982-.982a.867.867,0,1,1,1.227-1.227l.982.982a.867.867,0,0,1-.613,1.481Zm0,0" transform="translate(-233.817 -283.767)" fill="#4a4a4a"/>
        </g>
    </svg>
    );
    
    let IconImage = props => (
      <svg id="image" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 70 70">
        <g id="Grupo_305" data-name="Grupo 305">
          <g id="Grupo_304" data-name="Grupo 304">
            <path id="Caminho_154" data-name="Caminho 154" d="M63.438,0H6.563A6.562,6.562,0,0,0,0,6.563V63.438A6.562,6.562,0,0,0,6.563,70H63.438A6.562,6.562,0,0,0,70,63.438V6.563A6.562,6.562,0,0,0,63.438,0Zm2.188,63.438a2.187,2.187,0,0,1-2.187,2.188H6.563a2.187,2.187,0,0,1-2.187-2.187V6.563A2.187,2.187,0,0,1,6.563,4.375H63.438a2.187,2.187,0,0,1,2.188,2.188Z" fill="#9c9c9c"/>
            <path id="Caminho_155" data-name="Caminho 155" d="M116.747,245.136a2.187,2.187,0,0,0-3.084,0l-5.031,5.031-9.384-9.384a2.187,2.187,0,0,0-3.084,0L80.85,256.1a2.188,2.188,0,0,0,1.531,3.719h43.75a2.187,2.187,0,0,0,1.553-3.741Z" transform="translate(-69.257 -207.314)" fill="#9c9c9c"/>
            <ellipse id="Elipse_16" data-name="Elipse 16" cx="6.5" cy="7" rx="6.5" ry="7" transform="translate(35 17)" fill="#9c9c9c"/>
          </g>
        </g>
      </svg>
    );

    let IconSave = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 24.15 24.15">
        <path id="floppy-disk" d="M22.97,3.037,21.113,1.179A4.02,4.02,0,0,0,18.267,0H2.767A2.767,2.767,0,0,0,0,2.767V21.383A2.767,2.767,0,0,0,2.767,24.15H21.383a2.767,2.767,0,0,0,2.767-2.767V5.882A4.02,4.02,0,0,0,22.97,3.037ZM4.025,6.289V3.773a.755.755,0,0,1,.755-.755H15.345a.755.755,0,0,1,.755.755V6.289a.755.755,0,0,1-.755.755H4.78A.755.755,0,0,1,4.025,6.289Zm8.05,13.836A4.528,4.528,0,1,1,16.6,15.6,4.529,4.529,0,0,1,12.075,20.125Zm0,0" transform="translate(0)" fill="#fff"/>
      </svg>
    )

    let IconNoEndereco = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 127.142 157.237">
        <g id="endereco-residencial" transform="translate(-48.998)">
          <path id="Caminho_188" data-name="Caminho 188" d="M183,139.632v32.795h7.985V157.378a4.607,4.607,0,0,1,4.607-4.607h19.655a4.607,4.607,0,0,1,4.607,4.607v15.048h7.985V139.632l-22.419-22.419Zm31.939.549a4.607,4.607,0,0,1-4.607,4.607H200.5a4.607,4.607,0,1,1,0-9.213h9.827A4.607,4.607,0,0,1,214.936,140.181Z" transform="translate(-92.847 -81.216)" fill="#4a4a4a"/>
          <path id="Caminho_189" data-name="Caminho 189" d="M239,263h10.442v10.442H239Z" transform="translate(-131.649 -182.231)" fill="#4a4a4a"/>
          <path id="Caminho_190" data-name="Caminho 190" d="M112.569,0C76.755,0,48.089,29.789,49.02,65.555a63.557,63.557,0,0,0,16.221,40.755l.018-.019a63.465,63.465,0,0,0,92.658,2.09l.018.028a63.619,63.619,0,0,0,18.183-42.854C177.05,29.726,148.318,0,112.569,0Zm40.11,69.592a4.606,4.606,0,0,1-6.515,0L144.2,67.628V95.817a4.607,4.607,0,0,1-4.607,4.607H85.543a4.607,4.607,0,0,1-4.607-4.607V67.629l-1.964,1.964a4.607,4.607,0,0,1-6.515-6.515L90.764,44.772V29.482a4.607,4.607,0,1,1,9.213,0v6.077l9.334-9.334a4.607,4.607,0,0,1,6.515,0l36.853,36.853A4.606,4.606,0,0,1,152.679,69.592Z" transform="translate(0)" fill="#4a4a4a"/>
          <path id="Caminho_191" data-name="Caminho 191" d="M189.809,435.579a231.2,231.2,0,0,1,16.341,21.194h0l.178.261a4.609,4.609,0,0,0,7.616,0,230.945,230.945,0,0,1,16.515-21.451,72.873,72.873,0,0,1-40.65,0Z" transform="translate(-97.567 -301.81)" fill="#4a4a4a"/>
        </g>
      </svg>
    )

    let IconTelefoneCancel = pros =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 111.746 111.751">
        <g id="Ikon" transform="translate(-2.015 -2.045)">
          <path id="Caminho_192" data-name="Caminho 192" d="M28.229,39.779,10.636,9.307,16.28,3.663a5.624,5.624,0,0,1,8.838,1.229l11.67,20.9a5.585,5.585,0,0,1-1.04,6.789C35.513,32.9,28.386,39.38,28.229,39.779Z" transform="translate(7.451 0)" fill="#4a4a4a"/>
          <path id="Caminho_193" data-name="Caminho 193" d="M78.11,60.146,72.33,65.925,41.391,48.394c.42.222,7.49-7.411,7.939-7.732a5.6,5.6,0,0,1,6.7-.959L76.875,51.3a5.625,5.625,0,0,1,1.234,8.843Z" transform="translate(34.033 31.94)" fill="#4a4a4a"/>
          <path id="Caminho_194" data-name="Caminho 194" d="M68.991,76.342l34.635,19.626L98.2,101.4a26.732,26.732,0,0,1-31.56,4.546c-1.9-.594-14.467-5.1-35.384-26.017S5.831,46.442,5.238,44.538A26.724,26.724,0,0,1,9.783,12.98l5.574-5.574L34.6,40.728c.37,4.518,3.208,11.782,13.641,22.215,9.4,9.4,16.2,12.607,20.753,13.4Z" transform="translate(0 4.633)" fill="#4a4a4a"/>
          <path id="Caminho_195" data-name="Caminho 195" d="M77.308,42.763c6.784,7.118-3.42,17.331-10.546,10.545L55.257,41.8,43.75,53.308c-7.117,6.784-17.332-3.419-10.545-10.545L44.712,31.257,33.206,19.75c-6.784-7.118,3.42-17.331,10.546-10.545L55.257,20.712,66.763,9.205C73.88,2.421,84.1,12.625,77.308,19.75L65.8,31.257Z" transform="translate(25.031 4.262)" fill="#4a4a4a"/>
        </g>
      </svg>
    )

    let IconPlus = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="19" viewBox="0 0 26.723 26.721">
        <path id="adicionar" d="M24.84,11.635H18.547a.461.461,0,0,1-.461-.461V4.88A3.348,3.348,0,0,0,15.033,1.5a3.231,3.231,0,0,0-2.392.881,3.2,3.2,0,0,0-1.005,2.34v6.45a.461.461,0,0,1-.461.461H4.882A3.349,3.349,0,0,0,1.5,14.688a3.225,3.225,0,0,0,3.221,3.4h6.45a.461.461,0,0,1,.461.461v6.293a3.334,3.334,0,0,0,3.228,3.382A3.224,3.224,0,0,0,18.086,25v-6.45a.461.461,0,0,1,.461-.461H25a3.225,3.225,0,0,0,3.221-3.4A3.351,3.351,0,0,0,24.84,11.635Z" transform="translate(-1.5 -1.5)" fill="#fff"/>
      </svg>
    )

    let IconLixeira = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 21.241 28.294">
        <g id="delete" transform="translate(-63.818 0)">
          <path id="Caminho_186" data-name="Caminho 186" d="M85.059,9.084H71.973l5.86-5.86a.829.829,0,0,0,0-1.172L76.348.567a1.934,1.934,0,0,0-2.735,0L72.086,2.094,71.3,1.312a2.213,2.213,0,0,0-3.126,0L65.13,4.36a2.213,2.213,0,0,0,0,3.126l.782.782L64.385,9.795a1.934,1.934,0,0,0,0,2.735l1.485,1.485a.829.829,0,0,0,1.172,0l2.1-2.1V24.7a3.592,3.592,0,0,0,3.592,3.592h8.732A3.592,3.592,0,0,0,85.059,24.7ZM67.084,7.1,66.3,6.314a.553.553,0,0,1,0-.782L69.35,2.484a.553.553,0,0,1,.782,0l.782.782Zm7.158,16.557a.829.829,0,0,1-1.658,0V13.726a.829.829,0,0,1,1.658,0Zm3.688,0a.829.829,0,0,1-1.658,0V13.726a.829.829,0,0,1,1.658,0Zm3.688,0a.829.829,0,0,1-1.658,0V13.726a.829.829,0,0,1,1.658,0Z" transform="translate(0 0)" fill="#fff"/>
          <path id="Caminho_187" data-name="Caminho 187" d="M305.186,86.046a1.384,1.384,0,0,0-.919,1.152h7.954V85.82a1.382,1.382,0,0,0-1.825-1.309L305.2,86.04Z" transform="translate(-227.162 -79.771)" fill="#fff"/>
        </g>
      </svg>
    )

    let IconModeloMensagem = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.703 26.675">
        <g id="mensagem" transform="translate(0 0)">
          <path id="Caminho_375" data-name="Caminho 375" d="M1.22,26.675l1.742-6.968A11.306,11.306,0,0,1,0,12.174,11.425,11.425,0,0,1,4.081,3.529,14.7,14.7,0,0,1,13.8,0a14.886,14.886,0,0,1,9.767,3.526A11.383,11.383,0,0,1,27.7,12.174a11.383,11.383,0,0,1-4.138,8.648A14.886,14.886,0,0,1,13.8,24.348a15.181,15.181,0,0,1-5.717-1.1ZM13.8,1.623c-6.713,0-12.174,4.733-12.174,10.551a9.747,9.747,0,0,0,2.818,6.741l.311.324-1.1,4.406,4.376-2.188.347.15A13.561,13.561,0,0,0,13.8,22.725c6.772,0,12.282-4.733,12.282-10.551S20.57,1.623,13.8,1.623Zm0,0" transform="translate(0 0)" fill="#4a4a4a"/>
          <path id="Caminho_376" data-name="Caminho 376" d="M135,150h12.986v1.623H135Zm0,0" transform="translate(-127.696 -141.884)" fill="#4a4a4a"/>
          <path id="Caminho_377" data-name="Caminho 377" d="M135,210h12.986v1.623H135Zm0,0" transform="translate(-127.696 -198.637)" fill="#4a4a4a"/>
          <path id="Caminho_378" data-name="Caminho 378" d="M135,270h9.739v1.623H135Zm0,0" transform="translate(-127.696 -255.391)" fill="#4a4a4a"/>
        </g>
      </svg>
    );

    let IconWhatsApp = props =>(
      <svg xmlns="http://www.w3.org/2000/svg" width="21" viewBox="0 0 24 24">
        <g id="whatsapp" transform="translate(0 0)">
          <path id="Caminho_414" data-name="Caminho 414" d="M17.507,14.307l-.009.075c-2.2-1.1-2.429-1.242-2.713-.816-.2.3-.771.964-.944,1.162s-.349.21-.646.075a8.116,8.116,0,0,1-2.4-1.485,9.073,9.073,0,0,1-1.66-2.07c-.293-.506.32-.578.878-1.634a.55.55,0,0,0-.025-.524c-.075-.15-.672-1.62-.922-2.206s-.487-.51-.672-.51a1.488,1.488,0,0,0-1.368.344c-1.614,1.774-1.207,3.6.174,5.55,2.714,3.552,4.16,4.206,6.8,5.114a4.137,4.137,0,0,0,1.88.121A3.077,3.077,0,0,0,17.9,16.077a2.475,2.475,0,0,0,.18-1.425c-.074-.135-.27-.21-.57-.345Z" fill="#4a4a4a"/>
          <path id="Caminho_415" data-name="Caminho 415" d="M20.52,3.449C12.831-3.984.106,1.407.1,11.893a11.836,11.836,0,0,0,1.6,5.945L0,24l6.335-1.652A11.971,11.971,0,0,0,24,11.9a11.794,11.794,0,0,0-3.495-8.411ZM22,11.866A9.956,9.956,0,0,1,6.99,20.37l-.36-.214-3.75.975,1.005-3.645-.239-.375A9.918,9.918,0,0,1,19.093,4.876,9.788,9.788,0,0,1,22,11.866Z" fill="#4a4a4a"/>
        </g>
      </svg>
    );

    let { codigo } = useParams();
    let history = useHistory();

    const { whatsAppInfo } = useContext(GlobalContext);
    const [dadosPessoa, setdadosPessoa] = useState([]);
    const [fotoPessoa, setfotoPessoa] = useState();

    const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);

    const [dataNascimento, setdataNascimento] = useState(null);
    const [dataFundacao, setdataFundacao] = useState(null);

    const [showModalNovoEndereco, setshowModalNovoEndereco] = useState(false);
    const [showModalEditEndereco, setshowModalEditEndereco] = useState(false);
    const [enderecoDetalhes, setenderecoDetalhes] = useState();

    const [showModalNovoTelefone, setshowModalNovoTelefone] = useState(false);
    const [showModalEditTelefone, setshowModalEditTelefone] = useState(false);
    const [telefoneDetalhes, settelefoneDetalhes] = useState();

    const [alertError, setalertError] = useState(false);
    const [alertErrorMsg, setalertErrorMsg] = useState('');

    const [alertSucess, setalertSucess] = useState(false);
    const [alertSucessMsg, setalertSucessMsg] = useState('');

    const [progressLoadFoto, setprogressLoadFoto] = useState(0);

    const [enderecos, setenderecos] = useState([]);
    const [enderecosSelecionados, setenderecosSelecionados] = useState([]);
    const [pageSizeDataGridEnderecos, setpageSizeDataGridEnderecos] = useState(10);
    const [loadingDataGridEnderecos, setloadingDataGridEnderecos] = useState(true);

    const [telefones, settelefones] = useState([]);
    const [telefonesSelecionados, settelefonesSelecionados] = useState([]);
    const [pageSizeDataGridTelefones, setpageSizeDataGridTelefones] = useState(10);
    const [loadingDataGridTelefones, setloadingDataGridTelefones] = useState(true);
    
    const [habilitadoLead, sethabilitadoLead] = useState(false);

    const handleChangeDataNascimento = (data) => {
      setdataNascimento(data);
    };

    const handleChangeDataFundacao = (data) => {
      setdataFundacao(data);
    };
    
    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleCloseAlertError = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setalertError(false);
    };

    const handleCloseAlertSuccess = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setalertSucess(false);
    };

    const getEnderecosPessoa = async(idPessoa)=>{

      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      //ENDEREÇOS
      setloadingDataGridEnderecos(true);
      let enderecosPessoa = await api.post('listagemEnderecosPesssoa', {
        "pessoaId": idPessoa
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

      setenderecos(enderecosPessoa.data);
      setloadingDataGridEnderecos(false);
    }

    const getTelefonesPessoa = async(idPessoa)=>{

      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      //ENDEREÇOS
      setloadingDataGridTelefones(true);
      let telefonesPessoa = await api.post('listagemTelefonesPesssoa', {
        "pessoaId": idPessoa
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

      settelefones(telefonesPessoa.data);
      setloadingDataGridTelefones(false);
    }

    const detalhesPessoa = async()=>{
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        codigo === 'undefined' && history.push("/cadastros/pessoas");
    
        api.post('detalhesPessoa', {
            "codigo": codigo
        }, 
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        }).then( async response => {
            
            let data = response.data;
            setdadosPessoa(response.data);

            data.foto && setfotoPessoa(data.foto);

            formik.setFieldValue('tipo', data.tipo);
            formik.setFieldValue('nome', data.nome);
            data.email && formik.setFieldValue('email', data.email);
            data.habilitadoLead && sethabilitadoLead(Boolean(data.habilitadoLead));
            data.rg && formik.setFieldValue('rg', data.rg);
            data.cpf && formik.setFieldValue('cpf', data.cpf);
            data.nascimento && setdataNascimento(data.nascimento);
            data.nacionalidade && formik.setFieldValue('nacionalidade', data.nacionalidade);
            data.nomePai && formik.setFieldValue('nomePai', data.nomePai);
            data.nomeMae && formik.setFieldValue('nomeMae', data.nomeMae);
            data.cnpj && formik.setFieldValue('cnpj', data.cnpj);
            data.representante && formik.setFieldValue('representante', data.representante);
            data.inscricaoestadual && formik.setFieldValue('inscricaoestadual', data.inscricaoestadual);
            data.inscricaomunicipal && formik.setFieldValue('inscricaomunicipal', data.inscricaomunicipal);
            data.dataFundacao && setdataFundacao(data.dataFundacao);
            data.obs && formik.setFieldValue('obs', data.obs);


            getEnderecosPessoa(data.id);
            getTelefonesPessoa(data.id);

        }).catch((error) => {
            history.push("/cadastros/pessoas");
        });
        
        
    }

    const permissoesUsuario = async()=>{

      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);
  
      let permissoes = await api.post('permissoesUsuario', {}, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });
  
      // console.log(permissoes.data.pessoas[3].update);
      setpermissoesdoUsuario(permissoes.data.pessoas);
    }

    const detalhesEndereco = async(id)=>{

      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);
  
      let detalhes = await api.post('detalhesEnderecoPessoa', {
        "enderecoId": id,
        "pessoaId": dadosPessoa.id,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });
      
      setenderecoDetalhes(detalhes.data);
      setshowModalEditEndereco(true);
  
    }

    const detalhesTelefone = async(id)=>{

      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);
  
      let detalhes = await api.post('detalhesTelefonePessoa', {
        "telefoneId": id,
        "pessoaId": dadosPessoa.id,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });
      
      settelefoneDetalhes(detalhes.data);
      setshowModalEditTelefone(true);
  
    }

    const validationSchema = yup.object({
      tipo: yup
        .number('Seleciona o tipo de pessoa')
        .required('Tipo Obrigatório'),
      nome: yup
        .string('Insira o nome da pessoa')
        .required('Nome Obrigatório'),
      email: yup
        .string('Insira seu E-mail')
        .email('Insira um E-mail válido')
        .required('E-mail Obrigatório'),
      rg: yup
        .string('Insira o RG'),
      cpf: yup
        .string('Insira o CPF'),
      // nascimento: yup
      //   .string('Insira a data de nascimento'),
      cnpj: yup
        .string('Insira o CNPJ'),
      representante: yup
        .string('Insira o nome do representante da empresa'),
      inscricaoestadual: yup
        .string('Insira a inscrição estadual da empresa'),
      inscricaomunicipal: yup
        .string('Insira a inscrição municipal da empresa'),
    });

    const formik = useFormik({
      initialValues: {
        tipo: '',
        nome: '',
        email: '',
        rg: '',
        cpf: '',
        nascimento: '',
        nacionalidade: '',
        nomePai: '',
        nomeMae: '',
        cnpj: '',
        representante: '',
        inscricaoestadual: '',
        inscricaomunicipal: '',
        dataFundacao: '',
      },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
      
        //GET DADOS USER
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        await api.put(`updatePessoa`, {
            "id": dadosPessoa.id,
            "tipo": values.tipo, 
            "cliente": dadosPessoa.cliente,
            "habilitadoLead": Number(habilitadoLead),
            "fornecedor": dadosPessoa.fornecedor,
            "usuario": dadosPessoa.usuario,
            "nome": values.nome,
            "email": values.email ? values.email : null,
            "obs": values.obs ? values.obs : null,
            "rg": values.rg ? values.rg : null,
            "cpf": values.cpf ? values.cpf : null,
            "nascimento": dataNascimento ? dataNascimento : null,
            "nacionalidade": values.nacionalidade ? values.nacionalidade : null,
            "nomePai": values.nomePai ? values.nomePai : null,
            "nomeMae": values.nomeMae ? values.nomeMae : null,
            "cnpj": values.cnpj ? values.cnpj : null,
            "representante": values.representante ? values.representante : null,
            "inscricaoestadual": values.inscricaoestadual ? values.inscricaoestadual : null,
            "inscricaomunicipal": values.inscricaomunicipal ? values.inscricaomunicipal : null,
            "dataFundacao": dataFundacao ? dataFundacao : null
        },
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        });

        setalertSucess(true);        
        setalertSucessMsg(`Dados do(a) ${values.nome} Atualizados com sucesso!`);        
        setTimeout(() => { setalertSucess(false) }, 6000);
        
      }
    });

    let handleFotoPessoa = async(e)=>{

      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let imagem = e.target.files[0];

      let formData = new FormData();
      formData.append('pessoaId', dadosPessoa.id);
      formData.append('image', imagem);

      if(imagem.type === 'image/png' || imagem.type === 'image/gif' || imagem.type === 'image/jpeg'){
        console.log("upp")
        await api.put("uploadFotoPessoa", formData, {
          headers: { 
            "Content-type": "multipart/form-data",
            "Authorization": `Bearer ${auth.token}`
          },
          onUploadProgress: e => {
            const progress = parseInt(Math.round((e.loaded * 100) / e.total));
            setprogressLoadFoto(progress)
          },
        })

        setprogressLoadFoto(0);

        //Set Foto Selecionada In Preview
        const reader = new FileReader();
        reader.onload = () =>{
          if(reader.readyState === 2){
            setfotoPessoa(reader.result)
          }
        }
        reader.readAsDataURL(e.target.files[0])
      }else{
        setalertErrorMsg('Foto inválida, Apenas os formatos .png, .jpg e .jpeg são permitidos!')
        setalertError(true);
      }

    }

    useEffect(async ()=>{ 
        await detalhesPessoa();
        await permissoesUsuario();
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    

    let itensSubMenu = [
        { active: "active", title: "Pessoas", rota: "/cadastros/pessoas", icon: <IconPessoa/> },
        { active: "", title: "Veículos", rota: "/cadastros/veiculos", icon: <IconCarro/> },
        { active: "", title: "Contratos", rota: "/cadastros/contratos", icon: <IconContrato/> },
        { active: "", title: "Modelos de mensagens", rota: "/cadastros/modelosmensagem", icon: <IconModeloMensagem/> },
        { active: "", title: "Listas de Transmissões", rota: "/cadastros/listasTransmissoes", icon: <IconWhatsApp/> },
    ];

    const Input = styled('input')({
      display: 'none',
    });
   

    const handleDeleteEndereco = async()=>{
    
      Swal.fire({
        title: 'Tem certeza?',
        text: "Deseja deletar o(s) Endereço(s) selecionado(s)?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0554C2',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim deletar',
        cancelButtonText: 'Cancelar',
      }).then(async(result) => {
        if (result.isConfirmed) {
  
          let auth = localStorage.getItem("auth");
          auth = JSON.parse(auth);
  
          let enderecosid = [];
  
          for await (let id of enderecosSelecionados) {
            let dados = { id: id }
            enderecosid.push(dados);
          }
          
          await api.delete('deleteEnderecoPesssoa', {
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
            },
            data: {  "pessoaId": dadosPessoa.id, "enderecosIds": enderecosid }
          });

          await getEnderecosPessoa(dadosPessoa.id);
  
          Swal.fire(
            'Deletado!',
            'Endereço(s) deletado(s) com sucesso!',
            'success'
          )
        }
      })
  
    }

    const handleStatusEndereco = async(event, id)=>{

      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.put('definirPrincipalEnderecoPesssoa', {
        "id": id,
        "pessoaId": dadosPessoa.id,
        "principal": event.target.checked
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

      await getEnderecosPessoa(dadosPessoa.id);

    }
   
    const columnsDataGridEnderecos = [
      { field: 'id', hide: true, headerName: 'ID', width: 90  },
      {
        field: 'principal',
        headerName: 'Principal',
        flex: 1,
        minWidth: 100,
        editable: false,
        renderCell: (params) => ( //renderiza componente
          <p>
            <Switch
              disabled={!permissoesdoUsuario[3]?.update}
              checked={params.value ? true : false}
              onChange={(event)=>{ handleStatusEndereco(event, params.id) }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </p>
        ),
      },
      {
        field: 'cep',
        headerName: 'Cep',
        flex: 1,
        minWidth: 100,
        editable: false,
        valueFormatter: (params) => {
          return params.value
        },
      },
      {
        field: 'rua',
        headerName: 'Rua',
        flex: 1,
        minWidth: 250,
        editable: false,
        valueFormatter: (params) => {
          return params.value
        },
      },
      {
        field: 'numero',
        headerName: 'Número',
        flex: 1,
        minWidth: 80,
        editable: false,
        valueFormatter: (params) => {
          return params.value
        },
      },
      {
        field: 'bairro',
        headerName: 'Bairro',
        flex: 1,
        minWidth: 150,
        editable: false,
        valueFormatter: (params) => {
          return params.value
        },
      },
      {
        field: 'cidade',
        headerName: 'Cidade',
        flex: 1,
        minWidth: 150,
        editable: false,
        valueFormatter: (params) => {
          return params.value
        },
      },
      {
        field: 'estado',
        headerName: 'Estado',
        flex: 1,
        minWidth: 50,
        editable: false,
        valueFormatter: (params) => {
          return params.value
        },
      },
    ];

    
    const handleDeleteTelefone = async()=>{
    
      Swal.fire({
        title: 'Tem certeza?',
        text: "Deseja deletar o(s) Telefone(s) selecionado(s)?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0554C2',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim deletar',
        cancelButtonText: 'Cancelar',
      }).then(async(result) => {
        if (result.isConfirmed) {
  
          let auth = localStorage.getItem("auth");
          auth = JSON.parse(auth);
  
          let telefonesid = [];
  
          for await (let id of telefonesSelecionados) {
            let dados = { id: id }
            telefonesid.push(dados);
          }
          
          await api.delete('deleteTelefonePesssoa', {
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
            },
            data: {  "pessoaId": dadosPessoa.id, "telefonesIds": telefonesid }
          });

          await getTelefonesPessoa(dadosPessoa.id);
  
          Swal.fire(
            'Deletado!',
            'Telefone(s) deletado(s) com sucesso!',
            'success'
          )
        }
      })
  
    }

    const handlePrincipalTelefone = async(event, id)=>{

      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.put('definirPrincipalTelefonePesssoa', {
        "id": id,
        "pessoaId": dadosPessoa.id,
        "principal": event.target.checked
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

      await getTelefonesPessoa(dadosPessoa.id);

    }

    const handleIsWhatsAppTelefone = async(event, id)=>{

      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.put('definirWhatsAppTelefonePesssoa', {
        "id": id,
        "pessoaId": dadosPessoa.id,
        "whatsapp": event.target.checked
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

      await getTelefonesPessoa(dadosPessoa.id);

    }

    const columnsDataGridTelefones = [
      { field: 'id', hide: true, headerName: 'ID', width: 90  },
      {
        field: 'principal',
        headerName: 'Principal',
        flex: 1,
        minWidth: 100,
        editable: false,
        renderCell: (params) => ( //renderiza componente
          <p>
            <Switch
              disabled={!permissoesdoUsuario[3]?.update}
              checked={params.value ? true : false}
              onChange={(event)=>{ handlePrincipalTelefone(event, params.id) }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </p>
        ),
      },
      {
        field: 'whatsapp',
        headerName: 'whatsapp',
        flex: 1,
        minWidth: 100,
        editable: false,
        renderCell: (params) => ( //renderiza componente
          <p>
            <Switch
              disabled={!permissoesdoUsuario[3]?.update}
              checked={params.value ? true : false}
              onChange={(event)=>{ handleIsWhatsAppTelefone(event, params.id) }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </p>
        ),
      },
      {
        field: 'numero',
        headerName: 'Número',
        flex: 2,
        minWidth: 150,
        editable: false,
        valueFormatter: (params) => {
          return params.value
        },
      },
      {
        field: 'obs',
        headerName: 'obs',
        flex: 2,
        minWidth: 150,
        editable: false,
        valueFormatter: (params) => {
          return params.value
        },
      },
    ];
    

    function nenhumEnderecoEncontrado(){
      return(
        <GridOverlay>
          <div className="container-no-data-grid">
            <IconNoEndereco/>
            <p>Nenhum endereço encontrado</p>
          </div>
        </GridOverlay>
      )
    }

    function nenhumTelefoneEncontrado(){
      return(
        <GridOverlay>
          <div className="container-no-data-grid">
            <IconTelefoneCancel/>
            <p>Nenhum telefone encontrado</p>
          </div>
        </GridOverlay>
      )
    }

    

    function CircularProgressWithLabel(props) {
      return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress variant="determinate" {...props} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" component="div" color="text.secondary">
              {`${Math.round(props.value)}%`}
            </Typography>
          </Box>
        </Box>
      );
    }

    const handleHabilitadosLeads = async(props)=>{
      let hbleads = props.target.checked;
      sethabilitadoLead(hbleads);
    }

    return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcar - Destalhes pessoa</title>
        </Helmet>
      </HelmetProvider>


      <ModalNovoEndereco pessoaid={dadosPessoa.id} visible={showModalNovoEndereco} change={setshowModalNovoEndereco}  refreshEnderecos={getEnderecosPessoa} />
      <ModalEditEndereco permissoesUpdate={permissoesdoUsuario[3]?.update} pessoaid={dadosPessoa.id} detalhes={enderecoDetalhes} visible={showModalEditEndereco} change={setshowModalEditEndereco}  refreshEnderecos={getEnderecosPessoa} />

      <ModalNovoTelefone pessoaid={dadosPessoa.id} visible={showModalNovoTelefone} change={setshowModalNovoTelefone}  refreshTelefones={getTelefonesPessoa} />
      <ModalEditTelefone permissoesUpdate={permissoesdoUsuario[3]?.update} pessoaid={dadosPessoa.id} detalhes={telefoneDetalhes} visible={showModalEditTelefone} change={setshowModalEditTelefone}  refreshTelefones={getTelefonesPessoa} />

      <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
          {alertErrorMsg}
        </Alert>
      </Snackbar>

      <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
          {alertSucessMsg}
        </Alert>
      </Snackbar>


      
      <div>
        <div className='container-page'>
          <Submenu itens={ itensSubMenu } />
          
          <div className={`${whatsAppInfo?.session ? 'container' : 'container-no-margin' }`}>
          <form method='post' onSubmit={formik.handleSubmit}>

            <Box style={{ marginTop: '20px' }}>
              <Grid container spacing={2} >
                
                <Grid item xs={12} md={6}>
                  <div className='box-content'>

                    {
                      dadosPessoa.length === 0 ?

                      <Grid container spacing={0} >
                        <Grid container spacing={2} >
                          <Grid item xs={12} md={2.5}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                          </Grid>
                      
                          <Grid container spacing={1} item xs={12} md={9.5}>
                            <Grid item xs={12} md={12}>
                              <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                          </Grid>
                        
                        </Grid>
                      </Grid>

                      :
                      <>
                      <div className='header-box-content'> 
                        <h3 className='title'>Pessoa</h3>
                        {
                          permissoesdoUsuario[3]?.update &&
                          <ButtonCirclePrimary icon={<IconSave/>} onClick={()=>{ formik.handleSubmit() }}/>
                        }
                      </div>
                      <Grid container spacing={0}>

                        <Grid container spacing={2} >
                          <Grid item xs={12} md={2.5}>
                            <label htmlFor="button-file-foto">
                              {
                                permissoesdoUsuario[3]?.update &&
                                <Input accept="image/png, image/gif, image/jpeg" id="button-file-foto" onChange={handleFotoPessoa} type="file" />
                              }

                              {
                                progressLoadFoto.length > 0 ? 
                                
                                  <div className='box-progress-foto-pessoa'><CircularProgressWithLabel value={progressLoadFoto} /></div>
                                
                                :
                                <>
                                  {
                                    !fotoPessoa ?
                                    <div className='icon-upload-foto'>
                                      <IconImage/>
                                    </div>

                                    :

                                    <div className='box-foto-pessoa' style={{ backgroundImage: `url(${fotoPessoa})` }}></div>

                                  }
                                </>
                              }

                            </label>
                          </Grid>

                          <Grid container spacing={0} item xs={12} md={9.5}>
                            <Grid item xs={12} md={12} >
                              <TextField
                                fullWidth
                                id="nome"
                                name="nome"
                                label="nome"
                                margin="normal"
                                type="text"
                                value={formik.values.nome}
                                disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.nome && Boolean(formik.errors.nome)}
                                helperText={formik.touched.nome && formik.errors.nome}
                              />
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <FormControl sx={{ m: 0, width: '100%' }} >
                                <InputLabel id="label-tipo">Tipo</InputLabel>
                                <Select
                                  labelId="label-tipo"
                                  id="demo-simple-select"
                                  name="tipo"
                                  label="Tipo"
                                  placeholder='Tipo'
                                  value={formik.values.tipo}
                                  disabled={!permissoesdoUsuario[3]?.update}
                                  onChange={formik.handleChange}
                                  error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                                >
                                  <MenuItem value={1}>Física</MenuItem>
                                  <MenuItem value={2}>Jurídica</MenuItem>
                                </Select>
                                <FormHelperText error>{formik.touched.tipo && formik.errors.tipo}</FormHelperText>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <div style={{ 'marginLeft': '10px' }}>
                              <FormGroup>
                                <FormControlLabel control={
                                  <Switch
                                    disabled={!permissoesdoUsuario[3]?.update}
                                    checked={habilitadoLead}
                                    onChange={handleHabilitadosLeads}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                  />
                                } label="Habilitar p/ Leads" />
                              </FormGroup>
                              </div>
                            </Grid>

                          </Grid>
                        </Grid>

                        {
                          formik.values.tipo === 1 ?

                          <Grid container spacing={2} rowSpacing={-1}>
                            <Grid item xs={12} md={8}>
                              <TextField
                                fullWidth
                                id="email"
                                name="email"
                                label="E-mail"
                                margin="normal"
                                type="text"
                                value={formik.values.email}
                                disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                              />
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                                <DesktopDatePicker
                                  label="Data de Nascimento"
                                  inputFormat="dd/MM/yyyy"
                                  name="nascimento"
                                  value={dataNascimento}
                                  disabled={!permissoesdoUsuario[3]?.update}
                                  onChange={handleChangeDataNascimento}
                                  allowSameDateSelection={true}
                                  renderInput={(params) => <TextField style={{ width: '100%', marginTop: '15px'}} {...params} />}
                                />
                              </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <TextField
                                fullWidth
                                id="nacionalidade"
                                name="nacionalidade"
                                label="Nacionalidade"
                                margin="normal"
                                type="text"
                                value={formik.values.nacionalidade}
                                disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.nacionalidade && Boolean(formik.errors.nacionalidade)}
                                helperText={formik.touched.nacionalidade && formik.errors.nacionalidade}
                              />
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <TextField
                                fullWidth
                                id="rg"
                                name="rg"
                                label="RG"
                                margin="normal"
                                type="text"
                                value={formik.values.rg}
                                disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.rg && Boolean(formik.errors.rg)}
                                helperText={formik.touched.rg && formik.errors.rg}
                              />
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <TextField
                                fullWidth
                                id="cpf"
                                name="cpf"
                                label="CPF"
                                margin="normal"
                                type="text"
                                value={formik.values.cpf}
                                disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                                helperText={formik.touched.cpf && formik.errors.cpf}
                                InputProps={{
                                  inputComponent: CpfMask,
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="nomePai"
                                name="nomePai"
                                label="Nome do pai"
                                margin="normal"
                                type="text"
                                value={formik.values.nomePai}
                                disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.nomePai && Boolean(formik.errors.nomePai)}
                                helperText={formik.touched.nomePai && formik.errors.nomePai}
                              />
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <TextField
                                fullWidth
                                id="nomeMae"
                                name="nomeMae"
                                label="Nome da mãe"
                                margin="normal"
                                type="text"
                                value={formik.values.nomeMae}
                                disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.nomeMae && Boolean(formik.errors.nomeMae)}
                                helperText={formik.touched.nomeMae && formik.errors.nomeMae}
                              />
                            </Grid>

                          </Grid>

                          :

                          <Grid container spacing={2} rowSpacing={-1}>
                            <Grid item xs={12} md={4}>
                              <TextField
                                fullWidth
                                id="email"
                                name="email"
                                label="E-mail"
                                margin="normal"
                                type="text"
                                value={formik.values.email}
                                disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                              />
                            </Grid>
                            
                            <Grid item xs={12} md={8}>
                              <TextField
                                fullWidth
                                id="cnpj"
                                name="cnpj"
                                label="CNPJ"
                                margin="normal"
                                type="text"
                                value={formik.values.cnpj}
                                disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
                                helperText={formik.touched.cnpj && formik.errors.cnpj}
                                InputProps={{
                                  inputComponent: CnpjMask,
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <TextField
                                fullWidth
                                id="inscricaoestadual"
                                name="inscricaoestadual"
                                label="Inscrição estadual"
                                margin="normal"
                                type="text"
                                value={formik.values.inscricaoestadual}
                                disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.inscricaoestadual && Boolean(formik.errors.inscricaoestadual)}
                                helperText={formik.touched.inscricaoestadual && formik.errors.inscricaoestadual}
                              />
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <TextField
                                fullWidth
                                id="inscricaomunicipal"
                                name="inscricaomunicipal"
                                label="Inscrição municipal"
                                margin="normal"
                                type="text"
                                value={formik.values.inscricaomunicipal}
                                disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.inscricaomunicipal && Boolean(formik.errors.inscricaomunicipal)}
                                helperText={formik.touched.inscricaomunicipal && formik.errors.inscricaomunicipal}
                              />
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                                <DesktopDatePicker
                                  label="Data de Fundação"
                                  inputFormat="dd/MM/yyyy"
                                  name="dataFundacao"
                                  value={dataFundacao}
                                  disabled={!permissoesdoUsuario[3]?.update}
                                  onChange={handleChangeDataFundacao}
                                  allowSameDateSelection={true}
                                  renderInput={(params) => <TextField style={{ width: '100%', marginTop: '15px'}} {...params} />}
                                />
                              </LocalizationProvider>
                            </Grid>


                            <Grid item xs={12} md={4}>
                              <TextField
                                fullWidth
                                id="representante"
                                name="representante"
                                label="Nome completo do Representante"
                                margin="normal"
                                type="text"
                                value={formik.values.representante}
                                disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.representante && Boolean(formik.errors.representante)}
                                helperText={formik.touched.representante && formik.errors.representante}
                              />
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <TextField
                                fullWidth
                                id="rg"
                                name="rg"
                                label="RG do Representante"
                                margin="normal"
                                type="text"
                                value={formik.values.rg}
                                disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.rg && Boolean(formik.errors.rg)}
                                helperText={formik.touched.rg && formik.errors.rg}
                              />
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <TextField
                                fullWidth
                                id="cpf"
                                name="cpf"
                                label="CPF do Representante"
                                margin="normal"
                                type="text"
                                value={formik.values.cpf}
                                disabled={!permissoesdoUsuario[3]?.update}
                                onChange={formik.handleChange}
                                error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                                helperText={formik.touched.cpf && formik.errors.cpf}
                                InputProps={{
                                  inputComponent: CpfMask,
                                }}
                              />
                            </Grid>

              
                          </Grid>

                        } 

                      </Grid>
                      </>
                    
                    }

                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className='box-content'>

                    {
                      dadosPessoa.length === 0 ?

                      <Grid container spacing={0} item xs={12} md={12}>
                        <Grid item xs={12} md={12}>
                          <Skeleton animation="wave" variant="rectangular" width='100%' height={275} style={{ borderRadius: '5px' }}/>
                        </Grid>
                      </Grid>

                      :
                      <>
                      <div className='header-box-content'>
                        <h3 className='title'>Observações</h3>
                        {
                          permissoesdoUsuario[3]?.update &&
                          <ButtonCirclePrimary icon={<IconSave/>} onClick={()=>{ formik.handleSubmit() }}/>
                        }
                      </div>
                    
                      <Grid container spacing={0} item xs={12} md={12}>
                        <Grid item xs={12} md={12} >
                          <TextField
                            fullWidth
                            id="obs"
                            name="obs"
                            margin="normal"
                            multiline
                            disabled={!permissoesdoUsuario[3]?.update}
                            rows={14.5} //formik.values.tipo === 1 ? 14.5 : 14.5
                            defaultValue={formik.values.obs}
                            onChange={formik.handleChange}
                            error={formik.touched.obs && Boolean(formik.errors.obs)}
                            helperText={formik.touched.obs && formik.errors.obs}
                          />
                        </Grid>
                      </Grid>
                      </>
                    }

                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className='box-content'>
                    
                    <div className='header-box-content'>
                      <h3 className='title'>Endereços</h3>

                      <div style={{ display: 'flex'}}>

                        {
                          permissoesdoUsuario[1]?.delete &&
                          
                          <>
                          {
                            enderecosSelecionados.length > 0 &&

                            <div style={{ marginRight: '10px' }}>
                              <ButtonCircleDanger icon={<IconLixeira/>} onClick={()=>{ handleDeleteEndereco() }}/>
                            </div>
                          }
                          </>

                        }

                        {
                          permissoesdoUsuario[2]?.insert &&

                          <>
                          {
                            dadosPessoa.id &&  <ButtonCirclePrimary icon={<IconPlus/>} onClick={()=>{ setshowModalNovoEndereco(true) }}/>
                          }
                          </>

                        }

                      </div>
                    </div>
                    
                    <div className='container-data-grid'>
                      <DataGrid
                      sx={{ minHeight: '300px' }}
                      localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                      autoHeight
                      rows={enderecos}
                      columns={columnsDataGridEnderecos}
                      pageSize={pageSizeDataGridEnderecos}
                      onPageSizeChange={(newPageSize) => setpageSizeDataGridEnderecos(newPageSize)}
                      rowsPerPageOptions={[10, 50, 100]} 
                      checkboxSelection={permissoesdoUsuario[3]?.update}
                      disableSelectionOnClick
                      editMode='row'
                      loading={loadingDataGridEnderecos}
                      disableColumnMenu={true}
                      onSelectionModelChange={(props)=>{
                          setenderecosSelecionados(props)
                      }}
                      // onRowClick
                      onCellClick={(props)=>{
                        if(props.field !== "__check__" && props.field !== "principal"){
                          detalhesEndereco(props.id);
                        }
                      }}
                      components={{
                          NoRowsOverlay: nenhumEnderecoEncontrado,
                      }}
                      />
                  </div>

                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className='box-content'>
                    <div className='header-box-content'>
                      <h3 className='title'>Telefones</h3>

                      <div style={{ display: 'flex'}}>

                        {
                          permissoesdoUsuario[1]?.delete &&
                          
                          <>
                          {
                            telefonesSelecionados.length > 0 &&

                            <div style={{ marginRight: '10px' }}>
                              <ButtonCircleDanger icon={<IconLixeira/>} onClick={()=>{ handleDeleteTelefone() }}/>
                            </div>
                          }
                          </>

                        }

                        {
                          permissoesdoUsuario[2]?.insert &&

                          <>
                          {
                            dadosPessoa.id &&  <ButtonCirclePrimary icon={<IconPlus/>} onClick={()=>{ setshowModalNovoTelefone(true) }}/>
                          }
                          </>

                        }

                        

                      </div>
                    </div>

                    <div className='container-data-grid'>
                      <DataGrid
                      sx={{ minHeight: '300px' }}
                      localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                      autoHeight
                      rows={telefones}
                      columns={columnsDataGridTelefones}
                      pageSize={pageSizeDataGridTelefones}
                      onPageSizeChange={(newPageSize) => setpageSizeDataGridTelefones(newPageSize)}
                      rowsPerPageOptions={[10, 50, 100]} 
                      checkboxSelection={permissoesdoUsuario[3]?.update}
                      disableSelectionOnClick
                      editMode='row'
                      loading={loadingDataGridTelefones}
                      disableColumnMenu={true}
                      onSelectionModelChange={(props)=>{
                          settelefonesSelecionados(props)
                      }}
                      // onRowClick
                      onCellClick={(props)=>{
                        if(props.field !== "__check__" && props.field !== "principal" && props.field !== "whatsapp"){
                          detalhesTelefone(props.id);
                        }
                      }}
                      components={{
                          NoRowsOverlay: nenhumTelefoneEncontrado,
                      }}
                      />
                  </div>

                  </div>
                </Grid>
                

              </Grid>
            </Box>
          
          </form>
          </div>

        </div>
        <Footer/>
      </div>
        
    </>

    )
}