import React, {useState, useEffect } from 'react';

import api from '../../../../services/api';
import { useHistory } from "react-router-dom";

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import ButtonDefault from '../../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { AnoMask, mvalor, placaMask, mplaca } from '../../../../vendor/mascaras';
import { formataDinheiroforBanco } from '../../../../vendor/formatar';


export default function ModalUpdateMarcaModeloVeiculo({ codigo, visible, change}) {
  
  let history = useHistory();

  const [loaderPage, setloaderPage] = useState(false);

  const [mesReferencia, setmesReferencia] = useState([]);
  const [marcas, setmarcas] = useState([]);
  const [modelos, setmodelos] = useState([]);
  const [anos, setanos] = useState([]);
  const [consultaFipe, setconsultaFipe] = useState([]);

  const [jsonConsultaFIpe, setjsonConsultaFIpe] = useState();
  
  const validationSchema = yup.object({
    tipo: yup
      .number('Selecione o tipo de veículo')
      .required('Tipo Obrigatório'),
    marca: yup
      .string('Selecione a marca veículo')
      .required('Marca Obrigatória')
      .test({
        message: () => "Selecione a marca veículo",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),
    modelo: yup
      .string('Selecione o modelo do veículo')
      .required('Modelo Obrigatório')
      .test({
        message: () => "Selecione o modelo do veículo",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),
    ano: yup
      .string('Selecione o ano do veículo')
      .required('Ano Obrigatório')
      .test({
        message: () => "Selecione o ano do veículo",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),
    anoFabricacao: yup
      .number('Informe o ano de fabricação')
      .required('Ano de fabricação Obrigatório'),

  });

 
  const formik = useFormik({
    initialValues: {
      tipo: '',
      marca: '',
      modelo: '',
      ano: '',
      anoFabricacao: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
        
        let marca = JSON.parse(values.marca);
        let modelo = JSON.parse(values.modelo);
        let ano = JSON.parse(values.ano);
        
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        await api.put(`updateModeloMarcaVeiculo`, {
            "codigo": codigo,
            "titulo": marca.label+' '+modelo.label,
            "tipo": values.tipo,
            "marca": marca.label,
            "modelo": modelo.label,
            "ano":  ano.label,
            "anoFabricacao": values.anoFabricacao,
            "precoFipe": formataDinheiroforBanco(consultaFipe.Valor),
            "fipeCodigo": consultaFipe.CodigoFipe,
            "fipemesReferencia": consultaFipe.MesReferencia,
            "jsonConsultaFIpe": jsonConsultaFIpe
        },
        {
            headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
            }
        });

        window.location.reload();
        // history.push(`/cadastro/veiculo/${response.data.codigo}`);

    }
  });


  const getConsultarTabelaDeReferencia = async()=>{
    
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.post(`consultarTabelaDeReferencia`, {},
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    }); 

    setmesReferencia(response.data[0])
  }



  useEffect(()=>{
    getConsultarTabelaDeReferencia();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  const handleTipo =  async(props) =>{
    
    setloaderPage(true);

    formik.handleChange(props);
    formik.setFieldValue('marca', 'null');
    formik.setFieldValue('modelo', 'null');
    formik.setFieldValue('ano', 'null');
    formik.setFieldValue('anoFabricacao', '');

    setmodelos([]); 
    setanos([]); 
    setmarcas([]); 


    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let marcas = await api.post(`consultarMarcas`, {
        "codigoTabelaReferencia": mesReferencia.Codigo,
	    "codigoTipoVeiculo": props.target.value
    },
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    }); 

    let marcasArray = [];

    for await (let data of marcas.data) {
        let dados = {
            label: data.Label,
            id: data.Value,
        };

        marcasArray.push(dados);
    }

    setmarcas(marcasArray);
    setloaderPage(false);

  }

  const handleMarca = async(e, value)=>{

    formik.setFieldValue('marca', JSON.stringify(value));

    setmodelos([]); 
    setanos([]); 
    
    formik.setFieldValue('modelo', 'null');
    formik.setFieldValue('ano', 'null');
    formik.setFieldValue('anoFabricacao', '');

    if(value === null) return false

    setloaderPage(true);

    let marcacodigo = value.id;

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let modelos = await api.post(`consultarModelos`, {
      "codigoTabelaReferencia": mesReferencia.Codigo,
	    "codigoTipoVeiculo": formik.values.tipo,
	    "codigoMarca": marcacodigo
    },
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    let anosModeloArray = [];
    
    for await (let data of modelos.data.Anos) {
        let dados = {
            label: data.Label,
            id: data.Value,
        };

        anosModeloArray.push(dados);
    }

    // setmodelos(modelosArray);
    setanos(anosModeloArray);
    setloaderPage(false);
    
  }

  const handleAnoForGetModelos = async(e, value)=>{ //ConsultarModelosAtravesDoAno

    if(value === null) return false
    formik.setFieldValue('ano', JSON.stringify(value));
    formik.setFieldValue('modelo', 'null');

    setloaderPage(true);
    let ano = value.id;
    let anoModelo = value.id.split('-')[0];
    let codigoTipoCombustivel = Number(value.id.split('-')[1]);

    formik.setFieldValue('anoFabricacao', anoModelo);

    let marca = JSON.parse(formik.values.marca);

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let modelos = await api.post(`ConsultarModelosAtravesDoAno`, {
      "codigoTabelaReferencia": mesReferencia.Codigo,
      "codigoTipoVeiculo": formik.values.tipo,
      "codigoMarca": marca.id,
      "ano": ano,
      "anoModelo": anoModelo,
      "codigoTipoCombustivel": codigoTipoCombustivel
    },
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    let modelosArray = [];
    
    for await (let data of modelos.data) {
        let dados = {
            label: data.Label,
            id: data.Value,
        };

        modelosArray.push(dados);
    }

    setmodelos(modelosArray);
    setloaderPage(false);

  }

  const handleModeloGetTodosParametros = async(e, value)=>{
    
    if(value === null) return false

    setloaderPage(true);
    formik.setFieldValue('modelo', JSON.stringify(value));

    let ano = JSON.parse(formik.values.ano);
    let codigoTipoCombustivel = Number(ano.id.split('-')[1]);
    let anoModelo = ano.id.split('-')[0];
    let anocodigo = ano.id;

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);


    let response = await api.post(`consultarValorComTodosParametros`, {
        "codigoTabelaReferencia": mesReferencia.Codigo,
        "codigoTipoVeiculo": formik.values.tipo,
        "codigoMarca": JSON.parse(formik.values.marca)?.id,
        "codigoModelo": value.id,

        "ano": anocodigo,
        "codigoTipoCombustivel": codigoTipoCombustivel,
        "anoModelo": anoModelo,
        "tipoConsulta": "tradicional"
    },
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    setjsonConsultaFIpe(
      {
        "codigoTabelaReferencia": mesReferencia.Codigo,
        "codigoTipoVeiculo": formik.values.tipo,
        "codigoMarca": JSON.parse(formik.values.marca).id,
        "codigoModelo": value.id,
        "ano": anocodigo,
        "codigoTipoCombustivel": codigoTipoCombustivel,
        "anoModelo": anoModelo,
        "tipoConsulta": "tradicional"
      }
    );

    setconsultaFipe(response.data);
    setloaderPage(false);

  }


  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Alterar Modelo/Marca
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>
          
          <Grid container spacing={1} >
            
            <Grid item xs={12} md={12}>
                <FormControl  fullWidth={true} >
                    <InputLabel id="label-tipo">Tipo</InputLabel>
                    <Select
                    className='input'
                    labelId="label-tipo"
                    id="demo-simple-select"
                    name="tipo"
                    label="Tipo"
                    placeholder='Tipo'
                    value={formik.values.tipo}
                    onChange={handleTipo}
                    error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                    >
                    <MenuItem value={1}>Carros</MenuItem>
                    <MenuItem value={2}>Motos</MenuItem>
                    <MenuItem value={3}>Caminhões</MenuItem>
                    </Select>
                    <FormHelperText error>{formik.touched.tipo && formik.errors.tipo}</FormHelperText>
                </FormControl>
            </Grid>

            {
            formik.values.tipo &&

            <>
            
            {
                marcas.length > 0 &&
                <Grid item xs={12} md={12}>
                    <FormControl  fullWidth={true} >
                        <Autocomplete
                            className='input'
                            // disablePortal
                            required
                            id="autocomplete-marcas"
                            options={marcas}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            sx={{ width: '100%' }}
                            value={formik.values.marca ? JSON.parse(formik.values.marca) : null }
                            onChange={handleMarca}
                            noOptionsText="Nenhuma marca encontrada"
                            renderInput={(params) => 
                            <TextField {...params}
                                label="Marcas"
                                error={formik.touched.marca && Boolean(formik.errors.marca)}
                            />}
                        />
                        <FormHelperText error>{formik.touched.marca && formik.errors.marca}</FormHelperText>
                    </FormControl>
                </Grid>
            }

            {
                anos.length > 0 &&
                <>
                <Grid item xs={12} md={6}>
                    <FormControl  fullWidth={true} >
                    <Autocomplete
                        className='input'
                        // disablePortal
                        required
                        id="autocomplete-ano"
                        options={anos}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        sx={{ width: '100%' }}
                        value={formik.values.ano ? JSON.parse(formik.values.ano) : null }
                        onChange={handleAnoForGetModelos}
                        noOptionsText="Nenhum ano encontrado"
                        renderInput={(params) => 
                        <TextField {...params}
                            label="Ano"
                            error={formik.touched.ano && Boolean(formik.errors.ano)}
                        />}
                    />
                    <FormHelperText error>{formik.touched.ano && formik.errors.ano}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormControl  fullWidth={true} >
                    <TextField
                        className='input'
                        id="anoFabricacao"
                        name="anoFabricacao"
                        label="Ano de Fabricação"
                        margin="none"
                        type="text"
                        value={formik.values.anoFabricacao}
                        onChange={formik.handleChange}
                        error={formik.touched.anoFabricacao && Boolean(formik.errors.anoFabricacao)}
                        helperText={formik.touched.anoFabricacao && formik.errors.anoFabricacao}
                        InputProps={{
                        inputComponent: AnoMask,
                        }}
                        />
                    </FormControl>
                </Grid>
                </>
            }


            {
                modelos.length > 0 &&
                
                <Grid item xs={12} md={12}>
                    <FormControl  fullWidth={true} >
                        <Autocomplete
                            className='input'
                            // disablePortal
                            required
                            id="autocomplete-modelo"
                            options={modelos}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            sx={{ width: '100%' }}
                            value={formik.values.modelo ? JSON.parse(formik.values.modelo) : null }
                            onChange={handleModeloGetTodosParametros}
                            noOptionsText="Nenhum modelo encontrado"
                            renderInput={(params) => 
                            <TextField {...params}
                                label="Modelo"
                                error={formik.touched.modelo && Boolean(formik.errors.modelo)}
                            />}
                        />
                        <FormHelperText error>{formik.touched.modelo && formik.errors.modelo}</FormHelperText>
                    </FormControl>
                </Grid>
            }

            </>

            }            
            
          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} >

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon="" txt="Salvar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}