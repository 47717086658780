import React, {useState, useEffect, useRef} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';
import moment from 'moment';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import ButtonSquadPrimaryForInput from '../../componentes/ButtonSquadPrimaryForInput';

import api from '../../services/api';
import Swal from 'sweetalert2';
import Fancybox from '../../vendor/fancybox'; //https://fancyapps.com/docs/ui/fancybox/react

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import generatePdfThumbnails from 'pdf-thumbnails-generator';
import { Buffer } from 'buffer';

export default function TabStatusMsg({dadosCompra, dadosVenda, refreshDetalhesCompraVenda, handleNewTab}) {


  let IconSend = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 35.041 35.921">
        <g id="send-message" transform="translate(-4.961 0)">
            <path id="Caminho_350" data-name="Caminho 350" d="M5.012,34.282c-.3,1.356.746,1.931,1.713,1.5L39.382,19.033h0a1.242,1.242,0,0,0,0-2.145h0L6.724.142c-.966-.433-2.008.141-1.713,1.5.02.092,1.951,8.692,3,13.369L25.06,17.961,8.012,20.912c-1.05,4.677-2.981,13.277-3,13.369Z" transform="translate(0 0)" fill="#fff"/>
        </g>
    </svg>

  )

  let IconAnexo = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 38.44 40.899">
        <g id="paper-clip" transform="translate(-14.394 1.002)">
            <g id="Grupo_391" data-name="Grupo 391" transform="translate(14.394 -1.002)">
            <path id="Caminho_351" data-name="Caminho 351" d="M52.331,13.893a1.7,1.7,0,0,0-2.41.006L29.435,34.5A6.817,6.817,0,0,1,19.8,24.853L40.891,3.65a4.26,4.26,0,0,1,6.021,6.029L29.439,27.152l0,0a1.7,1.7,0,0,1-2.406-2.413l8.434-8.436a1.7,1.7,0,1,0-2.41-2.41l-8.434,8.436a5.112,5.112,0,0,0,7.23,7.229l.006-.007L49.321,12.089A7.668,7.668,0,1,0,38.477,1.243l-21.092,21.2A10.225,10.225,0,0,0,31.849,36.9L52.338,16.3A1.7,1.7,0,0,0,52.331,13.893Z" transform="translate(-14.394 1.002)" fill="#fff"/>
            </g>
        </g>
    </svg>
  )

  let IconZoom = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 12.515 12.516">
      <g id="icon" transform="translate(-5.267 -5.266)">
        <path id="XMLID_231_" d="M17.782,5.835V8.68a.569.569,0,1,1-1.137,0V7.209l-3.3,3.308a.569.569,0,0,1-.8-.8L15.841,6.4h-1.47a.569.569,0,0,1,0-1.138h2.843a.569.569,0,0,1,.569.569ZM17.214,13.8a.569.569,0,0,0-.569.569v1.47L13.4,12.616a.588.588,0,0,0-.82,0,.569.569,0,0,0-.008.8l3.217,3.223H14.311a.569.569,0,1,0,0,1.138h2.842a.642.642,0,0,0,.433-.167.6.6,0,0,0,.2-.4V14.368a.569.569,0,0,0-.569-.569Zm-7.56-1.207L6.4,15.838V14.368a.564.564,0,0,0-.564-.568h0a.568.568,0,0,0-.568.568v2.845a.569.569,0,0,0,.569.569H8.688a.569.569,0,0,0,0-1.138H7.218L10.464,13.4a.571.571,0,0,0-.81-.8ZM7.208,6.455h1.47a.569.569,0,0,0,0-1.138H5.836a.568.568,0,0,0-.569.569V8.731a.569.569,0,1,0,1.137,0V7.261l3.279,3.282a.569.569,0,1,0,.8-.8Z" fill="#fff"/>
      </g>
    </svg>
  )

  const refScroollMsgBox = useRef(null);

  const [loaderPage, setloaderPage] = useState(false);
  const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);
  const [radioCompraVenda, setradioCompraVenda] = useState('todos');
  const [dadosUsuarioLogado, setdadosUsuarioLogado] = useState([]);
  const [mensagens, setmensagens] = useState([]);

  const permissoesUsuario = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post('permissoesUsuario', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    // console.log(permissoes.data.pessoas[3].update);
    setpermissoesdoUsuario(permissoes.data.veiculos);
  }

  const generateTumbOfPDF = async(pdf_source, thumbnail_size)=>{
    try {
        const thumbnails = await generatePdfThumbnails(pdf_source, thumbnail_size);
        return thumbnails[0].thumbnail;
    } catch (err) {
        return null;
    }
  }

  const getMensagens = async(compraId, vendaId)=>{

    setloaderPage(true);
    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let usuarioLogado = await api.post('detalhesUsuarioLogado', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    

    let mensagensDados = await api.post('listagemStatusMensagens', {
        "compraId": compraId,
        "vendaId": vendaId,
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });


    let mensagens = [];

    for await (let data of mensagensDados.data) {

        let tumbPDF = null;

        if(data.type === 'application/pdf'){
          // tumbPDF = await generateTumbOfPDF(data.url, 300);

          if(data.thumbnail){

            let buffer = new Buffer.from( data.thumbnail.data, 'binary' );
            let bufferBase64 = buffer.toString('base64');

            tumbPDF = window.atob(bufferBase64); //decode base64

          }else{
            tumbPDF = await generateTumbOfPDF(data.url, 250);

            //SET THUMBNAIL IN ANEXO
            api.post(`setThumbnailAnexosStatusMensagem`,{
                "id": data.id,
                "thumbnail": tumbPDF,
            },
            {
                headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${auth.token}`
                }
            }).then( async request => {
      
            }).catch((error) => {
              
            });
          }

        }

        let dados = {
            id: data.id,
            usuario: data.usuario,
            pessoa_id: data.pessoa_id,
            type: data.type,
            url: data.url,
            mensagem: data.mensagem,
            tumbPDF: tumbPDF,
            data: data.data
        }

        mensagens.push(dados);
    }

    setdadosUsuarioLogado(usuarioLogado.data);
    setmensagens(mensagens);
    setloaderPage(false);
    scrollBottonMsgBox();

  }

  const validationSchema = yup.object({
    // mensagem: yup
    //   .string('Informe a mensagem'),
    // status: yup
    //   .string('Informe o status'),
  });

  const formik = useFormik({
    initialValues: {
      mensagem: '',
      statusvenda: '',
      statuscompra: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let vendaID = null;
      let compraID = null;

      switch (radioCompraVenda) {
        case 'compra':
          vendaID = null;
          compraID = dadosCompra.id;
          break;
        case 'venda':
          vendaID = dadosVenda.id;
          compraID = null;
          break;
        case 'todos':
          let vvendaid = dadosVenda.id ? dadosVenda.id : null;
          vendaID = vvendaid;
          compraID = dadosCompra.id;
          break;
      }


      if(values.mensagem){

        if(!vendaID && !compraID){
          compraID = dadosCompra.id
        }

        await api.post(`novoStatusMensagens`, {
            "tipo": null,
            "venda_id": vendaID, 
            "compra_id": compraID,
            "usuario_pessoa_id": dadosUsuarioLogado.id,
            "mensagem": values.mensagem,
            "status": null,
        },
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        });


        formik.setFieldValue('mensagem', '');

       

        switch (radioCompraVenda) {
          case 'compra':
            await getMensagens(dadosCompra.id, null);
            break;
          case 'venda':
            let vvendaid = dadosVenda.id ? dadosVenda.id : null;

            if(vvendaid){
              await getMensagens(null, dadosVenda.id);
            }else{
              await getMensagens(null, null);
            }
            break;
          case 'todos':
            let vendaid = dadosVenda.id ? dadosVenda.id : null;
            await getMensagens(dadosCompra.id, vendaid);
            break;
        }

        scrollBottonMsgBox();

      }


    }
  });

  
  useEffect(async ()=>{ 
      await permissoesUsuario();
      let vendaid = dadosVenda.id ? dadosVenda.id : null;
      dadosCompra && getMensagens(dadosCompra.id, vendaid);
      dadosCompra.status >= 0 && formik.setFieldValue('statuscompra', dadosCompra.status);
      dadosVenda.status && formik.setFieldValue('statusvenda', dadosVenda.status);

      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(async ()=>{ 
    
    dadosVenda.id ? setradioCompraVenda('todos') : setradioCompraVenda('compra')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dadosVenda]);


  const scrollBottonMsgBox = () => {
    // refScroollMsgBox.current?.scrollIntoView({behavior: 'smooth'});
    let heightSet = refScroollMsgBox.current?.scrollHeight;
    refScroollMsgBox.current?.scrollTo(0, heightSet);
  };

  const handleStatusCompra = async(props)=>{
    let value = props.target.value;

    if(dadosCompra.status >= 0 && dadosCompra.status !== value){

      let vendaID = null;
      let compraID = null;

      switch (radioCompraVenda) {
        case 'compra':
          vendaID = null;
          compraID = dadosCompra.id;
          break;
        case 'venda':
          vendaID = dadosVenda.id;
          compraID = null;
          break;
        case 'todos':
          let vvendaid = dadosVenda.id ? dadosVenda.id : null;
          vendaID = vvendaid;
          compraID = dadosCompra.id;
          break;
      }

      let statusName = '';
      switch (value) {
        case 0:
          statusName = 'Inativo';
          break;
        case 1:
          statusName = 'Ativo';
          break;
      }

      Swal.fire({
        title: 'Você deseja mudar o status?',
        text: `Deseja realmente alterar o status da compra para ${statusName}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#0554C2',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim alterar',
        cancelButtonText: 'Cancelar',
      }).then(async(result) => {
        if (result.isConfirmed) {

          //GET DADOS USER
          let auth = localStorage.getItem("auth");
          auth = JSON.parse(auth);
  
          await api.post(`novoStatusMensagens`, {
              "tipo": 'compra', 
              "venda_id": vendaID, 
              "compra_id": compraID,
              "usuario_pessoa_id": dadosUsuarioLogado.id,
              "status": value,
          },
          {
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
            }
          });
          
          await handleNewTab('5');
          refreshDetalhesCompraVenda();

          switch (radioCompraVenda) {
            case 'compra':
              await getMensagens(dadosCompra.id, null);
              setradioCompraVenda('compra');
              break;
            case 'venda':
              await getMensagens(null, dadosVenda.id);
              setradioCompraVenda('venda');
              break;
            case 'todos':
              await getMensagens(dadosCompra.id, dadosVenda.id);
              setradioCompraVenda('todos');
              break;
          }

          
          formik.handleChange(props);
          scrollBottonMsgBox();
  
          // Swal.fire(
          //   'Deletado!',
          //   'Pagamento(s) deletado(s) com sucesso!',
          //   'success'
          // )
  
        }
      })
    }
  }
  
  const handleStatusVenda = async(props)=>{
    let value = props.target.value;
    
    if(dadosVenda.status && dadosVenda.status !== value){

      let vendaID = null;
      let compraID = null;

      switch (radioCompraVenda) {
        case 'compra':
          vendaID = null;
          compraID = dadosCompra.id;
          break;
        case 'venda':
          vendaID = dadosVenda.id;
          compraID = null;
          break;
        case 'todos':
          let vvendaid = dadosVenda.id ? dadosVenda.id : null;
          vendaID = vvendaid;
          compraID = dadosCompra.id;
          break;
      }

      let statusName = '';
      switch (value) {
        case 1:
          statusName = 'Pendente';
          break;
        case 2:
          statusName = 'Comunicado venda';
          break;
        case 3:
          statusName = 'Finalizado';
          break;
        case 4:
          statusName = 'Comunicado venda c/ OBS';
          break;
        case 5:
          statusName = 'Finalizado c/ OBS';
          break;
      }

      Swal.fire({
        title: 'Você deseja mudar o status?',
        text: `Deseja realmente alterar o status da venda para ${statusName}?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#0554C2',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim alterar',
        cancelButtonText: 'Cancelar',
      }).then(async(result) => {
        if (result.isConfirmed) {

          //GET DADOS USER
          let auth = localStorage.getItem("auth");
          auth = JSON.parse(auth);
  
          await api.post(`novoStatusMensagens`, {
              "tipo": 'venda', 
              "venda_id": vendaID,
              "compra_id": compraID,
              "usuario_pessoa_id": dadosUsuarioLogado.id,
              "status": value,
          },
          {
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
            }
          });
          
          await handleNewTab('5');
          refreshDetalhesCompraVenda();

          switch (radioCompraVenda) {
            case 'compra':
              await getMensagens(dadosCompra.id, null);
              setradioCompraVenda('compra');
              break;
            case 'venda':
              await getMensagens(null, dadosVenda.id);
              setradioCompraVenda('venda');
              break;
            case 'todos':
              await getMensagens(dadosCompra.id, dadosVenda.id);
              setradioCompraVenda('todos');
              break;
          }

          formik.handleChange(props);
          scrollBottonMsgBox();
  
          // Swal.fire(
          //   'Deletado!',
          //   'Pagamento(s) deletado(s) com sucesso!',
          //   'success'
          // )
  
        }
      })
    }
  }

  const handleRadioCompraVenda = async(props)=>{
    let value = props.target.value;
    setradioCompraVenda(value);
    
    switch (value) {
      case 'compra':
        getMensagens(dadosCompra.id, null);
        break;
      case 'venda':
        let vvendaid = dadosVenda.id ? dadosVenda.id : null;

        if(vvendaid){
          getMensagens(null, dadosVenda.id);
        }else{
          getMensagens(null, null);
        }
        break;
      case 'todos':
        let vendaid = dadosVenda.id ? dadosVenda.id : null;
        await getMensagens(dadosCompra.id, vendaid);
        break;
    }
  }

  const Input = styled('input')({
    display: 'none',
  });

  const handleUpload = async(e)=>{
    
    setloaderPage(true);

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let anexos = e.target.files;

    let formData = new FormData();
    formData.append('usuario_pessoa_id', dadosUsuarioLogado.id);
    formData.append('mensagem', formik.values.mensagem);

    switch (radioCompraVenda) {
      case 'compra':
        formData.append('vendaId', null);
        formData.append('compraId', dadosCompra.id);
        break;
      case 'venda':
        formData.append('vendaId', dadosVenda.id);
        formData.append('compraId', null);
        break;
      case 'todos':
        let vvendaid = dadosVenda.id ? dadosVenda.id : null;
        formData.append('vendaId', vvendaid);
        formData.append('compraId', dadosCompra.id);
        break;
    }

    if(anexos.length > 5){
      Swal.fire(
        'Ops, Limite por vez atingido!',
        'Você só pode enviar 5 arquivos por vez.',
        'warning'
      )
    }else{
    
      for await (let anexo of anexos) {

        if(anexo.type === 'image/png' || anexo.type === 'image/gif' || anexo.type === 'image/jpeg' || anexo.type === 'application/pdf'){
          formData.append('anexos', anexo);
        }else{
          Swal.fire(
            'Ops, Arquivo inválido!',
            'Apenas os formatos .png, .jpg, .jpeg e .PDF são permitidos!',
            'error'
          )
        }
        
      }

      await api.put("uploadAnexosStatusMensagem", formData, {
        headers: { 
          "Content-type": "multipart/form-data",
          "Authorization": `Bearer ${auth.token}`
        },
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
          // setprogressUpload(progress)
        },
      });

      switch (radioCompraVenda) {
        case 'compra':
          await getMensagens(dadosCompra.id, null);
          break;
        case 'venda':
          let vvendaid = dadosVenda.id ? dadosVenda.id : null;

          if(vvendaid){
            await getMensagens(null, dadosVenda.id);
          }else{
            await getMensagens(null, null);
          }
          break;
        case 'todos':
          let vendaid = dadosVenda.id ? dadosVenda.id : null;
          await getMensagens(dadosCompra.id, vendaid);
          break;
      }

      formik.setFieldValue('mensagem', '');
      scrollBottonMsgBox();
      
    }
    
    setloaderPage(false);

  }



  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: '2000' }}
        open={loaderPage}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <HelmetProvider>
        <Helmet>
            <title>Nexcar - Status da venda</title>
        </Helmet>
      </HelmetProvider>

      <Box style={{ marginTop: '20px' }}>
        <Grid container spacing={2} >

            <Grid item xs={12} md={12}>
                <div className='box-content'>

                
                  <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">Situação de status para:</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={radioCompraVenda}
                      onChange={handleRadioCompraVenda}
                    >
                      <FormControlLabel value="compra" control={<Radio />} label="Compra" />

                      { dadosVenda.id && 
                        <>
                        <FormControlLabel value="venda" control={<Radio />} label="Venda" /> 
                        <FormControlLabel value="todos" control={<Radio />} label="Todos" />
                        </>
                      }
                    </RadioGroup>
                  </FormControl>  
                 
                  
                  <Fancybox>
                    <div className='box-mensagens-status'>
                      <article ref={refScroollMsgBox}>

                      {mensagens.map((v, k) =>{
                          //.format('YYYY-MM-DD HH:mm:ss')
                          let now = moment(new Date()); //todays date
                          let end = moment(moment(v.data).format('YYYY-MM-DD HH:mm:ss')); // another date
                          
                          let seconds = now.diff(end, 'seconds') // 44700
                          let minutes = now.diff(end, 'minutes') // 44700
                          let hours = now.diff(end, 'hours') // 745
                          let days = now.diff(end, 'days') // 31
                          let weeks = now.diff(end, 'weeks') // 4

                          let tempo = '';

                          if(seconds < 30){
                            tempo = `há ${seconds}s`;
                          }else if(minutes < 58){
                            tempo = `há ${minutes}min`;
                          }else if(hours <= 22){
                            tempo = hours === 1 ? `há ${hours} hora` : `há ${hours} horas`;
                          }else if(days <= 7){
                            tempo = days === 1 ? `há ${days} dia` : `há ${days} dias`;
                          }else {
                            tempo = `${moment(v.data).format('DD/MM/YYYY')} às ${moment(v.data).format('HH:mm')}`;
                          }
      
                          return (
                            <div key={k} className={ dadosUsuarioLogado.id === v.pessoa_id ? `container-box-msg right` : `container-box-msg left`}>
                              <div className='box-msg'>
                                <h3>{v.usuario}</h3>
                                <aside>
                                  {
                                    !v.url ?
                                    <p>{v.mensagem}</p>
                                    :
                                    <div style={{ marginTop: '5px', marginBottom: '2px'}}>
                                      {
                                        v.type === 'image/png' || v.type === 'image/gif' || v.type === 'image/jpeg' ?
                                        
                                        <>
                                          <div className='element-anexo-for-status-msg' style={{ backgroundImage: `url(${v.url})` }}>
                                            <div className='box-abrirAnexo'>
                                              <button type='button' data-fancybox="gallery" href={v.url} data-caption={v.titulo}>
                                                <IconZoom/>
                                              </button>
                                            </div>
                                          </div>
                                          <p>{v.mensagem}</p>
                                        </>
                                        :
                                        <>
                                          <div className='element-anexo-for-status-msg' style={{ backgroundImage: `url("${v.tumbPDF}")` }}> 
                                              <div className='box-abrirAnexo'>
                                                <button type='button' data-fancybox="gallery" href={v.url}>
                                                  <IconZoom/>
                                                </button>
                                              </div>
                                          </div>
                                          <p>{v.mensagem}</p>
                                        </>

                                      }
                                    </div>
                                  }
                                  
                                  <span>
                                    <Tooltip title={`${moment(v.data).format('DD/MM/YYYY')} às ${moment(v.data).format('HH:mm')}`}>
                                      <Button>{tempo}</Button>
                                    </Tooltip>
                                  </span>

                                </aside>
                              </div>
                            </div>
                          )
                      })}
                      
                      </article>
                    </div>
                  </Fancybox>
                
                  <form method='post' onSubmit={formik.handleSubmit}>
                    <div style={{ display: 'flex', marginTop: '10px'}}>  
                                
                        <label htmlFor="button-file-upload-msg">
                          <Input accept="image/png, image/gif, image/jpeg, .pdf" id="button-file-upload-msg" multiple onChange={handleUpload} type="file" />
                          <ButtonSquadPrimaryForInput type="button" component="span" icon={<IconAnexo/>}/>
                        </label>

                        
                        {
                          dadosVenda?.id && radioCompraVenda === 'venda' && 
                          <FormControl sx={{ m: 0, minWidth: '200px', marginLeft:'5px' }} >
                            <InputLabel id="label-statusvenda">Status</InputLabel>
                            <Select
                              labelId="label-statusvenda"
                              id="statusvenda-simple-select"
                              name="statusvenda"
                              label="Status Venda"
                              placeholder='Status'
                              value={formik.values.statusvenda}
                              disabled={!permissoesdoUsuario[3]?.update}
                              // onChange={(e)=>{ formik.setFieldValue('status', e.target.value) }}
                              onChange={handleStatusVenda}
                              error={formik.touched.status && Boolean(formik.errors.statusvenda)}
                            > 
                                <MenuItem value={1}>Pendente</MenuItem>
                                <MenuItem value={4}>Comunicado Venda c/ OBS</MenuItem>
                                <MenuItem value={2}>Comunicado Venda</MenuItem>
                                <MenuItem value={5}>Finalizado c/ OBS</MenuItem>
                                <MenuItem value={3}>Finalizado</MenuItem>
                            </Select>
                            <FormHelperText error>{formik.touched.statusvenda && formik.errors.statusvenda}</FormHelperText>
                          </FormControl>
                        }

                        {
                          radioCompraVenda === 'compra' && 
                          <FormControl sx={{ m: 0, minWidth: '200px', marginLeft:'5px' }} >
                            <InputLabel id="label-statuscompra">Status</InputLabel>
                            <Select
                              labelId="label-statuscompra"
                              id="statuscompra-simple-select"
                              name="statuscompra"
                              label="Status Compra"
                              placeholder='Status'
                              value={formik.values.statuscompra}
                              disabled={!permissoesdoUsuario[3]?.update}
                              // onChange={(e)=>{ formik.setFieldValue('status', e.target.value) }}
                              onChange={handleStatusCompra}
                              error={formik.touched.statuscompra && Boolean(formik.errors.statuscompra)}
                            > 
                                <MenuItem value={1}>Ativo</MenuItem>
                                <MenuItem value={0}>Inativo</MenuItem>
                            </Select>
                            <FormHelperText error>{formik.touched.statuscompra && formik.errors.statuscompra}</FormHelperText>
                          </FormControl>
                        }                         

                        <TextField
                          fullWidth
                          id="mensagem"
                          name="mensagem"
                          label=""
                          placeholder="Informe a situação da negociação..."
                          margin="none"
                          type="text"
                          value={formik.values.mensagem}
                          onChange={formik.handleChange}
                          error={formik.touched.mensagem && Boolean(formik.errors.mensagem)}
                          helperText={formik.touched.mensagem && formik.errors.mensagem}
                          inputProps={{
                              maxLength: 600,
                          }}
                          style={{ marginLeft:'5px' }}
                        />
                        <ButtonSquadPrimaryForInput icon={<IconSend/>} onClick={()=>{ formik.handleSubmit() }}/>

                    </div>
                  </form>
                </div>
            </Grid>

        </Grid>
      </Box>
        
      

    </>
    
  );
}