import React, {useState, useEffect} from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'; //CONFIGURE O WEBPACK EM \node_modules\react-scripts\webpack.config

import api from '../../../services/api';
import { formataDinheiro } from '../../../vendor/formatar';
 
export default function DocumentPDFVeiculos({ veiculos }) {

//   const [listagemPessoasExport, setlistagemPessoasExport] = useState([]);

//   const getDataExport = async()=>{
//         //GET DADOS USER
//         let auth = localStorage.getItem("auth");
//         auth = JSON.parse(auth);

//         let pessoas = await api.post('listagemVeiculosSelecionados', {
//         'veiculosIds': veiculosIds
//         }, 
//         {
//         headers: {
//             "Content-type": "application/json",
//             "Authorization": `Bearer ${auth.token}`
//         }
//         });

//         setlistagemPessoasExport(pessoas.data);
//   }

//   useEffect(()=>{
//     getDataExport();
    
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   },[veiculosIds]);


  const styles = StyleSheet.create({
        page: {
            backgroundColor: "#ffffff",
            display: 'flex',
            flexDirection: 'column',
            padding: '20px'
        },
        header:{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '30px',
        },  
        row: {
            display: 'flex',
            flexDirection: 'row',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '7px',
        },
        rowBG: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#f6f6f8',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
        },
        rowHeader:{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#000',
            ustifyContent: 'center',
            alignItems: 'center',
            padding: '5px',
        },
        txtrowHeader:{
            fontSize: '11px',
            color: '#fff',
            fontWeight: 'bold'
        },
        txtrow:{
            fontSize: '10px'
        }
  });

  return (
    <Document>
        <Page size="A4" style={styles.page}>

        <View style={styles.header}>
            <Text>Cadastro de veículos</Text>
        </View>


        <View style={ styles.rowHeader }>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Marca</Text>
            </View>
            <View style={{ display: 'flex', flex: '2' }}>
                <Text style={styles.txtrowHeader}>Modelo</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Ano Mod.</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Ano Fab.</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Placa</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Preço venda</Text>
            </View>
            <View style={{ display: 'flex', flex: '1' }}>
                <Text style={styles.txtrowHeader}>Cor</Text>
            </View>
        </View>

        {veiculos.map((v, k) =>{
            return (
                <View key={k} style={  k++ % 2 === 0 ? styles.row : styles.rowBG }>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.marca}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '2' }}>
                        <Text style={styles.txtrow}>{v.modelo}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.ano}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.anoFabricacao}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.placa}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{ formataDinheiro(parseInt(v.precoVenda))}</Text>
                    </View>
                    <View style={{ display: 'flex', flex: '1' }}>
                        <Text style={styles.txtrow}>{v.cor}</Text>
                    </View>
                </View>
            )
        })}
        
        </Page>
    </Document> 
  );
}