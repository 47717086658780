import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import moment from 'moment';
import api from '../../services/api';
import './style.css';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const AudioComponent = ({ data, tempo, currentlyPlaying, setCurrentlyPlaying }) => {
    const [media, setMedia] = useState({
        base64: '',
        mimetype: ''
    });
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0); // Progresso do áudio
    const [speed, setSpeed] = useState(1); // Velocidade do áudio

    const playerRef = useRef(null);

    const getMediaByMessage = async (messageId) => {
        try {
            const auth = JSON.parse(localStorage.getItem("auth"));

            const response = await api.post('whatsAppGetMediaByMessage', {
                messageId
            }, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${auth.token}`
                }
            });

            setMedia(response.data);
        } catch (error) {
            console.error("Erro ao carregar mídia:", error);
        }
    };

    useEffect(() => {
        if (data?.id || data?.msg_id) {
            getMediaByMessage(data?.msg_id || data?.id );
        }
    }, [data]);

    // Alternar entre play e pause
    const handlePlayPause = () => {
        if (currentlyPlaying && currentlyPlaying !== media.base64) {
            setCurrentlyPlaying(null); // Para o áudio anterior
        }
        setIsPlaying(!isPlaying); // Alterna o estado de reprodução
        setCurrentlyPlaying(media.base64); // Define este como o áudio atual
    };

    // Atualizar progresso do áudio
    const handleProgress = (state) => {
        setProgress(state.played * 100);
    };

    // Saltar para uma posição no áudio
    const handleSeek = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const clickX = e.clientX - rect.left;
        const seekTo = clickX / rect.width;
        playerRef.current.seekTo(seekTo, "fraction");
    };

    // Alterar velocidade de reprodução
    const handleSpeedChange = () => {
        const newSpeed = speed === 2 ? 1 : speed + 0.5; // Alterna entre 1x, 1.5x e 2x
        setSpeed(newSpeed);
    };

    useEffect(() => {
        if (currentlyPlaying !== media.base64) {
            setIsPlaying(false); // Pausa o áudio se não for o atualmente ativo
        }
    }, [currentlyPlaying, media.base64]);

    return (
        <>
            {media.mimetype && media.base64 ? (
                <div className='container-custom-audio-msg'>
                    <div className="custom-audio-container">
                        {/* Player invisível, mas funcional */}
                        <ReactPlayer
                            ref={playerRef}
                            url={`data:${media.mimetype};base64,${media.base64}`} // Passa a URL base64
                            playing={isPlaying} // Controla o estado de reprodução
                            controls={false} // Desativa controles nativos
                            height="0"
                            width="0"
                            playbackRate={speed} // Velocidade
                            onProgress={handleProgress} // Atualiza progresso
                            onPlay={() => setCurrentlyPlaying(media.base64)} // Define como atual ao tocar
                            onPause={() => setIsPlaying(false)} // Atualiza o estado ao pausar
                            onEnded={() => setIsPlaying(false)} // Reseta ao terminar
                        />

                        {/* Controles customizados */}
                        <div className="audio-controls">
                            <button onClick={handlePlayPause} className="play-pause-button">
                                {isPlaying ? <PauseIcon sx={{ fontSize: 33 }} /> : <PlayArrowIcon sx={{ fontSize: 33 }} />}
                            </button>
                            
                            {/* Barra de progresso */}
                            <div className="progress-bar-container" onClick={handleSeek}>
                                <div className="progress-bar" style={{ width: `${progress}%` }}></div>
                            </div>

                            {/* Tempo e Velocidade */}
                            <div className="time-speed">
                                <span>{`${(progress / 100 * playerRef.current?.getDuration() || 0).toFixed(0)}s`}</span>
                                <button onClick={handleSpeedChange} className="speed-button">
                                    {speed}x
                                </button>
                            </div>

                        </div>
                    </div>

                    <div className='data-box'>
                        <Tooltip title={`${moment(data.timestamp * 1000).format('DD/MM/YYYY')} às ${moment(data.timestamp * 1000).format('HH:mm')}`}>
                            <Button>{tempo}</Button>
                        </Tooltip>
                    </div>
                </div>
            ) : (
                <div className='container-custom-audio-msg'>
                    Carregando áudio...
                </div>
            )}
        </>
    );
};

export default AudioComponent;
