import React, {useState, useEffect } from 'react';

import api from '../../../../services/api';
import { useHistory } from "react-router-dom";

import { useFormik } from 'formik';
import * as yup from 'yup';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import Swal from 'sweetalert2';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import ButtonDefault from '../../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../../componentes/ButtonOutlineSecondary';

import SliderComparacaoPrecos from '../../../../componentes/SliderComparacaoPrecos';

import { AnoMask, mvalor, placaMask, mplaca } from '../../../../vendor/mascaras';
import { formataDinheiroforBanco } from '../../../../vendor/formatar';


export default function ModalNovoVeiculo({ visible, change, atualizarVeiculos}) {
  

  let history = useHistory();

  const [step, setstep] = useState(0);
  const [errorStep1, seterrorStep1] = useState(false);
  const [loaderPage, setloaderPage] = useState(false);

  const [mesReferencia, setmesReferencia] = useState([]);
  const [marcas, setmarcas] = useState([]);
  const [modelos, setmodelos] = useState([]);
  const [anos, setanos] = useState([]);
  const [consultaFipe, setconsultaFipe] = useState([]);

  const [combustiveis, setcombustiveis] = useState([]);
  const [cambios, setcambios] = useState([]);
  const [cores, setcores] = useState([]);

  const [jsonConsultaFIpe, setjsonConsultaFIpe] = useState();
  
 
  const validationSchema = yup.object({
    tipo: yup
      .number('Selecione o tipo de veículo')
      .required('Tipo Obrigatório'),
    marca: yup
      .string('Selecione a marca veículo')
      .required('Marca Obrigatória')
      .test({
        message: () => "Selecione a marca veículo",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),
    modelo: yup
      .string('Selecione o modelo do veículo')
      .required('Modelo Obrigatório')
      .test({
        message: () => "Selecione o modelo do veículo",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),
    ano: yup
      .string('Selecione o ano do veículo')
      .required('Ano Obrigatório')
      .test({
        message: () => "Selecione o ano do veículo",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),
    anoFabricacao: yup
      .number('Informe o ano de fabricação')
      .required('Ano de fabricação Obrigatório'),
    
    placa: yup
        .string('Informe a placa do veículo')
        .test({
            message: () => "Placa Obrigatória",
            test: async (values) => {
              if(step === 1 && !values){
                return false
              }else{
                return true
              } 
            },
          })
        .test({
              message: () => "Placa inválida, informe uma plca válida",
              test: async (values) => {
                let placa = values?.toUpperCase();
                placa = placa?.replace(/\s/, ""); //Removendo espaços
                placa = placa?.replace(/[^\w\-]+/g, ""); //Removendo caracters especiais e acentos
    
                let placaNormal = placa?.match("[A-Z]{3}-[0-9]{4}");
                let placaMercosul = placa?.match("[A-Z]{3}[0-9][A-Z][0-9]{2}");
    
                placa = placaNormal && placaNormal != 'null' ? placaNormal : placaMercosul;
    
                if(step === 1 && !placa){
                  return false
                }else{
                  return true
                } 
    
              },
            })
        .test({
          message: (data) => `Já existe um veículo cadastrado com a placa ${data.value}.`,
          test: async (values) => {
            if (step === 1) { 
              try {

                let auth = localStorage.getItem("auth");
                auth = JSON.parse(auth);

                let response = await api.post(`verificaPlacaExistenteVeiculo`, {
                    "placa": values,
                },
                {
                  headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${auth.token}`
                  }
                });

                if (response.data.veiculoExiste) {
                  return false;
                }else{
                  return true;
                }

              }catch (error) {
                // console.log(error);
              }
            }else{
                return true
            } 
          },
        }),
    // placa: yup
    //   .string('Informe a placa do veículo')
    //   .test({
    //     message: () => "Já existe um veículo cadastrado com essa placa.",
    //     test: async (values) => {
    //       if (step === 1) { 
    //         try {

    //           let auth = localStorage.getItem("auth");
    //           auth = JSON.parse(auth);

    //           let response = await api.post(`verificaPlacaExistenteVeiculo`, {
    //               "placa": values,
    //           },
    //           {
    //             headers: {
    //               "Content-type": "application/json",
    //               "Authorization": `Bearer ${auth.token}`
    //             }
    //           });

    //           if (response.data.veiculoExiste) {
    //             return false;
    //           }else{
    //             return true;
    //           }

    //         }catch (error) {
    //           // console.log(error);
    //         }
    //       }else{
    //           return true
    //       } 
    //     },
    //   }),

    renavam: yup
      .string('Informe o renavam do veículo')
      .test({
        message: () => "Renavam Obrigatório",
        test: async (values) => {
          if(step === 1 && !values){
            return false
          }else{
            return true
          } 
        },
      }),
    chassi: yup
      .string('Informe o chassi do veículo')
      .test({
        message: () => "Chassi Obrigatório",
        test: async (values) => {
          if(step === 1 && !values){
            return false
          }else{
            return true
          } 
        },
      }),
    combustivel: yup
      .string('Informe o Combustível do veículo')
      .test({
        message: () => "Combustível Obrigatório",
        test: async (values) => {
          if(step === 1 && !values){
            return false
          }else{
            return true
          } 
        },
      }),
    portas: yup
      .string('Obrigatório')
      .test({
        message: () => "Obrigatório",
        test: async (values) => {
          if(step === 1 && !values){
            return false
          }else{
            return true
          } 
        },
      }),
    cambio: yup
      .string('Informe o tipo Câmbio do veículo')
      .test({
        message: () => "Câmbio Obrigatório",
        test: async (values) => {
          if(step === 1 && !values){
            return false
          }else{
            return true
          } 
        },
      }),
    cor: yup
      .string('Informe a cor do veículo')
      .test({
        message: () => "Cor Obrigatória",
        test: async (values) => {
          if(step === 1 && !values){
            return false
          }else{
            return true
          } 
        },
      }),

    precovenda: yup
      .string('Informe o preço de venda do veículo')
      .test({
        message: () => "Preço de venda Obrigatório",
        test: async (values) => {
          if(step === 2 && !values){
            return false
          }else{
            return true
          } 
        },
      }),

  });

  const formik = useFormik({
    initialValues: {
      tipo: '',
      marca: '',
      modelo: '',
      ano: '',
      anoFabricacao: '',
      placa: '',
      renavam: '',
      chassi: '',
      combustivel: '',
      portas: '',
      cambio: '',
      cor: '',
      precovenda: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      
      step < 2 && setstep(step+1)
      
      if(step === 2 && formik.values.precovenda){ //TUDO CERTO PRA CADASTRAR
        
        let marca = JSON.parse(values.marca);
        let modelo = JSON.parse(values.modelo);
        let ano = JSON.parse(values.ano);
        
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let response = await api.post(`addVeiculo`, {
          "titulo": marca.label+' '+modelo.label,
          "tipo": values.tipo,
          "marca": marca.label,
          "modelo": modelo.label,
          "ano":  ano.label,
          "anoFabricacao": values.anoFabricacao,
          "cor": values.cor,
          "combustivel": values.combustivel,
          "portas": values.portas,
          "cambio": values.cambio,
          "precoVenda": formataDinheiroforBanco(values.precovenda),
          "precoFipe": formataDinheiroforBanco(consultaFipe.Valor),
          "fipeCodigo": consultaFipe.CodigoFipe,
          "fipemesReferencia": consultaFipe.MesReferencia,
          "placa": values.placa,
          "renavam": values.renavam,
          "chassi": values.chassi,
          "jsonConsultaFIpe": jsonConsultaFIpe
        },
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        });
        
        atualizarVeiculos(null, response.data.codigo.toString(), null);
        formik.resetForm();
        setstep(0);
        change(false);

      }      
    }
  });


  useEffect(()=>{
    let erros = JSON.stringify(formik.errors);
    erros = erros.length - 2;

    // if(erros === 0){
    //   step === 0 && seterrorStep1(false);
    // }else{
    //   step === 0 && seterrorStep1(true);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formik.errors]);


  const getConsultarTabelaDeReferencia = async()=>{
    
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.post(`consultarTabelaDeReferencia`, {},
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    }); 

    setmesReferencia(response.data[0])
  }

  const getCoresCombustiveisCambios = async()=>{
    
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let cambios = await api.get(`listagemCambios`,
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    let combustiveis = await api.get(`listagemCombustiveis`,
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    let cores = await api.get(`listagemCores`,
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    }); 

    setcambios(cambios.data);
    setcombustiveis(combustiveis.data);
    setcores(cores.data);

  }

  useEffect(()=>{
    getConsultarTabelaDeReferencia();
    getCoresCombustiveisCambios();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleTipo =  async(props) =>{
    
    setloaderPage(true);

    formik.handleChange(props);
    formik.setFieldValue('marca', 'null');
    formik.setFieldValue('modelo', 'null');
    formik.setFieldValue('ano', 'null');
    formik.setFieldValue('anoFabricacao', '');

    setmodelos([]); 
    setanos([]); 
    setmarcas([]); 


    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let marcas = await api.post(`consultarMarcas`, {
        "codigoTabelaReferencia": mesReferencia.Codigo,
	    "codigoTipoVeiculo": props.target.value
    },
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    }); 

    let marcasArray = [];

    for await (let data of marcas.data) {
        let dados = {
            label: data.Label,
            id: data.Value,
        };

        marcasArray.push(dados);
    }

    setmarcas(marcasArray);
    setloaderPage(false);

  }

  const handleMarca = async(e, value)=>{

    formik.setFieldValue('marca', JSON.stringify(value));

    setmodelos([]); 
    setanos([]); 
    
    formik.setFieldValue('modelo', 'null');
    formik.setFieldValue('ano', 'null');
    formik.setFieldValue('anoFabricacao', '');

    if(value === null) return false

    setloaderPage(true);

    let marcacodigo = value.id;

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let modelos = await api.post(`consultarModelos`, {
      "codigoTabelaReferencia": mesReferencia.Codigo,
	    "codigoTipoVeiculo": formik.values.tipo,
	    "codigoMarca": marcacodigo
    },
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    let anosModeloArray = [];
    
    for await (let data of modelos.data.Anos) {
        let dados = {
            label: data.Label,
            id: data.Value,
        };

        anosModeloArray.push(dados);
    }

    // setmodelos(modelosArray);
    setanos(anosModeloArray);
    setloaderPage(false);
    
  }

  const handleAnoForGetModelos = async(e, value)=>{ //ConsultarModelosAtravesDoAno

    if(value === null) return false
    formik.setFieldValue('ano', JSON.stringify(value));
    formik.setFieldValue('modelo', 'null');

    // setloaderPage(true);
    let ano = value.id;
    let anoModelo = value.id.split('-')[0];
    let codigoTipoCombustivel = Number(value.id.split('-')[1]);

    formik.setFieldValue('anoFabricacao', anoModelo);

    let marca = JSON.parse(formik.values.marca);

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let modelos = await api.post(`ConsultarModelosAtravesDoAno`, {
      "codigoTabelaReferencia": mesReferencia.Codigo,
      "codigoTipoVeiculo": formik.values.tipo,
      "codigoMarca": marca.id,
      "ano": ano,
      "anoModelo": anoModelo,
      "codigoTipoCombustivel": codigoTipoCombustivel
    },
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    let modelosArray = [];
    let modelosResponse = [];

    if(modelos.data?.codigo === '0'){
      Swal.fire(
        'Ops, parece que esse modelo ainda não existe!',
        'Selecione outro ano, o ano informado ainda não foi implementado!',
        'error'
      )
    }else{
      modelosResponse = modelos?.data;
    }


    for await (let data of modelosResponse) {
      let dados = {
          label: data.Label,
          id: data.Value,
      };

      modelosArray.push(dados);
    }
    
    setmodelos(modelosArray);
    setloaderPage(false);

  }

  const handleModeloGetTodosParametros = async(e, value)=>{
    
    if(value === null) return false

    setloaderPage(true);
    formik.setFieldValue('modelo', JSON.stringify(value));

    let ano = JSON.parse(formik.values.ano);
    let codigoTipoCombustivel = Number(ano.id.split('-')[1]);
    let anoModelo = ano.id.split('-')[0];
    let anocodigo = ano.id;

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);


    let response = await api.post(`consultarValorComTodosParametros`, {
        "codigoTabelaReferencia": mesReferencia.Codigo,
        "codigoTipoVeiculo": formik.values.tipo,
        "codigoMarca": JSON.parse(formik.values.marca)?.id,
        "codigoModelo": value.id,

        "ano": anocodigo,
        "codigoTipoCombustivel": codigoTipoCombustivel,
        "anoModelo": anoModelo,
        "tipoConsulta": "tradicional"
    },
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    setjsonConsultaFIpe(
      {
        "codigoTabelaReferencia": mesReferencia.Codigo,
        "codigoTipoVeiculo": formik.values.tipo,
        "codigoMarca": JSON.parse(formik.values.marca).id,
        "codigoModelo": value.id,
        "ano": anocodigo,
        "codigoTipoCombustivel": codigoTipoCombustivel,
        "anoModelo": anoModelo,
        "tipoConsulta": "tradicional"
      }
    );

    setconsultaFipe(response.data);
    setloaderPage(false);

  }


  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} onClose={()=>{ change(false) }}>
        <DialogTitle sx={{ m: 0, p: 0 }}>
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>
          
          <Grid container spacing={0} >
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '50px' }}>
                <Stepper activeStep={step} alternativeLabel>
                    <Step>
                    <StepLabel error={errorStep1}>Veículo</StepLabel>
                    </Step>
                    <Step>
                    <StepLabel error={false}>Detalhes</StepLabel>
                    </Step>
                    <Step>
                    <StepLabel error={false}>Preço</StepLabel>
                    </Step>
                </Stepper>

                <form method='post' onSubmit={formik.handleSubmit} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingLeft: '40px', paddingRight: '40px', paddingBottom: '40px' }}>
                    <h2 style={{ color: '#9C9C9C', fontWeight: 'normal', marginTop: '50px' }}>Cadastro de veículo</h2>
                    
                    {
                    step === 0 &&

                    <Grid container spacing={2} >

                        <Grid item xs={12} md={12}>
                        <FormControl  fullWidth={true} >
                            <InputLabel id="label-tipo">Tipo</InputLabel>
                            <Select
                            className='input'
                            labelId="label-tipo"
                            id="demo-simple-select"
                            name="tipo"
                            label="Tipo"
                            placeholder='Tipo'
                            value={formik.values.tipo}
                            onChange={handleTipo}
                            error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                            >
                            <MenuItem value={1}>Carros</MenuItem>
                            <MenuItem value={2}>Motos</MenuItem>
                            <MenuItem value={3}>Caminhões</MenuItem>
                            </Select>
                            <FormHelperText error>{formik.touched.tipo && formik.errors.tipo}</FormHelperText>
                        </FormControl>
                        </Grid>

                        {
                        formik.values.tipo &&

                        <>
                        
                        {
                            marcas.length > 0 &&
                            <Grid item xs={12} md={12}>
                                <FormControl  fullWidth={true} >
                                    <Autocomplete
                                        className='input'
                                        disablePortal
                                        required
                                        id="autocomplete-marcas"
                                        options={marcas}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        sx={{ width: '100%' }}
                                        value={formik.values.marca ? JSON.parse(formik.values.marca) : null }
                                        onChange={handleMarca}
                                        noOptionsText="Nenhuma marca encontrada"
                                        renderInput={(params) => 
                                        <TextField {...params}
                                            label="Marcas"
                                            error={formik.touched.marca && Boolean(formik.errors.marca)}
                                        />}
                                    />
                                    <FormHelperText error>{formik.touched.marca && formik.errors.marca}</FormHelperText>
                                </FormControl>
                            </Grid>
                        }

                        {
                            anos.length > 0 &&
                            <>
                            <Grid item xs={12} md={6}>
                                <FormControl  fullWidth={true} >
                                <Autocomplete
                                    className='input'
                                    disablePortal
                                    required
                                    id="autocomplete-ano"
                                    options={anos}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    sx={{ width: '100%' }}
                                    value={formik.values.ano ? JSON.parse(formik.values.ano) : null }
                                    onChange={handleAnoForGetModelos}
                                    noOptionsText="Nenhum ano encontrado"
                                    renderInput={(params) => 
                                    <TextField {...params}
                                        label="Ano"
                                        error={formik.touched.ano && Boolean(formik.errors.ano)}
                                    />}
                                />
                                <FormHelperText error>{formik.touched.ano && formik.errors.ano}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl  fullWidth={true} >
                                <TextField
                                    className='input'
                                    id="anoFabricacao"
                                    name="anoFabricacao"
                                    label="Ano de Fabricação"
                                    margin="none"
                                    type="text"
                                    value={formik.values.anoFabricacao}
                                    onChange={formik.handleChange}
                                    error={formik.touched.anoFabricacao && Boolean(formik.errors.anoFabricacao)}
                                    helperText={formik.touched.anoFabricacao && formik.errors.anoFabricacao}
                                    InputProps={{
                                    inputComponent: AnoMask,
                                    }}
                                    />
                                </FormControl>
                            </Grid>
                            </>
                        }


                        {
                            modelos.length > 0 &&
                            
                            <Grid item xs={12} md={12}>
                                <FormControl  fullWidth={true} >
                                    <Autocomplete
                                        className='input'
                                        disablePortal
                                        required
                                        id="autocomplete-modelo"
                                        options={modelos}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        sx={{ width: '100%' }}
                                        value={formik.values.modelo ? JSON.parse(formik.values.modelo) : null }
                                        onChange={handleModeloGetTodosParametros}
                                        noOptionsText="Nenhum modelo encontrado"
                                        renderInput={(params) => 
                                        <TextField {...params}
                                            label="Modelo"
                                            error={formik.touched.modelo && Boolean(formik.errors.modelo)}
                                        />}
                                    />
                                    <FormHelperText error>{formik.touched.modelo && formik.errors.modelo}</FormHelperText>
                                </FormControl>
                            </Grid>
                        }



                    
                        </>

                        }

                    </Grid>

                    }
                    
                    {
                    step === 1 &&

                    <Grid container spacing={2} >

                        <Grid item xs={12} md={12}>
                        <TextField
                            className='input'
                            fullWidth
                            id="placa"
                            name="placa"
                            label="Placa"
                            type="text"
                            value={formik.values.placa}
                            onChange={formik.handleChange}
                            error={formik.touched.placa && Boolean(formik.errors.placa)}
                            helperText={formik.touched.placa && formik.errors.placa}
                            
                            onKeyUp={()=>{ formik.setFieldValue('placa', mplaca(formik.values.placa)) }}
                            inputProps={{
                            // inputComponent: placaMask,
                            maxLength: 8,
                            }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                        <TextField
                            className='input'
                            fullWidth
                            id="renavam"
                            name="renavam"
                            label="Renavam"
                            type="text"
                            value={formik.values.renavam}
                            onChange={formik.handleChange}
                            error={formik.touched.renavam && Boolean(formik.errors.renavam)}
                            helperText={formik.touched.renavam && formik.errors.renavam}
                            inputProps={{
                            maxLength: 45,
                            }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                        <TextField
                            className='input'
                            fullWidth
                            id="chassi"
                            name="chassi"
                            label="Chassi"
                            type="text"
                            value={formik.values.chassi}
                            onChange={formik.handleChange}
                            error={formik.touched.chassi && Boolean(formik.errors.chassi)}
                            helperText={formik.touched.chassi && formik.errors.chassi}
                            inputProps={{
                            maxLength: 45,
                            }}
                            />
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <FormControl  fullWidth={true} >
                            <InputLabel id="label-combustivel">Combustível</InputLabel>
                            <Select
                                className='input'
                                labelId="label-combustivel"
                                name="combustivel"
                                label="Combustível"
                                placeholder='Combustível'
                                value={formik.values.combustivel}
                                onChange={formik.handleChange}
                                error={formik.touched.combustivel && Boolean(formik.errors.combustivel)}
                            >
                                {combustiveis.map((v, k) =>{
                                    return (
                                    <MenuItem key={k} value={v.id}>{v.nome}</MenuItem>
                                    )
                                })}
                                
                                
                            </Select>
                            <FormHelperText error>{formik.touched.combustivel && formik.errors.combustivel}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={4} >
                            <FormControl sx={{ m: 0, width: '100%' }} >
                            <InputLabel id="label-portas">Portas</InputLabel>
                            <Select
                                className='input'
                                labelId="label-portas"
                                id="portas-select"
                                name="portas"
                                label="Portas"
                                placeholder='Portas'
                                value={formik.values.portas}
                                onChange={formik.handleChange}
                                error={formik.touched.portas && Boolean(formik.errors.portas)}
                            >
                                <MenuItem value={0}>0</MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                            </Select>
                            <FormHelperText error>{formik.touched.portas && formik.errors.portas}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl  fullWidth={true} >
                            <InputLabel id="label-cambio">Câmbio</InputLabel>
                            <Select
                                className='input'
                                labelId="label-cambio"
                                name="cambio"
                                label="Câmbio"
                                placeholder='Câmbio'
                                value={formik.values.cambio}
                                onChange={formik.handleChange}
                                error={formik.touched.cambio && Boolean(formik.errors.cambio)}
                            >
                                {cambios.map((v, k) =>{
                                    return (
                                    <MenuItem key={k} value={v.id}>{v.nome}</MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText error>{formik.touched.cambio && formik.errors.cambio}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl  fullWidth={true} >
                            <InputLabel id="label-cor">Cor</InputLabel>
                            <Select
                                className='input'
                                labelId="label-cor"
                                name="cor"
                                label="Cor"
                                placeholder='Cor'
                                value={formik.values.cor}
                                onChange={formik.handleChange}
                                error={formik.touched.cor && Boolean(formik.errors.cor)}
                            >
                                {cores.map((v, k) =>{
                                    return (
                                    <MenuItem key={k} value={v.id}>{v.nome}</MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText error>{formik.touched.cor && formik.errors.cor}</FormHelperText>
                            </FormControl>
                        </Grid>

                    
                    </Grid>

                    }

                    {
                    step >= 2 &&

                    <Grid container spacing={2} >

                        <Grid item xs={12} md={12} sx={{ marginTop: '50px' }}>
                            <SliderComparacaoPrecos precoFipe={formataDinheiroforBanco(consultaFipe?.Valor)} />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                            className='input'
                            fullWidth
                            id="precovenda"
                            name="precovenda"
                            label="Preço de venda"
                            type="text"
                            value={formik.values.precovenda}
                            onChange={formik.handleChange}
                            error={formik.touched.precovenda && Boolean(formik.errors.precovenda)}
                            helperText={formik.touched.precovenda && formik.errors.precovenda}
                            onKeyUp={()=>{ formik.setFieldValue('precovenda', mvalor(formik.values.precovenda)) }}
                            inputProps={{
                                maxLength: 16,
                            }}
                            />
                        </Grid>

                    </Grid>
                    }

                    <div className='box-buttons-steps'>
                    {
                        step > 0 &&

                        <div className='box-tbn-steps'>
                        <ButtonOutlineSecondary type="button" icon="" txt="Voltar" onClick={ ()=>{  step > 0 && setstep(step-1)  } } />
                        </div>
                    }

                    <div className='box-tbn-steps'>
                        <ButtonDefault type="submit" icon="" txt={  step < 2 ? 'Continuar' : 'Finalizar'} />
                    </div>
                    </div>
                </form>
            </div>
          </Grid>

        </DialogContent>
    </Dialog>
    </>
  );

}