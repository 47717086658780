import React, {useState, useEffect, useRef} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams, useHistory } from "react-router-dom";
import './style.css';
import moment from 'moment';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';

import ButtonCirclePrimary from '../../componentes/ButtonCirclePrimary';
import ButtonDefault from '../../componentes/ButtonDefault';

import api from '../../services/api';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Skeleton from '@mui/material/Skeleton';

import { mplaca, mvalor } from '../../vendor/mascaras';
import { formataDinheiroforBanco, formataDinheiro } from '../../vendor/formatar';

import Swal from 'sweetalert2';

import SliderComparacaoPrecos from '../../componentes/SliderComparacaoPrecos';
import MultSelectOpcionaisVeiculo from '../../componentes/MultSelectOpcionaisVeiculo';
import MultSelectCaracteristicasVeiculo from '../../componentes/MultSelectCaracteristicasVeiculo';

import Fancybox from '../../vendor/fancybox'; //https://fancyapps.com/docs/ui/fancybox/react

import LinearProgress from '@mui/material/LinearProgress';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FsLightbox from 'fslightbox-react';

import ModalUpdateMarcaModeloVeiculo from '../Cadastros/Veiculos/modals/modalUpdateMarcaModeloVeiculo';

export default function TabCadastroVeiculo({dadosCompra}) {


    let IconSave = props =>(
        <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 24.15 24.15">
          <path id="floppy-disk" d="M22.97,3.037,21.113,1.179A4.02,4.02,0,0,0,18.267,0H2.767A2.767,2.767,0,0,0,0,2.767V21.383A2.767,2.767,0,0,0,2.767,24.15H21.383a2.767,2.767,0,0,0,2.767-2.767V5.882A4.02,4.02,0,0,0,22.97,3.037ZM4.025,6.289V3.773a.755.755,0,0,1,.755-.755H15.345a.755.755,0,0,1,.755.755V6.289a.755.755,0,0,1-.755.755H4.78A.755.755,0,0,1,4.025,6.289Zm8.05,13.836A4.528,4.528,0,1,1,16.6,15.6,4.529,4.529,0,0,1,12.075,20.125Zm0,0" transform="translate(0)" fill="#fff"/>
        </svg>
    )

    let IconZoom = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 12.515 12.516">
        <g id="icon" transform="translate(-5.267 -5.266)">
        <path id="XMLID_231_" d="M17.782,5.835V8.68a.569.569,0,1,1-1.137,0V7.209l-3.3,3.308a.569.569,0,0,1-.8-.8L15.841,6.4h-1.47a.569.569,0,0,1,0-1.138h2.843a.569.569,0,0,1,.569.569ZM17.214,13.8a.569.569,0,0,0-.569.569v1.47L13.4,12.616a.588.588,0,0,0-.82,0,.569.569,0,0,0-.008.8l3.217,3.223H14.311a.569.569,0,1,0,0,1.138h2.842a.642.642,0,0,0,.433-.167.6.6,0,0,0,.2-.4V14.368a.569.569,0,0,0-.569-.569Zm-7.56-1.207L6.4,15.838V14.368a.564.564,0,0,0-.564-.568h0a.568.568,0,0,0-.568.568v2.845a.569.569,0,0,0,.569.569H8.688a.569.569,0,0,0,0-1.138H7.218L10.464,13.4a.571.571,0,0,0-.81-.8ZM7.208,6.455h1.47a.569.569,0,0,0,0-1.138H5.836a.568.568,0,0,0-.569.569V8.731a.569.569,0,1,0,1.137,0V7.261l3.279,3.282a.569.569,0,1,0,.8-.8Z" fill="#fff"/>
        </g>
    </svg>
    )

    let IconCloseTimes = props =>(
    <svg id="close" xmlns="http://www.w3.org/2000/svg" width="13.663" height="13.506" viewBox="0 0 13.663 13.506">
        <g id="close_1_" transform="translate(0 0)">
        <path id="Caminho_85" data-name="Caminho 85" d="M1.3,15.943a1.294,1.294,0,0,1-.919-.311,1.294,1.294,0,0,1,0-1.825L11.365,2.82a1.294,1.294,0,0,1,1.89,1.76L2.2,15.633a1.294,1.294,0,0,1-.906.311Z" transform="translate(-0.001 -2.444)" fill="#fff"/>
        <path id="Caminho_86" data-name="Caminho 86" d="M13.733,15.343a1.294,1.294,0,0,1-.906-.375L1.839,3.98A1.294,1.294,0,0,1,3.664,2.155L14.717,13.143a1.294,1.294,0,0,1,.06,1.829q-.029.031-.06.06a1.294,1.294,0,0,1-.984.311Z" transform="translate(-1.463 -1.844)" fill="#fff"/>
        </g>
    </svg>
    )

    let IconUpload = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="30"viewBox="0 0 38.069 31.819">
        <g id="upload" transform="translate(-0.997 -4.993)">
        <path id="Caminho_87" data-name="Caminho 87" d="M29.948,12.692a10.167,10.167,0,0,0-19.722-.011,10.179,10.179,0,0,0,.954,20.314H15a1.272,1.272,0,1,0,0-2.545H11.18a7.634,7.634,0,0,1-.045-15.269,1.321,1.321,0,0,0,1.4-1.087,7.623,7.623,0,0,1,15.093,0,1.374,1.374,0,0,0,1.365,1.087,7.634,7.634,0,1,1,0,15.269H25.176a1.272,1.272,0,0,0,0,2.545h3.817a10.179,10.179,0,0,0,.954-20.3Z" transform="translate(0)" fill="#fff"/>
        <path id="Caminho_88" data-name="Caminho 88" d="M23.1,21.534a1.272,1.272,0,1,0,1.8-1.8l-6.362-6.362a1.272,1.272,0,0,0-1.8,0l-6.362,6.362a1.272,1.272,0,1,0,1.8,1.8l4.19-4.19V33.358a1.272,1.272,0,0,0,2.545,0V17.344Z" transform="translate(2.452 2.181)" fill="#fff"/>
        </g>
    </svg>

    )

    let IconEdit = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 19.987 19.992">
        <g id="Layer_92" data-name="Layer 92" transform="translate(-2.013 -2.008)">
        <path id="Caminho_344" data-name="Caminho 344" d="M21.1,2.9a3,3,0,0,0-4.27,0L14.91,4.85l4.24,4.24,1.92-1.92A3,3,0,0,0,21.1,2.9Z" fill="#fff"/>
        <path id="Caminho_345" data-name="Caminho 345" d="M13.49,6.27,5.1,14.66a1.15,1.15,0,0,0-.19.26L2.13,20.53A1,1,0,0,0,3.44,21.9l5.61-2.78a1.15,1.15,0,0,0,.26-.19l8.39-8.39Z" fill="#fff"/>
        <path id="Caminho_346" data-name="Caminho 346" d="M21,22H12a1,1,0,0,1,0-2h9a1,1,0,0,1,0,2Z" fill="#fff"/>
        </g>
    </svg>
    )
  
  let history = useHistory();

  const [dadosVeiculo, setdadosVeiculo] = useState([]);

  const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);

  const [precoPromocionalVencimento, setprecoPromocionalVencimento] = useState(null);

  const [alertError, setalertError] = useState(false);
  const [alertErrorMsg, setalertErrorMsg] = useState('');

  const [alertSucess, setalertSucess] = useState(false);
  const [alertSucessMsg, setalertSucessMsg] = useState('');

  const [combustiveis, setcombustiveis] = useState([]);
  const [cambios, setcambios] = useState([]);
  const [cores, setcores] = useState([]);

  const [opcionais, setopcionais] = useState([]);
  const [opcionaisdoveiculo, setopcionaisdoveiculo] = useState([]);
  const [caracteristicas, setcaracteristicas] = useState([]);
  const [caracteristicasdoveiculo, setcaracteristicasdoveiculo] = useState([]);
  const [fotosDoVeiculo, setfotosDoVeiculo] = useState([]);

  const [progressUploadFotos, setprogressUploadFotos] = useState(0);

  const handleChangePrecoPromocionalVencimento= (data) => {
      setprecoPromocionalVencimento(data);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseAlertError = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setalertError(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setalertSucess(false);
  };

  const getCoresCombustiveisCambios = async()=>{
    
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let cambios = await api.get(`listagemCambios`,
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    let combustiveis = await api.get(`listagemCombustiveis`,
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    let cores = await api.get(`listagemCores`,
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    }); 

    setcambios(cambios.data);
    setcombustiveis(combustiveis.data);
    setcores(cores.data);

  }

  const getOpcionais = async()=>{
    
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let opcionais = await api.get(`listagemOpcionais`,
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    setopcionais(opcionais.data);

  }

  const getCaracteristicas = async()=>{
    
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let caracteristicas = await api.get(`listagemCaracteristicas`,
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    setcaracteristicas(caracteristicas.data);

  }

  const getOpcionaisdoVeiculo = async(veiculoid)=>{
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let opcionaisDoVeiculo = await api.post(`listagemOpcionaisVeiculo`,
    {
        "id": veiculoid
    },
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    setopcionaisdoveiculo(opcionaisDoVeiculo.data);

  }

  const getCaracteristicasdoVeiculo = async(veiculoid)=>{
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let caracteristicasDoVeiculo = await api.post(`listagemCaracteristicasVeiculo`,
    {
        "id": veiculoid
    },
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });

    setcaracteristicasdoveiculo(caracteristicasDoVeiculo.data);

  }

  const getFotosdoVeiculo = async(veiculoid)=>{
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let fotos = await api.post(`listagemFotosVeiculo`,
    {
        "id": veiculoid
    },
    {
        headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
        }
    });
    

    let fotosGaleria = [];

    for await (let foto of fotos.data) {
      fotosGaleria.push(foto.url)
    }
    
    setfotosDoVeiculo(fotos.data);

  }

  const detalhesVeiculo = async()=>{
      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);
  
      api.post('detalhesVeiculo', {
          "codigo": dadosCompra?.codigoProduto
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      }).then( async response => {
          
          let data = response.data;
          setdadosVeiculo(response.data);

          await getCoresCombustiveisCambios();
        
          data.titulo && formik.setFieldValue('titulo', data.titulo);
          data.combustivelId && formik.setFieldValue('combustivel', data.combustivelId);
          data.cambioId && formik.setFieldValue('cambio', data.cambioId);
          data.portas && formik.setFieldValue('portas', data.portas);
          data.placa && formik.setFieldValue('placa', data.placa);
          data.renavam && formik.setFieldValue('renavam', data.renavam);
          data.chassi && formik.setFieldValue('chassi', data.chassi);
          data.precoVenda && formik.setFieldValue('precovenda', formataDinheiro(data.precoVenda));
          data.corId && formik.setFieldValue('cores', data.corId);
          data.precoPromocional && formik.setFieldValue('precopromocional', formataDinheiro(data.precoPromocional));
          data.precoPromocionalVencimento && setprecoPromocionalVencimento(data.precoPromocionalVencimento);
          data.descricao && formik.setFieldValue('descricao', data.descricao);

          await getOpcionais();
          await getCaracteristicas();
          await getOpcionaisdoVeiculo(data.id);
          await getCaracteristicasdoVeiculo(data.id);
          await getFotosdoVeiculo(data.id);

          
      }).catch((error) => {
          history.push("/cadastros/veiculos");
      });
      
      
  }

  const permissoesUsuario = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post('permissoesUsuario', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    // console.log(permissoes.data.pessoas[3].update);
    setpermissoesdoUsuario(permissoes.data.veiculos);
  }

  const validationSchema = yup.object({
    combustivel: yup
      .number('Selecione o Combustível')
      .required('Combustível Obrigatório'),
    cambio: yup
      .number('Selecione o tipo de Câmbio')
      .required('Câmbio Obrigatório'),
    portas: yup
      .number('Selecione o numero de portas')
      .required('Porta Obrigatória'),
    placa: yup
      .string('Informe a placa do veículo')
      .required('Placa Obrigatória'),
    renavam: yup
      .string('Informe o renavam do veículo')
      .required('Renavam Obrigatório'),
    chassi: yup
      .string('Informe o chassi do veículo')
      .required('Chassi Obrigatório'),
    cores: yup
      .number('Selecione a cor do veículo')
      .required('Cor Obrigatória'),
    precovenda: yup
      .string('Informe o preço do veículo')
      .required('Preço de venda Obrigatório'),

   
  });

  const formik = useFormik({
    initialValues: {
      titulo: '',
      combustivel: '',
      cambio: '',
      portas: '',
      placa: '',
      renavam: '',
      chassi: '',
      precovenda: '',
      cores: '',
      precopromocional: '',
      descricao: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
    
      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let promocionalVencimento = moment(moment(precoPromocionalVencimento, 'DD-MM-YYYY')).format('DD/MM/YYYY');

      let precodeVenda = formataDinheiroforBanco(values.precovenda);
      let precopromocional = formataDinheiroforBanco(values.precopromocional);

      await api.put(`updateVeiculo`, {
          "id": dadosVeiculo.id,
          "cor_id": values.cores,
          "precoVenda": precodeVenda,
          "precoPromocional": values.precopromocional ? precopromocional : null,
          "precoPromocionalVencimento": precoPromocionalVencimento === null ? null : promocionalVencimento,
          "titulo": values.titulo,
          "combustivel_id": values.combustivel,
          "cambio_id": values.cambio,
          "placa": values.placa,
          "renavam": values.renavam,
          "chassi": values.chassi,
          "portas": values.portas,
          "descricao": values.descricao ? values.descricao : null,
      },
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      setalertSucess(true);        
      setalertSucessMsg(`Dados Atualizados com sucesso!`);    
      setTimeout(() => { setalertSucess(false) }, 6000);   

    }
  });

  
  useEffect(async ()=>{ 
      await detalhesVeiculo();
      await permissoesUsuario();

      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dadosCompra]);

  const Input = styled('input')({
    display: 'none',
  });
  
  let handleFotoVeiculo = async(e)=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let imagens = e.target.files;

    let formData = new FormData();
    formData.append('id', dadosVeiculo.id);


    if(imagens.length > 7){
      Swal.fire(
        'Ops, Limite por vez atingido!',
        'Você só pode enviar 7 imagens por vez, e inserir até 20 fotos no veículo.',
        'warning'
      )
    }else{

      for await (let imagem of imagens) {

        if(imagem.type === 'image/png' || imagem.type === 'image/gif' || imagem.type === 'image/jpeg'){
          formData.append('imagens', imagem);
        }else{
          setalertErrorMsg('Foto inválida, Apenas os formatos .png, .jpg e .jpeg são permitidos!')
          setalertError(true);
          setTimeout(() => { setalertError(false) }, 6000);   
        }
        
      }

      let response = await api.put("uploadFotosVeiculo", formData, {
        headers: { 
          "Content-type": "multipart/form-data",
          "Authorization": `Bearer ${auth.token}`
        },
        onUploadProgress: e => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
          setprogressUploadFotos(progress)
        },
      });

      if(response.data.error_code === "MAXIMUM_FILES"){
        setalertErrorMsg(response.data.message)
        setalertError(true);
        setTimeout(() => { setalertError(false) }, 6000);
      }

      // await new Promise(r => setTimeout(r, imagens.length * 150));
      setprogressUploadFotos(0);
      getFotosdoVeiculo(dadosVeiculo.id);
    }
    

  }

  const handleDeleteFoto = async(idFoto)=>{
  
    Swal.fire({
      title: 'Tem certeza?',
      text: "Deseja deletar a Foto selecionada?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0554C2',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim deletar',
      cancelButtonText: 'Cancelar',
    }).then(async(result) => {
      if (result.isConfirmed) {

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
        
        await api.delete('deleteFotoVeiculo', {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          },
          data: {  "id": idFoto, "veiculoId": dadosVeiculo.id }
        });

        await getFotosdoVeiculo(dadosVeiculo.id);

        Swal.fire(
          'Deletado!',
          'Foto deletada com sucesso!',
          'success'
        )
      }
    })

  }

  const handleDragFoto = async(result)=>{
    

    if(permissoesdoUsuario[3]?.update){

      const items = Array.from(fotosDoVeiculo);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      let dadosArray = [];

      for await (let [key, item] of  Object.entries(items)) {
        let dados = {
          id: item.id,
          ordem: parseInt(key)
        }

        dadosArray.push(dados);

      }

      setfotosDoVeiculo(items);

      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.put(`updateOrdemFotosVeiculo`,
      {
          "produtoId": dadosVeiculo.id,
          "dados": dadosArray
      },
      {
          headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
          }
      })

    }else{
      setalertErrorMsg('Você não possui autorização para modificar a ordem das fotos.')
      setalertError(true);
      setTimeout(() => { setalertError(false) }, 6000);
    }

  }
  

  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcar - Detalhes veículo</title>
        </Helmet>
      </HelmetProvider>

      <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
          {alertErrorMsg}
        </Alert>
      </Snackbar>

      <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
          {alertSucessMsg}
        </Alert>
      </Snackbar>

        <form method='post' onSubmit={formik.handleSubmit}>

        <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={2} >
            

            <Grid item xs={12} md={12}>
                <div className='box-content'>
                
                {
                cores.length === 0?

                <Grid container item spacing={2} xs={12} md={12} direction="row" style={{ marginTop: '3px' }}>
                    <Grid item xs={12} md={2}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                    </Grid>
                    <Grid item xs={12} md={2}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                    </Grid>
                    <Grid item xs={12} md={2}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                    </Grid>
                    <Grid item xs={12} md={2}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                    </Grid>
                    <Grid item xs={12} md={2}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                    </Grid>
                    <Grid item xs={12} md={2}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                    </Grid>

                    <Grid item xs={12} md={2}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                    </Grid>
                    <Grid item xs={12} md={2}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                    </Grid>
                    <Grid item xs={12} md={2}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                    </Grid>
                    <Grid item xs={12} md={2}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={50} style={{ borderRadius: '5px' }}/>
                    </Grid>
                </Grid>
                
                :
                <>
                <div className='header-box-content'>
                    <h3 className='title'>Detalhes</h3>
                    {
                    permissoesdoUsuario[3]?.update &&
                    <ButtonCirclePrimary icon={<IconSave/>} onClick={()=>{ formik.handleSubmit() }}/>
                    }
                </div>

                <Grid container item spacing={2} xs={12} md={12} direction="row" style={{ marginTop: '3px' }}>
                    
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        id="titulo"
                        name="titulo"
                        label="Título (site)"
                        margin="none"
                        type="text"
                        value={formik.values.titulo}
                        disabled={!permissoesdoUsuario[3]?.update}
                        onChange={formik.handleChange}
                        error={formik.touched.titulo && Boolean(formik.errors.titulo)}
                        helperText={formik.touched.titulo && formik.errors.titulo}
                      />
                    </Grid>

                    <Grid item xs={12} md={2.5} >
                    <FormControl sx={{ m: 0, width: '100%' }} >
                        <InputLabel id="label-combustivel">Combustível</InputLabel>
                        <Select
                        labelId="label-combustivel"
                        id="combustivel-select"
                        name="combustivel"
                        label="Combustível"
                        placeholder='Combustível'
                        value={formik.values.combustivel}
                        disabled={!permissoesdoUsuario[3]?.update}
                        onChange={formik.handleChange}
                        error={formik.touched.combustivel && Boolean(formik.errors.combustivel)}
                        >
                        {combustiveis.map((v, k) =>{
                            return (
                                <MenuItem key={k} value={v.id}>{v.nome}</MenuItem>
                            )
                        })}
                        </Select>
                        <FormHelperText error>{formik.touched.combustivel && formik.errors.combustivel}</FormHelperText>
                    </FormControl>
                    </Grid>
                    
                    <Grid item xs={12} md={2} >
                    <FormControl sx={{ m: 0, width: '100%' }} >
                        <InputLabel id="label-cambio">Câmbio</InputLabel>
                        <Select
                        labelId="label-cambio"
                        id="cambio-select"
                        name="cambio"
                        label="Câmbio"
                        placeholder='Câmbio'
                        value={formik.values.cambio}
                        disabled={!permissoesdoUsuario[3]?.update}
                        onChange={formik.handleChange}
                        error={formik.touched.cambio && Boolean(formik.errors.cambio)}
                        >
                        {cambios.map((v, k) =>{
                            return (
                                <MenuItem key={k} value={v.id}>{v.nome}</MenuItem>
                            )
                        })}
                        </Select>
                        <FormHelperText error>{formik.touched.cambio && formik.errors.cambio}</FormHelperText>
                    </FormControl>
                    </Grid>

                    <Grid item xs={12} md={1.5} >
                    <FormControl sx={{ m: 0, width: '100%' }} >
                        <InputLabel id="label-portas">Portas</InputLabel>
                        <Select
                        labelId="label-portas"
                        id="portas-select"
                        name="portas"
                        label="Portas"
                        placeholder='Portas'
                        value={formik.values.portas}
                        disabled={!permissoesdoUsuario[3]?.update}
                        onChange={formik.handleChange}
                        error={formik.touched.portas && Boolean(formik.errors.portas)}
                        >
                        <MenuItem value={0}>0</MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        </Select>
                        <FormHelperText error>{formik.touched.portas && formik.errors.portas}</FormHelperText>
                    </FormControl>
                    </Grid>

                    <Grid item xs={12} md={2} >
                    <TextField
                        fullWidth
                        id="placa"
                        name="placa"
                        label="Placa"
                        margin="none"
                        type="text"
                        value={formik.values.placa}
                        disabled={!permissoesdoUsuario[3]?.update}
                        onChange={formik.handleChange}
                        error={formik.touched.placa && Boolean(formik.errors.placa)}
                        helperText={formik.touched.placa && formik.errors.placa}

                        onKeyUp={()=>{ formik.setFieldValue('placa', mplaca(formik.values.placa)) }}
                        inputProps={{
                        // inputComponent: placaMask,
                        maxLength: 7,
                        }}
                    />
                    </Grid>

                    <Grid item xs={12} md={2} >
                    <TextField
                        fullWidth
                        id="renavam"
                        name="renavam"
                        label="Renavam"
                        margin="none"
                        type="text"
                        value={formik.values.renavam}
                        disabled={!permissoesdoUsuario[3]?.update}
                        onChange={formik.handleChange}
                        error={formik.touched.renavam && Boolean(formik.errors.renavam)}
                        helperText={formik.touched.renavam && formik.errors.renavam}
                    />
                    </Grid>

                    <Grid item xs={12} md={2} >
                    <TextField
                        fullWidth
                        id="chassi"
                        name="chassi"
                        label="Chassi"
                        margin="none"
                        type="text"
                        value={formik.values.chassi}
                        disabled={!permissoesdoUsuario[3]?.update}
                        onChange={formik.handleChange}
                        error={formik.touched.chassi && Boolean(formik.errors.chassi)}
                        helperText={formik.touched.chassi && formik.errors.chassi}
                    />
                    </Grid>

                    {/* <Grid item xs={12} md={2} >
                    <FormControlLabel control={
                        <Switch
                        disabled={!permissoesdoUsuario[3]?.update}
                        checked={formik.values.chassi ? true : false}
                        // onChange={(event)=>{ handlePrincipalTelefone(event, params.id) }}
                        inputProps={{ 'aria-label': 'controlled' }}
                        />
                    } label="Label" />
                    </Grid> */}
                    
                    <Grid item xs={12} md={12} sx={{ marginTop: '13px', marginBottom: '13px' }}>
                    <div className='border-divider-full-width'></div>
                    </Grid>


                    <Grid item xs={12} md={2} >
                    <FormControl sx={{ m: 0, width: '100%' }} >
                        <InputLabel id="label-cores">Cor</InputLabel>
                        <Select
                        labelId="label-cores"
                        id="cores-select"
                        name="cores"
                        label="Cor"
                        placeholder='Cor'
                        value={formik.values.cores}
                        disabled={!permissoesdoUsuario[3]?.update}
                        onChange={formik.handleChange}
                        error={formik.touched.cores && Boolean(formik.errors.cores)}
                        >
                        {cores.map((v, k) =>{
                            return (
                                <MenuItem key={k} value={v.id}>{v.nome}</MenuItem>
                            )
                        })}
                        </Select>
                        <FormHelperText error>{formik.touched.cores && formik.errors.cores}</FormHelperText>
                    </FormControl>
                    </Grid>

                    <Grid item xs={12} md={2} >
                    <TextField
                        fullWidth
                        id="precovenda"
                        name="precovenda"
                        label="Preço de venda"
                        margin="none"
                        type="text"
                        value={formik.values.precovenda}
                        disabled={!permissoesdoUsuario[3]?.update}
                        onChange={formik.handleChange}
                        error={formik.touched.precovenda && Boolean(formik.errors.precovenda)}
                        helperText={formik.touched.precovenda && formik.errors.precovenda}
                        onKeyUp={()=>{ formik.setFieldValue('precovenda', mvalor(formik.values.precovenda)) }}
                        inputProps={{
                        maxLength: 16,
                        }}
                    />
                    </Grid>

                    <Grid item xs={12} md={2} >
                    <TextField
                        fullWidth
                        id="precopromocional"
                        name="precopromocional"
                        label="Preço promocional"
                        margin="none"
                        type="text"
                        value={formik.values.precopromocional}
                        disabled={!permissoesdoUsuario[3]?.update}
                        onChange={formik.handleChange}
                        error={formik.touched.precopromocional && Boolean(formik.errors.precopromocional)}
                        helperText={formik.touched.precopromocional && formik.errors.precopromocional}
                        onKeyUp={()=>{ formik.setFieldValue('precopromocional', mvalor(formik.values.precopromocional)) }}
                        inputProps={{
                        maxLength: 13,
                        }}
                    />
                    </Grid>

                    <Grid item xs={12} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                        <DesktopDatePicker
                        label="Vencimento da promoção"
                        inputFormat="dd/MM/yyyy"
                        name="precopromocionalvencimento"
                        value={precoPromocionalVencimento}
                        disabled={!permissoesdoUsuario[3]?.update}
                        onChange={handleChangePrecoPromocionalVencimento}
                        allowSameDateSelection={true}
                        renderInput={(params) => <TextField autoComplete='off' style={{ width: '100%', marginTop: '0px'}} {...params} />}
                        />
                    </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} md={4} sx={{ marginTop: '15px' }}>
                    <SliderComparacaoPrecos precoFipe={dadosVeiculo?.precoFipe} />
                    </Grid>
                    

                </Grid>
                </>
                }

                </div>
            </Grid>

            <Grid item xs={12} md={12}>
                <div className='box-content'>
                
                <div className='header-box-content'>
                    <h3 className='title'>Opcionais</h3>
                </div>
                
                <Grid container item spacing={2} xs={12} md={12} direction="row" style={{marginTop: '-5px'}}>
                {opcionais.map((v, k) =>{
                    return (
                        <Grid item xs={12} md={6} key={k}>
                        <MultSelectOpcionaisVeiculo idVeiculo={dadosVeiculo.id} dados={v} opcionaisDoVeiculo={opcionaisdoveiculo} atualizar={getOpcionaisdoVeiculo} disabled={!permissoesdoUsuario[3]?.update}/>
                        </Grid>
                    )
                })}
                </Grid>
                </div>
            </Grid>

            <Grid item xs={12} md={6}>
                <div className='box-content'>
                
                {
                cores.length === 0 ?
                    
                <Grid container spacing={0} item xs={12} md={12} style={{ marginTop: '10px' }}>
                    <Grid item xs={12} md={12}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={240} style={{ borderRadius: '5px' }}/>
                    </Grid>
                </Grid>

                :

                <>
                <div className='header-box-content'>
                    <h3 className='title'>Características</h3>
                </div>
                
                <Grid container item spacing={2} xs={12} md={12} direction="row" style={{ height: '256px', marginTop: '-5px' }}>
                    <Grid item xs={12} md={12}>
                        <MultSelectCaracteristicasVeiculo idVeiculo={dadosVeiculo.id} dados={caracteristicas} caracteristicasDoVeiculo={caracteristicasdoveiculo} atualizar={getCaracteristicasdoVeiculo} disabled={!permissoesdoUsuario[3]?.update}/>
                    </Grid>
                </Grid>
                </>
                }

                </div>
            </Grid>

            <Grid item xs={12} md={6}>
                <div className='box-content'>
                
                {
                cores.length === 0 ?
                    
                <Grid container spacing={0} item xs={12} md={12} style={{ marginTop: '10px' }}>
                    <Grid item xs={12} md={12}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={240} style={{ borderRadius: '5px' }}/>
                    </Grid>
                </Grid>

                :
                <>
                <div className='header-box-content'>
                    <h3 className='title'>Descrição</h3>
                    {
                    permissoesdoUsuario[3]?.update &&
                    <ButtonCirclePrimary icon={<IconSave/>} onClick={()=>{ formik.handleSubmit() }}/>
                    }
                </div>

                <Grid container spacing={0} item xs={12} md={12} style={{ marginTop: '10px' }}>
                    <Grid item xs={12} md={12} >
                        <TextField
                        fullWidth
                        id="descricao"
                        name="descricao"
                        margin="none"
                        multiline
                        disabled={!permissoesdoUsuario[3]?.update}
                        rows={9}
                        defaultValue={formik.values.descricao}
                        onChange={formik.handleChange}
                        error={formik.touched.descricao && Boolean(formik.errors.descricao)}
                        helperText={formik.touched.descricao && formik.errors.descricao}
                        />
                    </Grid>
                </Grid>
                </>

                }
                

                </div>
            </Grid>

            <Grid item xs={12} md={12}>
                <div className='box-content'>

                {
                cores.length === 0 ?

                <Grid container spacing={2} item xs={12} md={12} style={{ marginTop: '10px' }}>
                    <Grid item xs={12} md={1.5}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                    </Grid>
                    <Grid item xs={12} md={1.5}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                    </Grid>
                    <Grid item xs={12} md={1.5}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                    </Grid>
                    <Grid item xs={12} md={1.5}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                    </Grid>
                    <Grid item xs={12} md={1.5}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                    </Grid>
                    <Grid item xs={12} md={1.5}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                    </Grid>
                    <Grid item xs={12} md={1.5}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                    </Grid>
                    <Grid item xs={12} md={1.5}>
                    <Skeleton animation="wave" variant="rectangular" width='100%' height={140} style={{ borderRadius: '5px' }}/>
                    </Grid>
                </Grid>
                
                :

                <>
                <div className='header-box-content'>
                    <h3 className='title'>Fotos</h3>
                </div>

                <div className='box-fotos-detalhes-veiculos'>

                <Fancybox>
                    <DragDropContext onDragEnd={handleDragFoto}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided) => (
                        // id={props.galleryID}
                        <aside {...provided.droppableProps} ref={provided.innerRef} > 
                            {fotosDoVeiculo.map((v, k) =>{
                            return (
                                <Draggable key={v.id} draggableId={v.id.toString()} index={k}>
                                {(provided) => (
                                    <div className='box-element-foto' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                    <div className='element-foto' style={{ backgroundImage: `url(${v.url})` }}>
                                        
                                        {
                                        permissoesdoUsuario[3]?.update &&

                                        <div className='box-deleteFotoDVeiculo'>
                                            <button type='button' onClick={()=>{ handleDeleteFoto(v.id) }} className='deleteFotoDVeiculo'><IconCloseTimes/></button>
                                        </div>
                                        }

                                        <div className='box-abrirFotoDVeiculo'>
                                            <button type='button' data-fancybox="gallery" href={v.url}>
                                            <IconZoom/>
                                            </button>
                                        </div>

                                    </div>
                                    </div>
                                )}
                                </Draggable>
                            );
                            })}
                            {provided.placeholder}
                        </aside>
                        )}
                    </Droppable>
                    </DragDropContext>
                </Fancybox>
                    
                    {
                        permissoesdoUsuario[2]?.insert &&
                        
                        <div className='box-button-upload'>
                        <div style={{ width: '220px' }}>

                            {
                            progressUploadFotos === 0 &&

                            <label htmlFor="button-file-fotos-veiculo">
                                <Input accept="image/png, image/gif, image/jpeg" id="button-file-fotos-veiculo" multiple onChange={handleFotoVeiculo} type="file" />
                                <ButtonDefault type="button" icon={<IconUpload/>} component="span" txt="Adicionar fotos"  />
                            </label>
                            }
                            
                            {
                            progressUploadFotos > 0 && 
                            <LinearProgress variant="determinate" value={progressUploadFotos} />
                            }

                        </div>
                        <p>Formatos aceitos: .JPG, .JPEG, .PNG | Tamanho máximo: 5MB</p>
                        </div>
                    }

                </div>
                </>
                }

                </div>
            </Grid>

            
            

            </Grid>
        </Box>
        
        </form>

    </>
    
  );
}