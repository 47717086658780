import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import Slider from '@mui/material/Slider';

import { formataDinheiro } from '../../vendor/formatar';

export default function SliderComparacaoPrecos({ precoFipe }) {
  
  const [value, setValue] = useState([80]);
  const [marks, setmarks] = useState([]);

  useEffect(()=>{
    setmarks([
        { value: 80, label: formataDinheiro(precoFipe) }
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[precoFipe]);

  function valuetext(value) {
    return `Valor Fipe`;
  }
 

  return (
    <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        valueLabelDisplay="on"
        valueLabelFormat={valuetext}
        // getAriaValueText={valuetext}
        marks={marks}
        sx={{
            color: '#001A3E',
            '& .MuiSlider-rail': {
            opacity: '100',
            backgroundColor: '#001A3E',
            border: 'solid #001A3E 1px'
            },
            '& .MuiSlider-thumb': {
            width: 24,
            height: 24,
            backgroundColor: '#fff',
            '&:before': {
                boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
            },
            '&:hover, &.Mui-focusVisible, &.Mui-active': {
                boxShadow: 'none',
            },
            border: 'solid #001A3E 4px'
            },
            '& .MuiSlider-markLabel': {
            fontWeight: 'bold'
            },
        }}
    />
  );
}