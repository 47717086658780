import React, { useState, useEffect, useRef } from "react";

import api from "../../../services/api";
import { useHistory } from "react-router-dom";
import moment from 'moment';

import { useFormik } from "formik";
import * as yup from "yup";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";

import Box from '@mui/material/Box';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import Fancybox from '../../../vendor/fancybox'; //https://fancyapps.com/docs/ui/fancybox/react

import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import ButtonDefault from "../../../componentes/ButtonDefault";
import ButtonOutlineSecondary from "../../../componentes/ButtonOutlineSecondary";

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import StickerComponent from '../StickerComponent';
import AudioComponent from '../AudioComponent';
import ImgComponent from '../ImgComponent';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export default function ModalDetalhesSession({ dadosSession, visible, change }) {

  let IconNoMessage = props => (
    <svg id="fi_5089749" enableBackground="new 0 0 512.054 512.054" height="69" viewBox="0 0 512.054 512.054" xmlns="http://www.w3.org/2000/svg"><g><path d="m443.333 173.028c25.171 154.568-115.452 295.311-270.077 269.991 51.593 65.509 155.29 89.101 231.355 49.627l55.797 18.601c9.415 3.361 19.959-4.246 19.744-14.23v-70.462c54.686-78.011 36.608-195.377-36.819-253.527z"></path><path d="m415.885 207.817c0-114.581-93.219-207.8-207.8-207.8-161.221-2.096-263.619 183.897-175.666 318.787v81.813c-.221 9.985 10.353 17.597 19.744 14.23 0 0 63.177-21.064 63.177-21.064 135.192 69.55 302.374-33.798 300.545-185.966zm-158.533-49.172c.733-19.872 29.266-19.874 30 0v16.067c-.733 19.872-29.266 19.874-30 0zm-98.534 16.067c-.733 19.872-29.266 19.874-30 0v-16.067c.733-19.872 29.266-19.874 30 0zm14.558 85.812c-5.856-5.859-5.854-15.357.006-21.213 19.136-19.125 50.271-19.124 69.407 0 5.859 5.856 5.862 15.354.007 21.213-5.858 5.86-15.355 5.862-21.214.006-7.441-7.438-19.552-7.438-26.993 0-5.859 5.856-15.357 5.855-21.213-.006z"></path></g></svg>
  );

  let history = useHistory();
  const inputNumero = useRef(null);
  const refScroollMsgBox = useRef(null);

  const [loaderPage, setloaderPage] = useState(false);

  const [alertError, setalertError] = useState(false);
  const [alertErrorMsg, setalertErrorMsg] = useState("");

  const [alertSucess, setalertSucess] = useState(false);
  const [alertSucessMsg, setalertSucessMsg] = useState("");

  const [mensagesChat, setmensagesChat] = useState([]);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseAlertError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setalertError(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setalertSucess(false);
  };

  const getMensagensSession = async(sessionId)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemMensagensSessions?sessionId=${sessionId}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setmensagesChat(response.data);
  }

  useEffect(async()=>{
    if(visible){
      await getMensagensSession(dadosSession?.id);
    }

  },[visible]);

  return (
    <ThemeProvider theme={darkTheme}>
      <Backdrop
        sx={{ color: "#fff", zIndex: "2000" }}
        open={loaderPage}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={alertError}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseAlertError}
      >
        <Alert
          onClose={handleCloseAlertError}
          severity="error"
          sx={{ width: "100%", marginTop: "-150px" }}
        >
          {alertErrorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={alertSucess}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseAlertSuccess}
      >
        <Alert
          onClose={handleCloseAlertSuccess}
          severity="success"
          sx={{ width: "100%", marginTop: "-150px" }}
        >
          {alertSucessMsg}
        </Alert>
      </Snackbar>

      <Dialog
        open={visible}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => {
          change(false);
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <p style={{ margin: 0, fontSize: 16, fontWeight: 'normal' }}>{ dadosSession?.atendente }</p>
          <p style={{ margin: 0, fontSize: 11, fontWeight: 'normal' }}>
              <Tooltip title={`Última interação: ${moment(dadosSession?.ultimaInteracao).format('DD/MM/YYYY HH:mm')}`}>
                  <span>{  moment(dadosSession?.data).format('DD/MM/YYYY HH:mm')}</span>
              </Tooltip>
          </p>
          <IconButton
            aria-label="close"
            onClick={() => {
              change(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

        {
          mensagesChat?.length ?
    
          <Fancybox>  
            <div className='chat-detail-conversa'>
              <article ref={refScroollMsgBox}>
                {mensagesChat?.map((v, k) =>{
                    // Verifica se v.timestamp é válido
                    if (!v.timestamp || isNaN(v.timestamp)) return null;

                    // Converte o timestamp em um objeto Moment
                    const timestampMoment = moment(v.timestamp * 1000);
                    const now = moment();

                    // Cálculo da diferença de tempo
                    const seconds = now.diff(timestampMoment, 'seconds');
                    const minutes = now.diff(timestampMoment, 'minutes');
                    const hours = now.diff(timestampMoment, 'hours');
                    const days = now.diff(timestampMoment, 'days');
                    const weeks = now.diff(timestampMoment, 'weeks');

                    // Determinação do tempo
                    let tempo = '';
                    if (seconds < 60) {
                        tempo = "agora mesmo";
                    } else if (minutes >= 1 && minutes < 60) {
                        tempo = `há ${minutes} min`;
                    } else if (hours < 24) {
                        tempo = hours === 1 ? `há ${hours} hora` : `há ${hours} horas`;
                    } else if (days < 7) {
                        tempo = days === 1 ? `há ${days} dia` : `há ${days} dias`;
                    } else {
                        tempo = `${moment(v.data).format('DD/MM/YYYY')} às ${moment(v.data).format('HH:mm')}`;
                    }

                    return (
                      <div key={k}>

                          {
                              v.type === 'image' ? 
                                  <div style={{ paddingInline: 19, paddingBlock: 10 }} className={ v.fromMe === "1" ? `container-box-img-right` : `container-box-img-left`}>
                                      <ImgComponent data={v} tempo={tempo} />
                                  </div>
                              : v.type === "chat" ?
                                  <div className={ v.fromMe === "1" ? `container-box-msg right` : `container-box-msg left`}>
                                      <div className='box-msg'>
                                      <aside>

                                          <div style={{ marginTop: '5px', marginBottom: '2px'}}>
                                              <p style={{ whiteSpace: 'pre-wrap' }}>{v.body}</p>
                                          </div>

                                          <span>
                                              <Tooltip title={`${moment(v.data).format('DD/MM/YYYY')} às ${moment(v.data).format('HH:mm')}`}>
                                                  <Button>{tempo}</Button>
                                              </Tooltip>
                                          </span>

                                      </aside>
                                      </div>
                                  </div>
                              : v.type === "sticker" ?
                                <>
                                  <StickerComponent data={v} tempo={tempo} />
                                </>
                              : v.type === "ptt" ?
                                  <div className={ v.fromMe === "1" ? `container-box-msg right` : `container-box-msg left`}>
                                      <AudioComponent
                                          data={v}
                                          tempo={tempo}
                                          currentlyPlaying={currentlyPlaying}
                                          setCurrentlyPlaying={setCurrentlyPlaying}
                                      />
                                  </div>
                              : <></>

                          }
                      </div>
                    )
                })}

              </article>
            </div>
          </Fancybox>

          :

          <div className='no-message-selected' style={{ paddingBlock: 69 }}>
              <IconNoMessage />
              <h3>Nenhuma mensagem encontrada</h3>
              <p style={{ lineHeight: 1, textAlign: 'center' }}>Parece que não há mensagens registradas <br/> nesta sessão no momento.</p>
          </div>

        }

        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}
