import React, {useState, useRef, useEffect } from 'react';
import { initMercadoPago, Payment, StatusScreen } from '@mercadopago/sdk-react';
import './style.css';

import api from '../../../services/api';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonSecondary from '../../../componentes/ButtonSecondary';

import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function ModalMercadoPago({ fatura, dadosEmpresa, enderecos, visible, change }) {

  const [loaderPage, setLoaderPage] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [showPayment, setShowPayment] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState(null);


  function getPrimeiroEUltimoNome(nomeCompleto) {
    if (!nomeCompleto || typeof nomeCompleto !== "string") {
      return { primeiroNome: "", ultimoNome: "" };
    }
    
    const nomes = nomeCompleto.trim().split(" ");
    
    if (nomes.length === 1) {
      // Apenas um nome
      return { primeiroNome: nomes[0], ultimoNome: "" };
    }
  
    // Mais de um nome
    const primeiroNome = nomes[0];
    const ultimoNome = nomes[nomes.length - 1];
    
    return { primeiroNome, ultimoNome };
  }

  const principalEndereco = enderecos.filter(endereco => endereco.principal === 1);

  // initMercadoPago('TEST-d925532a-fd06-464d-b571-fd5cdc889747');
  initMercadoPago('APP_USR-145ef22a-8c4a-4d04-89c8-51374cdcc933');

  const initialization = {
    amount: fatura.valor,
    // preferenceId: "133750811-83b1919f-8c20-4592-8609-9090b30ee66f",
    payer: {
      firstName: getPrimeiroEUltimoNome(dadosEmpresa.contato).primeiroNome,
      lastName: getPrimeiroEUltimoNome(dadosEmpresa.contato).ultimoNome,
      identification: {
        type: "CNPJ",
        number: dadosEmpresa.cnpj && dadosEmpresa.cnpj?.replace(/[.\-\/]/g, '')
      },
      email: dadosEmpresa?.contatoEmail,
      address: {
        zipCode: principalEndereco[0]?.cep?.replace(/[.\-\/]/g, ''),
        federalUnit: "",
        city: principalEndereco[0]?.cidade,
        neighborhood: principalEndereco[0]?.bairro,
        streetName: principalEndereco[0]?.rua,
        streetNumber: `${principalEndereco[0]?.numero}`,
        complement: principalEndereco[0]?.complemento,
      }
    },
  };

  const customization = {
    visual: {
      showExternalReference: true,
      hideFormTitle: true,
      defaultPaymentOption: {
        // walletForm: true,
        creditCardForm: true,
        // debitCardForm: true,
        // savedCardForm: 'card id sent in the initialization',
        // ticketForm: true,
      },      
      style: {
        theme: "default",
        customVariables: {
          baseColor: "#0554C2",
        }
      },
    },
    paymentMethods: {
      ticket: "all",
      bankTransfer: "all",
      creditCard: "all",
      // debitCard: "all",
      // mercadoPago: "all",
      maxInstallments: 1
    },
    
   };

   const onSubmit = async ({ selectedPaymentMethod, formData }) => {
    try {
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      formData.faturaId = fatura.id;      

      const response = await api.post('processMercadoPago',
          formData,
          {
              headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${auth.token}`,
              }
          }
      );

      setPaymentId(response.data?.id);
      setShowPayment(false);
      setPaymentStatus(response.data.status);

    } catch (error) {
      console.log("Erro ao processar pagamento:", error);
    }
  };

  const onError = async (error) => {
    console.log(error);
  };
  
  const onReady = async () => {
    setLoaderPage(false);
  };

  const resetPayment = () => {
    setPaymentId(null);
    setShowPayment(true);
    setPaymentStatus(null);
  };

  useEffect(async()=>{
    if(visible){
      
    }

  },[visible]);



  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} onClose={()=>{ change(false); resetPayment(); }}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Pagar fatura
            <IconButton
              aria-label="close"
              onClick={() => { 
                change(false);
                resetPayment();
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

            { principalEndereco &&
            
              <>
                {showPayment ? (
                    <Payment
                        initialization={initialization}
                        customization={customization}
                        onSubmit={onSubmit}
                        onReady={onReady}
                        onError={onError}
                    />
                ) : (
                  <>
                    <StatusScreen
                        initialization={{ paymentId }}
                        customization={customization}
                        onReady={onReady}
                        onError={onError}
                    />
                    
                    {/* Exibe o botão de tentar novamente se o pagamento foi rejeitado */}
                    {paymentStatus === "rejected" && (

                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                          <div style={{ width: '200px' }}>
                            <ButtonSecondary
                                onClick={resetPayment}
                                txt="Tentar Novamente"
                            />
                          </div>
                        </div>
                    )}
                  </>
                )}
              
              </>
            }

            
        </DialogContent>
    </Dialog>
    </>
  );

}