import React, { createContext, useEffect, useState } from 'react';
import api from '../services/api';

// Cria o Contexto
export const GlobalContext = createContext();

// Provider para compartilhar informações
export const GlobalProvider = ({ children }) => {
  const [whatsAppInfo, setwhatsAppInfo] = useState([]);

  const getWhatsAppInfo = async()=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let responseinfo = await api.post('whatsAppInfo', {}, 
        {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${auth.token}`
            }
        }
    );

    let responseprofilepic = await api.post('whatsAppProfilePic', {
        "phone": responseinfo.data.response.phoneNumber.replace("@c.us", ""),
        }, 
        {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${auth.token}`
            }
        }
    );

    setwhatsAppInfo({
        info: responseinfo.data.response,
        photo: responseprofilepic.data.response,
        session: responseinfo.data.session
    }); 
  }

  useEffect(async ()=>{ 
    await getWhatsAppInfo();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <GlobalContext.Provider value={{ whatsAppInfo }}>
      {children}
    </GlobalContext.Provider>
  );
};
