import React, {useState, useEffect } from 'react';

import api from '../../../../services/api';
import { useHistory } from "react-router-dom";

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';


import ButtonDefault from '../../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


export default function ModalNovoContrato({ visible, change}) {
  
  let history = useHistory();

  const [loaderPage, setloaderPage] = useState(false);
  
  const validationSchema = yup.object({
    tipo: yup
      .number('Selecione o tipo')
      .required('Tipo Obrigatório'),
    nome: yup
        .string('Insira o nome do contrato')
        .required('Nome Obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
        tipo: '',
        nome: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let response = await api.post('addContrato', {
        "tipo": values.tipo,
        "nome": values.nome,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      formik.resetForm();
      change(false);

      history.push(`/cadastro/contrato/${response.data.codigo}`);
      
    }
  });


  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Novo contrato
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>
          
          <Grid container spacing={1} >
            
            <Grid item xs={12} md={4}>
              <FormControl sx={{ m: 0, width: '100%', marginTop: '15px' }} >
                <InputLabel id="label-tipo">Tipo</InputLabel>
                <Select
                  labelId="label-tipo"
                  id="demo-simple-select"
                  name="tipo"
                  label="Tipo"
                  placeholder='Tipo'
                  value={formik.values.tipo}
                  onChange={formik.handleChange}
                  error={formik.touched.tipo && Boolean(formik.errors.tipo)}
                >
                  <MenuItem value={1}>Venda</MenuItem>
                  <MenuItem value={2}>Compra</MenuItem>
                  <MenuItem value={3}>Troca</MenuItem>
                  <MenuItem value={4}>Distrato</MenuItem>
                </Select>
                <FormHelperText error>{formik.touched.tipo && formik.errors.tipo}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={8}>
                <TextField
                fullWidth
                id="nome"
                name="nome"
                label="Nome do contrato"
                margin="normal"
                type="text"
                value={formik.values.nome}
                onChange={formik.handleChange}
                error={formik.touched.nome && Boolean(formik.errors.nome)}
                helperText={formik.touched.nome && formik.errors.nome}
                />
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} >

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon="" txt="Adicionar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}