import React, {useState, useEffect } from 'react';
import api from '../../../services/api';
import moment from 'moment';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';
import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { mvalor, mnumero } from '../../../vendor/mascaras';
import { formataDinheiroforBanco } from '../../../vendor/formatar';

import ModalNovaPessoa from '../../Cadastros/Pessoas/modals/modalNovaPessoa';
import ModalDetalhesPessoa from '../../Cadastros/Pessoas/modals/modalDetalhesPessoa';
import ButtonSquadPrimaryForInput from '../../../componentes/ButtonSquadPrimaryForInput';

export default function ModalNovaContaApagar({ visible, change, refreshcontas}) {

  let IconPlus = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
      <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
  );

  let IconInfo = props =>(
    <svg id="information-button" xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.917 27.917">
      <path id="Caminho_118" data-name="Caminho 118" d="M13.958,0A13.959,13.959,0,1,0,27.917,13.958,13.959,13.959,0,0,0,13.958,0Zm2.906,21.633q-1.078.425-1.719.648a4.536,4.536,0,0,1-1.491.223,2.972,2.972,0,0,1-2.029-.637,2.055,2.055,0,0,1-.722-1.615,5.868,5.868,0,0,1,.053-.779c.037-.265.095-.562.174-.9l.9-3.176c.079-.3.148-.594.2-.864a3.83,3.83,0,0,0,.08-.748,1.152,1.152,0,0,0-.251-.847A1.423,1.423,0,0,0,11.1,12.7a2.521,2.521,0,0,0-.715.106c-.242.074-.453.142-.625.208L10,12.039q.883-.36,1.69-.616a4.992,4.992,0,0,1,1.524-.258,2.917,2.917,0,0,1,2,.626,2.081,2.081,0,0,1,.7,1.626q0,.207-.048.729a4.879,4.879,0,0,1-.18.958l-.895,3.167c-.073.254-.138.545-.2.87a4.6,4.6,0,0,0-.086.74,1.062,1.062,0,0,0,.282.86,1.581,1.581,0,0,0,.977.229,2.835,2.835,0,0,0,.74-.115,4.206,4.206,0,0,0,.6-.2ZM16.706,8.779A2.135,2.135,0,0,1,15.2,9.36a2.157,2.157,0,0,1-1.513-.581,1.856,1.856,0,0,1-.63-1.41,1.875,1.875,0,0,1,.63-1.413A2.142,2.142,0,0,1,15.2,5.368a2.118,2.118,0,0,1,1.507.587,1.9,1.9,0,0,1,0,2.823Z" fill="#fff"/>
    </svg>
  );


  const [loaderPage, setloaderPage] = useState(false);
  const [caixas, setcaixas] = useState([]);

  const [contasOrcamentarias, setcontasOrcamentarias] = useState([]);

  const [pessoas, setpessoas] = useState([]);
  const [showModalNovaPessoa, setshowModalNovaPessoa] = useState(false);
  const [showModalDetalhesPessoa, setshowModalDetalhesPessoa] = useState(false);
  const [codigoDetalhesPessoa, setcodigoDetalhesPessoa] = useState();

  const getContasOrcamentarias = async(operacao)=>{
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemContasOrcamentaria?operacao=${operacao}`,{
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setcontasOrcamentarias(response.data);
  }

  const getPessoas = async(credorId=null, codigoCredor=null)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemPessoas`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arrayPessoas = [];
    
    for await (let pessoa of response.data) {
        let dados = {
            codigo: pessoa.codigo,
            nome: pessoa.nome,
            id: pessoa.id
        }    
        
        arrayPessoas.push(dados);
    }

    setpessoas(arrayPessoas);

    if(codigoCredor){
      //populando credor no select
      let credorcode = arrayPessoas.find(x => x.codigo === codigoCredor);
      credorcode && formik.setFieldValue('credor', JSON.stringify(credorcode));
      setcodigoDetalhesPessoa(codigoCredor);
      // setshowModalDetalhesPessoa(true);

    }else if(credorId){
      //populando credor no select
      let credor = arrayPessoas.find(x => x.id === credorId);
      credor && formik.setFieldValue('credor', JSON.stringify(credor));
      setcodigoDetalhesPessoa(credor.codigo);
    }

  }

  useEffect(()=>{
    getContasOrcamentarias(2); //1 = crédito 2 = Débito
    getPessoas();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  const validationSchema = yup.object({
    data: yup
      .date('').typeError("Insira a data do lançamento")
      .required('Data de lançamento Obrigatória'),
    vencimento: yup
      .date('').typeError("Insira a data do Vencimento")
      .required('Data de Vencimento Obrigatória'),
    credor: yup
      .string('Selecione o Credor')
      .required('Credor Obrigatório')
      .test({
        message: () => "Selecione o Credor",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),
    valor: yup
      .string('Informe o valor')
      .required('Valor Obrigatório'),
    contaorcamentaria: yup
      .number('Selecione uma conta orçamentária')
      .required('Conta orçamentária Obrigatória'),
    descricao: yup
      .string('Informe a descrição da conta')
      .required('Descrição Obrigatória'),
    
  });

  const formik = useFormik({
    initialValues: {
      data: new Date(),
      vencimento: '', //moment().add(25, 'd'),
      credor: 'null',
      valor: '',
      contaorcamentaria: '',
      descricao: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let credor = JSON.parse(values.credor);

      await api.post('lancamentoContasApagar', {
        "data": values.data,
        "planoContaOrcamentaria_id": values.contaorcamentaria,
        "formaPagamento_id": null,
        "confirmado": 1,
        "vencimento": values.vencimento,
        "totalParcelas": 1,
        "venda_id":  null,
        "compra_id": null,
        "pessoa_id": credor.id, //cliente/fornecedor/credor pessoa id
        "descricao": values.descricao,
        "valor": formataDinheiroforBanco(values.valor),
        "operacao": 2 //1 = crédito 2 = Débito
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

      formik.resetForm();
      refreshcontas();
      change(false);

    }
  });

  const handleChangeCredor = async(event, value, reason, details)=>{
    formik.setFieldValue('credor', JSON.stringify(value));
    setcodigoDetalhesPessoa(value?.codigo);
  }

  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <ModalNovaPessoa visible={showModalNovaPessoa} change={setshowModalNovaPessoa} atualizarPessoas={getPessoas}/> 
    <ModalDetalhesPessoa codigo={codigoDetalhesPessoa} visiblemodal={showModalDetalhesPessoa} changemodal={setshowModalDetalhesPessoa}/>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} scroll='body' onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Nova Conta a Pagar
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={2.5} style={{marginTop: '0px'}}>

                <Grid item xs={12} md={6} >
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                    <DesktopDatePicker
                      label="Data"
                      inputFormat="dd/MM/yyyy"
                      name="data"
                      value={formik.values.data}
                      onChange={(data)=>{ formik.setFieldValue('data', data) }}
                      renderInput={(params) => <TextField style={{ width: '100%'}} {...params} error={formik.touched.data && Boolean(formik.errors.data)}/>}
                    />
                    <FormHelperText error>{formik.touched.data && formik.errors.data}</FormHelperText>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={6} >
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                    <DesktopDatePicker
                      label="Vencimento"
                      inputFormat="dd/MM/yyyy"
                      name="vencimento"
                      value={formik.values.vencimento}
                      onChange={(vencimento)=>{ formik.setFieldValue('vencimento', vencimento) }}
                      renderInput={(params) => <TextField style={{ width: '100%'}} {...params} error={formik.touched.vencimento && Boolean(formik.errors.vencimento)}/>}
                    />
                    <FormHelperText error>{formik.touched.vencimento && formik.errors.vencimento}</FormHelperText>
                  </LocalizationProvider>
                </Grid>

                <Grid container item xs={12} md={12}>

                  <Grid item xs={formik.values.credor === 'null' ? 10.1 : 8.25} md={formik.values.credor === 'null' ? 10.66 : 9.3} >
                      <FormControl sx={{ m: 0, width: '100%' }} >
                        <Autocomplete
                            className='input'
                            // disablePortal
                            id="combo-box-credor"
                            options={pessoas}
                            value={JSON.parse(formik.values.credor)}
                            getOptionLabel={(option) => option.nome}
                            renderOption={(props, option) => (
                              <li {...props} key={option.id}>{option.nome}</li>
                            )}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={handleChangeCredor}
                            noOptionsText="Nenhum credor encontrado."
                            sx={{ width: '100%' }}
                            renderInput={(params) => <TextField {...params} label="Credor"  error={formik.touched.credor && Boolean(formik.errors.credor)}/>}
                        />
                        <FormHelperText error>{formik.touched.credor && formik.errors.credor}</FormHelperText>
                      </FormControl>
                  </Grid>

                  <Grid>
                    <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovaPessoa(true) }}/>
                  </Grid>

                  {
                    formik.values.credor !== 'null' &&

                    <Grid>
                      <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalDetalhesPessoa(true) }}/>
                    </Grid>
                  }

                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    id="valor"
                    name="valor"
                    label="Valor"
                    margin="none"
                    type="text"
                    value={formik.values.valor}
                    onChange={formik.handleChange}
                    error={formik.touched.valor && Boolean(formik.errors.valor)}
                    helperText={formik.touched.valor && formik.errors.valor}
                    onKeyUp={()=>{ formik.setFieldValue('valor', formik.values.valor && mvalor(formik.values.valor)) }}
                    inputProps={{
                        maxLength: 15,
                    }}
                  />
                </Grid>
                
                <Grid item xs={12} md={12} >
                  <FormControl sx={{ m: 0, width: '100%' }} >
                    <InputLabel id="label-contaorcamentaria">Conta Orçamentária</InputLabel>
                    <Select
                      labelId="label-contaorcamentaria"
                      id="contaorcamentaria-simple-select"
                      name="contaorcamentaria"
                      label="Conta Orçamentária"
                      placeholder='Conta Orçamentária'
                      value={formik.values.contaorcamentaria}
                      onChange={formik.handleChange}
                      error={formik.touched.contaorcamentaria && Boolean(formik.errors.contaorcamentaria)}
                    >
                      {contasOrcamentarias.map((v, k) =>{
                          return (
                            <MenuItem key={k} value={v.id}>{v.descricao}</MenuItem>
                          )
                      })}
                    </Select>
                    <FormHelperText error>{formik.touched.contaorcamentaria && formik.errors.contaorcamentaria}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} >
                    <TextField
                        fullWidth
                        id="descricao"
                        name="descricao"
                        label="Descrição"
                        margin="none"
                        type="text"
                        multiline
                        rows={3}
                        value={formik.values.descricao}
                        onChange={formik.handleChange}
                        error={formik.touched.descricao && Boolean(formik.errors.descricao)}
                        helperText={formik.touched.descricao && formik.errors.descricao}
                        inputProps={{
                            maxLength: 200,
                        }}
                    />
                </Grid>

          </Grid>
        </DialogContent>

        <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

            <Grid item xs={4} md={3}>
                <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
            </Grid>
            
            <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon=""  txt="Adicionar" />
            </Grid>

        </Grid>
        </DialogActions>
        
        </form>
    </Dialog>
    </>
  );

}