import React, {useState, useEffect } from 'react';
import api from '../../../services/api';
import moment from 'moment';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Swal from 'sweetalert2';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';
import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonSecondary from '../../../componentes/ButtonSecondary';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';
import ButtonSquadPrimaryForInput from '../../../componentes/ButtonSquadPrimaryForInput';
import ButtonSecondaryOnlyIcon from '../../../componentes/ButtonSecondaryOnlyIcon';
import ButtonDanger from '../../../componentes/ButtonDanger';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { mvalor, mnumero } from '../../../vendor/mascaras';
import { formataDinheiroforBanco, formataDinheiro } from '../../../vendor/formatar';

import ModalNovaDespesa from './modalNovaDespesa';
import ModalEditDespesa from './modalEditDespesa';
import ModalFaturarDespesas from './modalFaturarDespesas';

import ModalNovaPessoa from '../../Cadastros/Pessoas/modals/modalNovaPessoa';
import ModalDetalhesPessoa from '../../Cadastros/Pessoas/modals/modalDetalhesPessoa';

import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

export default function ModalEditAutorizacaoServicos({ dadosCompra, compraId, codigoAutorizacao, visible, change, refreshAutorizacoes}) {


   let IconInfo = props =>(
    <svg id="information-button" xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.917 27.917">
        <path id="Caminho_118" data-name="Caminho 118" d="M13.958,0A13.959,13.959,0,1,0,27.917,13.958,13.959,13.959,0,0,0,13.958,0Zm2.906,21.633q-1.078.425-1.719.648a4.536,4.536,0,0,1-1.491.223,2.972,2.972,0,0,1-2.029-.637,2.055,2.055,0,0,1-.722-1.615,5.868,5.868,0,0,1,.053-.779c.037-.265.095-.562.174-.9l.9-3.176c.079-.3.148-.594.2-.864a3.83,3.83,0,0,0,.08-.748,1.152,1.152,0,0,0-.251-.847A1.423,1.423,0,0,0,11.1,12.7a2.521,2.521,0,0,0-.715.106c-.242.074-.453.142-.625.208L10,12.039q.883-.36,1.69-.616a4.992,4.992,0,0,1,1.524-.258,2.917,2.917,0,0,1,2,.626,2.081,2.081,0,0,1,.7,1.626q0,.207-.048.729a4.879,4.879,0,0,1-.18.958l-.895,3.167c-.073.254-.138.545-.2.87a4.6,4.6,0,0,0-.086.74,1.062,1.062,0,0,0,.282.86,1.581,1.581,0,0,0,.977.229,2.835,2.835,0,0,0,.74-.115,4.206,4.206,0,0,0,.6-.2ZM16.706,8.779A2.135,2.135,0,0,1,15.2,9.36a2.157,2.157,0,0,1-1.513-.581,1.856,1.856,0,0,1-.63-1.41,1.875,1.875,0,0,1,.63-1.413A2.142,2.142,0,0,1,15.2,5.368a2.118,2.118,0,0,1,1.507.587,1.9,1.9,0,0,1,0,2.823Z" fill="#fff"/>
    </svg>
   )

   let IconPrint = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 30.778 28.606">
        <g id="printing" transform="translate(0 -18.065)">
            <g id="Grupo_31" data-name="Grupo 31" transform="translate(9.917 41.014)">
            <g id="Grupo_30" data-name="Grupo 30">
                <path id="Caminho_68" data-name="Caminho 68" d="M174.894,399.834H166a1.026,1.026,0,1,0,0,2.052h8.891a1.026,1.026,0,0,0,0-2.052Z" transform="translate(-164.977 -399.834)" fill="#fff"/>
            </g>
            </g>
            <g id="Grupo_33" data-name="Grupo 33" transform="translate(9.917 37.778)">
            <g id="Grupo_32" data-name="Grupo 32">
                <path id="Caminho_69" data-name="Caminho 69" d="M174.894,346.006H166a1.026,1.026,0,1,0,0,2.052h8.891a1.026,1.026,0,0,0,0-2.052Z" transform="translate(-164.977 -346.006)" fill="#fff"/>
            </g>
            </g>
            <g id="Grupo_35" data-name="Grupo 35" transform="translate(0 18.065)">
            <g id="Grupo_34" data-name="Grupo 34">
                <path id="Caminho_70" data-name="Caminho 70" d="M28.384,25.029H25.119V19.091a1.026,1.026,0,0,0-1.026-1.026H6.684a1.026,1.026,0,0,0-1.026,1.026v5.938H2.394A2.4,2.4,0,0,0,0,27.422V37.744a2.4,2.4,0,0,0,2.394,2.394H5.658v5.507a1.026,1.026,0,0,0,1.026,1.026H24.093a1.026,1.026,0,0,0,1.026-1.026V40.138h3.264a2.4,2.4,0,0,0,2.394-2.394V27.422A2.4,2.4,0,0,0,28.384,25.029ZM7.71,20.117H23.068v4.912H7.71Zm15.358,24.5H7.71V36.225H23.067C23.067,36.48,23.067,44.426,23.067,44.619Zm1.026-13.566H21.482a1.026,1.026,0,1,1,0-2.052h2.611a1.026,1.026,0,0,1,0,2.052Z" transform="translate(0 -18.065)" fill="#fff"/>
            </g>
            </g>
        </g>
    </svg>

   )

   let IconNoAuth = props =>(
      <svg id="blocked" xmlns="http://www.w3.org/2000/svg" width="30"  viewBox="0 0 90.682 90.682">
        <path id="Caminho_196" data-name="Caminho 196" d="M60.455,34.535a8.537,8.537,0,0,0-8.5-8.086H49.119V18.892a18.892,18.892,0,0,0-37.784,0v7.557H8.5A8.514,8.514,0,0,0,0,34.95V67.067a8.514,8.514,0,0,0,8.5,8.5H35.4a32.46,32.46,0,0,1-1.4-9.446A32.072,32.072,0,0,1,60.455,34.535ZM18.892,26.449V18.892a11.335,11.335,0,1,1,22.67,0v7.557Z" fill="#4a4a4a"/>
        <path id="Caminho_197" data-name="Caminho 197" d="M35.56,11a24.56,24.56,0,1,0,24.56,24.56A24.588,24.588,0,0,0,35.56,11Zm0,7.557A16.839,16.839,0,0,1,44.575,21.2L21.2,44.575A16.931,16.931,0,0,1,35.56,18.557Zm0,34.006a16.839,16.839,0,0,1-9.015-2.645L49.921,26.544a16.839,16.839,0,0,1,2.645,9.015,17.029,17.029,0,0,1-17.007,17Z" transform="translate(30.563 30.563)" fill="#4a4a4a"/>
      </svg>
   )

   let IconDespesas = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 55.707 55.734">
      <g id="accounting" transform="translate(0 0.001)">
        <path id="Caminho_310" data-name="Caminho 310" d="M113.289,288a9.289,9.289,0,1,0,9.289,9.289A9.289,9.289,0,0,0,113.289,288Zm0,16.72a7.431,7.431,0,1,1,7.431-7.431A7.431,7.431,0,0,1,113.289,304.72Zm0,0" transform="translate(-91.925 -254.56)" fill="#4a4a4a"/>
        <path id="Caminho_311" data-name="Caminho 311" d="M155.716,322.787c1.134,0,1.858.55,1.858.929h1.858c0-1.32-1.169-2.389-2.787-2.694V320h-1.858v1.022c-1.617.3-2.787,1.372-2.787,2.694,0,1.563,1.632,2.787,3.716,2.787,1.134,0,1.858.55,1.858.929s-.723.929-1.858.929-1.858-.55-1.858-.929H152c0,1.32,1.169,2.389,2.787,2.694v1.022h1.858v-1.022c1.617-.3,2.787-1.372,2.787-2.694,0-1.563-1.632-2.787-3.716-2.787-1.134,0-1.858-.55-1.858-.929S154.581,322.787,155.716,322.787Zm0,0" transform="translate(-134.351 -282.845)" fill="#4a4a4a"/>
        <path id="Caminho_312" data-name="Caminho 312" d="M47.373,53.875H8.36V31.582H23.222A2.787,2.787,0,0,0,26.009,28.8V5.573H36.227V16.719H47.373v13h1.858V15.406L37.54,3.715H26.009V2.786A2.787,2.787,0,0,0,23.222,0H2.786A2.787,2.787,0,0,0,0,2.786V28.795a2.787,2.787,0,0,0,2.787,2.787H6.5V55.733H49.231v-13H47.373ZM38.084,6.886l7.975,7.975H38.084ZM1.857,28.8V2.786a.929.929,0,0,1,.929-.929H23.222a.929.929,0,0,1,.929.929V28.8a.929.929,0,0,1-.929.929H2.786a.929.929,0,0,1-.929-.929Zm0,0" fill="#4a4a4a"/>
        <path id="Caminho_313" data-name="Caminho 313" d="M256,200h1.858v1.858H256Zm0,0" transform="translate(-226.276 -176.778)" fill="#4a4a4a"/>
        <path id="Caminho_314" data-name="Caminho 314" d="M288,200h11.147v1.858H288Zm0,0" transform="translate(-254.56 -176.778)" fill="#4a4a4a"/>
        <path id="Caminho_315" data-name="Caminho 315" d="M256,168h1.858v1.858H256Zm0,0" transform="translate(-226.276 -148.494)" fill="#4a4a4a"/>
        <path id="Caminho_316" data-name="Caminho 316" d="M288,168h11.147v1.858H288Zm0,0" transform="translate(-254.56 -148.494)" fill="#4a4a4a"/>
        <path id="Caminho_317" data-name="Caminho 317" d="M256,232h1.858v1.858H256Zm0,0" transform="translate(-226.276 -205.063)" fill="#4a4a4a"/>
        <path id="Caminho_318" data-name="Caminho 318" d="M288,232h11.147v1.858H288Zm0,0" transform="translate(-254.56 -205.063)" fill="#4a4a4a"/>
        <path id="Caminho_319" data-name="Caminho 319" d="M256,264h1.858v1.858H256Zm0,0" transform="translate(-226.276 -233.347)" fill="#4a4a4a"/>
        <path id="Caminho_320" data-name="Caminho 320" d="M288,264h11.147v1.858H288Zm0,0" transform="translate(-254.56 -233.347)" fill="#4a4a4a"/>
        <path id="Caminho_321" data-name="Caminho 321" d="M44.436,24H24v6.5H44.436Zm-1.858,4.645H25.858V25.858h16.72Zm0,0" transform="translate(-21.214 -21.214)" fill="#4a4a4a"/>
        <path id="Caminho_322" data-name="Caminho 322" d="M35.716,88H33.858A1.858,1.858,0,0,0,32,89.858v1.858a1.858,1.858,0,0,0,1.858,1.858h1.858a1.858,1.858,0,0,0,1.858-1.858V89.858A1.858,1.858,0,0,0,35.716,88Zm-1.858,3.716V89.858h1.858v1.858Zm0,0" transform="translate(-28.285 -77.783)" fill="#4a4a4a"/>
        <path id="Caminho_323" data-name="Caminho 323" d="M91.716,88H89.858A1.858,1.858,0,0,0,88,89.858v1.858a1.858,1.858,0,0,0,1.858,1.858h1.858a1.858,1.858,0,0,0,1.858-1.858V89.858A1.858,1.858,0,0,0,91.716,88Zm-1.858,3.716V89.858h1.858v1.858Zm0,0" transform="translate(-77.783 -77.783)" fill="#4a4a4a"/>
        <path id="Caminho_324" data-name="Caminho 324" d="M147.716,88h-1.858A1.858,1.858,0,0,0,144,89.858v1.858a1.858,1.858,0,0,0,1.858,1.858h1.858a1.858,1.858,0,0,0,1.858-1.858V89.858A1.858,1.858,0,0,0,147.716,88Zm-1.858,3.716V89.858h1.858v1.858Zm0,0" transform="translate(-127.28 -77.783)" fill="#4a4a4a"/>
        <path id="Caminho_325" data-name="Caminho 325" d="M35.716,144H33.858A1.858,1.858,0,0,0,32,145.858v1.858a1.858,1.858,0,0,0,1.858,1.858h1.858a1.858,1.858,0,0,0,1.858-1.858v-1.858A1.858,1.858,0,0,0,35.716,144Zm-1.858,3.716v-1.858h1.858v1.858Zm0,0" transform="translate(-28.285 -127.281)" fill="#4a4a4a"/>
        <path id="Caminho_326" data-name="Caminho 326" d="M91.716,144H89.858A1.858,1.858,0,0,0,88,145.858v1.858a1.858,1.858,0,0,0,1.858,1.858h1.858a1.858,1.858,0,0,0,1.858-1.858v-1.858A1.858,1.858,0,0,0,91.716,144Zm-1.858,3.716v-1.858h1.858v1.858Zm0,0" transform="translate(-77.783 -127.281)" fill="#4a4a4a"/>
        <path id="Caminho_327" data-name="Caminho 327" d="M147.716,144h-1.858A1.858,1.858,0,0,0,144,145.858v1.858a1.858,1.858,0,0,0,1.858,1.858h1.858a1.858,1.858,0,0,0,1.858-1.858v-1.858A1.858,1.858,0,0,0,147.716,144Zm-1.858,3.716v-1.858h1.858v1.858Zm0,0" transform="translate(-127.28 -127.281)" fill="#4a4a4a"/>
        <path id="Caminho_328" data-name="Caminho 328" d="M35.716,200H33.858A1.858,1.858,0,0,0,32,201.858v1.858a1.858,1.858,0,0,0,1.858,1.858h1.858a1.858,1.858,0,0,0,1.858-1.858v-1.858A1.858,1.858,0,0,0,35.716,200Zm-1.858,3.716v-1.858h1.858v1.858Zm0,0" transform="translate(-28.285 -176.778)" fill="#4a4a4a"/>
        <path id="Caminho_329" data-name="Caminho 329" d="M91.716,200H89.858A1.858,1.858,0,0,0,88,201.858v1.858a1.858,1.858,0,0,0,1.858,1.858h1.858a1.858,1.858,0,0,0,1.858-1.858v-1.858A1.858,1.858,0,0,0,91.716,200Zm-1.858,3.716v-1.858h1.858v1.858Zm0,0" transform="translate(-77.783 -176.778)" fill="#4a4a4a"/>
        <path id="Caminho_330" data-name="Caminho 330" d="M147.716,200h-1.858A1.858,1.858,0,0,0,144,201.858v1.858a1.858,1.858,0,0,0,1.858,1.858h1.858a1.858,1.858,0,0,0,1.858-1.858v-1.858A1.858,1.858,0,0,0,147.716,200Zm-1.858,3.716v-1.858h1.858v1.858Zm0,0" transform="translate(-127.28 -176.778)" fill="#4a4a4a"/>
        <path id="Caminho_331" data-name="Caminho 331" d="M302.39,249.256a2.853,2.853,0,0,0-3.94,0L282.526,265.18a.94.94,0,0,0-.186.269l-2.242,4.869a.929.929,0,0,0,1.232,1.232l4.869-2.242a.931.931,0,0,0,.268-.186L302.39,253.2a2.786,2.786,0,0,0,0-3.941Zm-17.12,18.433-2.434,1.121,1.121-2.434L295.83,254.5l1.314,1.314ZM298.46,254.5l-1.314-1.313.65-.65,1.314,1.314Zm2.62-2.619-.657.657-1.314-1.314.658-.657a.95.95,0,0,1,1.313,0A.929.929,0,0,1,301.08,251.879Zm0,0" transform="translate(-247.5 -219.617)" fill="#4a4a4a"/>
      </g>
    </svg>
   )

   let IconFaturado = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 47.91 53.912">
      <g id="invoice" transform="translate(-28.5)">
        <g id="Grupo_386" data-name="Grupo 386" transform="translate(28.5)">
          <g id="Grupo_385" data-name="Grupo 385" transform="translate(0)">
            <path id="Caminho_332" data-name="Caminho 332" d="M111.659,253h-1.053a2.106,2.106,0,1,0,0,4.212h1.053a2.106,2.106,0,1,0,0-4.212Z" transform="translate(-100.076 -226.36)" fill="#4a4a4a"/>
            <path id="Caminho_333" data-name="Caminho 333" d="M203.979,173H190.606a2.106,2.106,0,0,0,0,4.212h13.373a2.106,2.106,0,0,0,0-4.212Z" transform="translate(-171.653 -154.784)" fill="#4a4a4a"/>
            <path id="Caminho_334" data-name="Caminho 334" d="M57.445,49.7H36.924a4.217,4.217,0,0,1-4.212-4.212V8.424a4.217,4.217,0,0,1,4.212-4.212H62.815a4.217,4.217,0,0,1,4.212,4.212v11.9a2.106,2.106,0,0,0,4.212,0V8.424A8.433,8.433,0,0,0,62.815,0H36.924A8.433,8.433,0,0,0,28.5,8.424V45.488a8.433,8.433,0,0,0,8.424,8.424H57.445a2.106,2.106,0,0,0,0-4.212Z" transform="translate(-28.5)" fill="#4a4a4a"/>
            <path id="Caminho_335" data-name="Caminho 335" d="M111.659,333h-1.053a2.106,2.106,0,1,0,0,4.212h1.053a2.106,2.106,0,1,0,0-4.212Z" transform="translate(-100.076 -297.936)" fill="#4a4a4a"/>
            <path id="Caminho_336" data-name="Caminho 336" d="M203.979,93H190.606a2.106,2.106,0,0,0,0,4.212h13.373a2.106,2.106,0,1,0,0-4.212Z" transform="translate(-171.653 -83.207)" fill="#4a4a4a"/>
            <path id="Caminho_337" data-name="Caminho 337" d="M355.85,264.262h-1.93a1.729,1.729,0,0,1-1.712-1.742v-.285a1.646,1.646,0,0,1,1.712-1.654h1.825a1.025,1.025,0,0,1,1.091.96,2.106,2.106,0,0,0,4.212,0,5.216,5.216,0,0,0-4.319-5.081v-1.354a2.106,2.106,0,0,0-4.212,0v1.427a5.88,5.88,0,0,0-2.771,1.523A5.8,5.8,0,0,0,348,262.235v.285a5.945,5.945,0,0,0,5.923,5.954h1.93a1.722,1.722,0,0,1,1.723,1.716v1a1.631,1.631,0,0,1-1.723,1.606h-3.136a.994.994,0,0,1-1.041-.989,2.106,2.106,0,1,0-4.212,0,5.138,5.138,0,0,0,1.557,3.707,5.249,5.249,0,0,0,3.5,1.489v1.163a2.106,2.106,0,0,0,4.212,0v-1.22a5.783,5.783,0,0,0,5.056-5.756v-1A5.938,5.938,0,0,0,355.85,264.262Z" transform="translate(-313.875 -226.36)" fill="#4a4a4a"/>
            <path id="Caminho_338" data-name="Caminho 338" d="M199.767,337.212a2.106,2.106,0,1,0,0-4.212h-9.161a2.106,2.106,0,0,0,0,4.212Z" transform="translate(-171.653 -297.936)" fill="#4a4a4a"/>
            <path id="Caminho_339" data-name="Caminho 339" d="M111.659,173h-1.053a2.106,2.106,0,0,0,0,4.212h1.053a2.106,2.106,0,0,0,0-4.212Z" transform="translate(-100.076 -154.784)" fill="#4a4a4a"/>
            <path id="Caminho_340" data-name="Caminho 340" d="M206.085,255.106A2.106,2.106,0,0,0,203.979,253H190.606a2.106,2.106,0,0,0,0,4.212h13.373A2.106,2.106,0,0,0,206.085,255.106Z" transform="translate(-171.653 -226.36)" fill="#4a4a4a"/>
            <path id="Caminho_341" data-name="Caminho 341" d="M111.659,93h-1.053a2.106,2.106,0,1,0,0,4.212h1.053a2.106,2.106,0,1,0,0-4.212Z" transform="translate(-100.076 -83.207)" fill="#4a4a4a"/>
          </g>
        </g>
      </g>
    </svg>
   )

   let IconLixeira = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 21.241 28.294">
      <g id="delete" transform="translate(-63.818 0)">
        <path id="Caminho_186" data-name="Caminho 186" d="M85.059,9.084H71.973l5.86-5.86a.829.829,0,0,0,0-1.172L76.348.567a1.934,1.934,0,0,0-2.735,0L72.086,2.094,71.3,1.312a2.213,2.213,0,0,0-3.126,0L65.13,4.36a2.213,2.213,0,0,0,0,3.126l.782.782L64.385,9.795a1.934,1.934,0,0,0,0,2.735l1.485,1.485a.829.829,0,0,0,1.172,0l2.1-2.1V24.7a3.592,3.592,0,0,0,3.592,3.592h8.732A3.592,3.592,0,0,0,85.059,24.7ZM67.084,7.1,66.3,6.314a.553.553,0,0,1,0-.782L69.35,2.484a.553.553,0,0,1,.782,0l.782.782Zm7.158,16.557a.829.829,0,0,1-1.658,0V13.726a.829.829,0,0,1,1.658,0Zm3.688,0a.829.829,0,0,1-1.658,0V13.726a.829.829,0,0,1,1.658,0Zm3.688,0a.829.829,0,0,1-1.658,0V13.726a.829.829,0,0,1,1.658,0Z" transform="translate(0 0)" fill="#fff"/>
        <path id="Caminho_187" data-name="Caminho 187" d="M305.186,86.046a1.384,1.384,0,0,0-.919,1.152h7.954V85.82a1.382,1.382,0,0,0-1.825-1.309L305.2,86.04Z" transform="translate(-227.162 -79.771)" fill="#fff"/>
      </g>
    </svg>
   )

   let IconFaturar = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 47.91 53.912">
      <g id="invoice" transform="translate(-28.5)">
        <g id="Grupo_386" data-name="Grupo 386" transform="translate(28.5)">
          <g id="Grupo_385" data-name="Grupo 385" transform="translate(0)">
            <path id="Caminho_332" data-name="Caminho 332" d="M111.659,253h-1.053a2.106,2.106,0,1,0,0,4.212h1.053a2.106,2.106,0,1,0,0-4.212Z" transform="translate(-100.076 -226.36)" fill="#fff"/>
            <path id="Caminho_333" data-name="Caminho 333" d="M203.979,173H190.606a2.106,2.106,0,0,0,0,4.212h13.373a2.106,2.106,0,0,0,0-4.212Z" transform="translate(-171.653 -154.784)" fill="#fff"/>
            <path id="Caminho_334" data-name="Caminho 334" d="M57.445,49.7H36.924a4.217,4.217,0,0,1-4.212-4.212V8.424a4.217,4.217,0,0,1,4.212-4.212H62.815a4.217,4.217,0,0,1,4.212,4.212v11.9a2.106,2.106,0,0,0,4.212,0V8.424A8.433,8.433,0,0,0,62.815,0H36.924A8.433,8.433,0,0,0,28.5,8.424V45.488a8.433,8.433,0,0,0,8.424,8.424H57.445a2.106,2.106,0,0,0,0-4.212Z" transform="translate(-28.5)" fill="#fff"/>
            <path id="Caminho_335" data-name="Caminho 335" d="M111.659,333h-1.053a2.106,2.106,0,1,0,0,4.212h1.053a2.106,2.106,0,1,0,0-4.212Z" transform="translate(-100.076 -297.936)" fill="#fff"/>
            <path id="Caminho_336" data-name="Caminho 336" d="M203.979,93H190.606a2.106,2.106,0,0,0,0,4.212h13.373a2.106,2.106,0,1,0,0-4.212Z" transform="translate(-171.653 -83.207)" fill="#fff"/>
            <path id="Caminho_337" data-name="Caminho 337" d="M355.85,264.262h-1.93a1.729,1.729,0,0,1-1.712-1.742v-.285a1.646,1.646,0,0,1,1.712-1.654h1.825a1.025,1.025,0,0,1,1.091.96,2.106,2.106,0,0,0,4.212,0,5.216,5.216,0,0,0-4.319-5.081v-1.354a2.106,2.106,0,0,0-4.212,0v1.427a5.88,5.88,0,0,0-2.771,1.523A5.8,5.8,0,0,0,348,262.235v.285a5.945,5.945,0,0,0,5.923,5.954h1.93a1.722,1.722,0,0,1,1.723,1.716v1a1.631,1.631,0,0,1-1.723,1.606h-3.136a.994.994,0,0,1-1.041-.989,2.106,2.106,0,1,0-4.212,0,5.138,5.138,0,0,0,1.557,3.707,5.249,5.249,0,0,0,3.5,1.489v1.163a2.106,2.106,0,0,0,4.212,0v-1.22a5.783,5.783,0,0,0,5.056-5.756v-1A5.938,5.938,0,0,0,355.85,264.262Z" transform="translate(-313.875 -226.36)" fill="#fff"/>
            <path id="Caminho_338" data-name="Caminho 338" d="M199.767,337.212a2.106,2.106,0,1,0,0-4.212h-9.161a2.106,2.106,0,0,0,0,4.212Z" transform="translate(-171.653 -297.936)" fill="#fff"/>
            <path id="Caminho_339" data-name="Caminho 339" d="M111.659,173h-1.053a2.106,2.106,0,0,0,0,4.212h1.053a2.106,2.106,0,0,0,0-4.212Z" transform="translate(-100.076 -154.784)" fill="#fff"/>
            <path id="Caminho_340" data-name="Caminho 340" d="M206.085,255.106A2.106,2.106,0,0,0,203.979,253H190.606a2.106,2.106,0,0,0,0,4.212h13.373A2.106,2.106,0,0,0,206.085,255.106Z" transform="translate(-171.653 -226.36)" fill="#fff"/>
            <path id="Caminho_341" data-name="Caminho 341" d="M111.659,93h-1.053a2.106,2.106,0,1,0,0,4.212h1.053a2.106,2.106,0,1,0,0-4.212Z" transform="translate(-100.076 -83.207)" fill="#fff"/>
          </g>
        </g>
      </g>
    </svg>
   )

   let IconSalvar = props =>(
    <svg id="diskette" xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 57.091 57.091">
      <g id="Grupo_376" data-name="Grupo 376" transform="translate(16.837 47.056)">
        <g id="Grupo_375" data-name="Grupo 375">
          <path id="Caminho_292" data-name="Caminho 292" d="M172.744,422H152.673a1.673,1.673,0,1,0,0,3.345h20.071a1.673,1.673,0,1,0,0-3.345Z" transform="translate(-151 -422)" fill="#fff"/>
        </g>
      </g>
      <g id="Grupo_378" data-name="Grupo 378" transform="translate(16.837 33.675)">
        <g id="Grupo_377" data-name="Grupo 377">
          <path id="Caminho_293" data-name="Caminho 293" d="M172.744,302H152.673a1.673,1.673,0,0,0,0,3.345h20.071a1.673,1.673,0,0,0,0-3.345Z" transform="translate(-151 -302)" fill="#fff"/>
        </g>
      </g>
      <g id="Grupo_380" data-name="Grupo 380" transform="translate(16.837 40.365)">
        <g id="Grupo_379" data-name="Grupo 379">
          <path id="Caminho_294" data-name="Caminho 294" d="M172.744,362H152.673a1.673,1.673,0,1,0,0,3.345h20.071a1.673,1.673,0,1,0,0-3.345Z" transform="translate(-151 -362)" fill="#fff"/>
        </g>
      </g>
      <g id="Grupo_382" data-name="Grupo 382" transform="translate(13.492)">
        <g id="Grupo_381" data-name="Grupo 381">
          <rect id="Retângulo_64" data-name="Retângulo 64" width="24" height="14" transform="translate(-0.492 0.091)" fill="#fff"/>
        </g>
      </g>
      <g id="Grupo_384" data-name="Grupo 384">
        <g id="Grupo_383" data-name="Grupo 383">
          <path id="Caminho_295" data-name="Caminho 295" d="M56.6,9.41,47.681.49A1.673,1.673,0,0,0,46.5,0H40.254V16.168a1.673,1.673,0,0,1-1.673,1.673H11.82a1.673,1.673,0,0,1-1.673-1.673V0H1.673A1.673,1.673,0,0,0,0,1.673V55.419a1.673,1.673,0,0,0,1.673,1.673H55.419a1.673,1.673,0,0,0,1.673-1.673V10.593A1.673,1.673,0,0,0,56.6,9.41Zm-13,44.336H13.492V30.33H43.6Z" fill="#fff"/>
        </g>
      </g>
    </svg>
   )

   let IconServicesCar = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="28" viewBox="0 0 48.156 29.809">
      <path id="servico-de-reparo" d="M46.626,57.846a1.861,1.861,0,0,0-.494.069l-1.877.517-1.949-4.747a4.252,4.252,0,0,0-3.637-2.439H20.433A4.252,4.252,0,0,0,16.8,53.685L15.34,57.231c.021-.235.033-.473.033-.712A7.71,7.71,0,0,0,11.5,49.842a.858.858,0,0,0-.426-.111.841.841,0,0,0-.846.872s-.015,3.848-.015,5.131a.564.564,0,0,1-.228.343l-.048.036A17.354,17.354,0,0,1,7.768,57.7H7.6a17.106,17.106,0,0,1-2.2-1.609l-.049-.037a.58.58,0,0,1-.189-.44c0-1.335-.013-5-.013-5a.825.825,0,0,0-.824-.881.943.943,0,0,0-.461.123A7.686,7.686,0,0,0,4.8,63.643V76.731a2.807,2.807,0,0,0,2.8,2.8h.16a2.807,2.807,0,0,0,2.8-2.8V63.648a7.673,7.673,0,0,0,2.552-1.693q.27-.27.509-.561L12.847,63.3a14.823,14.823,0,0,0-.93,4.715v9.43a1.979,1.979,0,0,0,1.976,1.976h2.581a1.978,1.978,0,0,0,1.976-1.976V75.094h22.2v2.355a1.979,1.979,0,0,0,1.976,1.976H45.21a1.978,1.978,0,0,0,1.976-1.976V68.02a14.825,14.825,0,0,0-.93-4.715l-.315-.766h.239a1.978,1.978,0,0,0,1.976-1.976V59.456A1.52,1.52,0,0,0,46.626,57.846Zm-30.358,3.62,2.948-7.18A2.555,2.555,0,0,1,21.4,52.824H37.708a2.555,2.555,0,0,1,2.181,1.462l2.948,7.18a.986.986,0,0,1-.981,1.462H17.248A.986.986,0,0,1,16.267,61.466Zm6.58,9.081a.793.793,0,0,1-.79.79h-5.6a.793.793,0,0,1-.79-.79V67.86a.793.793,0,0,1,.79-.79h5.6a.793.793,0,0,1,.79.79Zm20.536,0a.793.793,0,0,1-.79.79h-5.6a.793.793,0,0,1-.79-.79V67.86a.793.793,0,0,1,.79-.79h5.6a.793.793,0,0,1,.79.79Z" transform="translate(0 -49.726)" fill="#fff"/>
    </svg>
   )

   let IconPlus = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
      <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
   );


  const [loaderPage, setloaderPage] = useState(false);
  const [bancos, setbancos] = useState([]);
  const [pessoas, setpessoas] = useState([]);
  const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);

  const [despesas, setdespesas] = useState([]);
  const [despesasSelecionadas, setdespesasSelecionadas] = useState([]);
  const [pageSizeDataGridDespesas, setpageSizeDataGridDespesas] = useState(10);
  const [loadingDataGridDespesas, setloadingDataGridDespesas] = useState(true);

  const [showModalnovaDespesa, setshowModalnovaDespesa] = useState(false);
  const [showModaleditDespesa, setshowModaleditDespesa] = useState(false);
  const [showModalFaturarDespesas, setshowModalFaturarDespesas] = useState(false);
  const [idEditDespesa, setidEditDespesa] = useState();

  const [detalhesautorizacaoservico, setdetalhesautorizacaoservico] = useState([]);

  const [showModalNovaPessoa, setshowModalNovaPessoa] = useState(false);
  const [showModalDetalhesPessoa, setshowModalDetalhesPessoa] = useState(false);
  const [codigoDetalhesPessoa, setcodigoDetalhesPessoa] = useState();

  
  const permissoesUsuario = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post('permissoesUsuario', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setpermissoesdoUsuario(permissoes.data.compras);
  }

  const getPessoas = async(pessoaId=null, codigoPessoa=null)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemPessoas`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arrayPessoas = [];
    
    for await (let pessoa of response.data) {
        let dados = {
          codigo: pessoa.codigo,
          nome: pessoa.nome,
          id: pessoa.id
        }    
        
        arrayPessoas.push(dados);
    }

    setpessoas(arrayPessoas);

    if(codigoPessoa){
      //populando pessoa no select
      let pessoacode = arrayPessoas.find(x => x.codigo === codigoPessoa);
      pessoacode && formik.setFieldValue('fornecedor', JSON.stringify(pessoacode));
      setcodigoDetalhesPessoa(codigoPessoa);
      // setshowModalDetalhesPessoa(true);
  
    }else if(pessoaId){
        //populando pessoa no select
        let pessoa = arrayPessoas.find(x => x.id === pessoaId);
        pessoa && formik.setFieldValue('fornecedor', JSON.stringify(pessoa));
        setcodigoDetalhesPessoa(pessoa.codigo);
    }

  }

  const getDespesas = async(autorizacaoId, status=null)=>{
    setloadingDataGridDespesas(true);

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemDespesasVeiculo?compraid=&termo=&autorizacaoid=${autorizacaoId}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let despesasfaturadas = 0;
    let totaldasdespesas = response.data.length;

    for await (let despesas of response.data) {
      despesasfaturadas += despesas.faturado
    }

    if(despesasfaturadas === totaldasdespesas && status != 1){ //tudo faturado
      formik.setFieldValue('status', 2);

      //UPDATE STATUS AUTORIZAÇÃO SERVIÇO
      await api.put('updateStatusAutorizacaoServico', {
        "codigo": codigoAutorizacao,
        "status": 2,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

      refreshAutorizacoes();

    }else{
      
      let nstatus = status === 0 ? status : 1;
      formik.setFieldValue('status', nstatus);
      
      //UPDATE STATUS AUTORIZAÇÃO SERVIÇO
      await api.put('updateStatusAutorizacaoServico', {
        "codigo": codigoAutorizacao,
        "status": nstatus,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

      refreshAutorizacoes();
    }



    setdespesas(response.data);
    setloadingDataGridDespesas(false);
  }

  const detalhesAutorizacao = async()=>{

    setloaderPage(true);

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let responde = await api.post('detalhesAutorizacaoServicos', {
        "codigo": codigoAutorizacao
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let detalhes = responde.data;
    
    await getPessoas(detalhes.pessoa_id);
    await formik.setFieldValue('solicitacao', detalhes.solicitacao);
    await formik.setFieldValue('status', detalhes.status);
    await formik.setFieldValue('usuario', detalhes.usuario);

    setdetalhesautorizacaoservico(detalhes);
    getDespesas(detalhes.id, detalhes.status);

    setloaderPage(false);


  }

  const refreshDespesas = async()=>{
    getDespesas(detalhesautorizacaoservico.id);
  }


  useEffect(()=>{
    if(visible){
      permissoesUsuario();
      codigoAutorizacao && detalhesAutorizacao();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[visible]);

  const validationSchema = yup.object({
    fornecedor: yup
      .string('Selecione o Fornecedor')
      .required('Fornecedor Obrigatório')
      .test({
        message: () => "Selecione o Fornecedor",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),
    solicitacao: yup
      .string('Informe a solicitação do serviço')
      .required('Solicitação do serviço Obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      fornecedor: 'null',
      solicitacao: '',
      status: 1,
      usuario: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      let fornecedor = JSON.parse(values.fornecedor);
      
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.put('updateAutorizacaoServico', {
        "codigo": codigoAutorizacao,
        "fornecedor": fornecedor.id,
        "solicitacao": values.solicitacao,
        "status": values.status,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

      refreshAutorizacoes();
      change(false);

    }
  });

  const handleChangeFornecedor = async(event, value, reason, details)=>{
    formik.setFieldValue('fornecedor', JSON.stringify(value));
    setcodigoDetalhesPessoa(value.codigo);
  }


  const columnsDataGridDespesas = [
    { field: 'id', hide: true, headerName: 'ID', width: 90  },
    {
        field: 'descricao',
        headerName: 'Serviço',
        flex: 4,
        minWidth: 350,
        editable: false,
        valueFormatter: (params) => {
          return params.value; 
        },
    },
    {
      field: 'valor',
      headerName: 'Valor',
      flex: 1,
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => {
        return formataDinheiro(params.value); 
      },
    },
    {
      field: 'faturado',
      headerName: 'Faturado',
      type: 'string',
      flex: 0,
      minWidth: 90,
      editable: false,
      renderCell: (params) => ( //renderiza componente
        <>
          { params.value === 1 ? <IconFaturado /> : '' }
        </>
      ),        
    },
     
  ];

  function nenhumaDespesaEncontrada(){
    return(
      <GridOverlay>
        <div className="container-no-data-grid">
          {
            permissoesdoUsuario[0]?.view ?
              <>
                <IconDespesas/>
                <p>Nenhuma Despesa encontrada</p>
              </>
            :

            <>
              <IconNoAuth/>
              <p style={{ marginTop: '-0px' }}>Você não possui autorização para visualizar as Despesas!</p>
            </>

          }
        </div>
      </GridOverlay>
    )
  }

  const handleDeleteDespesas = async()=>{
    
    Swal.fire({
      title: 'Deseja deletar a(s) Despesa(s) selecionada(s)?',
      text: "Ao deletar a(s) despesa(s) você afetará diretamente os lançamentos no financeiro.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0554C2',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim deletar',
      cancelButtonText: 'Cancelar',
    }).then(async(result) => {
      if (result.isConfirmed) {

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let despesasid = [];

        for await (let id of despesasSelecionadas) {
          let dados = { id: id }
          despesasid.push(dados);
        }
        
        await api.delete('deleteDespesasVeiculo', {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          },
          data: {  "despesasIds": despesasid }
        });

        await refreshDespesas();

        Swal.fire(
          'Deletado!',
          'Despesa(s) deletada(s) com sucesso!',
          'success'
        )
      }
    })

  }

  const handleDetalhesDespesa = async(props)=>{
    let id = props.row.id;
    let faturado = props.row.faturado;

    if(faturado === 1 ){
      Swal.fire(
        'Ops, modificação não permitida!',
        'Você não pode alterar uma despesa faturada!',
        'error'
      )
    }else{
      setidEditDespesa(id)
      setshowModaleditDespesa(true);
    }
  }

  const handleChangeStatus = async(event, value, reason, details)=>{
    
    if(value.props.value === 0){ //se for cancelado

      Swal.fire({
        title: 'Deseja cancelar a autorização?',
        text: "Ao cancelar a autorização de serviços você removerá os lançamentos das despesas do financeiro.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0554C2',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, continuar',
        cancelButtonText: 'Cancelar',
      }).then(async(result) => {
        if (result.isConfirmed) {
  
          let auth = localStorage.getItem("auth");
          auth = JSON.parse(auth);

          //CANCELAR AUTORIZAÇÃO SERVIÇO
           await api.post('cancelarAutorizacaoServico', {
            "codigo": codigoAutorizacao,
          }, 
          {
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
            }
          })

          refreshAutorizacoes();
          change(false);

  
          Swal.fire(
            'Autorização cancelada!',
            'Faturamentos removidos das finanças com sucesso!',
            'success'
          )
        }
      })

    }else{
      formik.setFieldValue('status', value.props.value);
    }


  }

  const handleGerarAutorizacaoServico = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);


    let dadosAutorizacao = await api.post(`gerarAutorizacaoServico`, {
      "compraId": compraId,
      "codigoAutorizacao": codigoAutorizacao,
    },
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    dadosAutorizacao = dadosAutorizacao.data;

    NativeFancybox.show(
      // Array containing gallery items
      [
        // Gallery item
        {
          src: `https://sistema.desdobrar.com/apps/gerarAutorizacaoServico.php?data=${window.btoa(JSON.stringify(dadosAutorizacao))}`,
          type: "iframe",
          preload: false,
        },
      ],
      // Gallery options
      {
        mainClass: "myFancybox",
      }
    );

  }

  return(
    <>

    <ModalNovaPessoa visible={showModalNovaPessoa} change={setshowModalNovaPessoa} atualizarPessoas={getPessoas}/> 
    <ModalDetalhesPessoa codigo={codigoDetalhesPessoa} visiblemodal={showModalDetalhesPessoa} changemodal={setshowModalDetalhesPessoa}/>

    <ModalNovaDespesa compraId={compraId} idAutorizacaoServico={detalhesautorizacaoservico.id} codigoAutorizacao={codigoAutorizacao} visible={showModalnovaDespesa} change={setshowModalnovaDespesa} refreshDespesas={refreshDespesas} />
    <ModalEditDespesa idDespesa={idEditDespesa} visible={showModaleditDespesa} change={setshowModaleditDespesa} refreshDespesas={refreshDespesas} />
    <ModalFaturarDespesas dadosCompra={dadosCompra} despesasSelecionadas={despesasSelecionadas} detalhesautorizacaoservico={detalhesautorizacaoservico} visible={showModalFaturarDespesas} change={setshowModalFaturarDespesas} refreshDespesas={refreshDespesas} />

    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='md' fullWidth={true} scroll='body' onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Detalhe autorização de serviços
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={2.5} style={{marginTop: '0px'}}>

                <Grid item xs={12} md={3} >
                  <FormControl sx={{ m: 0, width: '100%' }} >
                    <InputLabel id="label-totalparcelas">Status</InputLabel>
                    <Select
                      labelId="label-status"
                      id="cartao-simple-status"
                      name="status"
                      label="Status"
                      placeholder='Status'
                      value={formik.values.status}
                      onChange={handleChangeStatus}
                      error={formik.touched.status && Boolean(formik.errors.status)}
                    >
                      
                        <MenuItem value={1}>Em andamento</MenuItem>
                        <MenuItem disabled value={2}>Faturada</MenuItem>
                        <MenuItem value={0}>Cancelada</MenuItem>
                    
                    </Select>
                    <FormHelperText error>{formik.touched.status && formik.errors.status}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid container item xs={12} md={6}>
                    <Grid item xs={formik.values.fornecedor === 'null' ? 10.2 : 8.3} md={formik.values.fornecedor === 'null' ? 10.2 : 8.5} >
                        <FormControl sx={{ m: 0, width: '100%'}} >
                        <Autocomplete
                            disablePortal
                            id="combo-box-fornecedor"
                            options={pessoas}
                            value={JSON.parse(formik.values.fornecedor)}
                            getOptionLabel={(option) => option.nome}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={handleChangeFornecedor}
                            noOptionsText="Nenhum fornecedor encontrado."
                            sx={{ width: '100%' }}
                            disabled={!permissoesdoUsuario[3]?.update}
                            renderInput={(params) => <TextField {...params} label="Fornecedor"  error={formik.touched.fornecedor && Boolean(formik.errors.fornecedor)}/>}
                        />
                        <FormHelperText error>{formik.touched.fornecedor && formik.errors.fornecedor}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={1.8} md={1.8} >
                        <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovaPessoa(true) }}/>
                    </Grid>
                    
                    {
                      formik.values.fornecedor !== 'null' &&

                      <Grid item xs={1.3} md={1.3} >
                        <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalDetalhesPessoa(true) }}/>
                      </Grid>
                    }


                </Grid>

                <Grid item xs={12} md={3} >
                    <TextField
                    fullWidth
                    id="usuario"
                    name="usuario"
                    label="Usuário"
                    margin="none"
                    type="text"
                    value={formik.values.usuario}
                    onChange={formik.handleChange}
                    error={formik.touched.usuario && Boolean(formik.errors.usuario)}
                    helperText={formik.touched.usuario && formik.errors.usuario}
                    disabled
                    inputProps={{
                        maxLength: 13,
                    }}
                    />
                </Grid>

                <Grid item xs={12} md={12} >
                    <TextField
                        fullWidth
                        id="solicitacao"
                        name="solicitacao"
                        label="Solicitação dos serviços"
                        multiline
                        disabled={!permissoesdoUsuario[3]?.update}
                        rows={2}
                        value={formik.values.solicitacao}
                        onChange={formik.handleChange}
                        error={formik.touched.solicitacao && Boolean(formik.errors.solicitacao)}
                        helperText={formik.touched.solicitacao && formik.errors.solicitacao}
                    />
                </Grid>
                
                <Grid item xs={12} md={12} >
                  <DataGrid
                    sx={{ minHeight: '400px' }}
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    autoHeight
                    rows={despesas}
                    columns={columnsDataGridDespesas}
                    pageSize={pageSizeDataGridDespesas}
                    onPageSizeChange={(newPageSize) => setpageSizeDataGridDespesas(newPageSize)}
                    rowsPerPageOptions={[10, 50, 100]} 
                    checkboxSelection
                    disableSelectionOnClick
                    editMode='row'
                    loading={loadingDataGridDespesas}
                    disableColumnMenu={true}
                    onSelectionModelChange={(props)=>{
                        setdespesasSelecionadas(props);
                    }}
                    // onRowClick
                    onCellClick={(props)=>{
                        if(props.field !== "__check__"){
                          handleDetalhesDespesa(props)
                        }
                    }}
                    components={{
                        NoRowsOverlay: nenhumaDespesaEncontrada,
                    }}
                    />
                </Grid>

          </Grid>
        </DialogContent>

        <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px', marginLeft: '2px'}}>

            {/* <Grid item xs={4} md={3}>
                <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
            </Grid> */}
            <Grid container item spacing={1.5} xs={12} md={9}>
                <Grid item xs={5} md={4}>
                    <ButtonSecondary type="button" icon={<IconServicesCar />} onClick={()=>{ setshowModalnovaDespesa(true) }}  txt="Novo Serviço" />
                </Grid>

                {
                  despesasSelecionadas.length > 0 &&

                  <>
                    <Grid item xs={4} md={3}>
                        <ButtonSecondary type="button" icon={<IconFaturar />} onClick={()=>{ setshowModalFaturarDespesas(true) }}  txt="Faturar" />
                    </Grid>

                    <Grid item xs={4} md={3}>
                        <ButtonDanger type="button" icon={<IconLixeira />} onClick={()=>{ handleDeleteDespesas() }} txt="Deletar" />
                    </Grid>                  
                  </>
                }

                <Grid item xs={3} md={2} >
                    <ButtonSecondaryOnlyIcon type="button" icon={<IconPrint/>} onClick={()=>{ handleGerarAutorizacaoServico() }} />
                </Grid>
            </Grid>
            
            <Grid item xs={12} md={3}>
                <ButtonDefault type="submit" icon={<IconSalvar/>}  txt="Salvar" />
            </Grid>

        </Grid>
        </DialogActions>
        
        </form>
    </Dialog>
    </>
  );

}