import React, {useState, useRef, useEffect } from 'react';
import './style.css';

import api from '../../../services/api';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';
import ButtonSquadPrimaryForInput from '../../../componentes/ButtonSquadPrimaryForInput';

import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { TelMask, mtel } from '../../../vendor/mascaras';

export default function ModalNovaConversa({ user, openChatInitConversa, verificarOuAtualizarAtendenteChat, visible, change }) {

  const [loaderPage, setloaderPage] = useState(false);
  const [canais, setcanais] = useState([]); 
  const [modelosmensagens, setmodelosmensagens] = useState([]);

  const getCanais = async()=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemCanais`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arrayCanais = [];
    
    for await (let canal of response.data) {
        let dados = {
          id: canal.id,
          nome: canal.nome
        }    
        
        arrayCanais.push(dados);
    }

    setcanais(arrayCanais);
  }

  const getModelosMensagens = async()=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemModelosMensagens`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arrayModelos = [];
    
    for await (let modelo of response.data) {
        let dados = {
            id: modelo.id,
            nome: modelo.nome
        }    
        
        arrayModelos.push(dados);
    }

    setmodelosmensagens(arrayModelos);

  }

  useEffect(async()=>{
    if(visible){
      getCanais();
      getModelosMensagens();
    }

  },[visible]);

  const validationSchema = yup.object({
      numero: yup
      .string('Informe um número de WhatsApp')
      .required('WhatsApp Obrigatório')
      .test({
        message: () => "Informe um número de WhatsApp",
        test: async (values) => {
          if (values) { 
            if (values.length < 14) { return false; } else{ return true; }
          }
        },
      }),
  });

  const formik = useFormik({
    initialValues: {
      numero: '',
      canal: 'null',
      modelomensagem: 'null',
      mensagem: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setloaderPage(true);
  
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let numero = values.numero;
      numero = numero.replace(/[()\- ]/g, '');
      numero = `55${numero}`;

      let canal = JSON.parse(values.canal);

      await api.post(`whatsAppSendMessages`, {
          "phone": numero,
          "message": values.mensagem,
          "isGroup": false
      },
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      await verificarOuAtualizarAtendenteChat(`${numero}@c.us`);
      formik.resetForm();
      change(false);
      setloaderPage(false);

      openChatInitConversa(numero);

      setTimeout(async () => {
        await api.put(
          `updateCanalUltimaSessao`,
          {
            "chatId": `${numero}@c.us`,
            "canalId": canal?.id
          },
          {
            headers: {
              "Content-type": "application/json",
              "Authorization": `Bearer ${auth.token}`
            }
          }
        );
      }, 5000);

      // Swal.fire(
      //   'Envio concluído!',
      //   'Veículo enviado com sucesso!',
      //   'success'
      // )
      
    }
  });


  const handleChangeContawhatsapp = async(event, value, reason, details)=>{
    formik.setFieldValue('contawhatsapp', JSON.stringify(value));
  }

  const handleChangeVeiculo = async(event, value, reason, details)=>{
    formik.setFieldValue('veiculo', JSON.stringify(value));
  }

  const handleChangeCanal = async (event, value, reason, details) => {
    formik.setFieldValue('canal', JSON.stringify(value));
  };

  const handleChangeModelomensagem = async(event, value, reason, details)=>{
    if(value?.id){
        setloaderPage(true);

        formik.setFieldValue('modelomensagem', JSON.stringify(value));

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let response = await api.post('detalhesModeloMensagem', {
                'id': value.id
            }, 
            {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${auth.token}`
            }
        });


        let primeiroNomeUser = user.nome.split(" ")[0];

        let msg = response.data.mensagem;
        msg = await msg?.replaceAll("{{primeiro_nome_usuario}}", `${primeiroNomeUser}`);

        formik.setFieldValue('mensagem', msg);
        setloaderPage(false);
    }else{
      formik.setFieldValue('modelomensagem', 'null');
    }

  }

  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Iniciar conversa
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={1} >

            <Grid item xs={12} md={12} >
              <TextField
                fullWidth
                id="numero"
                name="numero"
                label="WhatsApp"
                margin="normal"
                type="text"
                value={formik.values.numero}
                onChange={formik.handleChange}
                error={formik.touched.numero && Boolean(formik.errors.numero)}
                helperText={formik.touched.numero && formik.errors.numero}
                onKeyUp={()=>{ formik.setFieldValue('numero', mtel(formik.values.numero)) }}
                inputProps={{
                  maxLength: 15,
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} >
              <Autocomplete
                  // disablePortal
                  id="combo-box-canal"
                  options={canais}
                  value={JSON.parse(formik.values.canal)}
                  getOptionLabel={(option) => option.nome}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={handleChangeCanal}
                  noOptionsText="Nenhum canal encontrado."
                  sx={{ width: '100%', marginTop: 1.3 }}
                  renderInput={(params) => <TextField {...params} label="Canal"  error={formik.touched.canal && Boolean(formik.errors.canal)}/>}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
                <FormControl sx={{ marginTop: 1.3, width: '100%' }} >
                <Autocomplete
                    disablePortal
                    id="combo-box-modelomsg"
                    options={modelosmensagens}
                    value={JSON.parse(formik.values.modelomensagem)}
                    getOptionLabel={(option) => option.nome}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={handleChangeModelomensagem}
                    noOptionsText="Nenhum Modelo encontrado."
                    sx={{ width: '100%' }}
                    // disabled={!permissoesdoUsuario[3]?.update}
                    renderInput={(params) => <TextField {...params} label="Modelo de mensagem"  error={formik.touched.modelomensagem && Boolean(formik.errors.modelomensagem)}/>}
                />
                <FormHelperText error>{formik.touched.modelomensagem && formik.errors.modelomensagem}</FormHelperText>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={12} >
              <TextField
                fullWidth
                id="mensagem"
                name="mensagem"
                label="Mensagem"
                margin="normal"
                type="text"
                multiline
                rows={5}
                value={formik.values.mensagem}
                onChange={formik.handleChange}
                error={formik.touched.mensagem && Boolean(formik.errors.mensagem)}
                helperText={formik.touched.mensagem && formik.errors.mensagem}
              />
            </Grid>
        
          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon="" txt="Iniciar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}