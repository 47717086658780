import React, {useState, useEffect, useRef} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';
import moment from 'moment';

import api from '../../services/api';

import { useHistory } from "react-router-dom";
import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';
import Swal from 'sweetalert2';

import ButtonSquadPrimary from '../../componentes/ButtonSquadPrimary';
import ButtonSquadSecondary from '../../componentes/ButtonSquadSecondary';
import ButtonSquadDanger from '../../componentes/ButtonSquadDanger';
import InputSearchActions from '../../componentes/InputSearchActions';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


import { formataDinheiroforBanco, formataDinheiro } from '../../vendor/formatar';
import ModalNovaLiberacao from './modals/modalNovaLiberacao';
import ModalEditLiberacao from './modals/modalEditLiberacao';

export default function LiberacoesVeiculo({dadosCompra}) {


  let IconPlusBtn = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 34 34">
      <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
  );

  let IconPDFbtn = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="27.758" viewBox="0 0 24.93 27.758">
      <g id="pdf" transform="translate(-26.077)">
        <g id="Grupo_19" data-name="Grupo 19" transform="translate(28.387 25.607)">
          <g id="Grupo_18" data-name="Grupo 18">
            <path id="Caminho_62" data-name="Caminho 62" d="M68.677,472.334a3.073,3.073,0,0,0,2.914,2.15H86.074a3.072,3.072,0,0,0,2.914-2.15Z" transform="translate(-68.677 -472.334)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_21" data-name="Grupo 21" transform="translate(43.912 0.508)">
          <g id="Grupo_20" data-name="Grupo 20">
            <path id="Caminho_63" data-name="Caminho 63" d="M359.085,13.324l-3.563-3.563a3.106,3.106,0,0,0-.475-.389v4.407a.182.182,0,0,0,.182.182h4.345A3.046,3.046,0,0,0,359.085,13.324Z" transform="translate(-355.047 -9.372)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_23" data-name="Grupo 23" transform="translate(28.238)">
          <g id="Grupo_22" data-name="Grupo 22">
            <path id="Caminho_64" data-name="Caminho 64" d="M86.543,6.724H81.791a1.811,1.811,0,0,1-1.809-1.808V0H69a3.066,3.066,0,0,0-3.063,3.063v8.354H86.543V6.724Z" transform="translate(-65.935)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_25" data-name="Grupo 25" transform="translate(37.827 16.638)">
          <g id="Grupo_24" data-name="Grupo 24">
            <path id="Caminho_65" data-name="Caminho 65" d="M243.562,306.9h-.744v3.836h.744q.752,0,.752-.836v-2.165Q244.314,306.9,243.562,306.9Z" transform="translate(-242.818 -306.896)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_27" data-name="Grupo 27" transform="translate(33.072 16.638)">
          <g id="Grupo_26" data-name="Grupo 26">
            <path id="Caminho_66" data-name="Caminho 66" d="M155.928,306.9H155.1v1.763h.827q.752,0,.752-.836v-.092Q156.681,306.9,155.928,306.9Z" transform="translate(-155.101 -306.896)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_29" data-name="Grupo 29" transform="translate(26.077 13.044)">
          <g id="Grupo_28" data-name="Grupo 28">
            <path id="Caminho_67" data-name="Caminho 67" d="M48.952,240.6H28.132a2.057,2.057,0,0,0-2.055,2.055v6.826a2.057,2.057,0,0,0,2.055,2.055h20.82a2.057,2.057,0,0,0,2.055-2.055V242.66A2.057,2.057,0,0,0,48.952,240.6Zm-13,4.421h0a1.806,1.806,0,0,1-.564,1.467,2.248,2.248,0,0,1-1.492.472h-.827v1.805a.325.325,0,0,1-.192.3,1.063,1.063,0,0,1-.919,0,.324.324,0,0,1-.192-.3v-5.316a.356.356,0,0,1,.134-.276.514.514,0,0,1,.351-.117h1.73a2.121,2.121,0,0,1,1.433.464,1.823,1.823,0,0,1,.539,1.458v.042Zm4.672,2.173a1.859,1.859,0,0,1-.56,1.5,2.232,2.232,0,0,1-1.5.476H37.092a.672.672,0,0,1-.41-.117.338.338,0,0,1-.159-.276v-5.324a.338.338,0,0,1,.159-.276.673.673,0,0,1,.41-.117h1.479a2.232,2.232,0,0,1,1.5.476,1.859,1.859,0,0,1,.56,1.5Zm4.584-3.168a.324.324,0,0,1-.288.167H42.759v1.412h1.262a.333.333,0,0,1,.288.15.615.615,0,0,1,.1.359.6.6,0,0,1-.1.343.33.33,0,0,1-.293.15H42.759v2.156a.325.325,0,0,1-.192.3,1.063,1.063,0,0,1-.919,0,.324.324,0,0,1-.192-.3v-5.307a.331.331,0,0,1,.167-.3.742.742,0,0,1,.4-.1h2.9a.322.322,0,0,1,.293.167.749.749,0,0,1,.1.393A.759.759,0,0,1,45.212,244.032Z" transform="translate(-26.077 -240.605)" fill="#fff"/>
          </g>
        </g>
      </g>
    </svg>
  );

  let IconCSVBtn = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21"  viewBox="0 0 22.974 27.355">
      <g id="csv" transform="translate(-41)">
        <path id="Caminho_199" data-name="Caminho 199" d="M41,465.107a2.407,2.407,0,0,0,2.4,2.4H59.432a2.407,2.407,0,0,0,2.4-2.4V465H41Z" transform="translate(0 -440.156)" fill="#fff"/>
        <path id="Caminho_200" data-name="Caminho 200" d="M55.729,13.927V8.787l-5.942,5.942h5.14A.8.8,0,0,0,55.729,13.927Z" transform="translate(-8.318 -8.318)" fill="#fff"/>
        <path id="Caminho_201" data-name="Caminho 201" d="M61.837,2.4a2.407,2.407,0,0,0-2.4-2.4H49.014V5.61a2.407,2.407,0,0,1-2.4,2.4H41v2.939H61.837Z" fill="#fff"/>
        <path id="Caminho_202" data-name="Caminho 202" d="M63.172,235H41v10.685H63.172a.8.8,0,0,0,.8-.8V235.8A.8.8,0,0,0,63.172,235Zm-13.636,7.306a2.35,2.35,0,0,1-1.858.842,2.8,2.8,0,1,1,1.569-5.13.668.668,0,0,1-.748,1.107,1.469,1.469,0,1,0-.821,2.688,1.059,1.059,0,0,0,.729-.255,1.048,1.048,0,0,0,.106-.109.668.668,0,0,1,1.023.858Zm5.093-.633a1.893,1.893,0,0,1-1.927,1.475,2.667,2.667,0,0,1-1.981-.851.668.668,0,1,1,.99-.9,1.348,1.348,0,0,0,.991.413.591.591,0,0,0,.612-.374c.032-.177-.224-.338-.473-.43-.666-.245-1.257-.509-1.282-.52a.671.671,0,0,1-.093-.05,1.489,1.489,0,0,1-.664-1.474,1.633,1.633,0,0,1,1.182-1.321,2.249,2.249,0,0,1,2.449.7.668.668,0,0,1-1.108.735,1.112,1.112,0,0,0-.956-.152.309.309,0,0,0-.245.232.161.161,0,0,0,.044.143c.167.073.635.273,1.135.458A1.732,1.732,0,0,1,54.63,241.673Zm5.437-3.249-1.4,4.2a.766.766,0,0,1-.72.523h-.005a.765.765,0,0,1-.72-.508l-1.463-4.215a.668.668,0,0,1,1.262-.438l.909,2.62L58.8,238a.668.668,0,0,1,1.268.421Z" transform="translate(0 -222.445)" fill="#fff"/>
      </g>
    </svg>
  );

  let IconLixeira = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 115.029 153.224">
      <g id="delete" transform="translate(-63.818 0)">
        <path id="Caminho_186" data-name="Caminho 186" d="M178.847,49.194H107.979l31.736-31.736a4.489,4.489,0,0,0,0-6.349l-8.042-8.042a10.475,10.475,0,0,0-14.813,0l-8.268,8.269L104.358,7.1a11.985,11.985,0,0,0-16.93,0L70.922,23.611a11.985,11.985,0,0,0,0,16.93l4.232,4.233-8.268,8.268a10.475,10.475,0,0,0,0,14.813L74.928,75.9a4.489,4.489,0,0,0,6.348,0L92.654,64.519v69.253a19.453,19.453,0,0,0,19.453,19.453h47.286a19.453,19.453,0,0,0,19.453-19.453ZM81.5,38.424l-4.232-4.232a3,3,0,0,1,0-4.233L93.777,13.453a3,3,0,0,1,4.232,0l4.232,4.233Zm38.764,89.661a4.489,4.489,0,1,1-8.978,0V74.334a4.489,4.489,0,1,1,8.978,0Zm19.973,0a4.489,4.489,0,0,1-8.978,0V74.334a4.489,4.489,0,0,1,8.978,0Zm19.973,0a4.489,4.489,0,1,1-8.978,0V74.334a4.489,4.489,0,1,1,8.978,0Z" fill="#fff"/>
        <path id="Caminho_187" data-name="Caminho 187" d="M309.244,93.149a7.5,7.5,0,0,0-4.977,6.237H347.34V91.925a7.486,7.486,0,0,0-9.882-7.087l-28.121,8.281c-.03.009-.063.021-.093.03Z" transform="translate(-168.493 -59.168)" fill="#fff"/>
      </g>
    </svg>
  )

  let IconNoAuth = props =>(
    <svg id="blocked" xmlns="http://www.w3.org/2000/svg" width="30"  viewBox="0 0 90.682 90.682">
      <path id="Caminho_196" data-name="Caminho 196" d="M60.455,34.535a8.537,8.537,0,0,0-8.5-8.086H49.119V18.892a18.892,18.892,0,0,0-37.784,0v7.557H8.5A8.514,8.514,0,0,0,0,34.95V67.067a8.514,8.514,0,0,0,8.5,8.5H35.4a32.46,32.46,0,0,1-1.4-9.446A32.072,32.072,0,0,1,60.455,34.535ZM18.892,26.449V18.892a11.335,11.335,0,1,1,22.67,0v7.557Z" fill="#4a4a4a"/>
      <path id="Caminho_197" data-name="Caminho 197" d="M35.56,11a24.56,24.56,0,1,0,24.56,24.56A24.588,24.588,0,0,0,35.56,11Zm0,7.557A16.839,16.839,0,0,1,44.575,21.2L21.2,44.575A16.931,16.931,0,0,1,35.56,18.557Zm0,34.006a16.839,16.839,0,0,1-9.015-2.645L49.921,26.544a16.839,16.839,0,0,1,2.645,9.015,17.029,17.029,0,0,1-17.007,17Z" transform="translate(30.563 30.563)" fill="#4a4a4a"/>
    </svg>
  )

  let IconCarServices = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 57.82 58.027">
        <g id="Layer_30" data-name="Layer 30" transform="translate(-3.16 -2.973)">
            <path id="Caminho_296" data-name="Caminho 296" d="M60,30.86H53.94a1,1,0,0,0-1,1v1.93H52l-1.06-5A6.46,6.46,0,0,0,44.64,24H36V10.93A2.91,2.91,0,0,0,33.06,8H26.27a2.89,2.89,0,0,0-2.85-2.55h-.53a3.47,3.47,0,0,0-6.65,0h-.52A2.89,2.89,0,0,0,12.86,8H6.07a2.91,2.91,0,0,0-2.91,2.91V46.68a2.91,2.91,0,0,0,2.91,2.91h.54v8.16A3.26,3.26,0,0,0,9.86,61H15.4a3.26,3.26,0,0,0,3.25-3.25v-1h26.7v1A3.26,3.26,0,0,0,48.6,61h5.54a3.26,3.26,0,0,0,3.25-3.25V50.01l-.51-5.76A7.16,7.16,0,0,0,53,38.46l-.56-2.67h.53v.9a1.016,1.016,0,0,0,1,1h.17l6-1a1,1,0,0,0,.84-1V31.86a1,1,0,0,0-.98-1ZM31.11,12.54V45.07H8V12.54ZM14.83,8.37a.9.9,0,0,1,.89-.9h1.36a1,1,0,0,0,1-1,1.5,1.5,0,0,1,3,0,.4.4,0,0,0,0,.18,1,1,0,0,0,1,.82h1.37a.9.9,0,0,1,.88.9v2.17h-9.5ZM5.16,46.68V10.93A.91.91,0,0,1,6.07,10h6.76v.52H7a1,1,0,0,0-1,1V46.07a1,1,0,0,0,1,1H32.11a1,1,0,0,0,1-1V11.54a1,1,0,0,0-1-1H26.3V10h6.76a.91.91,0,0,1,.91.91V46.68a.91.91,0,0,1-.91.91h-27a.91.91,0,0,1-.9-.91Zm20.53,8.09v-1.9H38.31v1.9ZM54.88,44.43l.51,5.69v7.63A1.25,1.25,0,0,1,54.14,59H48.6a1.25,1.25,0,0,1-1.25-1.25v-1h2.11a1,1,0,0,0,0-2H40.31v-2.9a1,1,0,0,0-1-1H24.69a1,1,0,0,0-1,1v2.9H14.54a1,1,0,0,0,0,2h2.11v1A1.25,1.25,0,0,1,15.4,59H9.86a1.25,1.25,0,0,1-1.25-1.25V49.59H33.06A2.91,2.91,0,0,0,36,46.68v-7H49.72a5.15,5.15,0,0,1,5.16,4.75ZM36,37.69V26h8.67A4.43,4.43,0,0,1,49,29.23l1.79,8.46Zm23-2.85-4.06.67V32.86H59Z" fill="#4a4a4a"/>
            <path id="Caminho_297" data-name="Caminho 297" d="M41.22,45.53a2.6,2.6,0,0,0,.14,2.38l.15.25a3.48,3.48,0,0,0,3,1.67H50.6a3.55,3.55,0,0,0,3.47-3.61,3.66,3.66,0,0,0-1.18-2.71,3.36,3.36,0,0,0-2.58-.89H50.2L43,44.1a2.43,2.43,0,0,0-1.78,1.43Zm1.83.8a.47.47,0,0,1,.32-.27l7.16-1.45a1.4,1.4,0,0,1,1,.37,1.67,1.67,0,0,1,.53,1.24,1.55,1.55,0,0,1-1.47,1.61H44.47a1.45,1.45,0,0,1-1.24-.7l-.15-.25A.59.59,0,0,1,43.05,46.33Z" fill="#4a4a4a"/>
            <path id="Caminho_298" data-name="Caminho 298" d="M10.09,20.14h4.22a1,1,0,0,0,1-1v-4a1,1,0,0,0-1-1H10.09a1,1,0,0,0-1,1v4A1,1,0,0,0,10.09,20.14Zm1-4h2.22v2H11.09Z" fill="#4a4a4a"/>
            <path id="Caminho_299" data-name="Caminho 299" d="M16.84,16.7h5.7a1,1,0,0,0,0-2h-5.7a1,1,0,0,0,0,2Z" fill="#4a4a4a"/>
            <path id="Caminho_300" data-name="Caminho 300" d="M16.84,19.26H29.41a1,1,0,0,0,0-2H16.84a1,1,0,0,0,0,2Z" fill="#4a4a4a"/>
            <path id="Caminho_301" data-name="Caminho 301" d="M10.09,28h4.22a1,1,0,0,0,1-1V23a1,1,0,0,0-1-1H10.09a1,1,0,0,0-1,1v4A1,1,0,0,0,10.09,28Zm1-4h2.22v2H11.09Z" fill="#4a4a4a"/>
            <path id="Caminho_302" data-name="Caminho 302" d="M16.84,24.55h5.7a1,1,0,0,0,0-2h-5.7a1,1,0,0,0,0,2Z" fill="#4a4a4a"/>
            <path id="Caminho_303" data-name="Caminho 303" d="M16.84,27.11H29.41a1,1,0,0,0,0-2H16.84a1,1,0,0,0,0,2Z" fill="#4a4a4a"/>
            <path id="Caminho_304" data-name="Caminho 304" d="M10.09,35.84h4.22a1,1,0,0,0,1-1v-4a1,1,0,0,0-1-1H10.09a1,1,0,0,0-1,1v4A1,1,0,0,0,10.09,35.84Zm1-4h2.22v2H11.09Z" fill="#4a4a4a"/>
            <path id="Caminho_305" data-name="Caminho 305" d="M16.84,32.4h5.7a1,1,0,0,0,0-2h-5.7a1,1,0,0,0,0,2Z" fill="#4a4a4a"/>
            <path id="Caminho_306" data-name="Caminho 306" d="M16.84,35H29.41a1,1,0,0,0,0-2H16.84a1,1,0,0,0,0,2Z" fill="#4a4a4a"/>
            <path id="Caminho_307" data-name="Caminho 307" d="M10.09,43.69h4.22a1,1,0,0,0,1-1v-4a1,1,0,0,0-1-1H10.09a1,1,0,0,0-1,1v4A1,1,0,0,0,10.09,43.69Zm1-4h2.22v2H11.09Z" fill="#4a4a4a"/>
            <path id="Caminho_308" data-name="Caminho 308" d="M16.84,40.25h5.7a1,1,0,1,0,0-2h-5.7a1,1,0,0,0,0,2Z" fill="#4a4a4a"/>
            <path id="Caminho_309" data-name="Caminho 309" d="M16.84,42.81H29.41a1,1,0,0,0,0-2H16.84a1,1,0,0,0,0,2Z" fill="#4a4a4a"/>
        </g>
    </svg>
  )
  
  let history = useHistory();

  const [liberacoes, setliberacoes] = useState([]);
  const [liberacoesSelecionadas, setliberacoesSelecionadas] = useState([]);
  const [pageSizeDataGridliberacoes, setpageSizeDataGridliberacoes] = useState(10);
  const [loadingDataGridliberacoes, setloadingDataGridliberacoes] = useState(true);

  const [filtroTermo, setfiltroTermo] = useState('');
  const [filtroStatus, setfiltroStatus] = useState('all');

  const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);

  const [showModalNovaLiberacao, setshowModalNovaLiberacao] = useState(false);
  const [showModalEditLiberacao, setshowModalEditLiberacao] = useState(false);
  const [codigoDetalhesAutorizacao, setcodigoDetalhesAutorizacao] = useState();
  

  const getliberacoes = async(termo='', status='')=>{
    setloadingDataGridliberacoes(true);

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemLiberacoes?compraid=${dadosCompra.id}&termo=${termo}&status=${status}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    !response.data.error_code && setliberacoes(response.data);
    setloadingDataGridliberacoes(false);
  }

  const permissoesUsuario = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post('permissoesUsuario', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setpermissoesdoUsuario(permissoes.data.compras);
  }


  useEffect(()=>{
    getliberacoes();
    permissoesUsuario();

    // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[]);

  const handleDetalhesLiberacao = async(props)=>{
    let codigo = props.row.codigo;
    setcodigoDetalhesAutorizacao(codigo);
    setshowModalEditLiberacao(true);
  }

  const handleDeleteLiberacao = async()=>{
    
    Swal.fire({
      title: 'Tem certeza?',
      text: "Deseja deletar as Liberações selecionadas?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0554C2',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim deletar',
      cancelButtonText: 'Cancelar',
    }).then(async(result) => {
      if (result.isConfirmed) {

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let liberacoesIds = [];

        for await (let id of liberacoesSelecionadas) {
          let dados = { id: id }
          liberacoesIds.push(dados);
        }
        
        await api.delete('deleteLiberacoes', {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          },
          data: {  "liberacoesIds": liberacoesIds }
        });

        getliberacoes();

        Swal.fire(
          'Deletado!',
          'Compra(s) deletada(s) com sucesso!',
          'success'
        )
      }
    })

  }

  const handleSearchLiberacao = async(event)=>{
    let termo = event.target.value;

    if(termo.length >= 3){
        setfiltroTermo(termo);
        getliberacoes(termo, filtroStatus);
    }else{
        getliberacoes();
    }
  }

  const handleFiltroStatus = async(props)=>{

    let status = props.target.value;
    setfiltroStatus(status);
    getliberacoes(filtroTermo, status);
    
  }


  const columnsDataGridliberacoes = [
    { field: 'id', hide: true, headerName: 'ID', width: 90  },
    {
        field: 'status',
        headerName: 'Status',
        flex: 0,
        minWidth: 80,
        editable: false,
        renderCell: (params) => { //renderiza componente
          
          switch (params.value) {
              case 0:
                      return <span className='dotStatus naodevolvido'></span>
                  break;
              case 1:
                      return <span className='dotStatus devolvido'></span>
                  break;
          }
      
        },
    },
    {
      field: 'codigo',
      headerName: 'Código',
      flex: 0,
      minWidth: 135,
      editable: false,
      valueFormatter: (params) => {
        return params.value; 
      },
    },
    {
      field: 'data',
      headerName: 'Data da liberação',
      flex: 1,
      minWidth: 160,
      editable: false,
      valueFormatter: (params) => {
        return moment(moment(params.value)).format('DD/MM/YYYY - HH:mm'); 
      },
    },
    {
        field: 'dataDevolucao',
        headerName: 'Data da devolução',
        flex: 1,
        minWidth: 160,
        editable: false,
        valueFormatter: (params) => {
           return params.value ? moment(moment(params.value)).format('DD/MM/YYYY - HH:mm') : ''; 
        },
    },
    {
        field: 'cliente',
        headerName: 'Pessoa',
        flex: 2,
        minWidth: 300,
        editable: false,
        valueFormatter: (params) => {
          return params.value; 
        },
    },
    {
        field: 'motivo',
        headerName: 'Motivo',
        flex: 2,
        minWidth: 300,
        editable: false,
        valueFormatter: (params) => {
          return params.value; 
        },
      },
    {
      field: 'usuario',
      headerName: 'Gerada por',
      flex: 2,
      minWidth: 300,
      editable: false,
      valueFormatter: (params) => {
        return params.value; 
      },
    },
    
  ];

  function nenhumaLiberacaoEncontratda(){
    return(
      <GridOverlay>
        <div className="container-no-data-grid">
          {
            permissoesdoUsuario[0]?.view ?
              <>
                <IconCarServices/>
                <p>Nenhuma Liberação encontrada</p>
              </>
            :

            <>
              <IconNoAuth/>
              <p style={{ marginTop: '-0px' }}>Você não possui autorização para visualizar as Liberações!</p>
            </>

          }
        </div>
      </GridOverlay>
    )
  }


  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcar - Estoque - Liberações do veículo</title>
        </Helmet>
      </HelmetProvider>

      <ModalEditLiberacao dadosCompra={dadosCompra} compraId={dadosCompra.id} codigoLiberacao={codigoDetalhesAutorizacao} visible={showModalEditLiberacao} change={setshowModalEditLiberacao} refreshliberacoes={getliberacoes} />
      <ModalNovaLiberacao compraId={dadosCompra.id} km={dadosCompra.km} visible={showModalNovaLiberacao} change={setshowModalNovaLiberacao} refreshLiberacoes={getliberacoes}/>

      <div>
        <div className='box-actions-estoque'>

            {
                permissoesdoUsuario[2]?.insert &&
                
                <ButtonSquadPrimary icon={<IconPlusBtn/>} onClick={()=>{ setshowModalNovaLiberacao(true) }} />
            }

            {
            liberacoesSelecionadas.length > 0 &&
            <>  

                {
                    permissoesdoUsuario[1].delete &&

                    <div style={{ 'marginLeft': '5px' }}>
                        <ButtonSquadDanger icon={<IconLixeira/>} onClick={()=>{ handleDeleteLiberacao() }} />
                    </div>
                }

            </>
            }

            <div>
              <FormControl sx={{ m: 0, width: '100%', marginLeft: '5px'}} >
                <InputLabel id="label-status">Status</InputLabel>
                <Select
                  labelId="label-status"
                  id="demo-simple-select"
                  name="status"
                  label="Status"
                  size="small"
                  placeholder='Status'
                  value={filtroStatus}
                  onChange={handleFiltroStatus}
                  style={{ height: '43px' }}
                >
                  <MenuItem value='all'>Todos</MenuItem>
                  <MenuItem value={0}>Não devolvido</MenuItem>
                  <MenuItem value={1}>Devolvido</MenuItem>
                </Select>
              </FormControl>
            </div>   

            <div style={{ 'marginLeft': '10px', 'width': '100%' }}>
                <InputSearchActions onChange={handleSearchLiberacao}  placeholder="Procure uma liberação" />
            </div>
        </div>

        <div className='box-legendas-status'>
            <article> <span className='naodevolvido'></span> <p>Não devolvido</p> </article> 
            <article> <span className='devolvido'></span> <p>Devolvido</p> </article> 
        </div>

        <div style={{ backgroundColor: '#fff', padding: '1px', borderRadius: '5px', borderLeft: 'solid', borderLeftWidth: '4px', borderColor: '#0554C2' }}>
            <p style={{ fontSize: '13px', color: '#757575', marginLeft: '17px'}}>Evite futuros problemas! Controle o histórico de liberações para teste Drive, empréstimos ou manutenções.</p>
        </div>

        <div className='container-data-grid'>
            <DataGrid
            sx={{ minHeight: '400px' }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            autoHeight
            rows={liberacoes}
            columns={columnsDataGridliberacoes}
            pageSize={pageSizeDataGridliberacoes}
            onPageSizeChange={(newPageSize) => setpageSizeDataGridliberacoes(newPageSize)}
            rowsPerPageOptions={[10, 50, 100]} 
            checkboxSelection
            disableSelectionOnClick
            editMode='row'
            loading={loadingDataGridliberacoes}
            disableColumnMenu={true}
            onSelectionModelChange={(props)=>{
                setliberacoesSelecionadas(props);
            }}
            // onRowClick
            onCellClick={(props)=>{
                if(props.field !== "__check__"){
                    handleDetalhesLiberacao(props)
                }
            }}
            components={{
                NoRowsOverlay: nenhumaLiberacaoEncontratda,
            }}
            />
        </div>
      </div>
    </>
    
  );
}