import React, {useContext, useState} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import Footer from '../../componentes/Footer';
import Compras from './compras';
import Vendas from './vendas';
import ComprasEVendas from './comprasEvendas';
import { GlobalContext } from '../../context/GlobalContext';

export default function Estoque() {

  const { whatsAppInfo } = useContext(GlobalContext);
  const [tabPage, settabPage] = useState('0');

  const handleChangeTabPage = (event, newValue) => {
    settabPage(newValue);
  };


  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcar - Estoque</title>
        </Helmet>
      </HelmetProvider>

      <div>
        <div className='container-page'>

          <div className={`${whatsAppInfo?.session ? 'container' : 'container-no-margin' }`}>
            <TabContext value={tabPage}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '10px' }}>
                <TabList onChange={handleChangeTabPage} aria-label="" textColor="primary" indicatorColor="primary">
                  <Tab label="Todos" value="0" />
                  <Tab label="Compras" value="1" />
                  <Tab label="Vendas" value="2" />
                  {/* <Tab label="Devoluções" value="3" /> */}
                </TabList>
              </Box>
              <TabPanel value="0">
                <ComprasEVendas/>
              </TabPanel>

              <TabPanel value="1">
                <Compras/>
              </TabPanel>

              <TabPanel value="2">
                <Vendas/>
              </TabPanel>

              <TabPanel value="3">
                
              </TabPanel>
            </TabContext>
          </div>

        </div>
        <Footer/>
      </div>
    </>
    
  );
}