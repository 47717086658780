import React, {useState, useEffect } from 'react';
import api from '../../../services/api';
import moment from 'moment';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Swal from 'sweetalert2';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';
import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonSecondary from '../../../componentes/ButtonSecondary';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';
import ButtonSquadPrimaryForInput from '../../../componentes/ButtonSquadPrimaryForInput';
import ButtonSecondaryOnlyIcon from '../../../componentes/ButtonSecondaryOnlyIcon';
import ButtonDanger from '../../../componentes/ButtonDanger';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import ModalNovaPessoa from '../../Cadastros/Pessoas/modals/modalNovaPessoa';
import ModalDetalhesPessoa from '../../Cadastros/Pessoas/modals/modalDetalhesPessoa';

import ModalGerarDevolucaoLiberacao from './modalGerarDevolucaoLiberacao';

import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

import { mnumero } from '../../../vendor/mascaras';

export default function ModalEditLiberacao({ dadosCompra, compraId, codigoLiberacao, visible, change, refreshliberacoes}) {


   let IconInfo = props =>(
    <svg id="information-button" xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 27.917 27.917">
        <path id="Caminho_118" data-name="Caminho 118" d="M13.958,0A13.959,13.959,0,1,0,27.917,13.958,13.959,13.959,0,0,0,13.958,0Zm2.906,21.633q-1.078.425-1.719.648a4.536,4.536,0,0,1-1.491.223,2.972,2.972,0,0,1-2.029-.637,2.055,2.055,0,0,1-.722-1.615,5.868,5.868,0,0,1,.053-.779c.037-.265.095-.562.174-.9l.9-3.176c.079-.3.148-.594.2-.864a3.83,3.83,0,0,0,.08-.748,1.152,1.152,0,0,0-.251-.847A1.423,1.423,0,0,0,11.1,12.7a2.521,2.521,0,0,0-.715.106c-.242.074-.453.142-.625.208L10,12.039q.883-.36,1.69-.616a4.992,4.992,0,0,1,1.524-.258,2.917,2.917,0,0,1,2,.626,2.081,2.081,0,0,1,.7,1.626q0,.207-.048.729a4.879,4.879,0,0,1-.18.958l-.895,3.167c-.073.254-.138.545-.2.87a4.6,4.6,0,0,0-.086.74,1.062,1.062,0,0,0,.282.86,1.581,1.581,0,0,0,.977.229,2.835,2.835,0,0,0,.74-.115,4.206,4.206,0,0,0,.6-.2ZM16.706,8.779A2.135,2.135,0,0,1,15.2,9.36a2.157,2.157,0,0,1-1.513-.581,1.856,1.856,0,0,1-.63-1.41,1.875,1.875,0,0,1,.63-1.413A2.142,2.142,0,0,1,15.2,5.368a2.118,2.118,0,0,1,1.507.587,1.9,1.9,0,0,1,0,2.823Z" fill="#fff"/>
    </svg>
   )

   let IconPrint = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 30.778 28.606">
        <g id="printing" transform="translate(0 -18.065)">
            <g id="Grupo_31" data-name="Grupo 31" transform="translate(9.917 41.014)">
            <g id="Grupo_30" data-name="Grupo 30">
                <path id="Caminho_68" data-name="Caminho 68" d="M174.894,399.834H166a1.026,1.026,0,1,0,0,2.052h8.891a1.026,1.026,0,0,0,0-2.052Z" transform="translate(-164.977 -399.834)" fill="#fff"/>
            </g>
            </g>
            <g id="Grupo_33" data-name="Grupo 33" transform="translate(9.917 37.778)">
            <g id="Grupo_32" data-name="Grupo 32">
                <path id="Caminho_69" data-name="Caminho 69" d="M174.894,346.006H166a1.026,1.026,0,1,0,0,2.052h8.891a1.026,1.026,0,0,0,0-2.052Z" transform="translate(-164.977 -346.006)" fill="#fff"/>
            </g>
            </g>
            <g id="Grupo_35" data-name="Grupo 35" transform="translate(0 18.065)">
            <g id="Grupo_34" data-name="Grupo 34">
                <path id="Caminho_70" data-name="Caminho 70" d="M28.384,25.029H25.119V19.091a1.026,1.026,0,0,0-1.026-1.026H6.684a1.026,1.026,0,0,0-1.026,1.026v5.938H2.394A2.4,2.4,0,0,0,0,27.422V37.744a2.4,2.4,0,0,0,2.394,2.394H5.658v5.507a1.026,1.026,0,0,0,1.026,1.026H24.093a1.026,1.026,0,0,0,1.026-1.026V40.138h3.264a2.4,2.4,0,0,0,2.394-2.394V27.422A2.4,2.4,0,0,0,28.384,25.029ZM7.71,20.117H23.068v4.912H7.71Zm15.358,24.5H7.71V36.225H23.067C23.067,36.48,23.067,44.426,23.067,44.619Zm1.026-13.566H21.482a1.026,1.026,0,1,1,0-2.052h2.611a1.026,1.026,0,0,1,0,2.052Z" transform="translate(0 -18.065)" fill="#fff"/>
            </g>
            </g>
        </g>
    </svg>

   )

   let IconPlus = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
      <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
   );

   let IconSalvar = props =>(
    <svg id="diskette" xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 57.091 57.091">
      <g id="Grupo_376" data-name="Grupo 376" transform="translate(16.837 47.056)">
        <g id="Grupo_375" data-name="Grupo 375">
          <path id="Caminho_292" data-name="Caminho 292" d="M172.744,422H152.673a1.673,1.673,0,1,0,0,3.345h20.071a1.673,1.673,0,1,0,0-3.345Z" transform="translate(-151 -422)" fill="#fff"/>
        </g>
      </g>
      <g id="Grupo_378" data-name="Grupo 378" transform="translate(16.837 33.675)">
        <g id="Grupo_377" data-name="Grupo 377">
          <path id="Caminho_293" data-name="Caminho 293" d="M172.744,302H152.673a1.673,1.673,0,0,0,0,3.345h20.071a1.673,1.673,0,0,0,0-3.345Z" transform="translate(-151 -302)" fill="#fff"/>
        </g>
      </g>
      <g id="Grupo_380" data-name="Grupo 380" transform="translate(16.837 40.365)">
        <g id="Grupo_379" data-name="Grupo 379">
          <path id="Caminho_294" data-name="Caminho 294" d="M172.744,362H152.673a1.673,1.673,0,1,0,0,3.345h20.071a1.673,1.673,0,1,0,0-3.345Z" transform="translate(-151 -362)" fill="#fff"/>
        </g>
      </g>
      <g id="Grupo_382" data-name="Grupo 382" transform="translate(13.492)">
        <g id="Grupo_381" data-name="Grupo 381">
          <rect id="Retângulo_64" data-name="Retângulo 64" width="24" height="14" transform="translate(-0.492 0.091)" fill="#fff"/>
        </g>
      </g>
      <g id="Grupo_384" data-name="Grupo 384">
        <g id="Grupo_383" data-name="Grupo 383">
          <path id="Caminho_295" data-name="Caminho 295" d="M56.6,9.41,47.681.49A1.673,1.673,0,0,0,46.5,0H40.254V16.168a1.673,1.673,0,0,1-1.673,1.673H11.82a1.673,1.673,0,0,1-1.673-1.673V0H1.673A1.673,1.673,0,0,0,0,1.673V55.419a1.673,1.673,0,0,0,1.673,1.673H55.419a1.673,1.673,0,0,0,1.673-1.673V10.593A1.673,1.673,0,0,0,56.6,9.41Zm-13,44.336H13.492V30.33H43.6Z" fill="#fff"/>
        </g>
      </g>
    </svg>
   )

   let IconDevolucao = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 36.715 39">
        <g id="car-key" transform="translate(-15)">
            <path id="Caminho_362" data-name="Caminho 362" d="M264.491,54.952l-1.827,2.571-2.33,3.279a1.147,1.147,0,0,1-.969.535h-3.236a1.143,1.143,0,0,1-.8-.323l-1.326-1.285-1.326,1.285a1.143,1.143,0,0,1-1.592,0l-1.326-1.285-1.326,1.285a1.143,1.143,0,0,1-.8.323h-2.114V53.2h17.995A1.151,1.151,0,0,1,264.491,54.952Z" transform="translate(-212.968 -49.149)" fill="#fff"/>
            <path id="Caminho_363" data-name="Caminho 363" d="M29.209.737,19.584.015A4.323,4.323,0,0,0,15,4.3V11.94a4.325,4.325,0,0,0,4.586,4.284l9.622-.722a1.144,1.144,0,0,0,1.064-1.14V1.877A1.144,1.144,0,0,0,29.209.737Zm-6.586,9.638a1.143,1.143,0,0,1-2.287,0V5.865a1.143,1.143,0,0,1,2.287,0Z" transform="translate(0 0)" fill="#fff"/>
            <g id="Grupo_401" data-name="Grupo 401" transform="translate(15.118 21.224)">
            <path id="Caminho_364" data-name="Caminho 364" d="M325.187,279.453l-4.362,4.349a4.707,4.707,0,0,0-2.488-1.4l3.28-3.04A2.446,2.446,0,0,1,325.187,279.453Z" transform="translate(-295.349 -278.632)" fill="#fff"/>
            <path id="Caminho_365" data-name="Caminho 365" d="M52.355,289.128a2.445,2.445,0,0,0-3.452.155L43.167,295a4.73,4.73,0,0,1-4.593,5.862h-5.1a1.142,1.142,0,1,1,0-2.285h5.1a2.442,2.442,0,1,0,0-4.884H32.952c-6.827-5.973-14.011-1.7-15.914-.361a1.14,1.14,0,0,0-.485.934v6.848a1.143,1.143,0,0,0,1.143,1.142H19.86a10.836,10.836,0,0,1,4.976,1.209,18.363,18.363,0,0,0,9.949,1.985,16.244,16.244,0,0,0,7.44-2.4A14.113,14.113,0,0,0,45,300.715l7.513-8.138A2.441,2.441,0,0,0,52.355,289.128Z" transform="translate(-16.553 -287.738)" fill="#fff"/>
            </g>
        </g>
    </svg>
   )


  const [loaderPage, setloaderPage] = useState(false);
  const [pessoas, setpessoas] = useState([]);
  const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);
  const [detalhesLiberacao, setdetalhesLiberacao] = useState([]);

  const [showModalNovaPessoa, setshowModalNovaPessoa] = useState(false);
  const [showModalDetalhesPessoa, setshowModalDetalhesPessoa] = useState(false);
  const [codigoDetalhesPessoa, setcodigoDetalhesPessoa] = useState();

  const [showModalGerarDevolucao, setshowModalGerarDevolucao] = useState(false);

  const permissoesUsuario = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post('permissoesUsuario', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setpermissoesdoUsuario(permissoes.data.compras);
  }


  const getPessoas = async(pessoaId=null, codigoPessoa=null)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemPessoas`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arrayPessoas = [];
    
    for await (let pessoa of response.data) {
        let dados = {
            codigo: pessoa.codigo,
            nome: pessoa.nome,
            id: pessoa.id
        }    
        
        arrayPessoas.push(dados);
    }

    setpessoas(arrayPessoas);

    if(codigoPessoa){
      //populando pessoa no select
      let pessoacode = arrayPessoas.find(x => x.codigo === codigoPessoa);
      pessoacode && formik.setFieldValue('pessoa', JSON.stringify(pessoacode));
      setcodigoDetalhesPessoa(codigoPessoa);
      // setshowModalDetalhesPessoa(true);
  
    }else if(pessoaId){
        //populando pessoa no select
        let pessoa = arrayPessoas.find(x => x.id === pessoaId);
        pessoa && formik.setFieldValue('pessoa', JSON.stringify(pessoa));
        setcodigoDetalhesPessoa(pessoa.codigo);
    }

    

  }


  const detalhesdaLiberacao = async()=>{

    setloaderPage(true);

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let responde = await api.post('detalhesLiberacao', {
        "codigo": codigoLiberacao
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let detalhes = responde.data;
    

    await getPessoas(detalhes.pessoa_id);
    await formik.setFieldValue('motivo', detalhes.motivo);
    await formik.setFieldValue('obs', detalhes.obs);
    await formik.setFieldValue('status', detalhes.status);
    await formik.setFieldValue('data', moment(detalhes.data).format('DD/MM/YYYY HH:mm'));
    await formik.setFieldValue('usuario', detalhes.usuario);
    detalhes.kmEntrega !== 'null' && await formik.setFieldValue('kmentrega', detalhes.kmEntrega);
    detalhes.kmDevolvido !== 'null' && await formik.setFieldValue('kmdevolvido', detalhes.kmDevolvido);
    detalhes.dataDevolucao !== 'null' && await formik.setFieldValue('datadevolucao', moment(detalhes.dataDevolucao).format('DD/MM/YYYY HH:mm'));

    setdetalhesLiberacao(detalhes);
    setloaderPage(false);

  }



  useEffect(()=>{
    if(visible){
      permissoesUsuario();
      codigoLiberacao && detalhesdaLiberacao();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[visible]);

  const validationSchema = yup.object({
    pessoa: yup
      .string('Selecione a Pessoa')
      .required('Pessoa Obrigatória')
      .test({
        message: () => "Selecione a Pessoa",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
      }),
      motivo: yup
      .string('Informe o motivo da liberação')
      .required('Motivo Obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      pessoa: 'null',
      motivo: '',
      obs: '',
      status: 0,
      usuario: '',
      kmentrega: '',
      kmdevolvido: '',
      datadevolucao: '',
      data: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      let pessoa = JSON.parse(values.pessoa);
        
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.put('updateLiberacao', {
          "codigo": codigoLiberacao,
          "pessoaId": pessoa.id,
          "motivo": values.motivo,
          "obs": values.obs
      }, 
      {
          headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
          }
      })

      refreshliberacoes();
      change(false);

    }
  });

  const handleChangePessoa = async(event, value, reason, details)=>{
    formik.setFieldValue('pessoa', JSON.stringify(value));
    setcodigoDetalhesPessoa(value.codigo);
  }

  const handleGerarLiberacao = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);


    let dadosLiberacao = await api.post(`gerarLiberacao`, {
      "codigo": codigoLiberacao,
    },
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    dadosLiberacao = dadosLiberacao.data;
    
    NativeFancybox.show(
      // Array containing gallery items
      [
        // Gallery item
        {
          src: `https://sistema.desdobrar.com/apps/gerarLiberacao.php?data=${window.btoa(JSON.stringify(dadosLiberacao))}`,
          type: "iframe",
          preload: false,
        },
      ],
      // Gallery options
      {
        mainClass: "myFancybox",
      }
    );

  }

  const closeModalDetalhes = ()=>{
    change(false)
  }

  return(
    <>

    <ModalNovaPessoa visible={showModalNovaPessoa} change={setshowModalNovaPessoa} atualizarPessoas={getPessoas}/> 
    <ModalDetalhesPessoa codigo={codigoDetalhesPessoa} visiblemodal={showModalDetalhesPessoa} changemodal={setshowModalDetalhesPessoa}/>

    <ModalGerarDevolucaoLiberacao codigoLiberacao={codigoLiberacao} kmEntrega={formik.values.kmentrega} visible={showModalGerarDevolucao} change={setshowModalGerarDevolucao} refreshliberacoes={refreshliberacoes} closeModalDetalhes={closeModalDetalhes}/>

    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} scroll='body' onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Detalhe liberação
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={2.5} style={{marginTop: '0px'}}>

                <Grid container item xs={12} md={12}>

                    <Grid item xs={formik.values.pessoa === 'null' || detalhesLiberacao?.status === 1 ? 10.2 : 8.3} md={formik.values.pessoa === 'null' || detalhesLiberacao?.status === 1 ? 10.6 : 9.3} >
                        <FormControl sx={{ m: 0, width: '100%'}} >
                        <Autocomplete
                            disablePortal
                            id="combo-box-pessoa"
                            options={pessoas}
                            value={JSON.parse(formik.values.pessoa)}
                            getOptionLabel={(option) => option.nome}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={handleChangePessoa}
                            noOptionsText="Nenhuma pessoa encontrada."
                            sx={{ width: '100%' }}
                            disabled={!permissoesdoUsuario[3]?.update || detalhesLiberacao?.status === 1 && true}
                            renderInput={(params) => <TextField {...params} label="Pessoa"  error={formik.touched.pessoa && Boolean(formik.errors.pessoa)}/>}
                        />
                        <FormHelperText error>{formik.touched.pessoa && formik.errors.pessoa}</FormHelperText>
                        </FormControl>
                    </Grid>

                    {
                        detalhesLiberacao?.status === 0  &&

                        <Grid item xs={1.8} md={1.3} >
                            <ButtonSquadPrimaryForInput icon={<IconPlus/>} onClick={()=>{ setshowModalNovaPessoa(true) }}/>
                        </Grid>
                    }
                    

                    {
                      formik.values.pessoa !== 'null' &&

                      <Grid item xs={1.3} md={1.3} >
                        <ButtonSquadPrimaryForInput icon={<IconInfo/>} onClick={()=>{ setshowModalDetalhesPessoa(true) }}/>
                      </Grid>
                    }

                </Grid>

                <Grid item xs={12} md={8} >
                    <TextField
                        fullWidth
                        id="motivo"
                        name="motivo"
                        label="Motivo da liberação"
                        multiline
                        disabled={!permissoesdoUsuario[3]?.update || detalhesLiberacao?.status === 1 && true}
                        rows={1}
                        value={formik.values.motivo}
                        onChange={formik.handleChange}
                        error={formik.touched.motivo && Boolean(formik.errors.motivo)}
                        helperText={formik.touched.motivo && formik.errors.motivo}
                    />
                </Grid>

                <Grid item xs={12} md={4} >
                    <TextField
                    fullWidth
                    id="kmentrega"
                    name="kmentrega"
                    label="Km da entrega"
                    placeholder=""
                    margin="none"
                    type="text"
                    value={formik.values.kmentrega}
                    onChange={formik.handleChange}
                    error={formik.touched.kmentrega && Boolean(formik.errors.kmentrega)}
                    helperText={formik.touched.kmentrega && formik.errors.kmentrega}
                    onKeyUp={()=>{ formik.setFieldValue('kmentrega', formik.values.kmentrega && mnumero(formik.values.kmentrega)) }}
                    disabled
                    inputProps={{
                        maxLength: 20,
                    }}
                    />
                </Grid>

                <Grid item xs={12} md={12} >
                    <TextField
                        fullWidth
                        id="obs"
                        name="obs"
                        label="Obs"
                        multiline
                        disabled={!permissoesdoUsuario[3]?.update || detalhesLiberacao?.status === 1 && true}
                        rows={3}
                        value={formik.values.obs}
                        onChange={formik.handleChange}
                        error={formik.touched.obs && Boolean(formik.errors.obs)}
                        helperText={formik.touched.obs && formik.errors.obs}
                    />
                </Grid>

                <Grid item xs={12} md={6} >
                  <FormControl sx={{ m: 0, width: '100%' }} >
                    <InputLabel id="label-totalparcelas">Status</InputLabel>
                    <Select
                      labelId="label-status"
                      id="cartao-simple-status"
                      name="status"
                      label="Status"
                      placeholder='Status'
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      error={formik.touched.status && Boolean(formik.errors.status)}
                      disabled
                    >
                      
                        <MenuItem value={0}>Não devolvido</MenuItem>
                        <MenuItem value={1}>Devolvido</MenuItem>
                    
                    </Select>
                    <FormHelperText error>{formik.touched.status && formik.errors.status}</FormHelperText>
                  </FormControl>
                </Grid>


                {
                    detalhesLiberacao?.status === 1 &&

                    <>
                    <Grid item xs={12} md={6} >
                        <TextField
                        fullWidth
                        id="km"
                        name="kmdevolvido"
                        label="KM Devolvido"
                        margin="none"
                        type="text"
                        value={formik.values.kmdevolvido}
                        onChange={formik.handleChange}
                        error={formik.touched.kmdevolvido && Boolean(formik.errors.kmdevolvido)}
                        helperText={formik.touched.kmdevolvido && formik.errors.kmdevolvido}
                        disabled
                        inputProps={{
                            maxLength: 13,
                        }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} >
                        <TextField
                        fullWidth
                        id="data"
                        name="data"
                        label="Data da liberação"
                        margin="none"
                        type="text"
                        value={formik.values.data}
                        onChange={formik.handleChange}
                        error={formik.touched.data && Boolean(formik.errors.data)}
                        helperText={formik.touched.data && formik.errors.data}
                        disabled
                        />
                    </Grid>

                    <Grid item xs={12} md={6} >
                        <TextField
                        fullWidth
                        id="datadevolucao"
                        name="datadevolucao"
                        label="Data da devolução"
                        margin="none"
                        type="text"
                        value={formik.values.datadevolucao}
                        onChange={formik.handleChange}
                        error={formik.touched.datadevolucao && Boolean(formik.errors.datadevolucao)}
                        helperText={formik.touched.datadevolucao && formik.errors.datadevolucao}
                        disabled
                        />
                    </Grid>

                    

                    </>

                }

                
                <Grid item xs={12} md={ detalhesLiberacao?.status === 0 ? 6 : 12} >
                    <TextField
                    fullWidth
                    id="usuario"
                    name="usuario"
                    label="Usuário"
                    margin="none"
                    type="text"
                    value={formik.values.usuario}
                    onChange={formik.handleChange}
                    error={formik.touched.usuario && Boolean(formik.errors.usuario)}
                    helperText={formik.touched.usuario && formik.errors.usuario}
                    disabled
                    inputProps={{
                        maxLength: 13,
                    }}
                    />
                </Grid>

          </Grid>
        </DialogContent>

        <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px', marginLeft: '2px'}}>

            {/* <Grid item xs={4} md={3}>
                <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
            </Grid> */}
            <Grid container item spacing={1.5} xs={12} md={9}>
                <Grid item xs={3} md={2} >
                    <ButtonSecondaryOnlyIcon type="button" icon={<IconPrint/>} onClick={()=>{ handleGerarLiberacao() }} />
                </Grid>

                {
                    detalhesLiberacao?.status === 0 &&

                    <Grid item xs={6} md={6}>
                        <ButtonSecondary type="button" icon={<IconDevolucao />} onClick={()=>{ setshowModalGerarDevolucao(true) }}  txt="Gerar devolução" />
                    </Grid>
                }
                
            </Grid>
            
            <Grid item xs={12} md={3}>
                <ButtonDefault type="submit" icon={<IconSalvar/>}  txt="Salvar" />
            </Grid>

        </Grid>
        </DialogActions>
        
        </form>
    </Dialog>
    </>
  );

}