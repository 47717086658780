import React, {useState, useEffect, useRef} from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer'; //CONFIGURE O WEBPACK EM \node_modules\react-scripts\webpack.config
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';
import moment from 'moment';

import api from '../../services/api';

import { useHistory } from "react-router-dom";
import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';
import Swal from 'sweetalert2';

import ButtonSquadDropDownPrimary from '../../componentes/ButtonSquadDropDownPrimary';
import ButtonSquadPrimary from '../../componentes/ButtonSquadPrimary';
import ButtonSquadSecondary from '../../componentes/ButtonSquadSecondary';
import ButtonSquadDanger from '../../componentes/ButtonSquadDanger';
import InputSearchActions from '../../componentes/InputSearchActions';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import DocumentPDFcompras from './documentPDFcompras';

import { formataDinheiroforBanco, formataDinheiro } from '../../vendor/formatar';

export default function ComprasEVendas() {


  let IconPlusBtn = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 34 34">
      <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
  );

  let IconPDFbtn = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="27.758" viewBox="0 0 24.93 27.758">
      <g id="pdf" transform="translate(-26.077)">
        <g id="Grupo_19" data-name="Grupo 19" transform="translate(28.387 25.607)">
          <g id="Grupo_18" data-name="Grupo 18">
            <path id="Caminho_62" data-name="Caminho 62" d="M68.677,472.334a3.073,3.073,0,0,0,2.914,2.15H86.074a3.072,3.072,0,0,0,2.914-2.15Z" transform="translate(-68.677 -472.334)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_21" data-name="Grupo 21" transform="translate(43.912 0.508)">
          <g id="Grupo_20" data-name="Grupo 20">
            <path id="Caminho_63" data-name="Caminho 63" d="M359.085,13.324l-3.563-3.563a3.106,3.106,0,0,0-.475-.389v4.407a.182.182,0,0,0,.182.182h4.345A3.046,3.046,0,0,0,359.085,13.324Z" transform="translate(-355.047 -9.372)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_23" data-name="Grupo 23" transform="translate(28.238)">
          <g id="Grupo_22" data-name="Grupo 22">
            <path id="Caminho_64" data-name="Caminho 64" d="M86.543,6.724H81.791a1.811,1.811,0,0,1-1.809-1.808V0H69a3.066,3.066,0,0,0-3.063,3.063v8.354H86.543V6.724Z" transform="translate(-65.935)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_25" data-name="Grupo 25" transform="translate(37.827 16.638)">
          <g id="Grupo_24" data-name="Grupo 24">
            <path id="Caminho_65" data-name="Caminho 65" d="M243.562,306.9h-.744v3.836h.744q.752,0,.752-.836v-2.165Q244.314,306.9,243.562,306.9Z" transform="translate(-242.818 -306.896)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_27" data-name="Grupo 27" transform="translate(33.072 16.638)">
          <g id="Grupo_26" data-name="Grupo 26">
            <path id="Caminho_66" data-name="Caminho 66" d="M155.928,306.9H155.1v1.763h.827q.752,0,.752-.836v-.092Q156.681,306.9,155.928,306.9Z" transform="translate(-155.101 -306.896)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_29" data-name="Grupo 29" transform="translate(26.077 13.044)">
          <g id="Grupo_28" data-name="Grupo 28">
            <path id="Caminho_67" data-name="Caminho 67" d="M48.952,240.6H28.132a2.057,2.057,0,0,0-2.055,2.055v6.826a2.057,2.057,0,0,0,2.055,2.055h20.82a2.057,2.057,0,0,0,2.055-2.055V242.66A2.057,2.057,0,0,0,48.952,240.6Zm-13,4.421h0a1.806,1.806,0,0,1-.564,1.467,2.248,2.248,0,0,1-1.492.472h-.827v1.805a.325.325,0,0,1-.192.3,1.063,1.063,0,0,1-.919,0,.324.324,0,0,1-.192-.3v-5.316a.356.356,0,0,1,.134-.276.514.514,0,0,1,.351-.117h1.73a2.121,2.121,0,0,1,1.433.464,1.823,1.823,0,0,1,.539,1.458v.042Zm4.672,2.173a1.859,1.859,0,0,1-.56,1.5,2.232,2.232,0,0,1-1.5.476H37.092a.672.672,0,0,1-.41-.117.338.338,0,0,1-.159-.276v-5.324a.338.338,0,0,1,.159-.276.673.673,0,0,1,.41-.117h1.479a2.232,2.232,0,0,1,1.5.476,1.859,1.859,0,0,1,.56,1.5Zm4.584-3.168a.324.324,0,0,1-.288.167H42.759v1.412h1.262a.333.333,0,0,1,.288.15.615.615,0,0,1,.1.359.6.6,0,0,1-.1.343.33.33,0,0,1-.293.15H42.759v2.156a.325.325,0,0,1-.192.3,1.063,1.063,0,0,1-.919,0,.324.324,0,0,1-.192-.3v-5.307a.331.331,0,0,1,.167-.3.742.742,0,0,1,.4-.1h2.9a.322.322,0,0,1,.293.167.749.749,0,0,1,.1.393A.759.759,0,0,1,45.212,244.032Z" transform="translate(-26.077 -240.605)" fill="#fff"/>
          </g>
        </g>
      </g>
    </svg>
  );

  let IconCSVBtn = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21"  viewBox="0 0 22.974 27.355">
      <g id="csv" transform="translate(-41)">
        <path id="Caminho_199" data-name="Caminho 199" d="M41,465.107a2.407,2.407,0,0,0,2.4,2.4H59.432a2.407,2.407,0,0,0,2.4-2.4V465H41Z" transform="translate(0 -440.156)" fill="#fff"/>
        <path id="Caminho_200" data-name="Caminho 200" d="M55.729,13.927V8.787l-5.942,5.942h5.14A.8.8,0,0,0,55.729,13.927Z" transform="translate(-8.318 -8.318)" fill="#fff"/>
        <path id="Caminho_201" data-name="Caminho 201" d="M61.837,2.4a2.407,2.407,0,0,0-2.4-2.4H49.014V5.61a2.407,2.407,0,0,1-2.4,2.4H41v2.939H61.837Z" fill="#fff"/>
        <path id="Caminho_202" data-name="Caminho 202" d="M63.172,235H41v10.685H63.172a.8.8,0,0,0,.8-.8V235.8A.8.8,0,0,0,63.172,235Zm-13.636,7.306a2.35,2.35,0,0,1-1.858.842,2.8,2.8,0,1,1,1.569-5.13.668.668,0,0,1-.748,1.107,1.469,1.469,0,1,0-.821,2.688,1.059,1.059,0,0,0,.729-.255,1.048,1.048,0,0,0,.106-.109.668.668,0,0,1,1.023.858Zm5.093-.633a1.893,1.893,0,0,1-1.927,1.475,2.667,2.667,0,0,1-1.981-.851.668.668,0,1,1,.99-.9,1.348,1.348,0,0,0,.991.413.591.591,0,0,0,.612-.374c.032-.177-.224-.338-.473-.43-.666-.245-1.257-.509-1.282-.52a.671.671,0,0,1-.093-.05,1.489,1.489,0,0,1-.664-1.474,1.633,1.633,0,0,1,1.182-1.321,2.249,2.249,0,0,1,2.449.7.668.668,0,0,1-1.108.735,1.112,1.112,0,0,0-.956-.152.309.309,0,0,0-.245.232.161.161,0,0,0,.044.143c.167.073.635.273,1.135.458A1.732,1.732,0,0,1,54.63,241.673Zm5.437-3.249-1.4,4.2a.766.766,0,0,1-.72.523h-.005a.765.765,0,0,1-.72-.508l-1.463-4.215a.668.668,0,0,1,1.262-.438l.909,2.62L58.8,238a.668.668,0,0,1,1.268.421Z" transform="translate(0 -222.445)" fill="#fff"/>
      </g>
    </svg>
  );

  let IconLixeira = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 115.029 153.224">
      <g id="delete" transform="translate(-63.818 0)">
        <path id="Caminho_186" data-name="Caminho 186" d="M178.847,49.194H107.979l31.736-31.736a4.489,4.489,0,0,0,0-6.349l-8.042-8.042a10.475,10.475,0,0,0-14.813,0l-8.268,8.269L104.358,7.1a11.985,11.985,0,0,0-16.93,0L70.922,23.611a11.985,11.985,0,0,0,0,16.93l4.232,4.233-8.268,8.268a10.475,10.475,0,0,0,0,14.813L74.928,75.9a4.489,4.489,0,0,0,6.348,0L92.654,64.519v69.253a19.453,19.453,0,0,0,19.453,19.453h47.286a19.453,19.453,0,0,0,19.453-19.453ZM81.5,38.424l-4.232-4.232a3,3,0,0,1,0-4.233L93.777,13.453a3,3,0,0,1,4.232,0l4.232,4.233Zm38.764,89.661a4.489,4.489,0,1,1-8.978,0V74.334a4.489,4.489,0,1,1,8.978,0Zm19.973,0a4.489,4.489,0,0,1-8.978,0V74.334a4.489,4.489,0,0,1,8.978,0Zm19.973,0a4.489,4.489,0,1,1-8.978,0V74.334a4.489,4.489,0,1,1,8.978,0Z" fill="#fff"/>
        <path id="Caminho_187" data-name="Caminho 187" d="M309.244,93.149a7.5,7.5,0,0,0-4.977,6.237H347.34V91.925a7.486,7.486,0,0,0-9.882-7.087l-28.121,8.281c-.03.009-.063.021-.093.03Z" transform="translate(-168.493 -59.168)" fill="#fff"/>
      </g>
    </svg>
  )

  let IconNoAuth = props =>(
    <svg id="blocked" xmlns="http://www.w3.org/2000/svg" width="30"  viewBox="0 0 90.682 90.682">
      <path id="Caminho_196" data-name="Caminho 196" d="M60.455,34.535a8.537,8.537,0,0,0-8.5-8.086H49.119V18.892a18.892,18.892,0,0,0-37.784,0v7.557H8.5A8.514,8.514,0,0,0,0,34.95V67.067a8.514,8.514,0,0,0,8.5,8.5H35.4a32.46,32.46,0,0,1-1.4-9.446A32.072,32.072,0,0,1,60.455,34.535ZM18.892,26.449V18.892a11.335,11.335,0,1,1,22.67,0v7.557Z" fill="#4a4a4a"/>
      <path id="Caminho_197" data-name="Caminho 197" d="M35.56,11a24.56,24.56,0,1,0,24.56,24.56A24.588,24.588,0,0,0,35.56,11Zm0,7.557A16.839,16.839,0,0,1,44.575,21.2L21.2,44.575A16.931,16.931,0,0,1,35.56,18.557Zm0,34.006a16.839,16.839,0,0,1-9.015-2.645L49.921,26.544a16.839,16.839,0,0,1,2.645,9.015,17.029,17.029,0,0,1-17.007,17Z" transform="translate(30.563 30.563)" fill="#4a4a4a"/>
    </svg>
  )

  let IconCarBarrie = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 143.871 143.838">
      <g id="barrier" transform="translate(0 -0.059)">
        <path id="Caminho_203" data-name="Caminho 203" d="M29.919,337.632a6.312,6.312,0,1,0,6.312-6.315A6.32,6.32,0,0,0,29.919,337.632Zm8.408,0a2.1,2.1,0,1,1-2.1-2.1A2.1,2.1,0,0,1,38.327,337.632Z" transform="translate(-21.511 -238.167)" fill="#4a4a4a"/>
        <path id="Caminho_204" data-name="Caminho 204" d="M105.088,18.434a10.9,10.9,0,0,0,0-15.225,10.6,10.6,0,0,0-15.076,0l-10.125,10.3a2.108,2.108,0,1,0,3.007,2.954l2.787-2.836V32.145L70.933,47.13V28.639l5.074-5.164A2.108,2.108,0,1,0,73,20.521L10.848,83.773c-.035.035-.066.073-.1.11A14.743,14.743,0,0,0,0,98.054v42.355A3.5,3.5,0,0,0,3.507,143.9H25.989a3.433,3.433,0,0,0,3.451-3.488V123.285a2.108,2.108,0,1,0-4.215,0v16.4H4.215V98.054a10.5,10.5,0,1,1,21.009,0v15.405a2.108,2.108,0,1,0,4.215,0V98.054a14.753,14.753,0,0,0-.221-2.525ZM33.008,85.667V67.235L47.755,52.227V70.682ZM89.9,9.342l3.122-3.177a6.36,6.36,0,0,1,9.066,0,6.67,6.67,0,0,1,0,9.313L89.9,27.862ZM66.718,51.414,51.971,66.4V47.937L66.718,32.929Zm-49.705,32.1L28.793,71.525V89.951L27.68,91.082a14.757,14.757,0,0,0-10.668-7.568Z" transform="translate(0 0)" fill="#4a4a4a"/>
        <path id="Caminho_205" data-name="Caminho 205" d="M265.36,271.043l-6.416-5.3c.175-.722-2.7-12.642-2.868-13.677a11.128,11.128,0,0,0-10.928-8.765h-44.67a11.221,11.221,0,0,0-9.112,4.689,2.108,2.108,0,1,0,3.43,2.451,7,7,0,0,1,5.682-2.925h11.231v4.568a4.121,4.121,0,0,0,4.284,3.925h14a4.121,4.121,0,0,0,4.284-3.925v-4.568h10.868a6.938,6.938,0,0,1,6.813,5.464l2.664,11.979a4.628,4.628,0,0,1-4.493,5.6H195.494a4.628,4.628,0,0,1-4.493-5.6l1.3-5.838a2.108,2.108,0,0,0-4.115-.915c-.235,1.278-1.547,6.315-1.5,7.536l-6.415,5.3a12.97,12.97,0,0,0-4.723,10.03V302.11a7.571,7.571,0,0,0,5.816,7.354v3.55a5.749,5.749,0,0,0,5.742,5.743h9.814a5.749,5.749,0,0,0,5.742-5.743v-3.344h24.57a2.108,2.108,0,1,0,0-4.215H183.1a3.348,3.348,0,0,1-3.344-3.344v-5.265h12.333a6.386,6.386,0,0,0,.891-12.71l-5.565-.784a54.988,54.988,0,0,0-7.644-.536h-.015c-.24-3.176.6-6.484,3.193-8.523l4.86-4.017a8.769,8.769,0,0,0,7.682,4.5h54.638a8.769,8.769,0,0,0,7.682-4.5l4.86,4.017a8.769,8.769,0,0,1,3.193,6.781v1.742h-.2a55.038,55.038,0,0,0-7.645.536l-5.564.784a6.386,6.386,0,0,0,.89,12.71h12.515v5.265a3.348,3.348,0,0,1-3.344,3.344h-25.46a2.108,2.108,0,1,0,0,4.215h5.906v3.344a5.749,5.749,0,0,0,5.743,5.743h9.814a5.749,5.749,0,0,0,5.743-5.743v-3.55a7.571,7.571,0,0,0,5.815-7.354V281.074a12.97,12.97,0,0,0-4.723-10.031Zm-35.295-19.256a.5.5,0,0,1-.069,0h-14a.5.5,0,0,1-.069,0v-4.273h14.14Zm-31.623,61.226a1.529,1.529,0,0,1-1.527,1.527H187.1a1.529,1.529,0,0,1-1.527-1.527v-3.344h12.868v3.344Zm-18.669-25.983c3.863-.075,8.77.739,12.621,1.278a2.171,2.171,0,0,1-.3,4.32H179.758v-5.6Zm78.752,27.51h-9.814a1.529,1.529,0,0,1-1.527-1.527v-3.344h12.868v3.344A1.528,1.528,0,0,1,258.525,314.541Zm-5.172-21.911a2.171,2.171,0,0,1-.3-4.32c3.945-.554,8.86-1.369,12.817-1.278v5.6Z" transform="translate(-126.212 -174.884)" fill="#4a4a4a"/>
        <path id="Caminho_206" data-name="Caminho 206" d="M314.4,398.429l1.179-7.815a3.035,3.035,0,0,0-2.983-3.466H282.539a3.035,3.035,0,0,0-2.982,3.466l1.179,7.814A3,3,0,0,0,283.719,401h27.7a3,3,0,0,0,2.982-2.566Zm-14.814-1.649v-5.417h3.755l-.511,5.417h-3.244Zm-7.459,0-.511-5.417h3.755v5.417Zm-8.192-5.417h3.447l.511,5.417h-3.14Zm26.451,5.417h-3.322l.511-5.417H311.2Z" transform="translate(-200.966 -278.308)" fill="#4a4a4a"/>
      </g>
    </svg>
  )
  
  let history = useHistory();

  const [compras, setcompras] = useState([]);
  const [comprasSelecionadas, setcomprasSelecionadas] = useState([]);
  const [pageSizeDataGridCompras, setpageSizeDataGridCompras] = useState(10);
  const [loadingDataGridCompras, setloadingDataGridCompras] = useState(true);

  const [filtroTermo, setfiltroTermo] = useState();
  const [filtroStatus, setfiltroStatus] = useState(1);
  const [filtroFinalPlaca, setfiltroFinalPlaca] = useState('all');
  const [filtroConsignado, setfiltroConsignado] = useState(false);
  const [filtroEstoqueLoja, setfiltroEstoqueLoja] = useState(false);

  const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);
  
  const getCompras = async(termo='', status=1, consignado='', finalPlaca='', filtroEstoqueLoja='')=>{
    setloadingDataGridCompras(true);

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemComprasVendas?termo=${termo}&status=${status}&consignado=${consignado}&finalPlaca=${finalPlaca}&filtroEstoqueLoja=${filtroEstoqueLoja}`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    !response.data.error_code && setcompras(response.data);
    setloadingDataGridCompras(false);
  }

  const permissoesUsuario = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post('permissoesUsuario', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setpermissoesdoUsuario(permissoes.data.compras);
  }


  useEffect(()=>{
    getCompras();
    permissoesUsuario();

    // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[]);

  const handleDetalhesCompra = async(props)=>{
    let codigoCompra = props.row.codigoCompra;
    let codigoVenda = props.row.codigoVenda;


    codigoCompra && history.push(`/estoque/detalhes/${codigoCompra}`);
    codigoCompra && codigoVenda && history.push(`/estoque/detalhes/${codigoCompra}/${codigoVenda}`);

  }

  const handleDeleteCompra = async()=>{
    
    Swal.fire({
      title: 'Tem certeza?',
      text: "Deseja deletar a(s) Compra(s) selecionada(s)?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0554C2',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim deletar',
      cancelButtonText: 'Cancelar',
    }).then(async(result) => {
      if (result.isConfirmed) {

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let comprasid = [];

        for await (let id of comprasSelecionadas) {
          let dados = { id: id }
          comprasid.push(dados);
        }
        
        await api.delete('deleteCompra', {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          },
          data: {  "comprasIds": comprasid }
        });

        getCompras();

        Swal.fire(
          'Deletado!',
          'Compra(s) deletada(s) com sucesso!',
          'success'
        )
      }
    })

  }

  const handleSearchCompra = async(event)=>{
    let termo = event.target.value;

    if(termo.length >= 3){
        setfiltroTermo(termo);
        getCompras(termo, filtroStatus, filtroConsignado, filtroFinalPlaca, filtroEstoqueLoja);
    }else{
        getCompras();
    }
  }

  const handleFiltroConsignado = async(props)=>{
    
    let consignado = props.target.checked;

    setfiltroEstoqueLoja(false);
    setfiltroConsignado(consignado);
    getCompras(filtroTermo, filtroStatus, consignado, filtroFinalPlaca, '');

  }

  const handleFiltroEstoqueLoja = async(props)=>{
    
    let estoqueloja = props.target.checked;

    setfiltroEstoqueLoja(estoqueloja);
    setfiltroConsignado(false);
    getCompras(filtroTermo, filtroStatus, '', filtroFinalPlaca, estoqueloja);

  }
  

  const handleFiltroStatus = async(props)=>{

    let status = props.target.value;
    setfiltroStatus(status);
    getCompras(filtroTermo, status, filtroConsignado, filtroFinalPlaca, filtroEstoqueLoja);
    
  }

  const handleFiltroFinalPlaca = async(props)=>{

    let finalPlaca = props.target.value;
    setfiltroFinalPlaca(finalPlaca);
    getCompras(filtroTermo, filtroStatus, filtroConsignado, finalPlaca, filtroEstoqueLoja);
    
  }

  const columnsDataGridCompras = [
    { field: 'id', hide: true, headerName: 'ID', width: 90  },
    {
      field: 'data',
      headerName: 'Data',
      flex: 0,
      minWidth: 110,
      editable: false,
      valueFormatter: (params) => {
        return moment(moment(params.value)).format('DD/MM/YYYY'); 
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0,
      minWidth: 20,
      editable: false,
      renderCell: (params) => { //renderiza componente
        
        if(params.row.tipo === 'compra'){
            switch (params.value) {
                case 0:
                        return <span className='dotStatus inativo'></span>
                    break;
                case 1:
                        return <span className='dotStatus ativo'></span>
                    break;
            }
        }else if(params.row.tipo === 'venda'){
            switch (params.value) {
                case 1:
                        return <span className='dotStatus pendente'></span>
                    break;
                case 2:
                        return <span className='dotStatus comunicadoVenda'></span>
                    break;
                case 3:
                        return <span className='dotStatus finalizado'></span>
                    break;
                case 4:
                      return <span className='dotStatus comunicadoVendaComObs'></span>
                  break;
                case 5:
                      return <span className='dotStatus finalizadoComObs'></span>
                  break;

            }
        }
      },
    },
    {
      field: 'consignado',
      headerName: 'Consignado',
      flex: 0,
      minWidth: 110,
      editable: false,
      renderCell: (params) => { //renderiza componente
        
        if(params.value === 1){
          return <p>Consignado</p>
        }else{
          return <p>__</p>;
        }
    
      },
    },
    {
        field: 'marca',
        headerName: 'Marca',
        flex: 1,
        minWidth: 100,
        editable: false,
        renderCell: (params) => {

          let codigoCompra = params.row.codigoCompra;
          let codigoVenda = params.row.codigoVenda;
          let link = '';

          if(codigoCompra && !codigoVenda ){
            link = `/estoque/detalhes/${codigoCompra}`;
          }else{
            link = `/estoque/detalhes/${codigoCompra}/${codigoVenda}`;
          }

          return <a className='link-table' href={link}>{params.value}</a>; 
        },
    },
    {
        field: 'modelo',
        headerName: 'Modelo',
        flex: 2,
        minWidth: 250,
        editable: false,
        renderCell: (params) => {

          let codigoCompra = params.row.codigoCompra;
          let codigoVenda = params.row.codigoVenda;
          let link = '';

          if(codigoCompra && !codigoVenda ){
            link = `/estoque/detalhes/${codigoCompra}`;
          }else{
            link = `/estoque/detalhes/${codigoCompra}/${codigoVenda}`;
          }

          return <a className='link-table' href={link}>{params.value}</a>; 
        },
    },
    {
        field: 'ano',
        headerName: 'Ano Mod.',
        flex: 1,
        minWidth: 80,
        editable: false,
        renderCell: (params) => {

          let codigoCompra = params.row.codigoCompra;
          let codigoVenda = params.row.codigoVenda;
          let link = '';

          if(codigoCompra && !codigoVenda ){
            link = `/estoque/detalhes/${codigoCompra}`;
          }else{
            link = `/estoque/detalhes/${codigoCompra}/${codigoVenda}`;
          }

          return <a className='link-table' href={link}>{params.value}</a>; 
        },
    },
    {
        field: 'anoFabricacao',
        headerName: 'Ano Fab.',
        flex: 1,
        minWidth: 80,
        editable: false,
        renderCell: (params) => {

          let codigoCompra = params.row.codigoCompra;
          let codigoVenda = params.row.codigoVenda;
          let link = '';

          if(codigoCompra && !codigoVenda ){
            link = `/estoque/detalhes/${codigoCompra}`;
          }else{
            link = `/estoque/detalhes/${codigoCompra}/${codigoVenda}`;
          }

          return <a className='link-table' href={link}>{params.value}</a>; 
        },
    },
    {
        field: 'placa',
        headerName: 'Placa',
        flex: 1,
        minWidth: 100,
        editable: false,
        renderCell: (params) => {

          let codigoCompra = params.row.codigoCompra;
          let codigoVenda = params.row.codigoVenda;
          let link = '';

          if(codigoCompra && !codigoVenda ){
            link = `/estoque/detalhes/${codigoCompra}`;
          }else{
            link = `/estoque/detalhes/${codigoCompra}/${codigoVenda}`;
          }

          return <a className='link-table' href={link}>{params.value}</a>; 
        },
    },
    {
      field: 'km',
      headerName: 'KM',
      flex: 1,
      minWidth: 90,
      editable: false,
      renderCell: (params) => {

        let codigoCompra = params.row.codigoCompra;
        let codigoVenda = params.row.codigoVenda;
        let link = '';

        if(codigoCompra && !codigoVenda ){
          link = `/estoque/detalhes/${codigoCompra}`;
        }else{
          link = `/estoque/detalhes/${codigoCompra}/${codigoVenda}`;
        }

        return <a className='link-table' href={link}>{params.value}</a>; 
      },
    },
    {
      field: 'precoVenda',
      headerName: 'Preço de venda',
      flex: 1,
      minWidth: 130,
      editable: false,
      valueFormatter: (params) => {
        return formataDinheiro(params.value); 
      },
    },
    {
        field: 'fornecedor',
        headerName: 'Fornecedor',
        flex: 2,
        minWidth: 200,
        editable: false,
        valueFormatter: (params) => {
          return params.value; 
        },
    },
    {
        field: 'cliente',
        headerName: 'Cliente',
        flex: 2,
        minWidth: 200,
        editable: false,
        valueFormatter: (params) => {
          return params.value; 
        },
    },
  ];

  function nenhumaCompraEncontratda(){
    return(
      <GridOverlay>
        <div className="container-no-data-grid">
          {
            permissoesdoUsuario[0]?.view ?
              <>
                <IconCarBarrie/>
                <p>Nenhuma compra/venda encontrada</p>
              </>
            :

            <>
              <IconNoAuth/>
              <p style={{ marginTop: '-0px' }}>Você não possui autorização para visualizar as compras!</p>
            </>

          }
        </div>
      </GridOverlay>
    )
  }


  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcar - Estoque</title>
        </Helmet>
      </HelmetProvider>

      <div>
        <div className='box-actions-estoque'>

            {
                permissoesdoUsuario[2]?.insert &&
                
                <ButtonSquadDropDownPrimary 
                  icon={<IconPlusBtn/>} 
                  submenus={
                    [
                      {title: "Nova Compra", push: "/estoque/compra/nova"},
                      {title: "Nova Venda", push: "/estoque/venda/nova"}
                    ]
                  }
                />
                // <ButtonSquadPrimary icon={<IconPlusBtn/>} onClick={()=>{ history.push(`/estoque/compra/nova`); }} />
            }

            {
            comprasSelecionadas.length > 0 &&
            <>  

                <div style={{ 'marginLeft': '5px' }}>
                  <PDFDownloadLink document={<DocumentPDFcompras comprasIds={comprasSelecionadas} />} fileName="estoque-de-veiculos.pdf">
                      <ButtonSquadSecondary icon={<IconPDFbtn/>} />
                  </PDFDownloadLink>
                </div>

                {
                    permissoesdoUsuario[1].delete &&

                    <div style={{ 'marginLeft': '5px' }}>
                        <ButtonSquadDanger icon={<IconLixeira/>} onClick={()=>{ handleDeleteCompra() }} />
                    </div>
                }

            </>
            }

            <div style={{ 'marginLeft': '10px' }}>
              <FormGroup>
                <FormControlLabel control={
                  <Switch
                    disabled={!permissoesdoUsuario[0]?.view}
                    checked={filtroConsignado}
                    onChange={handleFiltroConsignado}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } label="Consignados" />
              </FormGroup>
            </div>

            <div style={{ 'marginLeft': '10px' }}>
              <FormGroup>
                <FormControlLabel control={
                  <Switch
                    disabled={!permissoesdoUsuario[0]?.view}
                    checked={filtroEstoqueLoja}
                    onChange={handleFiltroEstoqueLoja}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                } label="Estoque" />
              </FormGroup>
            </div>

            <div>
              <FormControl sx={{ m: 0, width: '100%', marginLeft: '5px'}} >
                <InputLabel id="label-status">Status</InputLabel>
                <Select
                  labelId="label-status"
                  id="demo-simple-select"
                  name="status"
                  label="Status"
                  size="small"
                  placeholder='Status'
                  value={filtroStatus}
                  onChange={handleFiltroStatus}
                  style={{ height: '43px' }}
                >
                  <MenuItem value='all'>Todos</MenuItem>
                  <MenuItem value={1}>Ativos</MenuItem>
                  <MenuItem value={0}>Inativos</MenuItem>

                  {/* STATUS VENDA ADD + 1 PARA NAO CONFLITAR COM STATUS COMPRA */}
                  <MenuItem value={1+1}>Pendente</MenuItem>
                  <MenuItem value={4+1}>Comunicado Venda c/ OBS</MenuItem>
                  <MenuItem value={2+1}>Comunicado Venda</MenuItem>
                  <MenuItem value={5+1}>Finalizado c/ OBS</MenuItem>
                  <MenuItem value={3+1}>Finalizado</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div style={{ 'marginLeft': '5px', 'width': '150px' }}>
              <FormControl sx={{ m: 0, width: '100%', marginLeft: '5px'}} >
                <InputLabel id="label-final-placa">Final da Placa</InputLabel>
                <Select
                  labelId="label-final-placa"
                  id="select-final-placa"
                  name="finalplaca"
                  label="Final da Placa"
                  size="small"
                  placeholder='Final da Placa'
                  value={filtroFinalPlaca}
                  onChange={handleFiltroFinalPlaca}
                  style={{ height: '43px' }}
                >
                  <MenuItem value='all'>Todos</MenuItem>
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                </Select>
              </FormControl>
            </div>            

            <div style={{ 'marginLeft': '10px', 'width': '100%' }}>
                <InputSearchActions onChange={handleSearchCompra}  placeholder="Procure uma compra ou venda" />
            </div>
        </div>

        <div className='box-legendas-status'>
            <article> <span className='ativo'></span> <p>Ativo</p> </article> 
            <article> <span className='inativo'></span> <p>Inativo</p> </article> 
            <article> <span className='pendente'></span> <p>Pendente</p> </article> 
            <article> <span className='comunicadoVendaComObs'></span> <p>Comunicado venda c/ OBS </p> </article> 
            <article> <span className='comunicadoVenda'></span> <p>Comunicado venda</p> </article> 
            <article> <span className='finalizadoComObs'></span> <p>Finalizado c/ OBS</p> </article>
            <article> <span className='finalizado'></span> <p>Finalizado</p> </article>
        </div>

        <div className='container-data-grid'>
            <DataGrid
            sx={{ minHeight: '400px' }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            autoHeight
            rows={compras}
            columns={columnsDataGridCompras}
            pageSize={pageSizeDataGridCompras}
            onPageSizeChange={(newPageSize) => setpageSizeDataGridCompras(newPageSize)}
            rowsPerPageOptions={[10, 50, 100]} 
            checkboxSelection={true}
            disableSelectionOnClick
            editMode='row'
            loading={loadingDataGridCompras}
            disableColumnMenu={true}
            onSelectionModelChange={(props)=>{
                // setcomprasSelecionadas(props);
            }}
            // onRowClick
            onCellClick={(props)=>{
                if(props.field !== "__check__"){
                    handleDetalhesCompra(props)
                }
            }}
            components={{
                NoRowsOverlay: nenhumaCompraEncontratda,
            }}
            />
        </div>
      </div>
    </>
    
  );
}