import React, {useState, useEffect } from 'react';
import api from '../../../services/api';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';

import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { mnumero } from '../../../vendor/mascaras';

export default function ModalGerarDevolucaoLiberacao({ codigoLiberacao, kmEntrega, visible, change, refreshliberacoes, closeModalDetalhes}) {

  const [loaderPage, setloaderPage] = useState(false);
 

  const validationSchema = yup.object({
    km: yup
      .string('Informe o km atual do veículo')
      .required('km Obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
       km: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.put('gerarDevolucaoLiberacao', {
        "codigo": codigoLiberacao,
        "km": values.km,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

      formik.resetForm();
      refreshliberacoes();
      change(false);
      closeModalDetalhes();

    }
  });

  

  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='xs' fullWidth={true} scroll='body' onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Gerar devolução
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={2.5} style={{marginTop: '0px'}}>

                <Grid item xs={12} md={12} >
                  <div style={{ backgroundColor: '#f6f6f8', padding: '1px', borderRadius: '5px', borderLeft: 'solid', borderLeftWidth: '4px', borderColor: '#0554C2' }}>
                      <p style={{ fontSize: '13px', color: '#757575', marginLeft: '17px'}}>O KM na entrega foi: <b>{kmEntrega}</b></p>
                  </div>
                </Grid>

                <Grid item xs={12} md={12} >
                    <TextField
                    fullWidth
                    id="km"
                    name="km"
                    label="Km atual do veículo"
                    placeholder="Km recebido na devolução"
                    margin="none"
                    type="text"
                    value={formik.values.km}
                    onChange={formik.handleChange}
                    error={formik.touched.km && Boolean(formik.errors.km)}
                    helperText={formik.touched.km && formik.errors.km}
                    onKeyUp={()=>{ formik.setFieldValue('km', formik.values.km && mnumero(formik.values.km)) }}
                    inputProps={{
                        maxLength: 20,
                    }}
                    />
                </Grid>
                
          </Grid>
        </DialogContent>

        <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

            <Grid item xs={4} md={4}>
                <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
            </Grid>
            
            <Grid item xs={4} md={4}>
            <ButtonDefault type="submit" icon=""  txt="Finalizar" />
            </Grid>

        </Grid>
        </DialogActions>
        
        </form>
    </Dialog>
    </>
  );

}