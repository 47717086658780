import React, {useState, useEffect, useRef } from 'react';
import api from '../../../services/api';
import './style.css';

import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import 'moment/locale/pt-br';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import Swal from 'sweetalert2';


import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';
import ButtonSecondary from '../../../componentes/ButtonSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { Editor } from '@tinymce/tinymce-react';
import Fancybox from '../../../vendor/fancybox'; //https://fancyapps.com/docs/ui/fancybox/react

import { PDFViewer } from '@react-pdf/renderer';
import DocumentPDFcontrato from './DocumentPDFcontrato';

import extenso from 'extenso';
import { formataDinheiroforBanco, formataDinheiro, formataDinheironoRs } from '../../../vendor/formatar';


export default function ModalGerirContratoVenda({ vendaId, dadosVenda, contratoid, visible, change, refreshDetalhesCompraVenda}) {

  let IconReset = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="22" viewBox="0 0 89.136 82.632">
      <g id="Layer_x0020_1" transform="translate(0 -1.581)">
        <g id="_210397528" transform="translate(0 1.581)">
          <path id="_210398128" d="M6.5,42.9H0l12.644,13.78L25.288,42.9h-6.54a29.071,29.071,0,1,1,58.143,0H89.135A41.316,41.316,0,1,0,6.5,42.9Z" transform="translate(0 -1.581)" fill="#fff"/>
          <path id="_210397720" d="M27.151,45.654l3.387,11.779A41.4,41.4,0,0,0,58.984,28.685L47.173,25.43A29.059,29.059,0,0,1,27.151,45.654Z" transform="translate(28.678 23.607)" fill="#fff"/>
          <path id="_210396832" d="M16.722,29.491,6.508,36.255a41.261,41.261,0,0,0,34.438,18.48q.786,0,1.564-.029l-.463-12.233A29.06,29.06,0,0,1,16.722,29.491Z" transform="translate(6.874 27.897)" fill="#fff"/>
        </g>
      </g>
    </svg>
  )

  let IconSave = props =>(
    <svg id="diskette" xmlns="http://www.w3.org/2000/svg" width="22" viewBox="0 0 57.091 57.091">
      <g id="Grupo_376" data-name="Grupo 376" transform="translate(16.837 47.056)">
        <g id="Grupo_375" data-name="Grupo 375">
          <path id="Caminho_292" data-name="Caminho 292" d="M172.744,422H152.673a1.673,1.673,0,1,0,0,3.345h20.071a1.673,1.673,0,1,0,0-3.345Z" transform="translate(-151 -422)" fill="#fff"/>
        </g>
      </g>
      <g id="Grupo_378" data-name="Grupo 378" transform="translate(16.837 33.675)">
        <g id="Grupo_377" data-name="Grupo 377">
          <path id="Caminho_293" data-name="Caminho 293" d="M172.744,302H152.673a1.673,1.673,0,0,0,0,3.345h20.071a1.673,1.673,0,0,0,0-3.345Z" transform="translate(-151 -302)" fill="#fff"/>
        </g>
      </g>
      <g id="Grupo_380" data-name="Grupo 380" transform="translate(16.837 40.365)">
        <g id="Grupo_379" data-name="Grupo 379">
          <path id="Caminho_294" data-name="Caminho 294" d="M172.744,362H152.673a1.673,1.673,0,1,0,0,3.345h20.071a1.673,1.673,0,1,0,0-3.345Z" transform="translate(-151 -362)" fill="#fff"/>
        </g>
      </g>
      <g id="Grupo_382" data-name="Grupo 382" transform="translate(13.492)">
        <g id="Grupo_381" data-name="Grupo 381">
          <rect id="Retângulo_64" data-name="Retângulo 64" width="24" height="14" transform="translate(-0.492 0.091)" fill="#fff"/>
        </g>
      </g>
      <g id="Grupo_384" data-name="Grupo 384">
        <g id="Grupo_383" data-name="Grupo 383">
          <path id="Caminho_295" data-name="Caminho 295" d="M56.6,9.41,47.681.49A1.673,1.673,0,0,0,46.5,0H40.254V16.168a1.673,1.673,0,0,1-1.673,1.673H11.82a1.673,1.673,0,0,1-1.673-1.673V0H1.673A1.673,1.673,0,0,0,0,1.673V55.419a1.673,1.673,0,0,0,1.673,1.673H55.419a1.673,1.673,0,0,0,1.673-1.673V10.593A1.673,1.673,0,0,0,56.6,9.41Zm-13,44.336H13.492V30.33H43.6Z" fill="#fff"/>
        </g>
      </g>
    </svg>
  )

  let IconPdf = props =>(
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="22" viewBox="0 0 58.31 60">
      <g id="Layer_1" data-name="Layer 1">
        <g id="pdf">
          <path id="Caminho_285" data-name="Caminho 285" d="M39,60H11A11,11,0,0,1,0,49V11A11,11,0,0,1,11,0H35a3,3,0,0,1,2.12.88l12,12A3,3,0,0,1,50,15v4a3,3,0,0,1-3,3H31a3,3,0,0,1-3-3V6H11a5,5,0,0,0-5,5V49a5,5,0,0,0,5,5H39a3,3,0,0,1,0,6Z" fill="#fff"/>
          <path id="Caminho_286" data-name="Caminho 286" d="M33.84,49.5H29.52A2.5,2.5,0,0,1,27,47V28.81a2.5,2.5,0,0,1,2.5-2.5h4.32a8.51,8.51,0,0,1,8.5,8.5V41a8.51,8.51,0,0,1-8.48,8.5ZM32,44.5h1.82a3.5,3.5,0,0,0,3.5-3.5V34.81a3.5,3.5,0,0,0-3.5-3.5H32Z" fill="#fff"/>
          <path id="Caminho_287" data-name="Caminho 287" d="M13,49.5A2.5,2.5,0,0,1,10.5,47V28.81a2.5,2.5,0,0,1,2.5-2.5h4.48a7.05,7.05,0,0,1,0,14.1h-2V47A2.5,2.5,0,0,1,13,49.5Zm2.5-14.09h2a2.05,2.05,0,0,0,0-4.1h-2Z" fill="#fff"/>
          <path id="Caminho_288" data-name="Caminho 288" d="M47.88,49.5a2.5,2.5,0,0,1-2.5-2.5V32.12a5.82,5.82,0,0,1,5.81-5.81h4.62a2.5,2.5,0,1,1,0,5H51.19a.81.81,0,0,0-.81.81V47a2.49,2.49,0,0,1-2.5,2.5Z" fill="#fff"/>
          <path id="Caminho_289" data-name="Caminho 289" d="M55.81,39.41H47.88a2.5,2.5,0,0,1,0-5h7.93a2.5,2.5,0,1,1,0,5Z" fill="#fff"/>
        </g>
      </g>
    </svg>
  )

  const editorRef = useRef(null);

  const [loaderPage, setloaderPage] = useState(false);
  const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);
  const [detalhescontrato, setdetalhescontrato] = useState([]);

  const permissoesUsuario = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post('permissoesUsuario', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setpermissoesdoUsuario(permissoes.data.contratos);
  }



  const detalhesContrato = async(contrato_id, reset=false)=>{

    setloaderPage(true);

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.post('detalhesContrato', {
        "id": contrato_id
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });


    //ENDEREÇOS
    let enderecosPessoa = await api.post('listagemEnderecosPesssoa', {
      "pessoaId": dadosVenda.clienteId,
      // "principal": true,
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    })
    
    enderecosPessoa = enderecosPessoa.data;

    //TELEFONES
    let telefonesPessoa = await api.post('listagemTelefonesPesssoa', {
      "pessoaId": dadosVenda.clienteId,
      // "principal": true,
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    })

    telefonesPessoa = telefonesPessoa.data;
    
    //DETALHES PESSOA
    let detalhesPessoa = await api.post('detalhesPessoa', {
        "id": dadosVenda.clienteId
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    detalhesPessoa = detalhesPessoa.data;


    let pagamentos = await api.post(`listagemPagamentosVenda`, {
      "vendaId": vendaId
    },{
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    pagamentos = pagamentos.data;
    let listaPagamentos = '';
    let numeroInciso = 1;

    function convertToRoman(num) {
      var roman = {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1
      };
      var str = '';
    
      for (var i of Object.keys(roman)) {
        var q = Math.floor(num / roman[i]);
        num -= q * roman[i];
        str += i.repeat(q);
      }
    
      return str;
    }

    let automoveisTroca = '';

    for await (let v of pagamentos) {

      let valor = Math.sign(Number(v.valor)) === -1 ? Number(v.valor) * -1 : Number(v.valor); //transformando o valor nevativo em positivo
      let valorporestenso = extenso(formataDinheironoRs(valor), { mode: 'currency', currency: { type: 'BRL' } });

      numeroInciso ++;

      switch (v.formaPagamento) {
        
        case 'Dinheiro':
          listaPagamentos += `<p>${convertToRoman(numeroInciso)} - <b>${formataDinheiro(valor)}</b> (${valorporestenso}), pago à vista no ato da assinatura deste contrato no <b>Dinheiro em espécie.</b></p>`;
          break;

        case 'Cartão de Debito':
          listaPagamentos += `<p>${convertToRoman(numeroInciso)} - <b>${formataDinheiro(valor)}</b> (${valorporestenso}), pago com <b>Cartão de débito</b>.</p>`;
          break;

        case 'Transferência Doc':
          listaPagamentos += `<p>${convertToRoman(numeroInciso)} - <b>${formataDinheiro(valor)}</b> (${valorporestenso}), pago à vista no ato da assinatura deste contrato via <b>DOC</b>.</p>`;
          break;

        case 'Transferência Pix':
          listaPagamentos += `<p>${convertToRoman(numeroInciso)} - <b>${formataDinheiro(valor)}</b> (${valorporestenso}), pago à vista no ato da assinatura deste contrato via <b>PIX</b>. Para o Banco de destino: <b>${v.lancamento}</b></p>`;
          break;

        case 'Transferência Ted':
          listaPagamentos += `<p>${convertToRoman(numeroInciso)} - <b>${formataDinheiro(valor)}</b> (${valorporestenso}), pago à vista no ato da assinatura deste contrato via <b>TED</b>.</p>`;
          break;

        case 'Cheque':
          listaPagamentos += `<p>${convertToRoman(numeroInciso)} - <b>${formataDinheiro(valor)}</b> (${valorporestenso}), pago via <b>Cheque</b> com vencimento para <b>${moment(v.vencimento).format('DD/MM/YYYY')}</b> - <b>Emitente:</b> ${v.emitente} | <b>Banco:</b> ${v.banco} | <b>Agência:</b> ${v.agencia} | <b>Conta:</b> ${v.conta}.</p>`;
          break;

        case 'A prazo':
          listaPagamentos += `<p>${convertToRoman(numeroInciso)} - <b>${formataDinheiro(valor)}</b> (${valorporestenso}), pago <b>a Prazo</b>${v.totalParcelas > 1 ? ` dividido em ${v.totalParcelas} parcelas` : `` }, com <b>vencimento para: ${moment(v.vencimento).format('DD/MM/YYYY')}</b>.</p>`;
          break;

        case 'Boleto':
          listaPagamentos += `<p>${convertToRoman(numeroInciso)} - <b>${formataDinheiro(valor)}</b> (${valorporestenso}), pago via <b>Boleto</b> com <b>vencimento para: ${moment(v.vencimento).format('DD/MM/YYYY')}</b>.</p>`;
          break;

        case 'Cartão de Crédito':

          let valorParcela = valor / v.totalParcelas;

          listaPagamentos += `<p>${convertToRoman(numeroInciso)} - <b>${formataDinheiro(valor)}</b> (${valorporestenso}), pago via <b>Cartão de crédito</b> em <b>${v.totalParcelas}x</b> de <b>${formataDinheiro(valorParcela)}</b>.</p>`;
          break;

        case 'Financiamento':
            let valorParcelaf = (v.valorParcela ? v.valorParcela : 0);
            listaPagamentos += `<p>${convertToRoman(numeroInciso)} - <b>${formataDinheiro(valor)}</b> (${valorporestenso}) 
            através de financiamento onde o veículo, objeto deste termo, será alienado ao banco <b>${v.financeira}</b>, ficando o comprador responsável pelo pagamento de <b>${v.totalParcelas}</b> parcelas no valor de 
            <b>${formataDinheiro(valorParcelaf)}</b> (${extenso(formataDinheironoRs(valorParcelaf), { mode: 'currency', currency: { type: 'BRL' } })}) 
            com vencimento todo dia <b>${moment(v.vencimento).format('DD')}</b> de cada mês, com início no mês de <b>${moment(v.vencimento).format('MMMM')}</b> 
            de <b>${moment(v.vencimento).format('YYYY')}</b> conforme ajustado na proposta do vendedor e no contrato de financiamento. </p>`;

            break;
        
        case 'Nota Promissória':

            if(v.parcela === 1){ 
              let valorParcelanota = v.valorTotal / v.totalParcelas;
              let valorporestensonotap = extenso(formataDinheironoRs(v.valorTotal), { mode: 'currency', currency: { type: 'BRL' } });
              listaPagamentos += `<p>${convertToRoman(numeroInciso)} - <b>${formataDinheiro(v.valorTotal)}</b> (${valorporestensonotap}),</b> referente a <b>${v.totalParcelas} (${extenso(v.totalParcelas, { number: { gender: 'f' } })})</b> Notas promissórias no valor de <b>${formataDinheiro(valorParcelanota)}</b> (${extenso(formataDinheironoRs(valorParcelanota), { mode: 'currency', currency: { type: 'BRL' } })}) CADA,
              com vencimento para todo dia <b>${moment(v.dataPrimeiroVencimento).format('DD')}</b> de cada mês, a partir de <b>${moment(v.dataPrimeiroVencimento).format('MMMM')}</b> de <b>${moment(v.dataPrimeiroVencimento).format('YYYY')}</b>, e as demais nos meses subsequentes. </p>`;
            }
            // listaPagamentos += `<p>${convertToRoman(numeroInciso)} - <b>${formataDinheiro(valor)}</b> (${valorporestenso}), pago via <b>Nota Promissória</b> em <b>${v.totalParcelas}x</b> de <b>${formataDinheiro(valorParcelanota)}</b>, com intervalo de pagamento de <b>${v.periododasParcelas} dia(s)</b> e <b>1º vencimento para: ${moment(v.vencimento).format('DD/MM/YYYY')}</b>.</p>`;
            break;

        case 'Veículo em troca':
            automoveisTroca += v.lancamento+'<br><br>';
            listaPagamentos += `<p>${convertToRoman(numeroInciso)} - O <b>COMPRADOR</b> entregará ao <b>VENDEDOR</b> no ato da assinatura deste contrato o seguinte veiculo: (<b>${v.lancamento}</b>) de sua propriedade pelo valor de <b>${formataDinheiro(valor)}</b> (${valorporestenso}), veículo este que está com a documentação de <b>${moment().format('YYYY')}</b> toda paga e sem multas até o momento.</p>`;
            listaPagamentos += `<p>Por opção própria, o <b>VENDEDOR</b> poderá concluir a negociação adquirindo o veículo do <b>COMPRADOR</b>, desde que o mesmo aceite a desvalorização do veículo mencionado em ate 30% (trinta por cento) do valor mencionado no inciso I desta cláusula.</p>`;
            break;
      }


    }

    moment.locale('pt-br');


    let contrato = await response?.data?.contrato;
    contrato = await contrato?.replaceAll("{{nome_pessoa}}", `<b>${detalhesPessoa?.nome}</b>`);
    contrato = await contrato?.replaceAll("{{data_nascimento_pessoa}}", `<b>${moment(detalhesPessoa?.nascimento).format('DD/MM/YYYY')}</b>`);
    contrato = await contrato?.replaceAll("{{nacionalidade_pessoa}}", `<b>${detalhesPessoa?.nacionalidade}</b>`);
    contrato = await contrato?.replaceAll("{{email_pessoa}}", `<b>${detalhesPessoa?.email}</b>`);
    contrato = await contrato?.replaceAll("{{cpf_pessoa}}", `${detalhesPessoa?.cpf ? `CPF: <b>${detalhesPessoa?.cpf}</b>` : '' }`);
    contrato = await contrato?.replaceAll("{{rg_pessoa}}", `${detalhesPessoa?.rg ? `RG: <b>${detalhesPessoa?.rg}</b>` : '' }`);
    contrato = await contrato?.replaceAll("{{nome_pai_pessoa}}", `${detalhesPessoa?.nomePai ? `Nome do pai: <b>${detalhesPessoa?.nomePai}</b>` : '' }`);
    contrato = await contrato?.replaceAll("{{nome_mae_pessoa}}", `${detalhesPessoa?.nomeMae ? `Nome da mãe: <b>${detalhesPessoa?.nomeMae}</b>` : '' }`);
    contrato = await contrato?.replaceAll("{{cpf_cnpj_pessoa}}", `<b>${ detalhesPessoa?.tipo === 1 ? detalhesPessoa?.cpf : detalhesPessoa?.cnpj}</b>`);

    contrato = await contrato?.replaceAll("{{representante_pessoa}}", `${detalhesPessoa?.representante ? `Representante: <b>${detalhesPessoa?.representante}</b>` : '' }`);
    contrato = await contrato?.replaceAll("{{rasao_social_pessoa}}", `<b>${detalhesPessoa?.nome}</b>`);
    contrato = await contrato?.replaceAll("{{ins_estadual_pessoa}}", `${detalhesPessoa?.inscricaoestadual ? `Inscrição estadual: <b>${detalhesPessoa?.inscricaoestadual}</b>` : '' }`);
    contrato = await contrato?.replaceAll("{{ins_municipal_pessoa}}", `${detalhesPessoa?.inscricaomunicipal ? `Inscrição municipal: <b>${detalhesPessoa?.inscricaomunicipal}</b>` : '' }`);
    contrato = await contrato?.replaceAll("{{cnpj_pessoa}}", `${detalhesPessoa?.cnpj ? `CNPJ: <b>${detalhesPessoa?.cnpj}</b>` : '' }`);

    contrato = await contrato?.replaceAll("{{cep_pessoa}}", `<b>${enderecosPessoa[0]?.cep ? enderecosPessoa[0]?.cep : ''}</b>`);
    contrato = await contrato?.replaceAll("{{rua_pessoa}}", `<b>${enderecosPessoa[0]?.rua ? enderecosPessoa[0]?.rua : ''}</b>`);
    contrato = await contrato?.replaceAll("{{numero_pessoa}}", `<b>${enderecosPessoa[0]?.numero ? enderecosPessoa[0]?.numero : ''}</b>`);
    contrato = await contrato?.replaceAll("{{bairro_pessoa}}", `<b>${enderecosPessoa[0]?.bairro ? enderecosPessoa[0]?.bairro : ''}</b>`);
    contrato = await contrato?.replaceAll("{{cidade_pessoa}}", `<b>${enderecosPessoa[0]?.cidade ? enderecosPessoa[0]?.cidade : ''}</b>`);
    contrato = await contrato?.replaceAll("{{estado_pessoa}}", `<b>${enderecosPessoa[0]?.estado ? enderecosPessoa[0]?.estado : ''}</b>`);
    contrato = await contrato?.replaceAll("{{complemento_pessoa}}", `<b>${enderecosPessoa[0]?.complemento ? enderecosPessoa[0]?.complemento : ''}</b>`);
    contrato = await contrato?.replaceAll("{{referencia_pessoa}}", `<b>${enderecosPessoa[0]?.referencia ? enderecosPessoa[0]?.referencia : ''}</b>`);

    contrato = await contrato?.replaceAll("{{telefones_pessoa}}", `<b>${telefonesPessoa[0]?.numero ? telefonesPessoa[0]?.numero : ''}</b>`);


    contrato = await contrato?.replaceAll("{{automoveis_itens}}", `<b>MARCA: ${dadosVenda?.marca} ${dadosVenda?.modelo}, COR: ${dadosVenda?.cor.toUpperCase()}, ANO ${dadosVenda?.anoFabricacao}, MODELO ${dadosVenda?.ano}, PLACA ${dadosVenda?.placa}, CHASSI ${dadosVenda?.chassi.toUpperCase()}, RENAVAM ${dadosVenda?.renavam.toUpperCase()} </b>`);
    contrato = await contrato?.replaceAll("{{automoveis_troca}}", `<b>${automoveisTroca}</b>`);
    
    // contrato = await contrato?.replaceAll("{{automoveis_troca}}", `<b>PLACA: ${dadosVenda?.placa} - MARCA: ${dadosVenda?.marca} MODELO: ${dadosVenda?.modelo} - ANO DO MODELO: ${dadosVenda?.ano} ANO DE FABRICAÇÃO: ${dadosVenda?.anoFabricacao} - COR: ${dadosVenda?.cor} - CHASSI: ${dadosVenda?.chassi.toUpperCase()} RENAVAM: ${dadosVenda?.renavam.toUpperCase()}</b>`);

    contrato = await contrato?.replaceAll("{{data_compra}}", `<b>${moment(dadosVenda?.data).format('DD')} de ${moment(dadosVenda?.data).format('MMMM')} de ${moment(dadosVenda?.data).format('YYYY')}</b>`);
    contrato = await contrato?.replaceAll("{{hora_compra}}", `<b>${moment(dadosVenda?.data).format('HH:mm')}</b>`);

    contrato = await contrato?.replaceAll("{{data_venda}}", `<b>${moment(dadosVenda?.data).format('DD')} de ${moment(dadosVenda?.data).format('MMMM')} de ${moment(dadosVenda?.data).format('YYYY')}</b>`);
    contrato = await contrato?.replaceAll("{{hora_venda}}", `<b>${moment(dadosVenda?.data).format('HH:mm')}</b>`);

    contrato = await contrato?.replaceAll("{{valor_total}}", `<b>${formataDinheiro(dadosVenda?.precoVenda)}</b>`);
    contrato = await contrato?.replaceAll("{{valor_total_por_extenso}}", `<b>${extenso(formataDinheironoRs(dadosVenda?.precoVenda), { mode: 'currency', currency: { type: 'BRL' } })}</b>`);
    contrato = await contrato?.replaceAll("{{pagamentos_informacoes}}", `${listaPagamentos}`);



    if(dadosVenda.contrato){// se o contrato html existir carrega ele
      formik.setFieldValue('contrato', dadosVenda.contrato);
    }else{ //se não carrega o modelo padão
      formik.setFieldValue('contrato', contrato);
    }

    reset && formik.setFieldValue('contrato', contrato);

    
    setdetalhescontrato(response.data);
    setloaderPage(false);
  }

  useEffect(()=>{
    contratoid && detalhesContrato(contratoid);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[visible]);
 
  useEffect(()=>{
    permissoesUsuario();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  const validationSchema = yup.object({
    // formapagamento: yup
    //   .number('Selecione a Forma de pagamento')
    //   .required('Forma de pagamento Obrigatória'),

  });

  const formik = useFormik({
    initialValues: {
      contrato: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.put(`updateContratoNaVenda`, {
        "id": dadosVenda.id,
        "contrato": values.contrato,
      },
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      refreshDetalhesCompraVenda();

      Swal.fire(
        'Contrato atualizado!',
        'Modificações atualizadas com sucesso!',
        'success'
      )

    }
  });


  const handleResetContrato = async()=>{
    Swal.fire({
      title: 'Ao resetar o contrato ele voltará para o modelo original.',
      text: "Isso altera as modificações que você possa ter feito manualmente. Deseja continuar?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#0554C2',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim resetar',
      cancelButtonText: 'Cancelar',
    }).then(async(result) => {
      if (result.isConfirmed) {

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);


        await api.put(`updateContratoNaVenda`, {
          "id": dadosVenda.id,
          "contrato": null,
        },
        {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          }
        });

        refreshDetalhesCompraVenda();
        detalhesContrato(dadosVenda.contrato_id, true);

        Swal.fire(
          'Contrato resetado!',
          'Reset realizado com sucesso!',
          'success'
        )

      }
    })
  }


  return(
    <>
   
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='lg' fullWidth={true} scroll='body' onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Gerir contrato
            <p style={{ fontWeight: 'normal', fontSize: '16px', lineHeight: '0', marginTop: '5px' }}>{detalhescontrato.nome}</p>
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={2.5} style={{marginTop: '0px'}}>

          <Grid item xs={12} md={12}>
            <Editor
                apiKey='uziuxnsxme98mwi6oypws1u2x5z2j514pt6rnr9xsxf2zzmm'
                onInit={(evt, editor) => editorRef.current = editor}
                value={formik.values.contrato}
                onEditorChange={(value)=>{ formik.setFieldValue('contrato', value); }}
                disabled={!permissoesdoUsuario[3]?.update}
                init={{
                height: 800,
                language: 'pt_BR',
                menubar: true,
                save_onsavecallback: function () { formik.handleSubmit() },
                save_enablewhendirty: false,
                plugins: [
                    'pagebreak advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount print preview save'
                ],
                toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | pagebreak | save | print ',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
          </Grid>
            
          </Grid>

        </DialogContent>

 
        <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

            {/* <Grid item xs={4} md={2.5}>
              <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Fechar" />
            </Grid> */}

            <Grid item xs={4} md={2}>
              <ButtonSecondary type="button" icon={<IconReset/>} onClick={()=>{ handleResetContrato() }}  txt="Resetar contrato" />
            </Grid>


            {/* <Grid item xs={4} md={2}>
              <div id="pdfContrato" style={{ display: 'none', width: '100%', backgroundColor: '#red' }}>
                <PDFViewer width='100%' height='800px' style={{ border: 'none' }}>
                  <DocumentPDFcontrato html={formik.values.contrato} />
                </PDFViewer>
              </div>

              <Fancybox>
                <div data-fancybox="dialog" data-src="#pdfContrato">
                  <ButtonSecondary type="button" icon={<IconPdf/>} txt="Gerar PDF" />
                </div>
              </Fancybox>              
            </Grid> */}
            
            <Grid item xs={4} md={2}>
            <ButtonDefault type="submit" icon={<IconSave/>}  txt="Salvar" />
            </Grid>

        </Grid>
        </DialogActions>
        
        </form>
    </Dialog>
    </>
  );

}