import React, {useContext, useState} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import Footer from '../../componentes/Footer';
import SubMenusFinancas from './submenusFinancas';

import TabLancamentosCaixas from './tabLancamentosCaixas';
import TabCaixas from './tabCaixas';
import TabplanoContas from './tabPlanoContas';
import { GlobalContext } from '../../context/GlobalContext';

export default function ControleCaixa() {

  const { whatsAppInfo } = useContext(GlobalContext);
  const [tabPage, settabPage] = useState('1');

  const handleChangeTabPage = (event, newValue) => {
    settabPage(newValue);
  };


  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcar - Controle de caixa</title>
        </Helmet>
      </HelmetProvider>

      <div>
        <div className='container-page'>

          <SubMenusFinancas activePage="controleCaixa" />

          <div className={`${whatsAppInfo?.session ? 'container' : 'container-no-margin' }`}>
            <TabContext value={tabPage}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '10px' }}>
                <TabList onChange={handleChangeTabPage} aria-label="" textColor="primary" indicatorColor="primary">
                  <Tab label="Lançamentos de caixa" value="1" />
                  <Tab label="Caixas" value="2" />
                  <Tab label="Plano de contas" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <TabLancamentosCaixas />
              </TabPanel>

              <TabPanel value="2">
                <TabCaixas />
              </TabPanel>

              <TabPanel value="3">
                <TabplanoContas />
              </TabPanel>
            </TabContext>
          </div>

        </div>
        <Footer/>
      </div>
    </>
    
  );
}