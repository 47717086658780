import React, { useContext, useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams, useHistory } from "react-router-dom";
import './style.css';
import moment from 'moment';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@material-ui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';

import Footer from '../../componentes/Footer';
import Submenu from '../../componentes/SubMenu';
import ButtonCirclePrimary from '../../componentes/ButtonCirclePrimary';
import ButtonDefault from '../../componentes/ButtonDefault';
import ButtonSquadPrimaryForInput from '../../componentes/ButtonSquadPrimaryForInput';
import ButtonCircleDanger from '../../componentes/ButtonCircleDanger';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import api from '../../services/api';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Skeleton from '@mui/material/Skeleton';

import Swal from 'sweetalert2';
import Fancybox from '../../vendor/fancybox'; //https://fancyapps.com/docs/ui/fancybox/react
import GrapesEditor from '../../componentes/GrapesEditor';
import { GlobalContext } from '../../context/GlobalContext';

export default function SiteConfig(props) {

    let history = useHistory();

    const { whatsAppInfo } = useContext(GlobalContext);
    const [loaderPage, setloaderPage] = useState(false);
    const [tabPage, settabPage] = useState('1');
    const [dadosEmpresa, setdadosEmpresa] = useState([]);

    const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);

    const [alertError, setalertError] = useState(false);
    const [alertErrorMsg, setalertErrorMsg] = useState('');

    const [alertSucess, setalertSucess] = useState(false);
    const [alertSucessMsg, setalertSucessMsg] = useState('');

    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleCloseAlertError = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setalertError(false);
    };

    const handleCloseAlertSuccess = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setalertSucess(false);
    };

    const permissoesUsuario = async()=>{

      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);
  
      let permissoes = await api.post('permissoesUsuario', {}, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });
  
      setpermissoesdoUsuario(permissoes.data.configEmpresa);
    }

    useEffect(async ()=>{ 
        await permissoesUsuario();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
  
   
    return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: '2000' }}
        open={loaderPage}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <HelmetProvider>
        <Helmet>
            <title>Nexcar - Gerenciar Site</title>
        </Helmet>
      </HelmetProvider>

      <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
        <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
          {alertErrorMsg}
        </Alert>
      </Snackbar>

      <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
        <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
          {alertSucessMsg}
        </Alert>
      </Snackbar>


      <div className={`${whatsAppInfo?.session ? 'container-page-site' : 'container-page-site-no-margin' }`}>
        <GrapesEditor />
      </div>
    </>

    )
}