import React, {useState, useEffect} from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import api from '../../services/api';

export default function MultSelectOpcionaisVeiculo({ idVeiculo, dados, opcionaisDoVeiculo, atualizar, disabled = false }) {

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [opcionais, setopcionais] = useState([]);
  const [values, setvalues] = useState([]);
  const [title, settitle] = useState();

  useEffect(async()=>{ 
    
    settitle(dados.categoria);
    setopcionais(dados.opcionais);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dados]);


  useEffect(async()=>{ 

    let opvarray = [];
    
    for await (let opv of opcionaisDoVeiculo) {
        let filtro = dados.opcionais.find(x => x.id === opv.id);
        filtro && opvarray.push(filtro)
        
    }      
    
    setvalues(opvarray);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[opcionaisDoVeiculo]);

  const handleChange = async(event, value, reason, details)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    if(reason === 'removeOption'){ //remover
        
        await api.delete(`deleteOpcionaisVeiculo`,
        {
            headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
            },
            data: {
                "opcionalId": details.option.id,
                "produtoId": idVeiculo 
            },
        });

    }else{ //adicionar
      
      await api.post(`addOpcionaisVeiculo`,
      {
        "opcionalId": details.option.id,
        "produtoId": idVeiculo 
      },
      {
          headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
          }
      });

    }

    atualizar(idVeiculo);  

  }

  return (
    <div>
      <Autocomplete
        multiple
        id={title}
        options={opcionais}
        disabled={disabled}
        disableCloseOnSelect
        getOptionLabel={(option) => option.nome}
        value={values}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={handleChange}
        disableClearable={true}
        openText="Abrir Opções"
        noOptionsText="Nenhuma opção encontrada."
        renderOption={(props, option, { selected }) => (
            <li {...props}>
            <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
            />
            {option.nome}
            </li>
        )}
        style={{ width: '100%' }}
        sx={{
            '& .MuiChip-root': {
                backgroundColor: '#001A3E',
                color: '#fff',
                padding: '10px'
            },
            '& .MuiSvgIcon-root': {
                color: '#fff!important',
            },
            '& .MuiSvgIcon-root:hover': {
                color: '#c4c4c4!important',
            },
            
        }}
        renderInput={(params) => (
            <TextField {...params} label={title} placeholder={title} />
        )}
        />
    </div>
  );
}
