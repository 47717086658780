import React, {useState, useEffect } from 'react';
import api from '../../../services/api';
import moment from 'moment';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';
import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { mvalor, mnumero } from '../../../vendor/mascaras';
import { formataDinheiroforBanco } from '../../../vendor/formatar';

export default function ModalEditContaBancaria({ contaId, visible, change, refreshContas}) {

  const [loaderPage, setloaderPage] = useState(false);
  const [bancos, setbancos] = useState([]);
  const [dadosContas, setdadosContas] = useState([]);


  const detalhesConta= async()=>{

    setloaderPage(true);

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.post('detalhesContaBancaria', {
        "id": contaId
    }, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let data = await response.data;

    data.descricao ? formik.setFieldValue('descricao', data.descricao) : formik.setFieldValue('descricao', '');
    await data.agencia && formik.setFieldValue('agencia', data.agencia);
    await data.conta && formik.setFieldValue('conta', data.conta);
    
    getBancos(data.banco_id);
    setdadosContas(data);
    setloaderPage(false);

  }


 
  useEffect(()=>{
    contaId && detalhesConta();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[contaId]);

  const getBancos = async(bancoid)=>{
    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get('listagemBancos',{
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });


    //populando banco no select
    let banco = response.data.find(x => x.id === bancoid);
    banco && formik.setFieldValue('banco', JSON.stringify(banco));

    setbancos(response.data);

  }


  const validationSchema = yup.object({
    banco: yup
      .string('Selecione o banco')
      .required('Banco Obrigatório')
      .test({
        message: () => "Selecione o banco",
        test: async (values) => {
          if (values) { 
            if (values === 'null') { return false; } else{ return true; }
          }
        },
    }),
    agencia: yup
      .string('Informe o número da agência')
      .required('Agência Obrigatória'),
    conta: yup
      .string('Informe o número da conta')
      .required('Conta Obrigatória'),
    
  });

  const formik = useFormik({
    initialValues: {
      descricao: '',
      banco: 'null',
      agencia: '',
      conta: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      let banco = JSON.parse(values.banco);
      
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);


      await api.post('updateContaBancaria', {
        "id": contaId,
        "descricao": values.descricao,
        "banco": banco.id,
        "agencia": values.agencia,
        "conta": values.conta,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

    //   formik.resetForm();
      refreshContas();
      change(false);

    }
  });

  const handleChangeBanco = async(event, value, reason, details)=>{
    formik.setFieldValue('banco', JSON.stringify(value))
  }

  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} scroll='body' onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Editar conta bancária
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={2.5} style={{marginTop: '0px'}}>

                <Grid item xs={12} md={12} >
                  <TextField
                      fullWidth
                      id="descricao"
                      name="descricao"
                      label="Descrição"
                      margin="none"
                      type="text"
                      value={formik.values.descricao}
                      onChange={formik.handleChange}
                      error={formik.touched.descricao && Boolean(formik.errors.descricao)}
                      helperText={formik.touched.descricao && formik.errors.descricao}
                      inputProps={{
                          maxLength: 155,
                      }}
                  />
                </Grid>

                <Grid item xs={12} md={12} >
                    <FormControl sx={{ m: 0, width: '100%' }} >
                    <Autocomplete
                        disablePortal
                        id="combo-box-banco"
                        options={bancos}
                        value={JSON.parse(formik.values.banco)}
                        getOptionLabel={(option) => option.nome}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={handleChangeBanco}
                        noOptionsText="Nenhum banco encontrado."
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} label="Banco"  error={formik.touched.banco && Boolean(formik.errors.banco)}/>}
                    />
                    <FormHelperText error>{formik.touched.banco && formik.errors.banco}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} >
                    <TextField
                        fullWidth
                        id="agencia"
                        name="agencia"
                        label="Agência"
                        margin="none"
                        type="text"
                        value={formik.values.agencia}
                        onChange={formik.handleChange}
                        error={formik.touched.agencia && Boolean(formik.errors.agencia)}
                        helperText={formik.touched.agencia && formik.errors.agencia}
                        inputProps={{
                            maxLength: 155,
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={6} >
                    <TextField
                        fullWidth
                        id="conta"
                        name="conta"
                        label="Conta"
                        margin="none"
                        type="text"
                        value={formik.values.conta}
                        onChange={formik.handleChange}
                        error={formik.touched.conta && Boolean(formik.errors.conta)}
                        helperText={formik.touched.conta && formik.errors.conta}
                        inputProps={{
                            maxLength: 155,
                        }}
                    />
                </Grid>
                
          </Grid>
        </DialogContent>

        <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

            <Grid item xs={4} md={3}>
                <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
            </Grid>
            
            <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon=""  txt="Salvar" />
            </Grid>

        </Grid>
        </DialogActions>
        
        </form>
    </Dialog>
    </>
  );

}