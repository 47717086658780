import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import NumberFormat from 'react-number-format';


export const mnumero = (v)=>{
    v=v.replace(/\D/g,"");//Remove tudo o que não é dígito
    return v;
}

export const muf = (v) => {
    v = v.toUpperCase(); // Converter para maiúsculas para tratar todas as variações de caso
    v = v.replace(/[^A-Z]/g, ""); // Remover todos os caracteres que não sejam letras de A a Z
    return v;
}

export const mDimensoes = (v) => {
    v=v.replace(/\D/g,"");//Remove tudo o que não é dígito
    return v;
}

export const mPeso = (v) => {
    v=v.replace(/\D/g,"");//Remove tudo o que não é dígito
    v=v.replace(/(\d)(\d{3})$/,"$1.$2");//coloca a virgula antes dos 3 últimos dígitos
    return v;
}

export const mvalor = (v)=>{
    v=v.replace(/\D/g,"");//Remove tudo o que não é dígito
    v=v.replace(/(\d)(\d{8})$/,"$1.$2");//coloca o ponto dos milhões
    v=v.replace(/(\d)(\d{5})$/,"$1.$2");//coloca o ponto dos milhares
    v=v.replace(/(\d)(\d{2})$/,"$1,$2");//coloca a virgula antes dos 2 últimos dígitos

    if(v === ""){
        return "";
    }else{
        return "R$ "+v;
    }
}

export const mporcentagem = (v)=>{
    v=v.replace(/\D/g,"");//Remove tudo o que não é dígito
    v=v.replace(/(\d)(\d{8})$/,"$1.$2");//coloca o ponto dos milhões
    v=v.replace(/(\d)(\d{5})$/,"$1.$2");//coloca o ponto dos milhares
    v=v.replace(/(\d)(\d{2})$/,"$1.$2");//coloca a virgula antes dos 2 últimos dígitos

    if(v === ""){
        return "";
    }else{
        return v+"%";
    }
}

export const mtel = (v)=>{
    v=v.replace(/\D/g,"");             		//Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d)/g,"($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    v=v.replace(/(\d)(\d{4})$/,"$1-$2");    //Coloca hífen entre o quarto e o quinto dígitos
    return v;
}

export const mplaca = (v)=>{
    v = v.toUpperCase();
    v=v.replace(/\s/, ""); //Removendo espaços
    v=v.replace(/[^\w\-]+/g, ""); //Removendo caracters especiais e acentos
    // v=v.replace(/^([a-zA-Z]{3})([0-9][0-9A-Z][0-9]{2})/, "$1-$2"); 

    if(v.match("[A-Z]{3}[0-9][A-Z][0-9]{2}")){//sefor mercosul
        v=v;
    }else{
        v=v.replace(/^([a-zA-Z]{3})([0-9]{4})/, "$1-$2"); //placa normal com -
    }
    
    return v;
}

export const placaMask = React.forwardRef(function placaMask(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
        {...other}
        mask=""
        definitions={{
            // '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        />
    );
});

export const DataMask = React.forwardRef(function DataMask(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
        {...other}
        mask="00/00/0000"
        definitions={{
            '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        />
    );
});

export const AnoMask = React.forwardRef(function AnoMask(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
        {...other}
        mask="0000"
        definitions={{
            '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        />
    );
});

export const CpfMask = React.forwardRef(function CpfMask(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
        {...other}
        mask="000.000.000-00"
        definitions={{
            '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        />
    );
});

export const CnpjMask = React.forwardRef(function CnpjMask(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
        {...other}
        mask="00.000.000/0000-00"
        definitions={{
            '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        />
    );
});

export const CEPMask = React.forwardRef(function CEPMask(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
        {...other}
        mask="00000-000"
        definitions={{
            '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        />
    );
});

export const TelMask = React.forwardRef(function TelMask(props, ref) {
    const { onChange, ...other } = props;

    const [maskTel, setmaskTel] = useState('(00) 0000-0000');

    return (
        <IMaskInput
            {...props}
            mask={maskTel}
            onBlur={e => {
                if (e.target.value.replace("_", "").length === 14) {
                    setmaskTel("(00) 0000-0000");
                }
            }}
            onFocus={e => {
                if (e.target.value.replace("_", "").length === 14) {
                    setmaskTel("(00) 00000-0000");
                }
            }}
            definitions={{
                '#': /[1-9]/,
            }}            
            inputRef={ref}
            overwrite
        />
    );
});



export const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
            onChange({
            target: {
                name: props.name,
                value: values.value,
            },
            });
        }}
        thousandSeparator
        isNumericString
        prefix="$"
        />
    );
});
  
NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
  