import React, {useState, useEffect } from 'react';
import api from '../../../services/api';
import moment from 'moment';

import { useFormik } from 'formik';
import * as yup from 'yup';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';
import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import ButtonDefault from '../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { mvalor, mnumero } from '../../../vendor/mascaras';
import { formataDinheiroforBanco } from '../../../vendor/formatar';

export default function ModalNovoLancamentosContasBancarias({ conta, operacao, visible, change, refreshlancamentos}) {


  const [loaderPage, setloaderPage] = useState(false);
  const [contas, setcontas] = useState([]);

  const [contasOrcamentarias, setcontasOrcamentarias] = useState([]);


  const getContas = async()=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let contas = await api.get(`listagemContasBancarias`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setcontas(contas.data);

  }

  const getContasOrcamentarias = async(operacao)=>{
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemContasOrcamentaria?operacao=${operacao}`,{
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setcontasOrcamentarias(response.data);
  }

  useEffect(()=>{
    getContas();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  useEffect(()=>{

    if(visible){

      formik.setFieldValue('operacao', Number(operacao));
      Number(operacao) > 0 && getContasOrcamentarias(Number(operacao));
      conta > 0 && formik.setFieldValue('conta', conta);

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[visible]);
  
  const validationSchema = yup.object({

    data: yup
      .date('').typeError("Insira a data do lançamento")
      .required('Data de lançamento Obrigatória'),
    conta: yup
      .number('Selecione a Conta Bancária')
      .required('Conta Bancária Obrigatória'),
    valor: yup
      .string('Informe o valor')
      .required('Valor Obrigatório'),
    contaorcamentaria: yup
      .number('Selecione uma conta orçamentária')
      .required('Conta orçamentária Obrigatória'),
    descricao: yup
      .string('Informe a descrição do pagamento')
      .required('Descrição Obrigatória'),
    
  });

  const formik = useFormik({
    initialValues: {
      data: new Date(),
      operacao: operacao, //1 = crédito 2 = Débito
      conta: '',
      valor: '',
      contaorcamentaria: '',
      descricao: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.post('lancamentoContaBancaria', {
        "contaBancaria_id": values.conta,
        "planoContaOrcamentaria_id": values.contaorcamentaria,
        "formaPagamento_id": 4,
        "venda_id": null,
        "compra_id": null,
        "compra_id_for_update_fluxo": null,
        "descricao": values.descricao,
        "destinoBanco": null,
        "destinoChavePix": null,
        "valor": formataDinheiroforBanco(values.valor),
        "operacao": values.operacao //1 = crédito 2 = Débito
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      })

      formik.resetForm();
      refreshlancamentos();
      change(false);

    }
  });

  

  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Dialog open={visible} maxWidth='sm' fullWidth={true} scroll='body' onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Novo Lançamento
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>

          <Grid container spacing={2.5} style={{marginTop: '0px'}}>

                <Grid item xs={12} md={6} >
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                    <DesktopDatePicker
                      label="Data"
                      inputFormat="dd/MM/yyyy"
                      name="data"
                      value={formik.values.data}
                      onChange={(data)=>{ formik.setFieldValue('data', data) }}
                      renderInput={(params) => <TextField style={{ width: '100%'}} {...params} error={formik.touched.data && Boolean(formik.errors.data)}/>}
                    />
                    <FormHelperText error>{formik.touched.data && formik.errors.data}</FormHelperText>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={6} >
                    <FormControl sx={{ m: 0, width: '100%' }} >
                    <InputLabel id="label-operacao">Operação</InputLabel>
                    <Select
                        labelId="label-operacao"
                        name="operacao"
                        label="Operação"
                        placeholder='Operação'
                        value={formik.values.operacao}
                        onChange={formik.handleChange}
                        error={formik.touched.operacao && Boolean(formik.errors.operacao)}
                        disabled
                    >

                        <MenuItem value='1'>Crédito</MenuItem>
                        <MenuItem value='2'>Débito</MenuItem>

                    </Select>
                    <FormHelperText error>{formik.touched.operacao && formik.errors.operacao}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={8} >
                    <FormControl sx={{ m: 0, width: '100%' }} >
                    <InputLabel id="label-conta">Conta Bancária</InputLabel>
                    <Select
                        labelId="label-conta"
                        id="demo-simple-select"
                        name="conta"
                        label="Conta Bancária"
                        placeholder='Conta Bancária'
                        value={formik.values.conta}
                        onChange={formik.handleChange}
                        error={formik.touched.conta && Boolean(formik.errors.conta)}
                    >

                        {contas.map((v, k) =>{
                            return (
                              <MenuItem key={k} value={v.id}>{`${v.descricao} - ${v.banco} - ${v.agencia} - ${v.conta}`}</MenuItem>
                            )
                        })}

                    </Select>
                    <FormHelperText error>{formik.touched.conta && formik.errors.conta}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={4} >
                    <TextField
                    fullWidth
                    id="valor"
                    name="valor"
                    label="Valor"
                    margin="none"
                    type="text"
                    value={formik.values.valor}
                    onChange={formik.handleChange}
                    error={formik.touched.valor && Boolean(formik.errors.valor)}
                    helperText={formik.touched.valor && formik.errors.valor}
                    onKeyUp={()=>{ formik.setFieldValue('valor', formik.values.valor && mvalor(formik.values.valor)) }}
                    inputProps={{
                        maxLength: 15,
                    }}
                    />
                </Grid>
                

                <Grid item xs={12} md={12} >
                  <FormControl sx={{ m: 0, width: '100%' }} >
                    <InputLabel id="label-contaorcamentaria">Conta Orçamentária</InputLabel>
                    <Select
                      labelId="label-contaorcamentaria"
                      id="contaorcamentaria-simple-select"
                      name="contaorcamentaria"
                      label="Conta Orçamentária"
                      placeholder='Conta Orçamentária'
                      value={formik.values.contaorcamentaria}
                      onChange={formik.handleChange}
                      error={formik.touched.contaorcamentaria && Boolean(formik.errors.contaorcamentaria)}
                    >
                      {contasOrcamentarias.map((v, k) =>{
                          return (
                            <MenuItem key={k} value={v.id}>{v.descricao}</MenuItem>
                          )
                      })}
                    </Select>
                    <FormHelperText error>{formik.touched.contaorcamentaria && formik.errors.contaorcamentaria}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} >
                    <TextField
                        fullWidth
                        id="descricao"
                        name="descricao"
                        label="Descrição"
                        margin="none"
                        type="text"
                        multiline
                        rows={3}
                        value={formik.values.descricao}
                        onChange={formik.handleChange}
                        error={formik.touched.descricao && Boolean(formik.errors.descricao)}
                        helperText={formik.touched.descricao && formik.errors.descricao}
                        inputProps={{
                            maxLength: 200,
                        }}
                    />
                </Grid>

          </Grid>
        </DialogContent>

        <DialogActions>
        <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{marginRight: '15px'}}>

            <Grid item xs={4} md={3}>
                <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
            </Grid>
            
            <Grid item xs={4} md={3}>
            <ButtonDefault type="submit" icon=""  txt="Adicionar" />
            </Grid>

        </Grid>
        </DialogActions>
        
        </form>
    </Dialog>
    </>
  );

}