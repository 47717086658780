import React, {useState, useEffect, useRef } from 'react';

import api from '../../../../services/api';
import { useHistory } from "react-router-dom";

import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import ButtonDefault from '../../../../componentes/ButtonDefault';
import ButtonOutlineSecondary from '../../../../componentes/ButtonOutlineSecondary';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import FormHelperText from '@mui/material/FormHelperText';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';
import TimePicker from '@mui/lab/TimePicker';
import { mnumero, mtel } from '../../../../vendor/mascaras';

export default function ModalNovaLista({ visible, change, getListas, handleDetalhesListaCode}) {
  
  let history = useHistory();
  const inputNumero = useRef(null);

  const [loaderPage, setloaderPage] = useState(false);

  const [alertError, setalertError] = useState(false);
  const [alertErrorMsg, setalertErrorMsg] = useState('');

  const [alertSucess, setalertSucess] = useState(false);
  const [alertSucessMsg, setalertSucessMsg] = useState('');

  const [showDaysInput, setShowDaysInput] = useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertError(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setalertSucess(false);
  };

  
  const validationSchema = yup.object({
    nome: yup
        .string('Insira o nome da Lista')
        .required('Nome Obrigatório'),
    datadisparo: yup
    .date('').typeError("Insira a data do disparo")
    .test({
      message: () => "Data Obrigatória",
      test: async (values) => {
        if (!values) { return false; } else{ return true; }
      },
    }),
    horadisparo: yup
    .date('').typeError("Informe a hora do disparo")
    .test({
      message: () => "Hora Obrigatória",
      test: async (values) => {
        if (!values) { return false; } else{ return true; }
      },
    }),
    ultimosdias: yup
    .number()
    .when('alcance', {
      is: (alcance) => [3, 4, 6].includes(alcance),
      then: yup.number().required('Quantidade de dias é obrigatória').typeError('Insira a quantidade de dias'),
      otherwise: yup.number().nullable(),
    }),
    mensagem: yup
    .string('Insira o conteúdo da Mensagem')
    .required('Mensagem Obrigatória'),
  });

  const formik = useFormik({
    initialValues: {
        nome: '',
        datadisparo: moment(),
        horadisparo: moment(),
        contato: '',
        mensagem: '',
        alcance: null,
        ultimosdias: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let response = await api.post('addListaTransmissao', {
        "nome": values.nome,
        "contato": values.contato,
        "mensagem": values.mensagem,
        "datadisparo": values.datadisparo,
        "horadisparo": values.horadisparo,
        "alcance": values.alcance,
        "ultimosdias": values.ultimosdias,
      }, 
      {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${auth.token}`
        }
      });

      formik.resetForm();
      change(false);
      getListas();

      if(response.data.codigo){
        handleDetalhesListaCode(response.data.codigo);
      }

      setalertSucess(true);        
      setalertSucessMsg(`Lista de transmissão Criada com sucesso!`);        
      setTimeout(() => { setalertSucess(false) }, 6000);

    }
  });

  const handleSelectAlcance = (event) => {
    const selectedValue = event.target.value;
    formik.handleChange(event);

    if ([3, 4, 6].includes(selectedValue)) {
      setShowDaysInput(true);
    } else {
      setShowDaysInput(false);
    }
  };

  const clearSelect = () => {
    formik.setFieldValue('alcance', '');
    setShowDaysInput(false);
  };

  return(
    <>
    <Backdrop
      sx={{ color: '#fff', zIndex: '2000' }}
      open={loaderPage}
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    <Snackbar open={alertError} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertError}>
      <Alert onClose={handleCloseAlertError} severity="error" sx={{ width: '100%', marginTop: '-150px' }}>
        {alertErrorMsg}
      </Alert>
    </Snackbar>

    <Snackbar open={alertSucess} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} onClose={handleCloseAlertSuccess}>
      <Alert onClose={handleCloseAlertSuccess} severity="success" sx={{ width: '100%', marginTop: '-150px' }}>
        {alertSucessMsg}
      </Alert>
    </Snackbar>

    <Dialog open={visible} maxWidth='md' fullWidth={true} onClose={()=>{ change(false) }}>
      <form method='post' onSubmit={formik.handleSubmit}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Nova Lista de transmissão
            <IconButton
              aria-label="close"
              onClick={()=>{ change(false) }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText></DialogContentText>
          
          <Grid container spacing={1} >
            
            <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  id="nome"
                  name="nome"
                  label="Nome da lista"
                  margin="normal"
                  type="text"
                  value={formik.values.nome}
                  onChange={formik.handleChange}
                  error={formik.touched.nome && Boolean(formik.errors.nome)}
                  helperText={formik.touched.nome && formik.errors.nome}
                />
            </Grid>

            <Grid item xs={12} md={3.5}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                <DesktopDatePicker
                  label="Data do Disparo"
                  inputFormat="dd/MM/yyyy"
                  name="datadisparo"
                  value={formik.values.datadisparo}
                  onChange={(data)=>{ formik.setFieldValue('datadisparo', data) }}
                  renderInput={(params) => <TextField style={{ width: '100%', marginTop: '16px', backgroundColor: '#fcfcfc'}} {...params} error={formik.touched.datadisparo && Boolean(formik.errors.datadisparo)}/>}
                />
                <FormHelperText error>{formik.touched.datadisparo && formik.errors.datadisparo}</FormHelperText>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={2.5} >
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                  <TimePicker
                    label="Hora do Disparo"
                    value={formik.values.horadisparo}
                    onChange={(hora)=>{ formik.setFieldValue('horadisparo', hora) }}
                    renderInput={(params) => <TextField style={{ width: '100%', marginTop: '16px', backgroundColor: '#fcfcfc'}} {...params} error={formik.touched.horadisparo && Boolean(formik.errors.horadisparo)} />}
                  />
                <FormHelperText error>{formik.touched.horadisparo && formik.errors.horadisparo}</FormHelperText>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={showDaysInput ? 8 : 12}>
              <FormControl sx={{ m: 0, width: '100%' }} >
                <InputLabel id="label-tipo">Alcance</InputLabel>
                <Select
                  labelId="label-alcance"
                  id="alcance-select"
                  name="alcance"
                  label="Alcance"
                  placeholder='Alcance'
                  value={formik.values.alcance}
                  onChange={handleSelectAlcance}
                  error={formik.touched.alcance && Boolean(formik.errors.alcance)}
                  endAdornment={
                    formik.values.alcance ? (
                      <IconButton
                        aria-label="clear"
                        onClick={clearSelect}
                        edge="end"
                        size="small"
                        sx={{ mr: 1 }}
                      >
                        <ClearIcon />
                      </IconButton>
                    ) : null
                  }
                >
                  <MenuItem value={1}>Todos os Clientes</MenuItem>
                  <MenuItem value={2}>Todos os Fornecedores</MenuItem>
                  <MenuItem value={3}>Clientes que Compraram nos Últimos X Dias</MenuItem>
                  <MenuItem value={4}>Fornecedores que Venderam nos Últimos X Dias</MenuItem>
                  <MenuItem value={5}>Todos os Clientes e Fornecedores</MenuItem>
                  <MenuItem value={6}>Clientes e Fornecedores que Negociaram nos Últimos X Dias</MenuItem>
                </Select>
                <FormHelperText error>{formik.touched.alcance && formik.errors.alcance}</FormHelperText>
              </FormControl>
            </Grid>
            
            {showDaysInput &&
              <Grid item xs={12} md={4} >
                <TextField
                  className='input'
                  fullWidth
                  name="ultimosdias"
                  label="Últimos Dias"
                  margin="none"
                  type="text"
                  value={formik.values.ultimosdias}
                  onChange={formik.handleChange}
                  error={formik.touched.ultimosdias && Boolean(formik.errors.ultimosdias)}
                  helperText={formik.touched.ultimosdias && formik.errors.ultimosdias}
                  onKeyUp={()=>{ formik.setFieldValue('ultimosdias', formik.values.ultimosdias && mnumero(formik.values.ultimosdias)) }}
                  inputProps={{
                    maxLength: 3,
                  }}
                />
              </Grid>
            }

            <Grid item xs={12} md={12}>
                <TextField
                  inputRef={inputNumero}
                  fullWidth
                  id="contato"
                  name="contato"
                  label="Contato (Envie um número de WhatsApp como contato)"
                  margin="normal"
                  type="text"
                  value={formik.values.contato}
                  onChange={formik.handleChange}
                  error={formik.touched.contato && Boolean(formik.errors.contato)}
                  helperText={formik.touched.contato && formik.errors.contato}
                  onKeyUp={()=>{ formik.setFieldValue('contato', mtel(formik.values.contato)) }}
                  inputProps={{
                    maxLength: 15,
                  }}
                />
            </Grid>

            <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id="mensagem"
                  name="mensagem"
                  label="Mensagem"
                  margin="normal"
                  type="text"
                  multiline
                  rows={10}
                  value={formik.values.mensagem}
                  onChange={formik.handleChange}
                  error={formik.touched.mensagem && Boolean(formik.errors.mensagem)}
                  helperText={formik.touched.mensagem && formik.errors.mensagem}
                />
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="flex-end" spacing={2} >

          <Grid item xs={4} md={3}>
            <ButtonOutlineSecondary type="button" onClick={()=>{ change(false) }} icon="" txt="Cancelar" />
          </Grid>
          
          <Grid item xs={4} md={3} style={{ marginRight: 15 }}>
            <ButtonDefault type="submit" icon="" txt="Adicionar" />
          </Grid>

          </Grid>
        </DialogActions>
        </form>
    </Dialog>
    </>
  );

}