import React, {useState, useEffect, useRef} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './style.css';
import moment from 'moment';

import { useFormik } from 'formik';
import * as yup from 'yup';
import api from '../../services/api';

import { useHistory } from "react-router-dom";
import { DataGrid, ptBR, GridOverlay } from '@mui/x-data-grid';
import Swal from 'sweetalert2';

import ButtonSquadPrimary from '../../componentes/ButtonSquadPrimary';
import ButtonSquadSecondary from '../../componentes/ButtonSquadSecondary';
import ButtonSquadDanger from '../../componentes/ButtonSquadDanger';
import InputSearchActions from '../../componentes/InputSearchActions';
import ButtonSecondarySlim from '../../componentes/ButtonSecondary/slim';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';

import { formataDinheiroforBanco, formataDinheiro } from '../../vendor/formatar';
import ModalNovaDespesa from './modals/modalNovaDespesa';
import ModalEditDespesa from './modals/modalEditDespesa';
import ModalFaturarDespesas from './modals/modalFaturarDespesas';
import { width } from '@mui/system';

export default function Despesas({dadosCompra}) {


  let IconPlusBtn = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 34 34">
      <path id="plus" d="M15.671,22.975V18.326H11.023a1.328,1.328,0,1,1,0-2.656h4.648V11.022a1.328,1.328,0,0,1,2.656,0V15.67h4.648a1.328,1.328,0,0,1,0,2.656H18.328v4.648a1.328,1.328,0,0,1-2.656,0Zm13.349-18A17,17,0,0,0,4.979,29.02,17.006,17.006,0,0,0,25.8,31.55a1.328,1.328,0,1,0-1.376-2.272,14.47,14.47,0,1,1,4.492-4.317,1.328,1.328,0,1,0,2.208,1.476,16.959,16.959,0,0,0-2.1-21.458Zm0,0" transform="translate(0.001 0)" fill="#fff"/>
    </svg>
  );

  let IconPDFbtn = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="27.758" viewBox="0 0 24.93 27.758">
      <g id="pdf" transform="translate(-26.077)">
        <g id="Grupo_19" data-name="Grupo 19" transform="translate(28.387 25.607)">
          <g id="Grupo_18" data-name="Grupo 18">
            <path id="Caminho_62" data-name="Caminho 62" d="M68.677,472.334a3.073,3.073,0,0,0,2.914,2.15H86.074a3.072,3.072,0,0,0,2.914-2.15Z" transform="translate(-68.677 -472.334)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_21" data-name="Grupo 21" transform="translate(43.912 0.508)">
          <g id="Grupo_20" data-name="Grupo 20">
            <path id="Caminho_63" data-name="Caminho 63" d="M359.085,13.324l-3.563-3.563a3.106,3.106,0,0,0-.475-.389v4.407a.182.182,0,0,0,.182.182h4.345A3.046,3.046,0,0,0,359.085,13.324Z" transform="translate(-355.047 -9.372)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_23" data-name="Grupo 23" transform="translate(28.238)">
          <g id="Grupo_22" data-name="Grupo 22">
            <path id="Caminho_64" data-name="Caminho 64" d="M86.543,6.724H81.791a1.811,1.811,0,0,1-1.809-1.808V0H69a3.066,3.066,0,0,0-3.063,3.063v8.354H86.543V6.724Z" transform="translate(-65.935)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_25" data-name="Grupo 25" transform="translate(37.827 16.638)">
          <g id="Grupo_24" data-name="Grupo 24">
            <path id="Caminho_65" data-name="Caminho 65" d="M243.562,306.9h-.744v3.836h.744q.752,0,.752-.836v-2.165Q244.314,306.9,243.562,306.9Z" transform="translate(-242.818 -306.896)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_27" data-name="Grupo 27" transform="translate(33.072 16.638)">
          <g id="Grupo_26" data-name="Grupo 26">
            <path id="Caminho_66" data-name="Caminho 66" d="M155.928,306.9H155.1v1.763h.827q.752,0,.752-.836v-.092Q156.681,306.9,155.928,306.9Z" transform="translate(-155.101 -306.896)" fill="#fff"/>
          </g>
        </g>
        <g id="Grupo_29" data-name="Grupo 29" transform="translate(26.077 13.044)">
          <g id="Grupo_28" data-name="Grupo 28">
            <path id="Caminho_67" data-name="Caminho 67" d="M48.952,240.6H28.132a2.057,2.057,0,0,0-2.055,2.055v6.826a2.057,2.057,0,0,0,2.055,2.055h20.82a2.057,2.057,0,0,0,2.055-2.055V242.66A2.057,2.057,0,0,0,48.952,240.6Zm-13,4.421h0a1.806,1.806,0,0,1-.564,1.467,2.248,2.248,0,0,1-1.492.472h-.827v1.805a.325.325,0,0,1-.192.3,1.063,1.063,0,0,1-.919,0,.324.324,0,0,1-.192-.3v-5.316a.356.356,0,0,1,.134-.276.514.514,0,0,1,.351-.117h1.73a2.121,2.121,0,0,1,1.433.464,1.823,1.823,0,0,1,.539,1.458v.042Zm4.672,2.173a1.859,1.859,0,0,1-.56,1.5,2.232,2.232,0,0,1-1.5.476H37.092a.672.672,0,0,1-.41-.117.338.338,0,0,1-.159-.276v-5.324a.338.338,0,0,1,.159-.276.673.673,0,0,1,.41-.117h1.479a2.232,2.232,0,0,1,1.5.476,1.859,1.859,0,0,1,.56,1.5Zm4.584-3.168a.324.324,0,0,1-.288.167H42.759v1.412h1.262a.333.333,0,0,1,.288.15.615.615,0,0,1,.1.359.6.6,0,0,1-.1.343.33.33,0,0,1-.293.15H42.759v2.156a.325.325,0,0,1-.192.3,1.063,1.063,0,0,1-.919,0,.324.324,0,0,1-.192-.3v-5.307a.331.331,0,0,1,.167-.3.742.742,0,0,1,.4-.1h2.9a.322.322,0,0,1,.293.167.749.749,0,0,1,.1.393A.759.759,0,0,1,45.212,244.032Z" transform="translate(-26.077 -240.605)" fill="#fff"/>
          </g>
        </g>
      </g>
    </svg>
  );

  let IconCSVBtn = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21"  viewBox="0 0 22.974 27.355">
      <g id="csv" transform="translate(-41)">
        <path id="Caminho_199" data-name="Caminho 199" d="M41,465.107a2.407,2.407,0,0,0,2.4,2.4H59.432a2.407,2.407,0,0,0,2.4-2.4V465H41Z" transform="translate(0 -440.156)" fill="#fff"/>
        <path id="Caminho_200" data-name="Caminho 200" d="M55.729,13.927V8.787l-5.942,5.942h5.14A.8.8,0,0,0,55.729,13.927Z" transform="translate(-8.318 -8.318)" fill="#fff"/>
        <path id="Caminho_201" data-name="Caminho 201" d="M61.837,2.4a2.407,2.407,0,0,0-2.4-2.4H49.014V5.61a2.407,2.407,0,0,1-2.4,2.4H41v2.939H61.837Z" fill="#fff"/>
        <path id="Caminho_202" data-name="Caminho 202" d="M63.172,235H41v10.685H63.172a.8.8,0,0,0,.8-.8V235.8A.8.8,0,0,0,63.172,235Zm-13.636,7.306a2.35,2.35,0,0,1-1.858.842,2.8,2.8,0,1,1,1.569-5.13.668.668,0,0,1-.748,1.107,1.469,1.469,0,1,0-.821,2.688,1.059,1.059,0,0,0,.729-.255,1.048,1.048,0,0,0,.106-.109.668.668,0,0,1,1.023.858Zm5.093-.633a1.893,1.893,0,0,1-1.927,1.475,2.667,2.667,0,0,1-1.981-.851.668.668,0,1,1,.99-.9,1.348,1.348,0,0,0,.991.413.591.591,0,0,0,.612-.374c.032-.177-.224-.338-.473-.43-.666-.245-1.257-.509-1.282-.52a.671.671,0,0,1-.093-.05,1.489,1.489,0,0,1-.664-1.474,1.633,1.633,0,0,1,1.182-1.321,2.249,2.249,0,0,1,2.449.7.668.668,0,0,1-1.108.735,1.112,1.112,0,0,0-.956-.152.309.309,0,0,0-.245.232.161.161,0,0,0,.044.143c.167.073.635.273,1.135.458A1.732,1.732,0,0,1,54.63,241.673Zm5.437-3.249-1.4,4.2a.766.766,0,0,1-.72.523h-.005a.765.765,0,0,1-.72-.508l-1.463-4.215a.668.668,0,0,1,1.262-.438l.909,2.62L58.8,238a.668.668,0,0,1,1.268.421Z" transform="translate(0 -222.445)" fill="#fff"/>
      </g>
    </svg>
  );

  let IconLixeira = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="17" viewBox="0 0 115.029 153.224">
      <g id="delete" transform="translate(-63.818 0)">
        <path id="Caminho_186" data-name="Caminho 186" d="M178.847,49.194H107.979l31.736-31.736a4.489,4.489,0,0,0,0-6.349l-8.042-8.042a10.475,10.475,0,0,0-14.813,0l-8.268,8.269L104.358,7.1a11.985,11.985,0,0,0-16.93,0L70.922,23.611a11.985,11.985,0,0,0,0,16.93l4.232,4.233-8.268,8.268a10.475,10.475,0,0,0,0,14.813L74.928,75.9a4.489,4.489,0,0,0,6.348,0L92.654,64.519v69.253a19.453,19.453,0,0,0,19.453,19.453h47.286a19.453,19.453,0,0,0,19.453-19.453ZM81.5,38.424l-4.232-4.232a3,3,0,0,1,0-4.233L93.777,13.453a3,3,0,0,1,4.232,0l4.232,4.233Zm38.764,89.661a4.489,4.489,0,1,1-8.978,0V74.334a4.489,4.489,0,1,1,8.978,0Zm19.973,0a4.489,4.489,0,0,1-8.978,0V74.334a4.489,4.489,0,0,1,8.978,0Zm19.973,0a4.489,4.489,0,1,1-8.978,0V74.334a4.489,4.489,0,1,1,8.978,0Z" fill="#fff"/>
        <path id="Caminho_187" data-name="Caminho 187" d="M309.244,93.149a7.5,7.5,0,0,0-4.977,6.237H347.34V91.925a7.486,7.486,0,0,0-9.882-7.087l-28.121,8.281c-.03.009-.063.021-.093.03Z" transform="translate(-168.493 -59.168)" fill="#fff"/>
      </g>
    </svg>
  )

  let IconNoAuth = props =>(
    <svg id="blocked" xmlns="http://www.w3.org/2000/svg" width="30"  viewBox="0 0 90.682 90.682">
      <path id="Caminho_196" data-name="Caminho 196" d="M60.455,34.535a8.537,8.537,0,0,0-8.5-8.086H49.119V18.892a18.892,18.892,0,0,0-37.784,0v7.557H8.5A8.514,8.514,0,0,0,0,34.95V67.067a8.514,8.514,0,0,0,8.5,8.5H35.4a32.46,32.46,0,0,1-1.4-9.446A32.072,32.072,0,0,1,60.455,34.535ZM18.892,26.449V18.892a11.335,11.335,0,1,1,22.67,0v7.557Z" fill="#4a4a4a"/>
      <path id="Caminho_197" data-name="Caminho 197" d="M35.56,11a24.56,24.56,0,1,0,24.56,24.56A24.588,24.588,0,0,0,35.56,11Zm0,7.557A16.839,16.839,0,0,1,44.575,21.2L21.2,44.575A16.931,16.931,0,0,1,35.56,18.557Zm0,34.006a16.839,16.839,0,0,1-9.015-2.645L49.921,26.544a16.839,16.839,0,0,1,2.645,9.015,17.029,17.029,0,0,1-17.007,17Z" transform="translate(30.563 30.563)" fill="#4a4a4a"/>
    </svg>
  )

  let IconDespesas = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 55.707 55.734">
      <g id="accounting" transform="translate(0 0.001)">
        <path id="Caminho_310" data-name="Caminho 310" d="M113.289,288a9.289,9.289,0,1,0,9.289,9.289A9.289,9.289,0,0,0,113.289,288Zm0,16.72a7.431,7.431,0,1,1,7.431-7.431A7.431,7.431,0,0,1,113.289,304.72Zm0,0" transform="translate(-91.925 -254.56)" fill="#4a4a4a"/>
        <path id="Caminho_311" data-name="Caminho 311" d="M155.716,322.787c1.134,0,1.858.55,1.858.929h1.858c0-1.32-1.169-2.389-2.787-2.694V320h-1.858v1.022c-1.617.3-2.787,1.372-2.787,2.694,0,1.563,1.632,2.787,3.716,2.787,1.134,0,1.858.55,1.858.929s-.723.929-1.858.929-1.858-.55-1.858-.929H152c0,1.32,1.169,2.389,2.787,2.694v1.022h1.858v-1.022c1.617-.3,2.787-1.372,2.787-2.694,0-1.563-1.632-2.787-3.716-2.787-1.134,0-1.858-.55-1.858-.929S154.581,322.787,155.716,322.787Zm0,0" transform="translate(-134.351 -282.845)" fill="#4a4a4a"/>
        <path id="Caminho_312" data-name="Caminho 312" d="M47.373,53.875H8.36V31.582H23.222A2.787,2.787,0,0,0,26.009,28.8V5.573H36.227V16.719H47.373v13h1.858V15.406L37.54,3.715H26.009V2.786A2.787,2.787,0,0,0,23.222,0H2.786A2.787,2.787,0,0,0,0,2.786V28.795a2.787,2.787,0,0,0,2.787,2.787H6.5V55.733H49.231v-13H47.373ZM38.084,6.886l7.975,7.975H38.084ZM1.857,28.8V2.786a.929.929,0,0,1,.929-.929H23.222a.929.929,0,0,1,.929.929V28.8a.929.929,0,0,1-.929.929H2.786a.929.929,0,0,1-.929-.929Zm0,0" fill="#4a4a4a"/>
        <path id="Caminho_313" data-name="Caminho 313" d="M256,200h1.858v1.858H256Zm0,0" transform="translate(-226.276 -176.778)" fill="#4a4a4a"/>
        <path id="Caminho_314" data-name="Caminho 314" d="M288,200h11.147v1.858H288Zm0,0" transform="translate(-254.56 -176.778)" fill="#4a4a4a"/>
        <path id="Caminho_315" data-name="Caminho 315" d="M256,168h1.858v1.858H256Zm0,0" transform="translate(-226.276 -148.494)" fill="#4a4a4a"/>
        <path id="Caminho_316" data-name="Caminho 316" d="M288,168h11.147v1.858H288Zm0,0" transform="translate(-254.56 -148.494)" fill="#4a4a4a"/>
        <path id="Caminho_317" data-name="Caminho 317" d="M256,232h1.858v1.858H256Zm0,0" transform="translate(-226.276 -205.063)" fill="#4a4a4a"/>
        <path id="Caminho_318" data-name="Caminho 318" d="M288,232h11.147v1.858H288Zm0,0" transform="translate(-254.56 -205.063)" fill="#4a4a4a"/>
        <path id="Caminho_319" data-name="Caminho 319" d="M256,264h1.858v1.858H256Zm0,0" transform="translate(-226.276 -233.347)" fill="#4a4a4a"/>
        <path id="Caminho_320" data-name="Caminho 320" d="M288,264h11.147v1.858H288Zm0,0" transform="translate(-254.56 -233.347)" fill="#4a4a4a"/>
        <path id="Caminho_321" data-name="Caminho 321" d="M44.436,24H24v6.5H44.436Zm-1.858,4.645H25.858V25.858h16.72Zm0,0" transform="translate(-21.214 -21.214)" fill="#4a4a4a"/>
        <path id="Caminho_322" data-name="Caminho 322" d="M35.716,88H33.858A1.858,1.858,0,0,0,32,89.858v1.858a1.858,1.858,0,0,0,1.858,1.858h1.858a1.858,1.858,0,0,0,1.858-1.858V89.858A1.858,1.858,0,0,0,35.716,88Zm-1.858,3.716V89.858h1.858v1.858Zm0,0" transform="translate(-28.285 -77.783)" fill="#4a4a4a"/>
        <path id="Caminho_323" data-name="Caminho 323" d="M91.716,88H89.858A1.858,1.858,0,0,0,88,89.858v1.858a1.858,1.858,0,0,0,1.858,1.858h1.858a1.858,1.858,0,0,0,1.858-1.858V89.858A1.858,1.858,0,0,0,91.716,88Zm-1.858,3.716V89.858h1.858v1.858Zm0,0" transform="translate(-77.783 -77.783)" fill="#4a4a4a"/>
        <path id="Caminho_324" data-name="Caminho 324" d="M147.716,88h-1.858A1.858,1.858,0,0,0,144,89.858v1.858a1.858,1.858,0,0,0,1.858,1.858h1.858a1.858,1.858,0,0,0,1.858-1.858V89.858A1.858,1.858,0,0,0,147.716,88Zm-1.858,3.716V89.858h1.858v1.858Zm0,0" transform="translate(-127.28 -77.783)" fill="#4a4a4a"/>
        <path id="Caminho_325" data-name="Caminho 325" d="M35.716,144H33.858A1.858,1.858,0,0,0,32,145.858v1.858a1.858,1.858,0,0,0,1.858,1.858h1.858a1.858,1.858,0,0,0,1.858-1.858v-1.858A1.858,1.858,0,0,0,35.716,144Zm-1.858,3.716v-1.858h1.858v1.858Zm0,0" transform="translate(-28.285 -127.281)" fill="#4a4a4a"/>
        <path id="Caminho_326" data-name="Caminho 326" d="M91.716,144H89.858A1.858,1.858,0,0,0,88,145.858v1.858a1.858,1.858,0,0,0,1.858,1.858h1.858a1.858,1.858,0,0,0,1.858-1.858v-1.858A1.858,1.858,0,0,0,91.716,144Zm-1.858,3.716v-1.858h1.858v1.858Zm0,0" transform="translate(-77.783 -127.281)" fill="#4a4a4a"/>
        <path id="Caminho_327" data-name="Caminho 327" d="M147.716,144h-1.858A1.858,1.858,0,0,0,144,145.858v1.858a1.858,1.858,0,0,0,1.858,1.858h1.858a1.858,1.858,0,0,0,1.858-1.858v-1.858A1.858,1.858,0,0,0,147.716,144Zm-1.858,3.716v-1.858h1.858v1.858Zm0,0" transform="translate(-127.28 -127.281)" fill="#4a4a4a"/>
        <path id="Caminho_328" data-name="Caminho 328" d="M35.716,200H33.858A1.858,1.858,0,0,0,32,201.858v1.858a1.858,1.858,0,0,0,1.858,1.858h1.858a1.858,1.858,0,0,0,1.858-1.858v-1.858A1.858,1.858,0,0,0,35.716,200Zm-1.858,3.716v-1.858h1.858v1.858Zm0,0" transform="translate(-28.285 -176.778)" fill="#4a4a4a"/>
        <path id="Caminho_329" data-name="Caminho 329" d="M91.716,200H89.858A1.858,1.858,0,0,0,88,201.858v1.858a1.858,1.858,0,0,0,1.858,1.858h1.858a1.858,1.858,0,0,0,1.858-1.858v-1.858A1.858,1.858,0,0,0,91.716,200Zm-1.858,3.716v-1.858h1.858v1.858Zm0,0" transform="translate(-77.783 -176.778)" fill="#4a4a4a"/>
        <path id="Caminho_330" data-name="Caminho 330" d="M147.716,200h-1.858A1.858,1.858,0,0,0,144,201.858v1.858a1.858,1.858,0,0,0,1.858,1.858h1.858a1.858,1.858,0,0,0,1.858-1.858v-1.858A1.858,1.858,0,0,0,147.716,200Zm-1.858,3.716v-1.858h1.858v1.858Zm0,0" transform="translate(-127.28 -176.778)" fill="#4a4a4a"/>
        <path id="Caminho_331" data-name="Caminho 331" d="M302.39,249.256a2.853,2.853,0,0,0-3.94,0L282.526,265.18a.94.94,0,0,0-.186.269l-2.242,4.869a.929.929,0,0,0,1.232,1.232l4.869-2.242a.931.931,0,0,0,.268-.186L302.39,253.2a2.786,2.786,0,0,0,0-3.941Zm-17.12,18.433-2.434,1.121,1.121-2.434L295.83,254.5l1.314,1.314ZM298.46,254.5l-1.314-1.313.65-.65,1.314,1.314Zm2.62-2.619-.657.657-1.314-1.314.658-.657a.95.95,0,0,1,1.313,0A.929.929,0,0,1,301.08,251.879Zm0,0" transform="translate(-247.5 -219.617)" fill="#4a4a4a"/>
      </g>
    </svg>
  )

  let IconFaturado = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 47.91 53.912">
      <g id="invoice" transform="translate(-28.5)">
        <g id="Grupo_386" data-name="Grupo 386" transform="translate(28.5)">
          <g id="Grupo_385" data-name="Grupo 385" transform="translate(0)">
            <path id="Caminho_332" data-name="Caminho 332" d="M111.659,253h-1.053a2.106,2.106,0,1,0,0,4.212h1.053a2.106,2.106,0,1,0,0-4.212Z" transform="translate(-100.076 -226.36)" fill="#4a4a4a"/>
            <path id="Caminho_333" data-name="Caminho 333" d="M203.979,173H190.606a2.106,2.106,0,0,0,0,4.212h13.373a2.106,2.106,0,0,0,0-4.212Z" transform="translate(-171.653 -154.784)" fill="#4a4a4a"/>
            <path id="Caminho_334" data-name="Caminho 334" d="M57.445,49.7H36.924a4.217,4.217,0,0,1-4.212-4.212V8.424a4.217,4.217,0,0,1,4.212-4.212H62.815a4.217,4.217,0,0,1,4.212,4.212v11.9a2.106,2.106,0,0,0,4.212,0V8.424A8.433,8.433,0,0,0,62.815,0H36.924A8.433,8.433,0,0,0,28.5,8.424V45.488a8.433,8.433,0,0,0,8.424,8.424H57.445a2.106,2.106,0,0,0,0-4.212Z" transform="translate(-28.5)" fill="#4a4a4a"/>
            <path id="Caminho_335" data-name="Caminho 335" d="M111.659,333h-1.053a2.106,2.106,0,1,0,0,4.212h1.053a2.106,2.106,0,1,0,0-4.212Z" transform="translate(-100.076 -297.936)" fill="#4a4a4a"/>
            <path id="Caminho_336" data-name="Caminho 336" d="M203.979,93H190.606a2.106,2.106,0,0,0,0,4.212h13.373a2.106,2.106,0,1,0,0-4.212Z" transform="translate(-171.653 -83.207)" fill="#4a4a4a"/>
            <path id="Caminho_337" data-name="Caminho 337" d="M355.85,264.262h-1.93a1.729,1.729,0,0,1-1.712-1.742v-.285a1.646,1.646,0,0,1,1.712-1.654h1.825a1.025,1.025,0,0,1,1.091.96,2.106,2.106,0,0,0,4.212,0,5.216,5.216,0,0,0-4.319-5.081v-1.354a2.106,2.106,0,0,0-4.212,0v1.427a5.88,5.88,0,0,0-2.771,1.523A5.8,5.8,0,0,0,348,262.235v.285a5.945,5.945,0,0,0,5.923,5.954h1.93a1.722,1.722,0,0,1,1.723,1.716v1a1.631,1.631,0,0,1-1.723,1.606h-3.136a.994.994,0,0,1-1.041-.989,2.106,2.106,0,1,0-4.212,0,5.138,5.138,0,0,0,1.557,3.707,5.249,5.249,0,0,0,3.5,1.489v1.163a2.106,2.106,0,0,0,4.212,0v-1.22a5.783,5.783,0,0,0,5.056-5.756v-1A5.938,5.938,0,0,0,355.85,264.262Z" transform="translate(-313.875 -226.36)" fill="#4a4a4a"/>
            <path id="Caminho_338" data-name="Caminho 338" d="M199.767,337.212a2.106,2.106,0,1,0,0-4.212h-9.161a2.106,2.106,0,0,0,0,4.212Z" transform="translate(-171.653 -297.936)" fill="#4a4a4a"/>
            <path id="Caminho_339" data-name="Caminho 339" d="M111.659,173h-1.053a2.106,2.106,0,0,0,0,4.212h1.053a2.106,2.106,0,0,0,0-4.212Z" transform="translate(-100.076 -154.784)" fill="#4a4a4a"/>
            <path id="Caminho_340" data-name="Caminho 340" d="M206.085,255.106A2.106,2.106,0,0,0,203.979,253H190.606a2.106,2.106,0,0,0,0,4.212h13.373A2.106,2.106,0,0,0,206.085,255.106Z" transform="translate(-171.653 -226.36)" fill="#4a4a4a"/>
            <path id="Caminho_341" data-name="Caminho 341" d="M111.659,93h-1.053a2.106,2.106,0,1,0,0,4.212h1.053a2.106,2.106,0,1,0,0-4.212Z" transform="translate(-100.076 -83.207)" fill="#4a4a4a"/>
          </g>
        </g>
      </g>
    </svg>
  )

  let IconFaturar = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 47.91 53.912">
      <g id="invoice" transform="translate(-28.5)">
        <g id="Grupo_386" data-name="Grupo 386" transform="translate(28.5)">
          <g id="Grupo_385" data-name="Grupo 385" transform="translate(0)">
            <path id="Caminho_332" data-name="Caminho 332" d="M111.659,253h-1.053a2.106,2.106,0,1,0,0,4.212h1.053a2.106,2.106,0,1,0,0-4.212Z" transform="translate(-100.076 -226.36)" fill="#fff"/>
            <path id="Caminho_333" data-name="Caminho 333" d="M203.979,173H190.606a2.106,2.106,0,0,0,0,4.212h13.373a2.106,2.106,0,0,0,0-4.212Z" transform="translate(-171.653 -154.784)" fill="#fff"/>
            <path id="Caminho_334" data-name="Caminho 334" d="M57.445,49.7H36.924a4.217,4.217,0,0,1-4.212-4.212V8.424a4.217,4.217,0,0,1,4.212-4.212H62.815a4.217,4.217,0,0,1,4.212,4.212v11.9a2.106,2.106,0,0,0,4.212,0V8.424A8.433,8.433,0,0,0,62.815,0H36.924A8.433,8.433,0,0,0,28.5,8.424V45.488a8.433,8.433,0,0,0,8.424,8.424H57.445a2.106,2.106,0,0,0,0-4.212Z" transform="translate(-28.5)" fill="#fff"/>
            <path id="Caminho_335" data-name="Caminho 335" d="M111.659,333h-1.053a2.106,2.106,0,1,0,0,4.212h1.053a2.106,2.106,0,1,0,0-4.212Z" transform="translate(-100.076 -297.936)" fill="#fff"/>
            <path id="Caminho_336" data-name="Caminho 336" d="M203.979,93H190.606a2.106,2.106,0,0,0,0,4.212h13.373a2.106,2.106,0,1,0,0-4.212Z" transform="translate(-171.653 -83.207)" fill="#fff"/>
            <path id="Caminho_337" data-name="Caminho 337" d="M355.85,264.262h-1.93a1.729,1.729,0,0,1-1.712-1.742v-.285a1.646,1.646,0,0,1,1.712-1.654h1.825a1.025,1.025,0,0,1,1.091.96,2.106,2.106,0,0,0,4.212,0,5.216,5.216,0,0,0-4.319-5.081v-1.354a2.106,2.106,0,0,0-4.212,0v1.427a5.88,5.88,0,0,0-2.771,1.523A5.8,5.8,0,0,0,348,262.235v.285a5.945,5.945,0,0,0,5.923,5.954h1.93a1.722,1.722,0,0,1,1.723,1.716v1a1.631,1.631,0,0,1-1.723,1.606h-3.136a.994.994,0,0,1-1.041-.989,2.106,2.106,0,1,0-4.212,0,5.138,5.138,0,0,0,1.557,3.707,5.249,5.249,0,0,0,3.5,1.489v1.163a2.106,2.106,0,0,0,4.212,0v-1.22a5.783,5.783,0,0,0,5.056-5.756v-1A5.938,5.938,0,0,0,355.85,264.262Z" transform="translate(-313.875 -226.36)" fill="#fff"/>
            <path id="Caminho_338" data-name="Caminho 338" d="M199.767,337.212a2.106,2.106,0,1,0,0-4.212h-9.161a2.106,2.106,0,0,0,0,4.212Z" transform="translate(-171.653 -297.936)" fill="#fff"/>
            <path id="Caminho_339" data-name="Caminho 339" d="M111.659,173h-1.053a2.106,2.106,0,0,0,0,4.212h1.053a2.106,2.106,0,0,0,0-4.212Z" transform="translate(-100.076 -154.784)" fill="#fff"/>
            <path id="Caminho_340" data-name="Caminho 340" d="M206.085,255.106A2.106,2.106,0,0,0,203.979,253H190.606a2.106,2.106,0,0,0,0,4.212h13.373A2.106,2.106,0,0,0,206.085,255.106Z" transform="translate(-171.653 -226.36)" fill="#fff"/>
            <path id="Caminho_341" data-name="Caminho 341" d="M111.659,93h-1.053a2.106,2.106,0,1,0,0,4.212h1.053a2.106,2.106,0,1,0,0-4.212Z" transform="translate(-100.076 -83.207)" fill="#fff"/>
          </g>
        </g>
      </g>
    </svg>
   )

  let IconSetaDebito = props =>(
    <svg id="up" xmlns="http://www.w3.org/2000/svg" width="21.737" height="21.737" viewBox="0 0 21.737 21.737">
      <g id="Grupo_301" data-name="Grupo 301">
        <path id="Caminho_151" data-name="Caminho 151" d="M10.869,21.737A10.869,10.869,0,1,1,21.737,10.869,10.863,10.863,0,0,1,10.869,21.737Zm4.045-11.665H12.879V5.755a.725.725,0,0,0-.725-.725H9.583a.725.725,0,0,0-.725.725v4.317H6.824a.725.725,0,0,0-.571,1.17L10.3,16.428a.725.725,0,0,0,1.143,0l4.045-5.185A.725.725,0,0,0,14.914,10.072Z" fill="#f44236"/>
      </g>
    </svg>
  );

  let IconDespesa = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46.003" viewBox="0 0 46 46.003">
      <g id="expenses" transform="translate(-3 -2.009)">
        <path id="Caminho_379" data-name="Caminho 379" d="M32.634,39.037c0-1.35,0-5.459,3.9-5.459h7.8V28.9a3.9,3.9,0,0,0-3.9-3.9H6.9A3.9,3.9,0,0,0,3,28.9V49.175a3.9,3.9,0,0,0,3.9,3.9H40.432a3.9,3.9,0,0,0,3.9-3.9V44.5h-7.8C32.634,44.5,32.634,40.387,32.634,39.037Z" transform="translate(0 -5.062)" fill="#fff"/>
        <path id="Caminho_380" data-name="Caminho 380" d="M38.21,21.019V16.291a2.259,2.259,0,0,0-1.125-1.954,2.361,2.361,0,0,0-2.356-.042L21.246,21.019Z" transform="translate(-4.017 -2.64)" fill="#fff"/>
        <ellipse id="Elipse_29" data-name="Elipse 29" cx="1.5" cy="2" rx="1.5" ry="2" transform="translate(37 32.016)" fill="#fff"/>
        <path id="Caminho_381" data-name="Caminho 381" d="M29.081,12.775V9.455a2.426,2.426,0,0,0-1.412-2.249,2.213,2.213,0,0,0-2.428.37l-13.582,11.9h3.982Z" transform="translate(-1.906 -1.099)" fill="#fff"/>
        <path id="Caminho_382" data-name="Caminho 382" d="M54.7,38H45.34c-1.4,0-2.34.664-2.34,3.9s.935,3.9,2.34,3.9H54.7a2.342,2.342,0,0,0,2.34-2.34V40.34A2.342,2.342,0,0,0,54.7,38Zm-7.019,7.019A3.119,3.119,0,1,1,50.8,41.9,3.122,3.122,0,0,1,47.679,45.019Z" transform="translate(-8.807 -7.924)" fill="#fff"/>
        <path id="Caminho_383" data-name="Caminho 383" d="M58.965,7.3,55.378,2.478a1.163,1.163,0,0,0-1.867,0l-3.565,4.8a.563.563,0,0,0,.4.961H52.1a5.624,5.624,0,0,1-2.618,5.946L49,14.479l3.119,2.34.137-.091A10.2,10.2,0,0,0,56.8,8.241h1.779a.549.549,0,0,0,.388-.937Z" transform="translate(-10.128)" fill="#fff"/>
      </g>
    </svg>
  );

  let IconCusto = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="47.503" height="47.503" viewBox="0 0 47.503 47.503">
      <g id="money-bag" transform="translate(0)">
        <path id="Caminho_384" data-name="Caminho 384" d="M18.185,185.4a32.987,32.987,0,0,0,9.832-1.2,4.769,4.769,0,0,1,1.383-4.371,4.846,4.846,0,0,1,0-6.958,4.846,4.846,0,0,1,0-6.958,4.838,4.838,0,0,1,.531-7.4,19.737,19.737,0,0,0-2.64-2.035,2.727,2.727,0,0,0,.636-1.7A2.783,2.783,0,0,0,25.144,152H11.227a2.783,2.783,0,0,0-2.783,2.783,2.727,2.727,0,0,0,.633,1.7A19.919,19.919,0,0,0,0,172.875C0,184.1,10.418,185.4,18.185,185.4Zm-.015-13.509a4.164,4.164,0,0,1-1.377-8.1v-2.051a1.392,1.392,0,1,1,2.783,0V163.8a4.168,4.168,0,0,1,2.769,3.914,1.392,1.392,0,1,1-2.783,0,1.392,1.392,0,1,0-1.392,1.392,4.165,4.165,0,0,1,1.407,8.089v1.245a1.392,1.392,0,1,1-2.783,0v-1.236a4.167,4.167,0,0,1-2.8-3.923,1.392,1.392,0,1,1,2.783,0,1.392,1.392,0,1,0,1.392-1.392Zm0,0" transform="translate(0 -137.897)" fill="#fff"/>
        <path id="Caminho_385" data-name="Caminho 385" d="M345.706,467H333.088a2.088,2.088,0,1,0,0,4.175h12.618a2.088,2.088,0,0,0,0-4.175Zm0,0" transform="translate(-300.29 -423.672)" fill="#fff"/>
        <path id="Caminho_386" data-name="Caminho 386" d="M345.706,392H333.088a2.088,2.088,0,1,0,0,4.175h12.618a2.088,2.088,0,0,0,0-4.175Zm0,0" transform="translate(-300.29 -355.63)" fill="#fff"/>
        <path id="Caminho_387" data-name="Caminho 387" d="M345.706,317H333.088a2.088,2.088,0,1,0,0,4.175h12.618a2.088,2.088,0,0,0,0-4.175Zm0,0" transform="translate(-300.29 -287.589)" fill="#fff"/>
        <path id="Caminho_388" data-name="Caminho 388" d="M345.706,242H333.088a2.088,2.088,0,1,0,0,4.175h12.618a2.088,2.088,0,0,0,0-4.175Zm0,0" transform="translate(-300.29 -219.547)" fill="#fff"/>
        <path id="Caminho_389" data-name="Caminho 389" d="M104.66,2.783a4.163,4.163,0,0,0-7.856,0H92.391a1.39,1.39,0,0,0-1.32,1.832l2.173,6.7h14.994l2.172-6.7a1.39,1.39,0,0,0-1.32-1.832Zm0,0" transform="translate(-82.556)" fill="#fff"/>
      </g>
    </svg>
  );

  let IconVenda = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46.839" viewBox="0 0 46 46.839">
      <g id="_054---Car-Sales" data-name="054---Car-Sales" transform="translate(0 -0.997)">
        <circle id="Oval" cx="0.836" cy="0.836" r="0.836" transform="translate(29.691 23.581)" fill="#fff"/>
        <circle id="Oval-2" data-name="Oval" cx="2.091" cy="2.091" r="2.091" transform="translate(5.018 31.945)" fill="#fff"/>
        <circle id="Oval-3" data-name="Oval" cx="0.836" cy="0.836" r="0.836" transform="translate(34.291 4.345)" fill="#fff"/>
        <path id="Shape" d="M5.345,33.673A3.345,3.345,0,0,0,2,37.018V41.2a3.345,3.345,0,0,0,3.345,3.345H22.909V42.873H17.055a2.509,2.509,0,0,1-2.509-2.509V37.855a2.509,2.509,0,0,1,2.509-2.509h5.855V32A106.143,106.143,0,0,0,5.345,33.673ZM11.2,39.109a3.764,3.764,0,1,1-3.764-3.764A3.764,3.764,0,0,1,11.2,39.109Z" transform="translate(-0.327 -5.073)" fill="#fff"/>
        <path id="Shape-2" data-name="Shape" d="M19,38.836v2.509a.836.836,0,0,0,.836.836h5.855V38H19.836A.836.836,0,0,0,19,38.836Z" transform="translate(-3.109 -6.055)" fill="#fff"/>
        <path id="Shape-3" data-name="Shape" d="M38.051,1.685a2.509,2.509,0,0,0-3.5.05l-1.28,1.5C23.775,1.15,16.532,4.345,16.247,14.147c-3.078.46-5.8,1.188-6.766,2.283L7.29,26.718a109.387,109.387,0,0,1,16.485-1.464V13.545a55.63,55.63,0,0,0-5.855.376c.351-8.18,5.98-10.664,14.068-9.2L26.2,11.462a2.509,2.509,0,0,0-.753,1.8v1.957h.836a.836.836,0,1,1,0,1.673h-.836V45.327a2.509,2.509,0,0,0,2.509,2.509H44.684a2.509,2.509,0,0,0,2.509-2.509V16.89H44.684a.836.836,0,1,1,0-1.673h2.509V13.261a2.509,2.509,0,0,0-.736-1.773Zm1.614,13.532h1.673a.836.836,0,0,1,0,1.673H39.666a.836.836,0,0,1,0-1.673ZM36.32,2.672a2.509,2.509,0,1,1-2.509,2.509A2.509,2.509,0,0,1,36.32,2.672ZM34.647,15.218H36.32a.836.836,0,0,1,0,1.673H34.647a.836.836,0,1,1,0-1.673Zm-.418,9.2a2.509,2.509,0,1,1-2.509-2.509A2.509,2.509,0,0,1,34.229,24.418Zm-4.6-9.2H31.3a.836.836,0,1,1,0,1.673H29.629a.836.836,0,1,1,0-1.673ZM41.757,38.636a2.509,2.509,0,1,1,2.509-2.509A2.509,2.509,0,0,1,41.757,38.636Zm2.86-17.815a2.509,2.509,0,0,1,.318,3.529L32.389,39.405a2.509,2.509,0,1,1-3.847-3.212L41.087,21.139a2.509,2.509,0,0,1,3.529-.318Z" transform="translate(-1.193 0)" fill="#fff"/>
        <path id="Shape-4" data-name="Shape" d="M0,30.673a1.673,1.673,0,0,0,1.673,1.673h.585a4.918,4.918,0,0,1,2.074-.836L4.876,29h-3.2A1.673,1.673,0,0,0,0,30.673Z" transform="translate(0 -4.582)" fill="#fff"/>
        <path id="Shape-5" data-name="Shape" d="M34.192,41.355a.837.837,0,0,0,1.288,1.071L48.026,27.371A.837.837,0,0,0,46.738,26.3Z" transform="translate(-5.563 -4.091)" fill="#fff"/>
        <path id="Shape-6" data-name="Shape" d="M12.691,54.855V49H6v5.855a.836.836,0,0,0,.836.836h5.018A.836.836,0,0,0,12.691,54.855Z" transform="translate(-0.982 -7.855)" fill="#fff"/>
        <circle id="Oval-4" data-name="Oval" cx="0.836" cy="0.836" r="0.836" transform="translate(39.727 35.29)" fill="#fff"/>
      </g>
    </svg>
  );

  let IconLucro = props =>(
    <svg xmlns="http://www.w3.org/2000/svg" width="42.449" height="48.297" viewBox="0 0 42.449 48.297">
      <g id="profits" transform="translate(-31 0)">
        <path id="Caminho_390" data-name="Caminho 390" d="M152.411,8.584h1.415v2.924a1.414,1.414,0,0,0,1.415,1.415h5.66a1.414,1.414,0,0,0,1.415-1.415V8.584h1.415a1.415,1.415,0,0,0,1-2.415L159.072.414a1.415,1.415,0,0,0-2,0l-5.66,5.754a1.415,1.415,0,0,0,1,2.415Z" transform="translate(-108.677 0)" fill="#fff"/>
        <path id="Caminho_391" data-name="Caminho 391" d="M252.32,272a11.32,11.32,0,1,0,11.32,11.32A11.32,11.32,0,0,0,252.32,272Zm2.371,16.412a5.333,5.333,0,0,1-.957.365v.391a1.415,1.415,0,0,1-2.83,0v-.347a4.5,4.5,0,0,1-1.6-.747,1.415,1.415,0,0,1,1.7-2.264,2.553,2.553,0,0,0,2.426.07c.31-.155.478-.342.478-.439,0-.169,0-.2-.188-.3a6.716,6.716,0,0,0-1.653-.435,8.493,8.493,0,0,1-2.47-.721,3.011,3.011,0,0,1-1.686-2.793,3.34,3.34,0,0,1,2.04-2.97,5.323,5.323,0,0,1,.959-.367v-.389a1.415,1.415,0,0,1,2.83,0v.346a4.5,4.5,0,0,1,1.6.747,1.415,1.415,0,0,1-1.7,2.264,2.556,2.556,0,0,0-2.429-.071c-.312.156-.475.339-.475.439,0,.169,0,.2.188.3a6.72,6.72,0,0,0,1.653.435,8.49,8.49,0,0,1,2.47.721,3.011,3.011,0,0,1,1.686,2.793A3.341,3.341,0,0,1,254.691,288.412Z" transform="translate(-190.191 -246.342)" fill="#fff"/>
        <path id="Caminho_392" data-name="Caminho 392" d="M92.415,171.245h25.469a1.415,1.415,0,0,0,1.415-1.415v-1.415A1.415,1.415,0,0,0,117.884,167H92.415A1.415,1.415,0,0,0,91,168.415v1.415A1.415,1.415,0,0,0,92.415,171.245Z" transform="translate(-54.34 -151.247)" fill="#fff"/>
        <path id="Caminho_393" data-name="Caminho 393" d="M61,468.415v1.415a1.415,1.415,0,0,0,1.415,1.415H80.9A14.179,14.179,0,0,1,77.118,467h-14.7A1.415,1.415,0,0,0,61,468.415Z" transform="translate(-27.17 -422.948)" fill="#fff"/>
        <path id="Caminho_394" data-name="Caminho 394" d="M61,318.415v1.415a1.415,1.415,0,0,0,1.415,1.415H75.436A13.967,13.967,0,0,1,77.118,317h-14.7A1.415,1.415,0,0,0,61,318.415Z" transform="translate(-27.17 -287.097)" fill="#fff"/>
        <path id="Caminho_395" data-name="Caminho 395" d="M32.415,396.245H48.7A13.952,13.952,0,0,1,47.979,392H32.415A1.415,1.415,0,0,0,31,393.415v1.415A1.415,1.415,0,0,0,32.415,396.245Z" transform="translate(0 -355.023)" fill="#fff"/>
        <path id="Caminho_396" data-name="Caminho 396" d="M32.415,246.245H52.05A14.1,14.1,0,0,1,62.129,242H32.415A1.415,1.415,0,0,0,31,243.415v1.415A1.415,1.415,0,0,0,32.415,246.245Z" transform="translate(0 -219.172)" fill="#fff"/>
      </g>
    </svg>
  );
  
  let history = useHistory();

  const [autorizacoes, setautorizacoes] = useState([]);
  const [autorizacoesSelecionadas, setautorizacoesSelecionadas] = useState([]);
  const [pageSizeDataGridAutorizacoes, setpageSizeDataGridAutorizacoes] = useState(10);
  const [loadingDataGridAutorizacoes, setloadingDataGridAutorizacoes] = useState(true);

  const [filtroTermo, setfiltroTermo] = useState('');
  const [filtroStatus, setfiltroStatus] = useState('all');

  const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);

  const [despesas, setdespesas] = useState([]);
  const [despesasSelecionadas, setdespesasSelecionadas] = useState([]);
  const [pageSizeDataGridDespesas, setpageSizeDataGridDespesas] = useState(10);
  const [loadingDataGridDespesas, setloadingDataGridDespesas] = useState(true);

  const [showModalnovaDespesa, setshowModalnovaDespesa] = useState(false);
  const [showModaleditDespesa, setshowModaleditDespesa] = useState(false);
  const [showModalFaturarDespesas, setshowModalFaturarDespesas] = useState(false);
  const [idEditDespesa, setidEditDespesa] = useState();

  const [valortotaldedespesas, setvalortotaldedespesas] = useState(0);
  

  const permissoesUsuario = async()=>{

    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post('permissoesUsuario', {}, 
    {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    setpermissoesdoUsuario(permissoes.data.compras);
  }


  const getDespesas = async(termo='')=>{
    setloadingDataGridDespesas(true);

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemDespesasVeiculo?compraid=${dadosCompra.id}&termo=${termo}&autorizacaoid=`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let totalDespesas = 0;

    for await (let despesas of response.data) {
        totalDespesas += Number(despesas.valor);
    }

    setvalortotaldedespesas(totalDespesas);

    setdespesas(response.data);
    setloadingDataGridDespesas(false);
  }

  useEffect(()=>{
    permissoesUsuario();
    getDespesas();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  },[]);


  
  const columnsDataGridDespesas = [
    { field: 'id', hide: true, headerName: 'ID', width: 90  },
    {
      field: 'data',
      headerName: 'Data',
      flex: 0,
      minWidth: 100,
      editable: false,
      valueFormatter: (params) => {
        return moment(params.value).format('DD/MM/YYYY');
      },
    },
    {
        field: 'descricao',
        headerName: 'Descrição',
        flex: 2,
        minWidth: 350,
        editable: false,
        valueFormatter: (params) => {
          return params.value; 
        },
    },
    {
      field: 'valor',
      headerName: 'Valor',
      flex: 1,
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => {
        return formataDinheiro(params.value); 
      },
    },
    {
      field: 'faturado',
      headerName: 'Faturado',
      type: 'string',
      flex: 1,
      minWidth: 90,
      editable: false,
      renderCell: (params) => ( //renderiza componente
        <>
          { params.value === 1 ? <IconFaturado /> : '' }
        </>
      ),        
    },
     
  ];

  function nenhumaDespesaEncontrada(){
    return(
      <GridOverlay>
        <div className="container-no-data-grid">
          {
            permissoesdoUsuario[0]?.view ?
              <>
                <IconDespesas/>
                <p>Nenhuma Despesa encontrada</p>
              </>
            :

            <>
              <IconNoAuth/>
              <p style={{ marginTop: '-0px' }}>Você não possui autorização para visualizar as Despesas!</p>
            </>

          }
        </div>
      </GridOverlay>
    )
  }

  const refreshDespesas = async()=>{
    getDespesas();
  }

  const handleDeleteDespesas = async()=>{
    
    Swal.fire({
      title: 'Deseja deletar a(s) Despesa(s) selecionada(s)?',
      text: "Ao deletar a(s) despesa(s) você afetará diretamente os lançamentos no financeiro.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0554C2',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim deletar',
      cancelButtonText: 'Cancelar',
    }).then(async(result) => {
      if (result.isConfirmed) {

        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        let despesasid = [];

        for await (let id of despesasSelecionadas) {
          let dados = { id: id }
          despesasid.push(dados);
        }
        
        await api.delete('deleteDespesasVeiculo', {
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${auth.token}`
          },
          data: {  "despesasIds": despesasid }
        });

        await refreshDespesas();

        Swal.fire(
          'Deletado!',
          'Despesa(s) deletada(s) com sucesso!',
          'success'
        )
      }
    })

  }

  const handleDetalhesDespesa = async(props)=>{
    let id = props.row.id;
    let faturado = props.row.faturado;

    if(faturado === 1 ){
      Swal.fire(
        'Ops, modificação não permitida!',
        'Você não pode alterar uma despesa faturada!',
        'error'
      )
    }else{
      setidEditDespesa(id)
      setshowModaleditDespesa(true);
    }
  }


  const handleSearchDespesa = async(event)=>{
    let termo = event.target.value;

    if(termo.length >= 3){
        setfiltroTermo(termo);
        getDespesas(termo);
    }else{
        getDespesas();
    }
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
            <title>Nexcar - Estoque Autorizações de serviços</title>
        </Helmet>
      </HelmetProvider>

    
      <ModalNovaDespesa compraId={dadosCompra.id} idAutorizacaoServico={null} codigoAutorizacao={null} visible={showModalnovaDespesa} change={setshowModalnovaDespesa} refreshDespesas={refreshDespesas} />
      <ModalEditDespesa idDespesa={idEditDespesa} visible={showModaleditDespesa} change={setshowModaleditDespesa} refreshDespesas={refreshDespesas} />
      <ModalFaturarDespesas dadosCompra={dadosCompra} despesasSelecionadas={despesasSelecionadas} detalhesautorizacaoservico={null} visible={showModalFaturarDespesas} change={setshowModalFaturarDespesas} refreshDespesas={refreshDespesas} />

      <div>

        <Grid container xs={12} md={12} spacing={0} justifyContent='space-between' style={{ marginBottom: '10px' }}>
          
          <Grid item xs={12} md={2.8}>
            <div className='box-cards-despesas' style={{ borderBottomWidth: '5px', borderBottomColor: '#F44236', borderBottomStyle: 'solid' }}>
                <aside>
                  <IconDespesa />
                  <div>
                    <h3>Despesas</h3>
                    <p>Gastos adicionais</p>
                  </div>
                </aside>

                <div>
                  <p style={{ fontSize: '20px' }}>{formataDinheiro(valortotaldedespesas)}</p>
                </div>
            </div>
          </Grid>
          <Grid item xs={12} md={0.2}></Grid>

          <Grid item xs={12} md={2.8}>
            <div className='box-cards-despesas' style={{ borderBottomWidth: '5px', borderBottomColor: '#FFB405', borderBottomStyle: 'solid' }}>
                <aside>
                  <IconCusto />
                  <div>
                    <h3>Custo total</h3>
                    <p>Compra + Gastos</p>
                  </div>
                </aside>

                <div>
                  <p style={{ fontSize: '20px' }}>{formataDinheiro(Number(dadosCompra?.precoCompra) + valortotaldedespesas)}</p>
                </div>
            </div>
          </Grid>
          <Grid item xs={12} md={0.2}></Grid>

          <Grid item xs={12} md={2.8}>
            <div className='box-cards-despesas' style={{ borderBottomWidth: '5px', borderBottomColor: '#0554C2', borderBottomStyle: 'solid' }}>
                <aside>
                  <IconVenda />
                  <div>
                    <h3>Venda</h3>
                    <p>Preço de venda</p>
                  </div>
                </aside>

                <div>
                  <p style={{ fontSize: '20px' }}>{formataDinheiro(Number(dadosCompra?.precoVenda))}</p>
                </div>
            </div>
          </Grid>
          <Grid item xs={12} md={0.2}></Grid>

          <Grid item xs={12} md={2.8}>
            <div className='box-cards-despesas' style={{ borderBottomWidth: '5px', borderBottomColor: '#04AE39', borderBottomStyle: 'solid' }}>
                <aside>
                  <IconLucro />
                  <div>
                    <h3>Lucro</h3>
                    <p>Custo - venda</p>
                  </div>
                </aside>

                <div>
                  <p style={{ fontSize: '20px' }}>{formataDinheiro(Number((dadosCompra?.precoVenda)) - Number((dadosCompra?.precoCompra) + valortotaldedespesas) )}</p>
                </div>
            </div>
          </Grid>

        </Grid>

        <div className='box-actions-estoque'>

            {
                permissoesdoUsuario[2]?.insert &&
                
                <ButtonSquadPrimary icon={<IconPlusBtn/>} onClick={()=>{ setshowModalnovaDespesa(true) }} />
            }

            {
            despesasSelecionadas.length > 0 &&
            <>  

                {
                    permissoesdoUsuario[1].delete &&

                    <div style={{ 'marginLeft': '5px' }}>
                        <ButtonSquadDanger icon={<IconLixeira/>} onClick={()=>{ handleDeleteDespesas() }} />
                    </div>
                }

                {
                    permissoesdoUsuario[3].update &&

                    <div style={{ marginLeft: '5px', width: '150px' }}>
                        <ButtonSecondarySlim icon={<IconFaturar/>} txt="Faturar" onClick={()=>{ setshowModalFaturarDespesas(true) }} />
                    </div>
                }

            </>
            }  

            <div style={{ 'marginLeft': '10px', display: 'flex', flex: '1'}}>
                <InputSearchActions onChange={handleSearchDespesa}  placeholder="Procure uma despesa" />
            </div>

            <div style={{ 'marginLeft': '10px', 'width': 'auto', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <IconSetaDebito/>
                <p style={ valortotaldedespesas > 0 ? {color: 'red', lineHeight: '0', marginLeft: '5px', fontWeight: 'bold'} : {color: '#4A4A4A', lineHeight: '0', marginLeft: '5px', fontWeight: 'bold'} }>
                    {formataDinheiro(valortotaldedespesas)}
                </p>
            </div>

        </div>

        <div className='container-data-grid'>
            <DataGrid
                sx={{ minHeight: '400px' }}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                autoHeight
                rows={despesas}
                columns={columnsDataGridDespesas}
                pageSize={pageSizeDataGridDespesas}
                onPageSizeChange={(newPageSize) => setpageSizeDataGridDespesas(newPageSize)}
                rowsPerPageOptions={[10, 50, 100]} 
                checkboxSelection
                disableSelectionOnClick
                editMode='row'
                loading={loadingDataGridDespesas}
                disableColumnMenu={true}
                onSelectionModelChange={(props)=>{
                    setdespesasSelecionadas(props);
                }}
                // onRowClick
                onCellClick={(props)=>{
                    if(props.field !== "__check__"){
                        handleDetalhesDespesa(props)
                    }
                }}
                components={{
                    NoRowsOverlay: nenhumaDespesaEncontrada,
                }}
            />
        </div>
      </div>
    </>
    
  );
}