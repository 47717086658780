import React, { useState, useEffect, useRef } from "react";

import api from "../../../services/api";
import { useHistory } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";

import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import ButtonDefault from "../../../componentes/ButtonDefault";
import ButtonOutlineSecondary from "../../../componentes/ButtonOutlineSecondary";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { TelMask, mtel } from '../../../vendor/mascaras';

export default function ModalNovoInteresse({ idLead, detalhesLead, visible, change, getInteresses }) {
  let history = useHistory();
  const inputNumero = useRef(null);

  const [loaderPage, setloaderPage] = useState(false);

  const [alertError, setalertError] = useState(false);
  const [alertErrorMsg, setalertErrorMsg] = useState("");

  const [alertSucess, setalertSucess] = useState(false);
  const [alertSucessMsg, setalertSucessMsg] = useState("");

  const [veiculos, setveiculos] = useState([]);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseAlertError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setalertError(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setalertSucess(false);
  };

  const getVeiculos = async(veiculoId)=>{

    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let response = await api.get(`listagemCompras?termo=&status=1&consignado=&notInCompra=&finalPlaca=`, {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      }
    });

    let arrayVeiculos = [];
    
    for await (let data of response.data) {
        let dados = {
            id: data.produtoId,
            compraId: data.id,
            codigoCompra: data.codigo,
            nome: `${data.placa} - ${data.marca} ${data.modelo}`,
            logoMarca: data.marcaLogo,
            precoVenda: data.precoVenda,
            precoFipe: data.precoFipe,
        }    
        
        arrayVeiculos.push(dados);
    }

    setveiculos(arrayVeiculos);

    //populando veiculo no select
    let veiculo = arrayVeiculos.find(x => x.id === veiculoId);
    veiculo && formik.setFieldValue('veiculo', JSON.stringify(veiculo));

  }

  useEffect(async()=>{
    if(visible){
      await getVeiculos();
    }

  },[visible]);

  const validationSchema = yup.object({
    nome: yup.string("Insira o veículo de interesse").required("Veículo de interesse obrigatório"),
    interesse: yup.string("Selecione o interesse do cliente").required("Interesse obrigatório")
  });

  const formik = useFormik({
    initialValues: {
      nome: "",
      veiculo: 'null',
      interesse: "",
      obs: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let veiculo = JSON.parse(values.veiculo);

      await api.post(
        "addInteresseLead",
        {
          idLead: idLead,
          nome: values.nome,
          compraId: veiculo?.compraId ? veiculo?.compraId : null,
          chatId: detalhesLead.chatId,
          interesse: values.interesse,
          obs: values.obs,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      formik.resetForm();
      change(false);
      getInteresses(idLead);

      setalertSucess(true);
      setalertSucessMsg(`Interesse Adicionado com sucesso!`);
      setTimeout(() => {
        setalertSucess(false);
      }, 6000);
    },
  });

  const handleChangeVeiculo = async(event, value, reason, details)=>{
    formik.setFieldValue('nome', (value?.nome ? value?.nome : ''));
    formik.setFieldValue('veiculo', JSON.stringify(value));
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: "2000" }}
        open={loaderPage}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={alertError}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseAlertError}
      >
        <Alert
          onClose={handleCloseAlertError}
          severity="error"
          sx={{ width: "100%", marginTop: "-150px" }}
        >
          {alertErrorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={alertSucess}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseAlertSuccess}
      >
        <Alert
          onClose={handleCloseAlertSuccess}
          severity="success"
          sx={{ width: "100%", marginTop: "-150px" }}
        >
          {alertSucessMsg}
        </Alert>
      </Snackbar>

      <Dialog
        open={visible}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => {
          change(false);
        }}
      >
        <form method="post" onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            Novo interesse
            <IconButton
              aria-label="close"
              onClick={() => {
                change(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText></DialogContentText>

            <Grid container spacing={1}>
              
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id="nome"
                  name="nome"
                  label="Veículo de interesse"
                  margin="normal"
                  type="text"
                  value={formik.values.nome}
                  onChange={formik.handleChange}
                  error={formik.touched.nome && Boolean(formik.errors.nome)}
                  helperText={formik.touched.nome && formik.errors.nome}
                />
              </Grid>

              <Grid item xs={12} md={12} >
                <FormControl sx={{ m: 0, width: '100%' }} >
                  <Autocomplete
                      className='input'
                      // disablePortal
                      id="combo-box-veiculo"
                      options={veiculos}
                      value={JSON.parse(formik.values.veiculo)}
                      getOptionLabel={(option) => option.nome}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          <img
                            loading="lazy"
                            width="35"
                            src={option.logoMarca}
                            alt=""
                          />
                          {option.nome}
                        </Box>
                      )}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={handleChangeVeiculo}
                      noOptionsText="Nenhum veículo encontrado."
                      renderInput={(params) => <TextField {...params} label="Veículo do estoque"  error={formik.touched.veiculo && Boolean(formik.errors.veiculo)}/>}
                  />
                  <FormHelperText error>{formik.touched.veiculo && formik.errors.veiculo}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormControl sx={{ m: 0, width: '100%', marginTop: 1 }} >
                  <InputLabel id="label-interesse">Interesse</InputLabel>
                  <Select
                    labelId="label-interesse"
                    id="interesse-select"
                    name="interesse"
                    label="Interesse"
                    placeholder='Interesse'
                    value={formik.values.interesse}
                    onChange={formik.handleChange}
                    error={formik.touched.interesse && Boolean(formik.errors.interesse)}
                    helperText={formik.touched.interesse && formik.errors.interesse}
                  >
                    <MenuItem value={1}>Compra</MenuItem>
                    <MenuItem value={2}>Venda</MenuItem>
                    <MenuItem value={3}>Troca</MenuItem>
                  </Select>
                  <FormHelperText error>{formik.touched.interesse && formik.errors.interesse}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  id="obs"
                  name="obs"
                  label="Obs"
                  margin="normal"
                  type="text"
                  multiline
                  rows={9}
                  value={formik.values.obs}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.obs &&
                    Boolean(formik.errors.obs)
                  }
                  helperText={
                    formik.touched.obs && formik.errors.obs
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
            >
              <Grid item xs={4} md={4}>
                <ButtonOutlineSecondary
                  type="button"
                  onClick={() => {
                    change(false);
                  }}
                  icon=""
                  txt="Cancelar"
                />
              </Grid>

              <Grid item xs={4} md={4} style={{ marginRight: 15 }}>
                <ButtonDefault type="submit" icon="" txt="Adicionar" />
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
