import React, { useState, useEffect, useRef } from "react";

import api from "../../../../services/api";
import { useHistory } from "react-router-dom";
import "../style.css";

import { useFormik } from "formik";
import * as yup from "yup";
import moment from 'moment';

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from '@mui/icons-material/Clear';

import ButtonCircleDanger from "../../../../componentes/ButtonCircleDanger";
import ButtonCirclePrimary from "../../../../componentes/ButtonCirclePrimary";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import ButtonDefault from "../../../../componentes/ButtonDefault";
import ButtonOutlineSecondary from "../../../../componentes/ButtonOutlineSecondary";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";

import CircularProgress from "@mui/material/CircularProgress";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import Tabs from "@mui/material/Tabs";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";

import Swal from "sweetalert2";

import Skeleton from "@mui/material/Skeleton";
import Fancybox from "../../../../vendor/fancybox"; //https://fancyapps.com/docs/ui/fancybox/react
import { DataGrid, ptBR, GridOverlay } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ModalNovoContato from "./modalNovoContato";
import ModalEditContato from "./modalEditContato";
import ButtonSecondaryOnlyIcon from "../../../../componentes/ButtonSecondaryOnlyIcon";

import FormHelperText from '@mui/material/FormHelperText';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import ptLocale from 'date-fns/locale/pt-BR';
import TimePicker from '@mui/lab/TimePicker';

import Papa from "papaparse";
import * as XLSX from "xlsx";
import { mtel, mnumero } from "../../../../vendor/mascaras";

export default function ModalEditLista({
  id,
  code = null,
  visible,
  change,
  getListas,
}) {

  let IconZoom = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      viewBox="0 0 12.515 12.516"
    >
      <g id="icon" transform="translate(-5.267 -5.266)">
        <path
          id="XMLID_231_"
          d="M17.782,5.835V8.68a.569.569,0,1,1-1.137,0V7.209l-3.3,3.308a.569.569,0,0,1-.8-.8L15.841,6.4h-1.47a.569.569,0,0,1,0-1.138h2.843a.569.569,0,0,1,.569.569ZM17.214,13.8a.569.569,0,0,0-.569.569v1.47L13.4,12.616a.588.588,0,0,0-.82,0,.569.569,0,0,0-.008.8l3.217,3.223H14.311a.569.569,0,1,0,0,1.138h2.842a.642.642,0,0,0,.433-.167.6.6,0,0,0,.2-.4V14.368a.569.569,0,0,0-.569-.569Zm-7.56-1.207L6.4,15.838V14.368a.564.564,0,0,0-.564-.568h0a.568.568,0,0,0-.568.568v2.845a.569.569,0,0,0,.569.569H8.688a.569.569,0,0,0,0-1.138H7.218L10.464,13.4a.571.571,0,0,0-.81-.8ZM7.208,6.455h1.47a.569.569,0,0,0,0-1.138H5.836a.568.568,0,0,0-.569.569V8.731a.569.569,0,1,0,1.137,0V7.261l3.279,3.282a.569.569,0,1,0,.8-.8Z"
          fill="#fff"
        />
      </g>
    </svg>
  );

  let IconCloseTimes = (props) => (
    <svg
      id="close"
      xmlns="http://www.w3.org/2000/svg"
      width="13.663"
      height="13.506"
      viewBox="0 0 13.663 13.506"
    >
      <g id="close_1_" transform="translate(0 0)">
        <path
          id="Caminho_85"
          data-name="Caminho 85"
          d="M1.3,15.943a1.294,1.294,0,0,1-.919-.311,1.294,1.294,0,0,1,0-1.825L11.365,2.82a1.294,1.294,0,0,1,1.89,1.76L2.2,15.633a1.294,1.294,0,0,1-.906.311Z"
          transform="translate(-0.001 -2.444)"
          fill="#fff"
        />
        <path
          id="Caminho_86"
          data-name="Caminho 86"
          d="M13.733,15.343a1.294,1.294,0,0,1-.906-.375L1.839,3.98A1.294,1.294,0,0,1,3.664,2.155L14.717,13.143a1.294,1.294,0,0,1,.06,1.829q-.029.031-.06.06a1.294,1.294,0,0,1-.984.311Z"
          transform="translate(-1.463 -1.844)"
          fill="#fff"
        />
      </g>
    </svg>
  );

  let IconUpload = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      viewBox="0 0 38.069 31.819"
    >
      <g id="upload" transform="translate(-0.997 -4.993)">
        <path
          id="Caminho_87"
          data-name="Caminho 87"
          d="M29.948,12.692a10.167,10.167,0,0,0-19.722-.011,10.179,10.179,0,0,0,.954,20.314H15a1.272,1.272,0,1,0,0-2.545H11.18a7.634,7.634,0,0,1-.045-15.269,1.321,1.321,0,0,0,1.4-1.087,7.623,7.623,0,0,1,15.093,0,1.374,1.374,0,0,0,1.365,1.087,7.634,7.634,0,1,1,0,15.269H25.176a1.272,1.272,0,0,0,0,2.545h3.817a10.179,10.179,0,0,0,.954-20.3Z"
          transform="translate(0)"
          fill="#fff"
        />
        <path
          id="Caminho_88"
          data-name="Caminho 88"
          d="M23.1,21.534a1.272,1.272,0,1,0,1.8-1.8l-6.362-6.362a1.272,1.272,0,0,0-1.8,0l-6.362,6.362a1.272,1.272,0,1,0,1.8,1.8l4.19-4.19V33.358a1.272,1.272,0,0,0,2.545,0V17.344Z"
          transform="translate(2.452 2.181)"
          fill="#fff"
        />
      </g>
    </svg>
  );

  let IconNoContact = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" viewBox="0 0 28 28">
      <g id="layer1" transform="translate(-254 -342)">
        <path
          id="rect35136"
          d="M261,342a3.016,3.016,0,0,0-3,3v5h-2a1,1,0,0,0,0,2h2v3h-2a1,1,0,0,0,0,2h2v3h-2a1,1,0,0,0,0,2h2v5a3.016,3.016,0,0,0,3,3h15a3.016,3.016,0,0,0,3-3V345a3.016,3.016,0,0,0-3-3Zm0,2h15a.977.977,0,0,1,1,1v22a.977.977,0,0,1-1,1H261a.977.977,0,0,1-1-1V345A.977.977,0,0,1,261,344Z"
          fill="#4a4a4a"
          fillRule="evenodd"
        />
        <path
          id="path35908"
          d="M269,347a4,4,0,1,0,4,4A4.015,4.015,0,0,0,269,347Zm0,2a2,2,0,1,1-2,2A1.985,1.985,0,0,1,269,349Z"
          fill="#4a4a4a"
          fillRule="evenodd"
        />
        <path
          id="rect35928"
          d="M267,356a4,4,0,0,0,0,8h4a4,4,0,0,0,0-8Zm0,2h4a2,2,0,0,1,0,4h-4a2,2,0,0,1,0-4Z"
          fill="#4a4a4a"
          fillRule="evenodd"
        />
        <path
          id="path56563"
          d="M255,342a1,1,0,0,0-.707,1.707l26,26a1,1,0,0,0,1.414-1.414l-26-26A1,1,0,0,0,255,342Z"
          fill="#4a4a4a"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );

  let IconPlusC = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      viewBox="0 0 26.723 26.721"
    >
      <path
        id="adicionar"
        d="M24.84,11.635H18.547a.461.461,0,0,1-.461-.461V4.88A3.348,3.348,0,0,0,15.033,1.5a3.231,3.231,0,0,0-2.392.881,3.2,3.2,0,0,0-1.005,2.34v6.45a.461.461,0,0,1-.461.461H4.882A3.349,3.349,0,0,0,1.5,14.688a3.225,3.225,0,0,0,3.221,3.4h6.45a.461.461,0,0,1,.461.461v6.293a3.334,3.334,0,0,0,3.228,3.382A3.224,3.224,0,0,0,18.086,25v-6.45a.461.461,0,0,1,.461-.461H25a3.225,3.225,0,0,0,3.221-3.4A3.351,3.351,0,0,0,24.84,11.635Z"
        transform="translate(-1.5 -1.5)"
        fill="#fff"
      />
    </svg>
  );

  let IconLixeira = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      viewBox="0 0 21.241 28.294"
    >
      <g id="delete" transform="translate(-63.818 0)">
        <path
          id="Caminho_186"
          data-name="Caminho 186"
          d="M85.059,9.084H71.973l5.86-5.86a.829.829,0,0,0,0-1.172L76.348.567a1.934,1.934,0,0,0-2.735,0L72.086,2.094,71.3,1.312a2.213,2.213,0,0,0-3.126,0L65.13,4.36a2.213,2.213,0,0,0,0,3.126l.782.782L64.385,9.795a1.934,1.934,0,0,0,0,2.735l1.485,1.485a.829.829,0,0,0,1.172,0l2.1-2.1V24.7a3.592,3.592,0,0,0,3.592,3.592h8.732A3.592,3.592,0,0,0,85.059,24.7ZM67.084,7.1,66.3,6.314a.553.553,0,0,1,0-.782L69.35,2.484a.553.553,0,0,1,.782,0l.782.782Zm7.158,16.557a.829.829,0,0,1-1.658,0V13.726a.829.829,0,0,1,1.658,0Zm3.688,0a.829.829,0,0,1-1.658,0V13.726a.829.829,0,0,1,1.658,0Zm3.688,0a.829.829,0,0,1-1.658,0V13.726a.829.829,0,0,1,1.658,0Z"
          transform="translate(0 0)"
          fill="#fff"
        />
        <path
          id="Caminho_187"
          data-name="Caminho 187"
          d="M305.186,86.046a1.384,1.384,0,0,0-.919,1.152h7.954V85.82a1.382,1.382,0,0,0-1.825-1.309L305.2,86.04Z"
          transform="translate(-227.162 -79.771)"
          fill="#fff"
        />
      </g>
    </svg>
  );

  let IconUploadFile = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      viewBox="0 0 36.754 40.575"
    >
      <g
        id="FILES_AND_FOLDER"
        data-name="FILES AND FOLDER"
        transform="translate(-48.218 0.001)"
      >
        <path
          id="Caminho_416"
          data-name="Caminho 416"
          d="M315.351,12.935a60.5,60.5,0,0,0,7.95.324l-.006-.01A42.647,42.647,0,0,0,311.389,1.31a60.423,60.423,0,0,0,.324,7.99A4.429,4.429,0,0,0,315.351,12.935Z"
          transform="translate(-242.297 -1.207)"
          fill="#fff"
        />
        <path
          id="Caminho_417"
          data-name="Caminho 417"
          d="M316.237,308.16a8.077,8.077,0,1,0,8.077,8.077A8.077,8.077,0,0,0,316.237,308.16Zm3.867,7.925a1.11,1.11,0,0,1-1.569,0l-1.189-1.189v5.352a1.109,1.109,0,0,1-2.219,0V314.9l-1.189,1.189a1.11,1.11,0,0,1-1.569-1.569l3.083-3.082a1.109,1.109,0,0,1,1.569,0l3.083,3.082a1.109,1.109,0,0,1,0,1.569Z"
          transform="translate(-239.343 -283.74)"
          fill="#fff"
        />
        <path
          id="Caminho_418"
          data-name="Caminho 418"
          d="M81.079,14.172c-.528.013-1.055.022-1.579.022a63.068,63.068,0,0,1-6.67-.356H72.81a6.27,6.27,0,0,1-3.655-1.845A6.247,6.247,0,0,1,67.31,8.339a.154.154,0,0,0,0-.019A62.952,62.952,0,0,1,66.974.027,95.723,95.723,0,0,0,54.5.541a7,7,0,0,0-5.741,5.741,157.793,157.793,0,0,0,0,26.179A7,7,0,0,0,54.5,38.2a95.574,95.574,0,0,0,14.144.46,10.33,10.33,0,0,1-1.506-9.482H56.084a1.109,1.109,0,0,1,0-2.219H68.209a10.416,10.416,0,0,1,3.48-3.351h-15.6a1.109,1.109,0,0,1,0-2.219h17.2A1.109,1.109,0,0,1,74.39,22.5v.006a10.382,10.382,0,0,1,6.72.594c.061-2.947.05-6.039-.032-8.928ZM67.737,18.039H56.087a1.109,1.109,0,0,1,0-2.219H67.737a1.109,1.109,0,1,1,0,2.219Z"
          transform="translate(0 0)"
          fill="#fff"
        />
      </g>
    </svg>
  );

  const inputNumero = useRef(null);

  const [tabPage, settabPage] = useState("1");
  const [loaderPage, setloaderPage] = useState(false);

  const [alertError, setalertError] = useState(false);
  const [alertErrorMsg, setalertErrorMsg] = useState("");

  const [alertSucess, setalertSucess] = useState(false);
  const [alertSucessMsg, setalertSucessMsg] = useState("");

  const [permissoesdoUsuario, setpermissoesdoUsuario] = useState([]);

  const [detalheLista, setdetalheLista] = useState([]);
  const [fotosDaLista, setfotosDaLista] = useState([]);
  const [progressUploadFotos, setprogressUploadFotos] = useState(0);

  const [contatos, setcontatos] = useState([]);
  const [contatosSelecionados, setcontatosSelecionados] = useState([]);
  const [pageSizeDataGridContatos, setpageSizeDataGridContatos] = useState(10);
  const [loadingDataGridContatos, setloadingDataGridContatos] = useState(true);

  const [showModalNovoContato, setshowModalNovoContato] = useState(false);
  const [showModalEditContato, setshowModalEditContato] = useState(false);
  const [dadosdetalhesContato, setdadosdetalhesContato] = useState();

  const [showDaysInput, setShowDaysInput] = useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseAlertError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setalertError(false);
  };

  const handleCloseAlertSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setalertSucess(false);
  };

  const Input = styled("input")({
    display: "none",
  });

  const validationSchema = yup.object({
    nome: yup.string("Insira o nome da Lista").required("Nome Obrigatório"),
    datadisparo: yup
    .date('').typeError("Insira a data do disparo")
    .test({
      message: () => "Data Obrigatória",
      test: async (values) => {
        if (!values) { return false; } else{ return true; }
      },
    }),
    horadisparo: yup
    .date('').typeError("Informe a hora do disparo")
    .test({
      message: () => "Hora Obrigatória",
      test: async (values) => {
        if (!values) { return false; } else{ return true; }
      },
    }),
    ultimosdias: yup
    .number()
    .when('alcance', {
      is: (alcance) => [3, 4, 6].includes(alcance),
      then: yup.number().required('Quantidade de dias é obrigatória').typeError('Insira a quantidade de dias'),
      otherwise: yup.number().nullable(),
    }),
    mensagem: yup
      .string("Insira o conteúdo da Mensagem")
      .required("Mensagem Obrigatória"),
  });

  const formik = useFormik({
    initialValues: {
      nome: "",
      datadisparo: null,
      horadisparo: null,
      contato: "",
      mensagem: "",
      alcance: null,
      ultimosdias: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //GET DADOS USER
      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      let response = await api.put(
        "updateListaTransmissao",
        {
          id: id,
          nome: values.nome,
          datadisparo: values.datadisparo,
          horadisparo: values.horadisparo,
          contato: values.contato,
          mensagem: values.mensagem,
          alcance: values.alcance,
          ultimosdias: values.ultimosdias,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      formik.resetForm();
      change(false);
      getListas();

      setalertSucess(true);
      setalertSucessMsg(`Lista de transmissão Atualizada com sucesso!`);
      setTimeout(() => {
        setalertSucess(false);
      }, 6000);
    },
  });

  const permissoesUsuario = async () => {
    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let permissoes = await api.post(
      "permissoesUsuario",
      {},
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      }
    );

    setpermissoesdoUsuario(permissoes.data.modelosMensagens);
  };

  const detalhesLista = async () => {
    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    api
      .post(
        "detalhesListaTransmissao",
        {
          codigo: code,
          id: id,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      )
      .then(async (response) => {
        let data = response.data;
        setdetalheLista(data);

        let datadisparo = moment(data?.agendamento).format('YYYY/MM/DD');
        let horadisparo = moment(data?.agendamento).format('YYYY/MM/DD HH:mm:ss'); 


        if ([3, 4, 6].includes(data.alcance)) {
          setShowDaysInput(true);
        } else {
          setShowDaysInput(false);
        }

        formik.setFieldValue("nome", data.nome);
        formik.setFieldValue("alcance", data.alcance);
        formik.setFieldValue("ultimosdias", data.ultimosDias);
        formik.setFieldValue('datadisparo', datadisparo);
        formik.setFieldValue('horadisparo', horadisparo);
        formik.setFieldValue("contato", data.contato);
        formik.setFieldValue("mensagem", data.mensagem);

        await getFotosDaLista(data.id);
        await getContatos(data.id);
      })
      .catch((error) => {
        change(false);
      });
  };

  const getFotosDaLista = async (listaid) => {
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let fotos = await api.post(
      `listagemFotosListaTransmissao`,
      {
        id: listaid,
      },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      }
    );

    let fotosGaleria = [];

    for await (let foto of fotos.data) {
      fotosGaleria.push(foto.url);
    }

    setfotosDaLista(fotos.data);
  };

  const getContatos = async (listaId) => {
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    setloadingDataGridContatos(true);
    let response = await api.post(
      "listagemContatosListaTransmissao",
      {
        listaId: listaId,
      },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      }
    );

    setcontatos(response.data);
    setloadingDataGridContatos(false);
  };

  useEffect(async () => {
    settabPage("1");
    formik.resetForm();

    if(visible){
      await detalhesLista();
      await permissoesUsuario();
    }
  }, [visible]);

  let handleFotoLista = async (e) => {
    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let imagens = e.target.files;

    let formData = new FormData();
    formData.append("id", detalheLista.id);

    if (imagens.length > 7) {
      Swal.fire(
        "Ops, Limite por vez atingido!",
        "Você só pode enviar 3 imagens.",
        "warning"
      );
    } else {
      for await (let imagem of imagens) {
        if (
          imagem.type === "image/png" ||
          imagem.type === "image/gif" ||
          imagem.type === "image/jpeg"
        ) {
          formData.append("imagens", imagem);
        } else {
          setalertErrorMsg(
            "Foto inválida, Apenas os formatos .png, .jpg e .jpeg são permitidos!"
          );
          setalertError(true);
          setTimeout(() => {
            setalertError(false);
          }, 6000);
        }
      }
      let response = await api.put("uploadFotosListaTransmissao", formData, {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${auth.token}`,
        },
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
          setprogressUploadFotos(progress);
        },
      });

      if (response.data.error_code === "MAXIMUM_FILES") {
        setalertErrorMsg(response.data.message);
        setalertError(true);
        setTimeout(() => {
          setalertError(false);
        }, 6000);
      }
      // await new Promise(r => setTimeout(r, imagens.length * 150));
      setprogressUploadFotos(0);
      getFotosDaLista(detalheLista.id);
    }
  };

  const handleChangeTabPage = (event, newValue) => {
    settabPage(newValue);
  };

  const handleDragFoto = async (result) => {
    if (permissoesdoUsuario[3]?.update) {
      const items = Array.from(fotosDaLista);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      let dadosArray = [];

      for await (let [key, item] of Object.entries(items)) {
        let dados = {
          id: item.id,
          ordem: parseInt(key),
        };

        dadosArray.push(dados);
      }

      setfotosDaLista(items);

      let auth = localStorage.getItem("auth");
      auth = JSON.parse(auth);

      await api.put(
        `updateOrdemFotosListaTransmissao`,
        {
          listaId: detalheLista.id,
          dados: dadosArray,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
    } else {
      setalertErrorMsg(
        "Você não possui autorização para modificar a ordem das fotos."
      );
      setalertError(true);
      setTimeout(() => {
        setalertError(false);
      }, 6000);
    }
  };

  const handleDeleteFoto = async (idFoto) => {
    Swal.fire({
      title: "Tem certeza?",
      text: "Deseja deletar a Foto selecionada?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0554C2",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim deletar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);
        await api.delete("deleteFotoListaTransmissao", {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
          data: { id: idFoto, listaId: detalheLista.id },
        });
        await getFotosDaLista(detalheLista.id);
        Swal.fire("Deletado!", "Foto deletada com sucesso!", "success");
      }
    });
  };

  const columnsDataGridContatos = [
    { field: "id", hide: true, headerName: "ID", width: 90 },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => {
        return params.value;
      },
    },
    {
      field: "numero",
      headerName: "Número",
      flex: 1,
      minWidth: 150,
      editable: false,
      valueFormatter: (params) => {
        return mtel(params.value);
      },
    },
    {
      field: "enviado",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (params) => {
        if (params.value === 1) {
          return <span className='listStatus concluido'>Enviado</span>;
        }

        return <span className='listStatus pausado'>Aguardando Envio</span>;
      },
    },
  ];

  function nenhumContatoEncontrado() {
    return (
      <GridOverlay>
        <div className="container-no-data-grid">
          <IconNoContact />
          <p>Nenhum Contato Encontrado.</p>
        </div>
      </GridOverlay>
    );
  }

  const detalhesContato = async (data) => {
    setdadosdetalhesContato(data);
    setshowModalEditContato(true);
  };

  const handleDeleteContato = async () => {
    Swal.fire({
      title: "Tem certeza?",
      text: "Deseja deletar o(s) Contato(s) selecionado(s)?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0554C2",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, deletar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let auth = localStorage.getItem("auth");
        auth = JSON.parse(auth);

        setloaderPage(true);

        let contatosid = [];
        for await (let id of contatosSelecionados) {
          let dados = { id: id };
          contatosid.push(dados);
        }
        await api.delete("deleteContatosListaTransmissao", {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${auth.token}`,
          },
          data: { contatosid: contatosid },
        });

        await getContatos(detalheLista.id);
        setloaderPage(false);

        Swal.fire(
          "Deletado!",
          "Contato(s) deletado(s) com sucesso!",
          "success"
        );
      }
    });
  };

  let cadastroImportContatos = async (contatos) => {
    //GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    await api.post(
      "addMultiplosContatosListaTransmissao",
      {
        idLista: detalheLista?.id,
        contatos: contatos,
      },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
      }
    );

    getContatos(detalheLista?.id);

    setalertSucess(true);
    setalertSucessMsg(`Contatos Importados com sucesso!`);
    setTimeout(() => {
      setalertSucess(false);
    }, 6000);
  };

  let handleImportContatos = async (e) => {
    // GET DADOS USER
    let auth = localStorage.getItem("auth");
    auth = JSON.parse(auth);

    let arquivo = e.target.files[0];

    if (!arquivo) {
      Swal.fire(
        "Nenhum arquivo selecionado",
        "Por favor, selecione um arquivo.",
        "warning"
      );
      return;
    }

    if (
      arquivo.type !== "text/csv" &&
      arquivo.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      arquivo.type !== "application/vnd.ms-excel"
    ) {
      Swal.fire(
        "Arquivo inválido",
        "Apenas os formatos .csv, .xlsx e .xls são permitidos!",
        "error"
      );
      return;
    }

    setloaderPage(true);

    const padronizarDados = (dados) => {
      return dados.map((item) => ({
        nome: item.nome || item.Nome || item.name || item.Name || item.Contato || item.contato || "",
        telefone:
          item.telefone ||
          item.Telefone ||
          item.phone ||
          item.tel ||
          item.Tel ||
          item.Phone ||
          item.numero ||
          item.Numero ||
          "",
      }));
    };

    if (arquivo.type === "text/csv") {
      Papa.parse(arquivo, {
        complete: async (results) => {
          const dadosPadronizados = padronizarDados(results.data);
          await cadastroImportContatos(dadosPadronizados);
          setloaderPage(false);
        },
        header: true,
        delimiter: ";",
      });
    } else {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        const dadosPadronizados = padronizarDados(json);
        await cadastroImportContatos(dadosPadronizados);
        setloaderPage(false);
      };
      reader.readAsArrayBuffer(arquivo);
    }
  };

  const handleSelectAlcance = (event) => {
    const selectedValue = event.target.value;
    formik.handleChange(event);

    if ([3, 4, 6].includes(selectedValue)) {
      setShowDaysInput(true);
    } else {
      setShowDaysInput(false);
    }
  };

  const clearSelect = () => {
    formik.setFieldValue('alcance', null);
    setShowDaysInput(false);
  };

  return (
    <>
      <ModalNovoContato
        idLista={detalheLista?.id}
        visible={showModalNovoContato}
        change={setshowModalNovoContato}
        getContatos={getContatos}
      />
      <ModalEditContato
        idLista={detalheLista?.id}
        detalhes={dadosdetalhesContato}
        visible={showModalEditContato}
        change={setshowModalEditContato}
        getContatos={getContatos}
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: "2000" }}
        open={loaderPage}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={alertError}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseAlertError}
      >
        <Alert
          onClose={handleCloseAlertError}
          severity="error"
          sx={{ width: "100%", marginTop: "-150px" }}
        >
          {alertErrorMsg}
        </Alert>
      </Snackbar>

      <Snackbar
        open={alertSucess}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseAlertSuccess}
      >
        <Alert
          onClose={handleCloseAlertSuccess}
          severity="success"
          sx={{ width: "100%", marginTop: "-150px" }}
        >
          {alertSucessMsg}
        </Alert>
      </Snackbar>

      <Dialog
        open={visible}
        maxWidth="md"
        fullWidth={true}
        onClose={() => {
          change(false);
        }}
      >
        <form method="post" onSubmit={formik.handleSubmit}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            Editar Lista de transmissão
            <IconButton
              aria-label="close"
              onClick={() => {
                change(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText></DialogContentText>

            <Grid container spacing={1}>
              <TabContext value={tabPage}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    width: "100%",
                  }}
                >
                  <Tabs
                    value={tabPage}
                    onChange={handleChangeTabPage}
                    aria-label="estoque-tabs"
                    textColor="primary"
                    indicatorColor="primary"
                    variant="scrollable"
                  >
                    <Tab label="Detalhes" value="1" />
                    <Tab label="Fotos" value="2" />
                    <Tab label="Contatos" value="3" />
                  </Tabs>
                </Box>

                <TabPanel value="1" style={{ width: "100%" }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id="nome"
                        name="nome"
                        label="Nome da lista"
                        margin="normal"
                        type="text"
                        value={formik.values.nome}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.nome && Boolean(formik.errors.nome)
                        }
                        helperText={formik.touched.nome && formik.errors.nome}
                      />
                    </Grid>

                    <Grid item xs={12} md={3.5}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={ptLocale}
                      >
                        <DesktopDatePicker
                          label="Data do Disparo"
                          inputFormat="dd/MM/yyyy"
                          name="datadisparo"
                          value={formik.values.datadisparo}
                          onChange={(data) => {
                            formik.setFieldValue("datadisparo", data);
                          }}
                          renderInput={(params) => (
                            <TextField
                              style={{
                                width: "100%",
                                marginTop: "16px",
                                backgroundColor: "#fcfcfc",
                              }}
                              {...params}
                              error={
                                formik.touched.datadisparo &&
                                Boolean(formik.errors.datadisparo)
                              }
                            />
                          )}
                        />
                        <FormHelperText error>
                          {formik.touched.datadisparo &&
                            formik.errors.datadisparo}
                        </FormHelperText>
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} md={2.5}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={ptLocale}
                      >
                        <TimePicker
                          label="Hora do Disparo"
                          value={formik.values.horadisparo}
                          onChange={(hora) => {
                            formik.setFieldValue("horadisparo", hora);
                          }}
                          renderInput={(params) => (
                            <TextField
                              style={{
                                width: "100%",
                                marginTop: "16px",
                                backgroundColor: "#fcfcfc",
                              }}
                              {...params}
                              error={
                                formik.touched.horadisparo &&
                                Boolean(formik.errors.horadisparo)
                              }
                            />
                          )}
                        />
                        <FormHelperText error>
                          {formik.touched.horadisparo &&
                            formik.errors.horadisparo}
                        </FormHelperText>
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} md={showDaysInput ? 8 : 12}>
                      <FormControl sx={{ m: 0, width: '100%' }} >
                        <InputLabel id="label-tipo">Alcance</InputLabel>
                        <Select
                          id="alcance-select"
                          name="alcance"
                          label="Alcance"
                          placeholder='Alcance'
                          value={formik.values.alcance}
                          onChange={handleSelectAlcance}
                          error={formik.touched.alcance && Boolean(formik.errors.alcance)}
                          endAdornment={
                            formik.values.alcance ? (
                              <IconButton
                                aria-label="clear"
                                onClick={clearSelect}
                                edge="end"
                                size="small"
                                sx={{ mr: 1 }}
                              >
                                <ClearIcon />
                              </IconButton>
                            ) : null
                          }
                        >
                          <MenuItem value={1}>Todos os Clientes</MenuItem>
                          <MenuItem value={2}>Todos os Fornecedores</MenuItem>
                          <MenuItem value={3}>Clientes que Compraram nos Últimos X Dias</MenuItem>
                          <MenuItem value={4}>Fornecedores que Venderam nos Últimos X Dias</MenuItem>
                          <MenuItem value={5}>Todos os Clientes e Fornecedores</MenuItem>
                          <MenuItem value={6}>Clientes e Fornecedores que Negociaram nos Últimos X Dias</MenuItem>
                        </Select>
                        <FormHelperText error>{formik.touched.alcance && formik.errors.alcance}</FormHelperText>
                      </FormControl>
                    </Grid>
                    
                    {showDaysInput &&
                      <Grid item xs={12} md={4} >
                        <TextField
                          className='input'
                          fullWidth
                          name="ultimosdias"
                          label="Últimos Dias"
                          margin="none"
                          type="text"
                          value={formik.values.ultimosdias}
                          onChange={formik.handleChange}
                          error={formik.touched.ultimosdias && Boolean(formik.errors.ultimosdias)}
                          helperText={formik.touched.ultimosdias && formik.errors.ultimosdias}
                          onKeyUp={()=>{ formik.setFieldValue('ultimosdias', formik.values.ultimosdias && mnumero(formik.values.ultimosdias)) }}
                          inputProps={{
                            maxLength: 3,
                          }}
                        />
                      </Grid>
                    }

                    <Grid item xs={12} md={12}>
                      <TextField
                        inputRef={inputNumero}
                        fullWidth
                        id="contato"
                        name="contato"
                        label="Contato (Envie um número de WhatsApp como contato)"
                        margin="normal"
                        type="text"
                        value={formik.values.contato}
                        onChange={formik.handleChange}
                        error={formik.touched.contato && Boolean(formik.errors.contato)}
                        helperText={formik.touched.contato && formik.errors.contato}
                        onKeyUp={()=>{ formik.setFieldValue('contato', mtel(formik.values.contato)) }}
                        inputProps={{
                          maxLength: 15,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <TextField
                        fullWidth
                        id="mensagem"
                        name="mensagem"
                        label="Mensagem"
                        margin="normal"
                        type="text"
                        multiline
                        rows={10}
                        value={formik.values.mensagem}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.mensagem &&
                          Boolean(formik.errors.mensagem)
                        }
                        helperText={
                          formik.touched.mensagem && formik.errors.mensagem
                        }
                      />
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value="2" style={{ width: "100%" }}>
                  <Grid container item spacing={2}>
                    <div
                      className="box-content"
                      style={{ width: "100%", marginLeft: "22px" }}
                    >
                      {detalheLista.length === 0 ? (
                        <Grid
                          container
                          spacing={2}
                          item
                          xs={12}
                          md={12}
                          style={{ marginTop: "10px" }}
                        >
                          <Grid item xs={12} md={1.5}>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              width="100%"
                              height={140}
                              style={{ borderRadius: "5px" }}
                            />
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              width="100%"
                              height={140}
                              style={{ borderRadius: "5px" }}
                            />
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              width="100%"
                              height={140}
                              style={{ borderRadius: "5px" }}
                            />
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              width="100%"
                              height={140}
                              style={{ borderRadius: "5px" }}
                            />
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              width="100%"
                              height={140}
                              style={{ borderRadius: "5px" }}
                            />
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              width="100%"
                              height={140}
                              style={{ borderRadius: "5px" }}
                            />
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              width="100%"
                              height={140}
                              style={{ borderRadius: "5px" }}
                            />
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              width="100%"
                              height={140}
                              style={{ borderRadius: "5px" }}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <>
                          <div className="box-fotos-lista-transmissao">
                            <Fancybox>
                              <DragDropContext onDragEnd={handleDragFoto}>
                                <Droppable
                                  droppableId="droppable"
                                  direction="horizontal"
                                >
                                  {(provided) => (
                                    <aside
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      id={detalheLista.id}
                                    >
                                      {fotosDaLista.map((v, k) => {
                                        return (
                                          <Draggable
                                            key={v.id}
                                            draggableId={v.id.toString()}
                                            index={k}
                                          >
                                            {(provided) => (
                                              <div
                                                className="box-element-foto"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <div
                                                  className="element-foto"
                                                  style={{
                                                    backgroundImage: `url(${v.url})`,
                                                  }}
                                                >
                                                  {permissoesdoUsuario[3]
                                                    ?.update && (
                                                    <div className="box-deleteFotoDProduto">
                                                      <button
                                                        type="button"
                                                        onClick={() => {
                                                          handleDeleteFoto(
                                                            v.id
                                                          );
                                                        }}
                                                        className="deleteFotoDProduto"
                                                      >
                                                        <IconCloseTimes />
                                                      </button>
                                                    </div>
                                                  )}

                                                  <div className="box-abrirFotoDProduto">
                                                    <button
                                                      type="button"
                                                      data-fancybox="gallery"
                                                      href={v.url}
                                                    >
                                                      <IconZoom />
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </Draggable>
                                        );
                                      })}
                                      {provided.placeholder}
                                    </aside>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </Fancybox>

                            {permissoesdoUsuario[2]?.insert && (
                              <div className="box-button-upload">
                                <div style={{ width: "220px" }}>
                                  {progressUploadFotos === 0 && (
                                    <label htmlFor="button-file-fotos-produto">
                                      <Input
                                        accept="image/png, image/gif, image/jpeg"
                                        id="button-file-fotos-produto"
                                        multiple
                                        onChange={handleFotoLista}
                                        type="file"
                                      />
                                      <ButtonDefault
                                        type="button"
                                        icon={<IconUpload />}
                                        component="span"
                                        txt="Adicionar fotos"
                                      />
                                    </label>
                                  )}

                                  {progressUploadFotos > 0 && (
                                    <LinearProgress
                                      variant="determinate"
                                      value={progressUploadFotos}
                                    />
                                  )}
                                </div>
                                <p>
                                  Formatos aceitos: .JPG, .JPEG, .PNG | Tamanho
                                  máximo: 5MB
                                </p>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </Grid>
                </TabPanel>

                <TabPanel value="3" style={{ width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: "10px",
                      }}
                    >
                      {permissoesdoUsuario[1]?.delete && (
                        <>
                          {contatosSelecionados.length > 0 && (
                            <div style={{ marginRight: "10px" }}>
                              <ButtonCircleDanger
                                icon={<IconLixeira />}
                                onClick={() => {
                                  handleDeleteContato();
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}

                      {permissoesdoUsuario[2]?.insert && (
                        <>
                          <ButtonCirclePrimary
                            icon={<IconPlusC />}
                            onClick={() => {
                              setshowModalNovoContato(true);
                            }}
                          />
                        </>
                      )}
                    </div>
                    <DataGrid
                      sx={{ minHeight: "300px" }}
                      localeText={
                        ptBR.components.MuiDataGrid.defaultProps.localeText
                      }
                      autoHeight
                      rows={contatos}
                      columns={columnsDataGridContatos}
                      pageSize={pageSizeDataGridContatos}
                      onPageSizeChange={(newPageSize) =>
                        setpageSizeDataGridContatos(newPageSize)
                      }
                      rowsPerPageOptions={[10, 50, 100]}
                      checkboxSelection={permissoesdoUsuario[3]?.update}
                      disableSelectionOnClick
                      editMode="row"
                      loading={loadingDataGridContatos}
                      disableColumnMenu={true}
                      onSelectionModelChange={(props) => {
                        setcontatosSelecionados(props);
                      }}
                      onCellClick={(props) => {
                        if (props.field !== "__check__") {
                          detalhesContato(props.row);
                        }
                      }}
                      components={{
                        NoRowsOverlay: nenhumContatoEncontrado,
                      }}
                    />
                  </div>
                </TabPanel>
              </TabContext>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              style={{ paddingInline: 15 }}
            >
              {tabPage === "3" && (
                <Grid container item spacing={1.5} xs={4} md={6}>
                  <Grid item xs={3} md={2}>
                    <label htmlFor="button-import-contatos">
                      <Input
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        id="button-import-contatos"
                        onChange={handleImportContatos}
                        type="file"
                      />
                      <ButtonSecondaryOnlyIcon
                        type="button"
                        component="span"
                        icon={<IconUploadFile />}
                      />
                    </label>
                  </Grid>
                </Grid>
              )}

              <Grid item xs={4} md={3}>
                <ButtonOutlineSecondary
                  type="button"
                  onClick={() => {
                    change(false);
                  }}
                  icon=""
                  txt="Cancelar"
                />
              </Grid>

              <Grid item xs={4} md={3}>
                <ButtonDefault type="submit" icon="" txt="Salvar" />
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
